
const API_URL = process.env.REACT_APP_API_URL;


export const URL = {
  login: `${API_URL}login`,
  logout: `${API_URL}logout`,
  token_refresh: `${API_URL}token_refresh`,
  resetPassword: `${API_URL}reset-password`,
  changePassword: `${API_URL}change-password`,
  forgotPassword: `${API_URL}forgot-password`,
  updatePassword: `${API_URL}update-password`,
  onBoardingUser: `${API_URL}users`,
  listServices: `${API_URL}services`,
  listCapabilities: `${API_URL}capabilities`,
  getUploadUrl: `${API_URL}signed-urls`,
  // getQueue: `${API_URL}queued-documents`,
  documents: `${API_URL}documents`,
  directory: `${API_URL}directory`,
  modifyTable: `${API_URL}modify-table`,
  updateMultipleTables: `${API_URL}update-multiple-tables`,
  taskResult: `${API_URL}task-result`,
  resultDate: `${API_URL}result-date`,
  aggTable: `${API_URL}document-tables`,
  generateTask: `${API_URL}generate-task`,

  organizations: `${API_URL}organizations`,
  auth: `${API_URL}auth/`,
  users: `${API_URL}users`,
  groups: `${API_URL}groups`,
  getRoles: `${API_URL}roles`,
  getApiData: `${API_URL}apis`,
  getSwaggerJson: `${API_URL}api-documentation`,
  getFeedbackUrl: `${API_URL}documents`,
  getResultTypes: `${API_URL}result-types`,
  getTrashUrl: `${API_URL}trash`,
  getFileUrl: `${API_URL}fetch-file`,
  getStatsUrl: `${API_URL}admin-stats/`,

  // Titlepoint endpoints
  searchTitlepoint: `${API_URL}search-titlepoint/`,
  getDocList: `${API_URL}doc-list/`,
  importFiles: `${API_URL}import-files/`,
};
