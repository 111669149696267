import {withStyles} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {PatchRequestV2} from '../../../../common/requests';
import {URL} from '../../../../common/url';

const styles = {
  modal: {
    background: 'white',
    position: 'relative',
    width: '550px',
    top: '10%',
    left: '50%',
    marginLeft: '-275px',
    textAlign: 'left'
  },
  modalTitle: {
    background: 'linear-gradient(70deg, #5159F8, #5EAAFF) !important',
    color: 'white',
    textAlign: 'left',
    padding: '20px'
  },
  modalContent: {
    padding: '20px'
  },
  tenantLogoArea: {
    width: '100%'
  },
  tenantLogo: {
    maxWidth: '100%'
  },
  submit: {
    textAlign: 'right',
    borderTop: '1px solid #E1E3E4',
    paddingTop: '16px'
  },
  label: {
    marginLeft: '10px !important'
  },
  actionButton: {
    marginRight: '10px',
    color: '#d3d3d3',
    fontSize: '16px',
    fontWeight: 'bold'
  },
  prevNext: {
    borderTop: '0'
  }
};

class logoUpload extends Component {

  state = {
    src: null,
    blob: null,
    crop: {
      x: 10,  //Starting x coordinate
      y: 10,  //Starting y coordinate
      width: 80, //Width of the image
      height: 80  //Height of the image
      // aspect: 16 / 9, //Use if you want to keep a particular aspect ratio for the image
    }
  };

  controller = new window.AbortController();

  closeLogoUploadModal = (value) => {
    this.props.logoModalOpen(value);
  };

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () =>
          this.setState({
            src: reader.result
          }),
        false
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  onImageLoaded = image => {
    this.imageRef = image;
    console.log('onCropComplete', image);
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
    console.log('onCropComplete', crop);
  };

  onCropChange = crop => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({crop});
  };

  getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        this.setState({blob})
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState({croppedImageUrl});
    }
  }

  uploadLogo = () => {
    const payload = {
      logo: this.state.croppedImageUrl,
    };
    PatchRequestV2(
      URL.organizations + '/' + this.props.org.id + "/", 
      this.controller.signal,
      payload,(err)=> {console.log(err)});
  };

  // uploadLogo = () => {
  //   const {blob} = this.state
  //   if (blob !== null) {
  //     const file = Utils.blobToFile(this.state.blob)
  //
  //     const payload = new FormData()
  //     payload.append("logo", file)
  //
  //     const headers = {
  //       "Authorization": `Bearer ${getToken()}`,
  //       "source": `WebApp`,
  //       "refresh_token": getRefreshToken(),
  //       "user_name": getUserName(),
  //       "Content-Type": "multipart/form-data",
  //     };
  //
  //     return fetch(URL.organizations + '/' + this.props.org.id + '/', {
  //       method: 'POST',
  //       headers,
  //       body: JSON.stringify(payload),
  //       retryOn: [503],
  //       retries: 2,
  //       signal: this.controller.signal,
  //     }).then(response => response.json()
  //       .then(json => response.ok ? json : Promise.reject(json))).then((data) => {
  //     }).catch((err) => {
  //       if (err.name === 'AbortError') {
  //         return;
  //       }
  //       if (err.hasOwnProperty("code") && err.code === "token_not_valid") {
  //         token_refresh()
  //       }
  //       else {
  //         toast.error("Failed to upload logo.")
  //         console.log(err);
  //       }
  //     });
  //   }
  // };

  render() {
    const {src, crop, croppedImageUrl} = this.state;
    const {classes} = this.props;

    return (
      <div className={classes.modal}>
        <div className={classes.modalTitle}>
          <Typography variant="h6">
            Upload Logo
          </Typography>
        </div>
        <div className={classes.modalContent}>
          <div className="tenantLogoArea">
            <div>
              <input type="file" onChange={this.onSelectFile} />
            </div>
            {src && (
              <ReactCrop
                src={src}
                crop={crop}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            )}
            {croppedImageUrl && (
              <div>
                <h4>Preview Cropped Image</h4>
                <img alt="Crop" style={{maxWidth: '100%'}} src={croppedImageUrl} />
              </div>
            )}
          </div>
          <div className="prevNext">
            <Button
              className={classes.button}
              onClick={() => this.closeLogoUploadModal(false)}
            >
              CANCEL
            </Button>

            <Button
              variant="raised"
              color="primary"
              className={classes.button}
              onClick={() => this.uploadLogo()}
            >
              CROP & SAVE
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withStyles(styles)(logoUpload));
