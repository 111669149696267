import {withStyles} from '@material-ui/core';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import * as PropTypes from 'prop-types';
import React, {Component} from 'react';
import {GetRequest, PostRequest} from '../../../../common/requests';
import {URL} from '../../../../common/url';
import { withTranslation } from 'react-i18next';
import Chip from '@material-ui/core/Chip';
import './custom.scss';

const styles = {
  modal: {
    background: 'white',
    position: 'relative',
    width: '550px',
    top: '20%',
    left: '35%',
    textAlign: 'left'
  },
  outlinedFilter: {
    padding: '1rem 0'
  },
  modalTitle: {
    background: 'linear-gradient(70deg, #5159F8, #5EAAFF)',
    color: 'white',
    textAlign: 'left',
    padding: '20px'
  },
  modalContent: {
    padding: '20px'
  },
  formInput: {
    margin: '10px',
    width: '95%'
  },
  selectCapabilities: {
    borderRadius: '10px',
    padding: '0 10px 10px !important',
    border: '1px solid #E1E3E4'
  },
  submit: {
    textAlign: 'right',
    borderTop: '1px solid #E1E3E4',
    paddingTop: '16px'
  },
  label: {
    marginLeft: '10px !important'
  },
  actionButton: {
    marginRight: '10px',
    color: '#d3d3d3',
    fontSize: '16px',
    fontWeight: 'bold'
  }
};


class StepFour extends Component {
  static propTypes = {
    // modalOpen: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
  };

  state = {
    capabilities: [],
    selectedCapabilities: [],
    submit: false,
    activeStep: 0,
    skipped: new Set()
  };

  controller = new window.AbortController();

  componentDidMount() {
    this.getCapabilities();
  }

  // getCapabilities = () => {
  //   GetRequest(URL.listCapabilities, (data) => {
  //   const capabilities = data.payload || [];
  //     this.setState({capabilities, selectedCapabilities: [capabilities[0]]});
  //     console.log (data.payload);
  //   }, (err) => {
  //     console.log(err);
  //   });
  // };

  getCapabilities = () => {
    const { services } = this.props;

    for (let service of services) {
      const params = {
        service: service.service,
      };
      let esc = encodeURIComponent;
      let query = Object.keys(params)
        .map(k => esc(k) + '=' + esc(params[k]))
        .join('&');
      GetRequest(URL.listCapabilities + '?' + query, (data) => {
        let {capabilities} = this.state
        if (data.payload && data.payload.length > 0) {
          let fetchedCapabilities = []
          for (let capability of data.payload) {
            capability["service"] = service.service
            fetchedCapabilities.push(capability)
          }
          capabilities = [...capabilities, ...fetchedCapabilities]

          this.setState(prevState => ({
            ...prevState,
            capabilities,
          }))

          this.props.setCapList(capabilities)
        }
      }, (err) => {
        console.log(err)
      });
    }
  };

  handleChange = (event) => {
    const {name, value} = event.target;
    this.setState(prevState => ({
      ...prevState,
      [name]: value
    }), () => this.checkValidations());
  };


  handleChangeCapability = (event) => {
    const { value } = event.target;
    this.setState(prevState => ({ ...prevState, selectedCapabilities: value }));
    this.props.setCapabilities(event)
  };

  handleGroup = (event) => {
    const {value} = event.target;
    this.setState(prevState => ({...prevState, selectedCapabilities: value}));
  };

  handleAdd = () => {
    const {t, modalOpen} = this.props;
    const {selectedGroups} = this.state;
    const payload = {
      groups: selectedGroups,
      //role: status ? 'Admin' : 'User',
      status: t('super-admin.create-org-modal.act')
    };

    this.setState({submit: true}, () => {
      if (this.checkValidations()) {
        PostRequest(URL.onBoardingUser, this.controller.signal, payload, () => {
          modalOpen(false, true);
        }, (err) => {
          console.log(err);
        });
      }
    });
  };

  render() {
    const {t, classes} = this.props;
    const {selectedCapabilities, capabilities} = this.state;

    return (
      <div className="stepFour">
        <Typography variant="subtitle1">
          <b>{t('super-admin.create-org-modal.step')} 4</b> {t('super-admin.create-org-modal.sel-cap')}
        </Typography>

        <Typography variant="subtitle2" style={{marginTop: '20px'}}>
          <b>{t('super-admin.create-org-modal.sel-cap')}</b>
        </Typography>

        <Select
            multiple
            value={selectedCapabilities}
            variant='outlined'
            label={t('super-admin.create-org-modal.sel-cap')}
            onChange={this.handleChangeCapability}
            input={<Input id="select-multiple" />}
            className='selectCapabilities'
            inputProps={{ id: 'fieldId' }}
            style={{width : '100%', textOverflow: 'ellipsis'}}
            renderValue={selected => (
            <div className={classes.chips}>
              {selected.map(value => (
                <Chip key={value} label={value} className={classes.chip} />
              ))}
            </div>
          )}
          >
            {capabilities.length > 0 && capabilities.map(cap => (
              <MenuItem
                key={cap.capability}
                value={cap.capability}
                style={{width : '100%', textOverflow: 'ellipsis'}}>
                {cap.label}
              </MenuItem>
            ))}
        </Select>
      </div>
    );
  }
}

export default withTranslation()(withStyles(styles)(StepFour));
