import fetch from 'fetch-retry';
import {
  getRefreshToken,
  getToken,
  getUserName,
  token_refresh
} from './loginService';

const getHeaders = () => {
  const headers = {};
  headers.Authorization = `Bearer ${getToken()}`;
  headers["source"] = `WebApp`;
  headers["refresh_token"] = getRefreshToken();
  headers["Content-Type"] = "application/json";
  headers["user_name"] = getUserName();
  return headers;
};

export const getPutHeaders = () => {
  const headers = {};
  headers["Content-Type"] = "application/octet-stream";
  headers["x-ms-blob-type"] = "BlockBlob"; // for azure signed urls
  headers.Authorization = `Bearer ${getToken()}`;
  headers["source"] = `WebApp`;
  headers["refresh_token"] = getRefreshToken();

  return headers;
};

const getGetHeaders = () => {
  const headers = {};
  headers["Access-Control-Allow-Origin"] = "*";
  return headers;
};

export const PostRequest = (url, signal, payload, successCallback, errorCallback, headers=null) => {
  return fetch(url, {
    method: 'POST',
    headers: headers || getHeaders(),
    body: JSON.stringify(payload),
    retryOn: [503],
    retries: 2,
    signal: signal,
  }).then(response => response.json()
    .then(json => (response.ok ? json : Promise.reject(json)))).then((data) => {
      // if(data.statusCode === 401){
      //   logout();
      // }
    successCallback(data);
  }).catch((err) => {
    if (err.name === 'AbortError') {
      return;
    }
    else if (err.hasOwnProperty("code") && err.code === "token_not_valid") {
      token_refresh()
    }
    else
      errorCallback(err);
  });
};


export const GetRequest = (url, successCallback, errorCallback) => {
  fetch(url, {
    method: 'GET',
    headers: getHeaders(),
  }).then(response => response.json()
    .then(json => (response.ok ? json : Promise.reject(json)))).then((data) => {
    // if(data.statusCode === 401){
    //   logout();
    // }
    successCallback(data);
  }).catch((err) => {
    if (err.hasOwnProperty("code") && err.code === "token_not_valid") {
      token_refresh()
    }
    else {
      console.error(err)
      // errorCallback(err); 
    }
  });
};

export const GetDocument = (url, successCallback, errorCallback) => {
  fetch(url, {
    method: 'GET',
    headers: getGetHeaders(),
  }).then(response => response.json()
    .then(json => (response.ok ? json : Promise.reject(json)))).then((data) => {
    // if(data.statusCode === 401){
    //   logout();
    // }
    successCallback(data);
  }).catch((err) => {
    if (err.hasOwnProperty("code") && err.code === "token_not_valid") {
      token_refresh()
    }
    else
      errorCallback(err);
  });
};

export const GetRequestNew = (url, successCallback, errorCallback) => {
  fetch(url, {
    method: 'GET',
    headers: getGetHeaders(),
    mode: "cors",
  }).then(response => response.blob())
    .then((data) => {successCallback(data);
  }).catch((err) => {
    if (err.hasOwnProperty("code") && err.code === "token_not_valid") {
      token_refresh()
    }
    else
      errorCallback(err);
  });
};


export const PutRequest = (url, signal, payload, successCallback, errorCallback, headers=null) => {
  return fetch(url, {
    method: 'PUT',
    headers: headers || getPutHeaders(),
    body: payload,
    retryOn: [503],
    retries: 2,
    signal: signal,
    mode: "no-cors",
  }).then(response => response)
    .then((data) => {successCallback(data);
  }).catch((err) => {
    if (err.name === 'AbortError') {
      return;
    } else if (err.hasOwnProperty('code') && err.code === 'token_not_valid') {
      token_refresh()
    } else
      errorCallback(err);

  });
};

export const DeleteRequest = (url, successCallback, errorCallback) => {
  fetch(url, {
    method: 'DELETE',
    headers: getHeaders(),
  }).then(response => (response.ok ? response : Promise.reject(response))).then((data) => {
    // if(data.statusCode === 401){
    //   logout();
    // }
    successCallback(data);
  }).catch((err) => {
    if (err.hasOwnProperty("code") && err.code === "token_not_valid") {
      token_refresh()
    }
    else
      errorCallback(err);
  });
};

export const PatchRequest = (url, signal, payload, successCallback, errorCallback) => {
  return fetch(url, {
    method: 'PATCH',
    headers: getHeaders(),
    body: JSON.stringify(payload),
    retryOn: [503],
    retries: 2,
    signal: signal,
  }).then(response => (response.ok ? response : Promise.reject(response))).then((data) => {
    // if(data.statusCode === 401){
    //   logout();
    // }
    successCallback(data);
  }).catch((err) => {
    if (err.name === 'AbortError') {
      return;
    }
    else if (err.hasOwnProperty("code") && err.code === "token_not_valid") {
      token_refresh()
    }
    else
      errorCallback(err);

  });
};


export const PatchRequestV2 = (url, signal, payload, successCallback, errorCallback) => {
  return fetch(url, {
    method: 'PATCH',
    headers: getHeaders(),
    body: JSON.stringify(payload),
    retryOn: [503],
    retries: 2,
    signal: signal,
  }).then(response => response.json()
    .then(json => response.ok ? json : Promise.reject(json))).then((data) => {
    // if(data.statusCode === 401){
    //   logout();
    // }
    successCallback(data);
  }).catch((err) => {
    if (err.name === 'AbortError') {
      return;
    }
    if (err.hasOwnProperty("code") && err.code === "token_not_valid") {
      token_refresh()
    }
    else
      errorCallback(err);
  });
};

export const UpdateRequest = (url, signal, payload, successCallback, errorCallback) => {
  return fetch(url, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(payload),
    retryOn: [503],
    retries: 2,
    signal: signal,
  }).then(response => response.json()
      .then(json => (response.ok ? json : Promise.reject(json)))).then((data) => {
    // if(data.statusCode === 401){
    //   logout();
    // }
    successCallback(data);
  }).catch((err) => {
    if (err.name === 'AbortError') {
      return;
    }
    if (err.hasOwnProperty("code") && err.code === "token_not_valid") {
      token_refresh()
    }
    else
      errorCallback(err);
  });
};
