import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import _ from 'lodash';
import React, {Fragment} from 'react';

/*
	Row level extracted data component 
	Should allow for:
		searching
		editing
		displaying synthetic data
		collections of collections
		tables?
		enriched data expansion
		summary data for several documents
*/

class Detail extends React.Component {

  editRow = (e, row) => {
    e.stopPropagation();
    const {updateActiveDoc, goToPage, setEditingEnabled} = this.props;
    
    // console.log('editing')
    // jQuery('.highlightRow').removeClass('highlightRow');
    // let activeRow = jQuery('#dataRow_' + row.uuid + '_' + row.index);
    // activeRow.addClass('highlightRow');
    // activeRow.find('.rowValue').addClass('hide');
    // activeRow.find('.rowField').removeClass('hide');
    //
    
    setEditingEnabled(true)
    
    updateActiveDoc(row.uuid, row.regions, row.index);
    
    if (row.regions && row.index) {
      goToPage(row.regions, row.index, row);
    }
  };

  createRow = (row) => {
    // TODO: add classes arg to function args so you can tweak rows more with css
    const {uuid, classes, enabledIndex, confidence, doneEditing} = this.props;
    const {disableEditing, activateRow} = this.props;
    // log("in detail")
    // log(uuid)
    row.uuid = uuid;
    let fragment = <div
      id={'dataRow_' + uuid + '_' + row.index}
      className={`${classes.fileInfoContainer} dataresult`}
      key={(row.index ? row.index : row.id)}
      onClick={(e) => activateRow(e, row)}
    >

      {/* Left Side */}
      <div className={classes.leftInfo}>
        {row.label}
        {(row.hasOwnProperty('value_type') && row.value_type === 'synthetic') ? '**' : ''}
      </div>

      {/* Right Side */}
      <div className={'rightInfo'}>
        {
          <Fragment>
            <div className={'display-inline rowValue'} suppressContentEditableWarning={true} contentEditable={enabledIndex === (row.index ? row.index : row.id)}>{row.value}
              {(row.confidence && confidence) ?
                <span className="confidence">({parseInt(row.confidence * 100)}%)</span> : null}
              <span onClick={(e) => this.editRow(e, row)}><EditIcon className={classes.smallIcon} /></span>
            </div>
            <TextField
              className={'rowField hide'}
              id={'enableEditing' + (row.index ? row.index : row.id)}
              onBlur={(e) => disableEditing(uuid, e.target.value, (row.index ? row.index : row.id), undefined)}
              defaultValue={row.value}
              multiline={true}
            />
          </Fragment>
        }
      </div>
      {doneEditing.indexOf((row.index ? row.index : row.id)) >= 0 ?
        <div className="done-icon"><CheckCircleIcon /></div> : null}
    </div>;
    return fragment;
  };

  render() {
    const {row, classes} = this.props;

    // this array with aggregate all data for this row
    let final_html = [];

    let collection_title = <Fragment key={(row.index ? row.index : row.id)}>
      <Grid>
        <Typography variant="subtitle2" className={`${classes.title} dataresult`}><b>{row.label}</b></Typography>
      </Grid>
    </Fragment>;

    let items = [];
    let complete_row = [];
    if (row.type === 'collection') {
      // use separate markup for top level collection
      final_html.push(collection_title);
      items = row.items;

      // Treat all sub items as normal rows
      _.forEach(items, (item) => {
        _.forEach(item.elements, (row) => {
          final_html.push(this.createRow(row));
        });
      });

    } else if (row.hasOwnProperty('enriched_data')) {
      // wrap enriched data row in markup
      let enriched_summary = <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}>{this.createRow(row)}</ExpansionPanelSummary>;
      let enriched_details = [];

      _.forEach(row.enriched_data, (edata) => {
        enriched_details.push(this.createRow(edata));
      });

      enriched_details = <ExpansionPanelDetails>{enriched_details}</ExpansionPanelDetails>;
      let enriched_row =
        <ExpansionPanel className={`dataresult`}>{[enriched_summary, enriched_details]}</ExpansionPanel>;
      final_html.push(enriched_row);

    } else {
      // this is (probably) a normal row
      items.push({'elements': [row]});
      _.forEach(items, (item) => {
        _.forEach(item.elements, (row) => {
          complete_row = [];
          complete_row.push(this.createRow(row));
        });
      });
      final_html.push(complete_row);

    }
    return final_html;
  }
}

export default Detail;
