import React from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import * as PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import {PostRequest} from "../../../common/requests";
import {URL} from "../../../common/url";
import { withTranslation } from 'react-i18next';


const styles = {
  modal: {
    background: 'white',
    position: 'relative',
    width: '450px',
    top: '20%',
    left: '35%',
    textAlign: 'left',
  },
  modalTitle: {
    background: 'linear-gradient(70deg, #5159F8, #5EAAFF)',
    color: 'white',
    textAlign: 'left',
    padding: '15px',
  },
  modalContent: {
    padding: '20px',
  },
  formInput: {
    margin: '10px',
    width: '95%'
  },
  submit: {
    textAlign: 'right',
    borderTop: '1px solid #E1E3E4',
    paddingTop: '24px'
  },
  actionButton: {
    marginRight: '10px',
    color: '#d3d3d3',
    fontSize: '16px',
    fontWeight: 'bold'
  },
  descriptionText: {
    margin: '40px auto 4px 0px',
    width: '95%',
    color: '#1e2a5a',
    fontSize: '17px',
    fontFamily: '\'Open Sans\', sans-serif !important'
  }
};

class CreateGroup extends React.Component{
  static propTypes = {
    modalOpen: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
  };

  state = {
    name: '',
    description: '',
    submit: false,
    nameError: '',
  };

  controller = new window.AbortController();

  checkValidations() {
    const {t} = this.props;
    if (!this.state.submit) {
      return;
    }
    let valid = true;
    let nameError = "";

    if (!this.state.name || this.state.name.trim() === '') {
      nameError = t('msg.name-empty')
      valid = false;
    }


    this.setState({
      nameError: nameError,
    });
    return valid;
  }


  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(prevState => ({ ...prevState, [name]: value }), () => this.checkValidations());
  };

  handleAdd = () => {
    const { name , description} = this.state;
    const { modalOpen, orgId, getGroups } = this.props;
    const payload = {
      name: name,
      description: description,
    };
    this.setState({submit: true}, ()=> {
      if (this.checkValidations()) {
        PostRequest(URL.groups + "?org_id=" + orgId, this.controller.signal, payload, (data) => {
          modalOpen(false);
          getGroups();
        }, (err) => {
          console.log(err)
        });
      }})
  };

  render() {
    const {t, classes, modalOpen} = this.props;
    const {name, description, nameError} = this.state;
    return(
      <div className={classes.modal} key="inputForm">
        <div className={classes.modalTitle}>
          <Typography variant="h6" >
            {t('admin.create-group.add-grp')}
          </Typography>
        </div>
        <div className={classes.modalContent}>
          <Typography variant="subtitle1" style={{marginBottom:'8px'}}>
            <b>{t('admin.create-group.create-grp')}</b>
          </Typography>
          <form onSubmit={event => {event.preventDefault();}} noValidate>
            <TextField
              name="name"
              id="name"
              label={t('admin.create-group.label')}
              onChange={this.handleChange}
              value={name}
              className="group-name"
              inputProps={{ maxLength: 64 }}
              helperText={nameError}
              error={nameError !== ''}
            />
            <div className={classes.descriptionText}>{t('admin.create-group.desc')}</div>
            <TextField
              name="description"
              id="description"
              onChange={this.handleChange}
              value={description}
              className="description-box"
              style={{marginBottom: '38px'}}
              variant="outlined"
              inputProps={{ maxLength: 100 }}
              helperText={t('admin.create-group.help-txt')}
              multiline
            />
            <div className={classes.submit}>
              <Button className={classes.actionButton} onClick={() => modalOpen(false)}>{t('admin.create-group.cancel')}</Button>
              <Button onClick={this.handleAdd} className="successButton">{t('admin.create-group.create-grp')}</Button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default withTranslation()(withStyles(styles)(CreateGroup));
