import {AutocompleteSelectCellEditor} from 'ag-grid-autocomplete-editor';
import _ from 'lodash';
import {getUserOrg} from '../../../../common/loginService';
import {GetRequest} from '../../../../common/requests';
import {URL} from '../../../../common/url';
import Utils from '../../../../utils';
import selectData from '../tax_classifier';
import TableUtils from '../utils';
import {refreshSearch} from './SearchUtils';

let log = console.info.bind(window.console);

const descColumn = ['description', 'remark', 'tekst'];
const mlColumn = 'Tax_Category';
const rocColumn = 'RoC';
const rocOptions = ['1 yr', '3 yrs', 'Revenue', 'No Claim', 'S14Q', 'Renewal Basis', 'Replacement Basis', 'Tba'];


export const handleTableView = (table, _this) => {


  const {processing, isAggView} = _this.state;
  if (!table) {
    return;
  }
  if (table.regions !== undefined) {
    _this.updateActiveDoc(table.uuid, table.regions, table.index);
    _this.goToPage(table.regions, table.index);
  }
  const editable = (
    (!isAggView && !processing.includes(table['uuid'])) ||
    (isAggView && !processing.length)
  );

  let tableName = table.label;

  let columnDefs = [];
  let rowData = [];
  let colIndexNameMapping = {};

  // Make sure table all columns in table row has `value` set.
  const hRow = table.items.filter(obj => obj.subtype === 'header_row')[0];

  if (hRow !== undefined) {
    // Some table currently are not extracted with headers
    _.forEach(hRow.elements, (cell, idx) => {
      cell['value'] = TableUtils.getTableCellValue(cell, 'Header ' + cell.column_index);
    });
  }


  // Convert table data into ag-grid data format.
  _.forEach(table.items, (item, i) => {
    if (item.subtype === 'header_row') {
      _.forEach(item.elements, (cell, idx) => {
        let dType = 'category';
        const cellValue = cell.value;
        const valueWords = ['number', 'date', 'amount', 'cost', 'acquisition'];
        valueWords.some(v => {
          let words = cellValue.toLowerCase()
            .replace(/[\r\n\t\s\s]+/gm, ' ')
            .split(' ');
          if (words.indexOf(v) >= 0) {
            dType = 'series';
          }
          return true;
        });

        const columnCell = {
          headerName: cellValue,
          field: cellValue,
          sortable: true,
          filter: true,
          editable: editable,
          menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
          resizable: true,
          chartDataType: dType
        };

        // _this changes style when classifying, yet preserves
        // when in the Aggregate table view.
        if (!editable) {
          columnCell['cellStyle'] = {
            fontStyle: 'italic !important',
            fontWeight: '300 !important'
          };
        }

        // Quick attempt to normalize the columns that will be used as desc
        // that will get passed to the category extractor
        let descWords = descColumn;
        descWords.some(v => {
          let words = cellValue.toLowerCase().replace(/[\r\n\t\s\s]+/gm, ' ').split(' ');
          if (words.indexOf(v) >= 0) {
            // columnCell.headerName = "Description";
            // columnCell.field = "Description";
          }
          return true;
        });

        // for compare table
        if (cell.value === 'uuid') {
          columnCell.hide = true;
        }

        if (cell.value === 'Document') {
          columnCell.cellStyle = function (params) {
            return {
              backgroundColor: '#ecf2f4',
              cursor: 'pointer'
            };
          };
        }

        if (cell.value === 'Confidence') {
          columnCell.pinned = 'right';
          columnCell.width = 120;
          columnCell.cellStyle = function (params) {
            if (parseFloat(params.value) >= 0.75) {
              return {backgroundColor: '#0eba0e'};
            }
            if (parseFloat(params.value) >= 0.5) {
              return {backgroundColor: '#81c784'};
            }
            if (parseFloat(params.value) >= 0.3) {
              return {backgroundColor: '#ffb74d'};
            }
            if (parseFloat(params.value) >= 0.0) {
              return {backgroundColor: '#e57373'};
            }
          };
        }

        if (cell.value === rocColumn) {
          columnCell.pinned = 'right';
          columnCell.cellEditorSelector = function (params) {
            return {
              component: 'agRichSelectCellEditor',
              params: {values: rocOptions}
            };
          };
        }

        if (cell.value === mlColumn) {
          columnCell.pinned = 'right';

          columnCell.cellEditor = AutocompleteSelectCellEditor;
          columnCell.cellEditorParams = {
            required: true,
            selectData: selectData,
            placeholder: 'Select a Category'
          };

          columnCell.valueFormatter = (params) => {
            if (params.value) {
              return params.value.label || params.value.value || params.value;
            }
            return '';
          };
        }

        // Display checkbox in first column only.
        if (idx === 0 && editable)
          columnCell['checkboxSelection'] = true;

        // Don't change _this. If you do, normal tables in compare view would also
        // have checkboxes for column at index 2.
        const isCompareTable = tableName.toLowerCase() === 'comparison table';
        if (isCompareTable && idx === 2)
          columnCell['checkboxSelection'] = true;

        columnDefs.push(columnCell);
        colIndexNameMapping[cell.column_index] = columnCell.headerName;
      });
    } else if (item.subtype === 'data_row') {
      let row = {};
      _.forEach(item.elements, (data, index) => {
        row[colIndexNameMapping[data.column_index]] = data.hasOwnProperty('value') ? data.value : '';
      });
      row.row_index = item.row_index;
      row.index = item.index;
      row.category = row.category === 'None' ? '3 yrs' : row.category;
      rowData.push(row);
    }
  });



  _this.setState({
    openModal: true,
    tableRow: table,
    enabledColIndex: null,
    selectedTableIndex: table.index,
    tableData: table,
    columnDefs,
    tableName,
    rowData
  }, () => {
    setTimeout(() => {
      // log('unlocking table')
    }, 500);
  });
};


/**
 * Attempts to set the following items in state
 *
 *  documents -> list of info from each document such as name, id, uuid, path. Does not include info stored in document
 *
 *  doc_list -> list of document uuids in the current ResultViewer
 *
 *  activeId -> The uuid of the active document, in combination view defaults to the first uuid in the doc_list
 *
 *  activeDoc -> The document info of the the document specified by the activeId. Does not contain data in document
 *
 *  resultViewerFiles -> Passed down from props, controlled in FileManagent
 *
 *  selectedFile -> File in resultViewerFiles with the uuid from payload
 *
 * @memberof ResultViewer
 */
export const getDocuments = (_this) => {
  const {id} = _this.state;

  const params = {
    org_id: getUserOrg(),
    trash: false,
    id: id
  };
  const query = Utils.encodeQueryParams(params);

  GetRequest(URL.documents + '?' + query, (data) => {
    let resultViewerFiles = [];
    let selectedFile = null;

    try {
      resultViewerFiles = _this.props.resultViewerFiles;
      selectedFile = resultViewerFiles.filter(obj => obj.id === data.payload[0].id)[0].uuid;
    } catch (e) {}
    let docs = [];
    let doc_list = [];
    _.forEach(data.payload, (doc) => {
      docs[doc.uuid] = doc;
      doc_list.push(doc.uuid);
    });

    _this.setState(
      {
        documents: docs,
        doc_list: doc_list,
        activeId: doc_list[0],
        activeDoc: docs[doc_list[0]],
        resultViewerFiles,
        selectedFile
      }, () => {
        //The refresh search need the names of the documents to proper do what it needs to do
        refreshSearch(_this);
      });
  });
};


export const getResultDate = (init, _this) => {
  const {id} = _this.state;

  const params = {
    org_id: getUserOrg(),
    id: id
  };
  const query = Utils.encodeQueryParams(params);
  GetRequest(URL.resultDate + '?' + query, (data) => {
    try {
      if (init === true) {
        _this.setState({
          resultDate: data.result_date,
          newResultDate: data.result_date
        });
      } else {
        _this.setState({
          newResultDate: data.result_date
        });
      }
    } catch (e) {}
  }, (err) => {
    log('not found');
    log(err);
  });
};


export const getTaskResults = (uuid = null, _this) => {
  // accept id if looking for specific doc or use url param
  const {id, docData, isAggView, tableData, selectedTableIndex} = _this.state;
  let idList = '';
  let singleDoc = false;

  if (uuid === null) {
    idList = id;
  } else {
    idList = uuid;
    singleDoc = true;
  }
  const params = {
    'id': idList
  };
  const query = Utils.encodeQueryParams(params);
  GetRequest(URL.taskResult + '?' + query, (data) => {
    if (singleDoc) {
      docData[uuid] = data.payload[uuid];
      const table = docData[uuid].data.extracted_data.filter(
        obj => obj.index === selectedTableIndex
      )[0];
      table['uuid'] = uuid;
      // Only update the table if the uuid, matches the current,
      // *open* table's uuid
      if (!isAggView && uuid === tableData['uuid']) {
        handleTableView(table, _this);
      }
      _this.setState({
        docData: docData,
        loadingLeft: false
      }, () => {
        log('///SINGLE///');
        // _this.generateCsvData()
        getResultTypes(_this);
        refreshSearch(_this);
      });

    } else {
      _this.setState({
        docData: data.payload,
        loadingLeft: false
      }, () => {
        log('//////');
        // _this.generateCsvData()
        getResultTypes(_this);
        refreshSearch(_this);
      });
    }


    // TODO flatten the extracted data for non tabular data so it works for search
    // _this.state.search.addDocuments(data.payload.data.extracted_data);
  }, (error) => {
    _this.setState({loadingLeft: false});
  });
};


export const getAggTable = (_this) => {
  /**
   * Attempts to get the aggregate table and set it in the state.
   *
   * _this function is called once during componentDidMount and once again
   * for each table update made by the user (to refresh the aggregate table
   * data).
   *
   * Additionally, it is called once classification of an aggregate table
   * is completed IFF the table is open, represented by isAggView (app state).
   *
   * @memberof ResultViewer
   */
  const {id, capabilities, isAggView} = _this.state;
  let tmp = id.split(',');
  if (tmp.length < 2) {
    return true;
  }
  if (capabilities.indexOf('tax_classifier') < 0) {
    return true;
  }
  const params = {
    'id': id
  };
  const query = Utils.encodeQueryParams(params);
  GetRequest(URL.aggTable + '?' + query, (data) => {
    log(data);
    if (isAggView) {
      data.payload['label'] = 'Comparison Table';
      handleTableView(data.payload, _this);
    }
    _this.setState({
      aggTable: data.payload
    });
  }, (error) => {
    log('loading error');
    _this.setState({loadingLeft: false});
  });
};


export const renderDocFromBlob = (_this) => {
  let {id, activeId, activeRegions, documentBlobs} = _this.state;

  // this is a one time catch that allows us to load the first doc quicker
  // so we don't have to wait for getDocuments to resolve
  if (activeId === null) {
    activeId = id.split(',')[0];
  }

  if (documentBlobs[activeId] === undefined) {
    GetRequest(URL.getFileUrl + '/' + activeId, (data) => {

      let bytes = Utils.base64ToArrayBuffer(data.payload.file_obj);
      documentBlobs[activeId] = new Blob([bytes], {type: 'application/pdf'});
      _this.setState({
        fileURL: window.URL.createObjectURL(documentBlobs[activeId]),
        loadingRight: false,
        documentBlobs: documentBlobs
      }, () => {
        log('blob refreshed');
        log(activeRegions);
        // this.goToPage(activeRegions, activeIndex)
      });

    }, (error) => {
      _this.setState({loadingRight: false});
    });
  } else {
    _this.setState({
      fileURL: window.URL.createObjectURL(documentBlobs[activeId]),
      loadingRight: false
    }, () => {
      log('blob refreshed');
      log(activeRegions);
      // this.goToPage(activeRegions, activeIndex)
    });
  }
};


/**
 * Attempts to set the resultTypes and selectedResults in state
 *
 * @memberof ResultViewer
 */
export const getResultTypes = (_this) => {
  GetRequest(URL.getResultTypes, (data) => {
    try {
      _this.setState({
        resultTypes: data.payload['result_types'],
        selectedResult: data.payload['result_types'].find(a => a['result_type'] === _this.state.data['result_type'])
      });
    } catch (err) {
      log('Result Type not found');
      log(err);
    }
  }, (err) => console.log(err));
};


/**
 * Generates the csvData and returns it as an array, does not update state
 *
 * @returns {*} Array of the generated inforamation
 *
 * @memberof ResultViewer

 /**
 * Converts the fields in the data into a csv readable format
 *
 * @param {*} data {The documents object, who's fields will be converted into an array to be used in csv format}
 * @param {*} array {The array that will be populated with the data returned}
 * @param {*} spacer {Boolean value as to whether or not an empty row should be added at the end, default true}
 * @param {*} cutEmpty {Boolean value as to if there are no fields, the function shouldn't modify the array, default true}
 * @param {*} header {Value to be given at the start of the first row, default value "Fields"}
 * @returns {*} {The array passed into the function, with rows appended}
 * @memberof ResultViewer
 */
const genFieldData = (data, array, spacer = true, cutEmpty = true, header = 'Fields', _this) => {
  //Go through the fields of each
  let fields = new Set();
  let fieldDict = {};
  //Loop over all the given documents
  for (let doc of Object.values(data)) {
    //Loop over all the fields in the given doc
    doc.data.extracted_data.forEach(extracted => {
      if (extracted.type === 'field') {
        fields.add(extracted.label);
      }
    });
  }

  //If there are no fields, do nothing to the array
  if (cutEmpty && fields.size === 0) {
    return array;
  }

  //Set a dictionary to allow easy access to the field index
  let index = 1;
  fields.forEach((field) => {
    fieldDict[field] = index++;
  });

  //Push the header line to the array
  array.push([header, ...fields]);

  //Add a row for each document
  for (let [docId, doc] of Object.entries(data)) {
    let row = new Array(fields + 1);
    for (let i = 0; i < fields + 1; i++) {
      row.push(undefined);
    }

    //First element should be the document name
    row[0] = _this.state.documents[docId].name;

    //Other elements should either be filled in or not
    doc.data.extracted_data.forEach(extracted => {
      if (extracted.type === 'field') {
        row[fieldDict[extracted.label]] = extracted.value;
      }
    });

    //Push the generated row onto the array
    array.push(row);
  }

  //If the spacer is set to true, create an empty row at the end
  spacer && array.push([]);

  return array;
};


/**
 * Converts the collections in the data into a csv readable format
 *
 * @param {*} data {The documents object, who's collections will be converted into an array to be used in csv format}
 * @param {*} array {The array that will be populated with the data returned}
 * @param {*} spacer {Boolean value as to whether or not an empty row should be added at the end, default true}
 * @returns {*} {The array passed into the function, with rows appended}
 * @memberof ResultViewer
 */
const genTableData = (data, array, spacer = true, _this) => {
  //Iterate over each document
  for (let doc of Object.values(data)) {
    //Iterate over each extracted object, searching for collections
    doc.data.extracted_data.forEach(extracted => {
      if (extracted.type === 'collection') {
        genSingleTable(extracted, array);

        //If the spacer is set to true, create an empty row at the end
        spacer && array.push([]);
      }
    });
  }
  return array;
};


/**
 * Converts the table into a csv readable format
 *
 * @param {*} table {The collection object to be rendered}
 * @param {*} array {The array that will be populated with the data returned}
 * @param {*} strip {Whether or not we need to strip the first column, given it contains internal uuid data, default false}
 * @returns {*} {The array passed into the function, with rows appended}
 * @memberof ResultViewer
 */
const genSingleTable = (table, array, strip = false) => {
  //Iterate over each row
  table.items.forEach(row => {
    let items = row.elements;

    //Create a list of values from each cell object, stipping the uuid if specified
    array.push(items.map(elementObject =>
      elementObject.value
    ).filter((_, index) =>
      !(strip && index === 0)
    ));

    return array;
  });
};


/**
 * Generates the csvData and returns it as an array, does not update state
 *
 * @returns {*} Array of the generated inforamation
 *
 * @memberof ResultViewer
 */
export const generateCsvData = (_this) => {
  const {docData, documents, aggTable} = _this.state;

  //Check all the information we need is properly in place
  if (!docData || !documents) {
    return [];
  }

  let arr = [];

  //If aggTable exists, render as if it's not an aggTable
  if (!aggTable) {
    genFieldData(docData, arr, true, true, 'Fields', _this);
    genTableData(docData, arr, true, _this);
  } else {
    genFieldData(docData, arr, true, true, 'Fields', _this);
    genSingleTable(aggTable, arr, true);
    arr.push([]);
    Object.entries(docData).forEach(docInfo => {
      let [key, value] = docInfo;
      genFieldData({[key]: value}, arr, true, true, 'Fields', _this);
      genTableData({[key]: value}, arr, true, _this);
    });
  }
  // _this.setState({csvData: arr})
  return arr;
};
