import React from 'react'
import {Route, Switch} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {withStyles} from "@material-ui/core";
import FileManagement from "./components/FileManagement";
import Queue from "./components/Queue"
import ResultViewer from "./components/ResultViewer";
import ViewList from '@material-ui/icons/ViewList';
import Tooltip from "@material-ui/core/Tooltip";
import { withTranslation } from 'react-i18next';

const styles ={
  workMain: {
    padding: '0 56px 3rem 56px',
  },
  titleTabs: {
    margin: 'auto 90px 0 auto',
    cursor: 'pointer',
    fontFamily: 'Raleway !important',
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '1.2',
    letterSpacing: '-0.32px',
    color: '#1e2a5a'
  },
  tabHeader: {
    paddingLeft: '50px',
    margin: '1.5rem 0 2.5rem 0',
    display: 'flex'
  },
  activeTab: {
    borderBottom: '4.9px solid #5EAEFF',
    fontWeight: 'bold',
  },
  disableTab: {
    color: '#b7b7b7',
    cursor: 'default !important'
  },
  mainTitle: {
    // fontFamily: '\'Raleway\',sans-serif!important',
    fontWeight: '600',
    color: '#1e2a5a',
    margin: '1.5rem 0 2rem 0',
    fontFamily: 'Raleway !important',
    fontSize: '1.8rem'
  },
  listIcon: {
    width: '50px',
    height: '50px',
    background: 'white',
    padding: '10px',
    border: '1px solid #e1e3e4',
    cursor: 'pointer'
  },
  right20: {
    marginRight: '20px'
  },
  queueCount: {
    width: '18px',
    height: '18px',
    borderRadius: '50%',
    fontSize: '9px',
    fontWeight: 'bold',
    backgroundColor: '#ef65b1',
    position: 'absolute',
    top: '-8px',
    right: '-20px',
    color: 'white',
    textAlign: 'center',
    lineHeight: '18px'
  },
  '@media (max-width: 575.98px)': {
    uploadContent: {
      width: "blue"
    },
    modal: {
    minHeight: '80%',
    minWidth: '100%',
  },
  },
  '@media (min-width: 576px) and (max-width: 767.98px)': {
    uploadContent: {
      width: "blue"
    },
    modal: {
    minHeight: '80%',
    minWidth: '100%',
  },
  },
  '@media (min-width: 768px) and (max-width: 991.98px)': {
    uploadContent: {
      width: "blue"
    },
    modal: {
    minHeight: '80%',
    minWidth: '100%',
  },
  }
};

class WorkSpace extends React.Component {

  state = {
    selectedTab: 'document',
    displayType: 'module',
    currentLocation: '/',
    resultViewerFiles: [],
  };

  componentDidMount() {
    if (document.URL.includes("results")) {
      this.setState({selectedTab: 'result'})
    } else if (document.URL.includes("queue")) {
      this.setState({selectedTab: 'queue'})
    }
  }

  setResultViewerFiles = (resultViewerFiles) => {
    this.setState(prevState => ({...prevState, resultViewerFiles}))
  }

  changeTab = (value, refresh) => {
    const {history} = this.props;
    const {selectedTab} = this.state;
    if (selectedTab !== value) {
        this.setState({selectedTab: value});
      if (refresh) {
        value === 'document' ? history.push('/files') : history.push(`/${value}`)
      }
    }
  };

  changeLocation = (value) => {
    this.setState({currentLocation: value})
  };

  changeDisplayType = (value) => {
    this.setState({displayType: value})
  };

  render() {
    const {t, classes, resultViewerProcessing} = this.props;
    const {selectedTab, currentLocation, displayType, queueData, resultViewerFiles} = this.state;

    return (
        <div className={classes.workMain}>
          <Grid container>
            <Grid container className={selectedTab === 'document' ? 'workspace-header' : 'workspace-padding-top'}>
              <Grid item xs={12} sm={2} className={`${classes.titleHead}`}>
              {
                (() => {
                  switch (true) {
                    case (selectedTab === 'document'):
                      return <Typography variant='h5' className={classes.mainTitle}>{t('main-page.title')}</Typography>
                    case (selectedTab === 'queue'):
                      return <Typography variant='h5' className={classes.mainTitle}>{t('main-page.queue')}</Typography>
                    case (selectedTab === 'result'):
                      return <Typography variant='h5' className={classes.mainTitle}>{t('main-page.resultviewer')}</Typography>
                    default:
                      return <Typography variant='h5' className={classes.mainTitle}>{t('main-page.title')}</Typography>
                  }
                })()
              }
              </Grid>
              <Grid className='' item xs={12} sm={10} style={{display: 'flex'}}>
                <Grid item className={classes.tabHeader} style={{marginRight: 'auto', display: 'none'}}>
              <span
                  className={`${selectedTab === 'document' ? classes.activeTab : null} ${classes.titleTabs} title-tabs-margin`}
                  onClick={() => this.changeTab('document', true)}
              >{t('main-page.tabs.docs')}</span>
                  <span onClick={() => this.changeTab('queue', true)}
                        className={`${selectedTab === 'queue' ? classes.activeTab : null} ${classes.titleTabs} position-relative`}>{t('main-page.tabs.q')}{false ?
                      <div className={classes.queueCount}>{queueData.length}</div> : null}</span>
                  <span
                      className={`${selectedTab === 'result' ? classes.activeTab : classes.disableTab} ${classes.titleTabs}`}
                  >{t('main-page.tabs.res-viewer')}</span>

                </Grid>
                {selectedTab === 'document' ? (
                    <Grid item style={{marginLeft: 'auto', display: 'flex', marginTop: '1rem'}}>
                      <Tooltip title='Grid view'>
                        <div className={`${classes.listIcon} ${classes.right20}`}
                             onClick={() => this.changeDisplayType('module')}
                             style={{
                               color: displayType === 'module' ? '#959daf' : 'd3d3d3',
                               boxShadow: displayType === 'module' ? '0 8px 8px 0 #d1dae5' : ''
                             }}>
                          <i className="fa fa-th-large grid-icon"
                             style={{color: displayType === 'module' ? '#959daf' : '#d3d3d3'}} aria-hidden="true"/>
                        </div>
                      </Tooltip>
                      <Tooltip title='List view'>
                        <ViewList className={classes.listIcon} onClick={() => this.changeDisplayType('list')}
                                  style={{
                                    color: displayType !== 'module' ? '#959daf' : '#d3d3d3',
                                    boxShadow: displayType !== 'module' ? '0 8px 8px 0 #d1dae5' : ''
                                  }}/>
                      </Tooltip>
                    </Grid>
                ) : null}
              </Grid>
            </Grid>
            <Switch>
              <Route
                  exact
                  path="/trash"
                  render={(props) =>
                      <FileManagement
                          key={2}
                          currentLocation={currentLocation}
                          displayType={displayType}
                          changeTab={this.changeTab}
                          trash {...props} />}
              />
              <Route
                  path="/queue"
                  exact
                  render={(props) => <Queue changeTab={this.changeTab} {...props} />}
              />
              <Route
                  path="/results"
                  exact
                  render={(props) => {
                    return (<ResultViewer
                        changeTab={this.changeTab}
                        resultViewerProcessing={resultViewerProcessing}
                        arraysEqual={this.props.arraysEqual}
                        resultViewerFiles={resultViewerFiles} {...props} />)
                  }
                  }
              />
              <Route
                  path="/files"
                  render={(props) =>
                      <FileManagement
                          key={1}
                          trash={false} currentLocation={currentLocation}
                          changeTab={this.changeTab}
                          changeLocation={this.changeLocation}
                          setResultViewerFiles={this.setResultViewerFiles}
                          displayType={displayType} {...props} />}
              />
            </Switch>
          </Grid>
        </div>
    )
  }
}

export default withTranslation()(withStyles(styles)(WorkSpace));
