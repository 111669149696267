import {Table} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from '@material-ui/core/Modal';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import withStyles from '@material-ui/core/styles/withStyles';
import KeyboardArrowRightIcon from '@material-ui/core/SvgIcon/SvgIcon';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import SearchIcon from '@material-ui/icons/Search';
import SettingsIcon from '@material-ui/icons/Settings';
import _ from 'lodash';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import React, {Component, Fragment} from 'react';
import {withTranslation} from 'react-i18next';
import AddCapabilities from '../../../admin/components/AddCapabilities';
import {getUserTimezone} from '../../../common/loginService';
import {GetRequest, PatchRequestV2} from '../../../common/requests';
import {URL} from '../../../common/url';
import Utils from '../../../utils';
import CreateOrganization from '../createOrganization';
import LogoUpload from './logoUpload';

const styles = {
  formInput: {
    width: '40%'
  },
  filter: {
    minWidth: '175px',
    marginLeft: '1rem',
    background: '#F4F6F9'
  },
  groupSelect: {
    minWidth: '40%',
    maxWidth: '40%'
  },
  label: {
    // marginLeft: '10px !important'
  },
  button: {
    textTransform: 'unset',
    borderRadius: '30px',
    color: '#959CAE',
    fontSize: '14px',
    padding: '8px 16px'
  },
  updateButton: {
    color: '#686868',
    borderRadius: '8px',
    fontSize: '13px',
    fontWeight: '500',
    border: '1px solid #e1e3e4',
    padding: '10px 15px',
    marginLeft: '1rem',
    textTransform: 'unset'
  },
  sendResetButton: {
    color: '#ffffff',
    backgroundColor: '#618bff',
    fontFamily: '\'Open Sans\', sans-serif !important',
    marginTop: '40px'
  },
  resetText: {
    color: '#1b1b1b',
    fontSize: '17px',
    fontFamily: '\'Open Sans\', sans-serif !important'
  },
  resetDescription: {
    color: '#686868',
    fontSize: '18px',
    fontFamily: '\'Open Sans\', sans-serif !important'
  },
  personIcon: {
    fontSize: '18px',
    marginRight: '10px'
  },
  mlAuto: {
    marginLeft: 'auto'
  },
  outlinedFilter: {
    padding: '1rem 0'
  },
  innerGrid: {
    marginTop: '2rem'
  },
  tableIcon: {
    marginRight: '0.8rem',
    marginBottom: '-0.5rem',
    color: '#959daf'
  },
  tableHeader: {
    borderBottom: '2px solid #e8e8e8'
  },
  editBack: {
    paddingLeft: 0,
    marginLeft: '-0.5rem'
  },
  titleHead: {
    marginTop: '1.5rem',
    marginBottom: '1rem'
  },
  titleMain: {
    marginTop: '2rem'
  },
  inputSide: {
    width: '100%'
  },
  firstGrid: {
    width: '40%',
    marginRight: '2rem'
  },
  searchBar: {
    width: '50%',
    paddingTop: '1rem'
  },
  limitText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px'
  },
  apiKeys: {
    '& input' :{
        borderBottom : '0px',
        padding: '0',
        minWidth: '500px',
        marginTop: '4px'
    },
  },
  loader: {
    position: 'relative',
    left: 'calc(50% - 20px)',
    marginTop: '40px',
    marginRight: 'auto'
  },
  menuItem: {
    fontFamily: '\'Open Sans\', sans-serif !important',
    fontSize: '17px',
    color: '#141b2d',
    padding: '0 16px'
  },
  modal: {
    background: 'white',
    position: 'relative',
    width: '450px',
    top: '30%',
    left: '30%',
    textAlign: 'left'
  },
  modalSubject: {
    color: '#141b2d',
    fontSize: '21px',
    fontFamily: '\'Open Sans\', sans-serif !important',
    marginBottom: '24px'
  },
  modalDescription: {
    fontFamily: '\'Open Sans\', sans-serif !important',
    color: '#1e2a5a'
  },
  submitDelete: {
    textAlign: 'right',
    marginTop: '24px'
  },
  actionButton: {
    marginRight: '10px',
    color: '#000000',
    fontWeight: 'bold',
    fontSize: '15px',
    fontFamily: '\'Open Sans\', sans-serif !important'
  },
  modalItem: {
    '&:hover': {
      cursor: 'pointer'
    },
    display: 'flex'
  },
  modalText: {
    verticalAlign: 'super',
    textDecoration: 'none !important',
    color: '#141b2d',
    fontFamily: '\'Open Sans\', sans-serif !important',
    fontSize: '17px'
  },
  avatarText: {
    verticalAlign: 'super',
    textDecoration: 'none !important',
    color: '#141b2d',
    fontSize: '12px'
  },
  rightArrow: {
    display: 'inline',
    margin: '0 20px'
  },
  brd: {
    width: '100%',
    borderTop: '1px solid #e1e3e4',
    marginTop: '50px'
  },
  optionsFiles: {
    position: 'absolute',
    width: 'max-content',
    zIndex: '2',
    boxShadow: '0 5px 2px 0 rgba(209, 218, 229, 0.7)',
    border: '1px solid #e1e3e4',
    padding: '16px',
    borderRadius: '10px',
    fontFamily: '\'Open Sans\', sans-serif !important'
  },
  ActiveTag: {
    background: '#5fafff',
    marginLeft: '40px',
    marginRight: '32px',
    borderRadius: '20px',
    fontSize: '13px',
    color: 'white',
    fontFamily: '\'Open Sans\', sans-serif !important',
    padding: '4px 8px'
  },
  mainGrid: {
    margin: '8px',
  },
  select: {
    minWidth: '150px',
  },
  textInput: {
    '& input' :{
      padding: '10px 10px 10px 15px',
      height: '2.1875em',
      width: '100%'
    },
    marginRight: '30px',
    width: '50%'
  },
  textDesc: {
    '& input' :{
      padding: '10px 10px 10px 15px',
      height: '2.1875em',
      width: '100%'
    },
    marginRight: '30px',
    width: '100%'
  },

  title: {
    marginBottom: '0.5rem',
    marginTop: '1rem',
    fontSize: '17px'
  },
  sideGrid: {
    width: '50%',
    marginRight: '30px',
  },
  formControl: {
    width: '100%',
  },
  formUser: {
    width: '40%',
  },
  refreshButton: {
    background:'#618AFF',
    color: 'white',
    borderRadius: '8px',
    textTransform: 'none',
    fontSize: '14px',
    padding: '8px 34px',
    marginTop: '7px'
  },
  apiButton: {
    textTransform: 'none',
    fontSize: '11px',
    boxShadow: 'none',
    borderRadius: '15px',
    background: 'none',
    border: '1px solid #ccc',
    padding: '3px'
  },
  submitButton: {
    background:'#618AFF',
    color: 'white',
    borderRadius: '2.5rem',
    marginTop: '2.5rem'
  },
  backButton: {
    marginRight: '10px',
    color: '#959CAE',
    borderRadius: '12px',
  },
  text: {
    marginLeft: '10px',
  },
  capGrid: {
    width: '40%'
  },
  capForm: {
    width: '100%'
  },
  siteConfigHeading: {
    fontSize: '18px',
    fontFamily: 'Raleway !important',
    color: '#686868',
    fontWeight: '500',
    letterSpacing: '0.22px',
    lineHeight: '1.2'
  },
  metaInfoKeys: {
    color: '#1e2a5a',
    fontSize: '16px',
    fontWeight: 'normal'
  },
  metaInfoDiv: {
    fontSize: '18px',
    color: '#686868',
    fontFamily: 'OpenSans !important',
  },
  logoUploadButton: {
    background:'#fff',
    color: '#686868',
    borderRadius: '8px',
    textTransform: 'none',
    fontSize: '14px',
    padding: '8px 24px',
    margin : '20px 0',
    verticalAlign: 'bottom'
  },
  logoInfoText: {
    color: '#1e2a5a',
    fontSize: '16px',
    fontWeight: 'normal'
  },
  uploadedLogo:{
    border: '1px solid #e1e4e5',
    maxWidth: '260px',
    padding: '15px',
    display: 'inline',
    margin: '20px 10px 20px 0',
    verticalAlign: 'bottom'
  }
};

class Organization extends Component {

  state = {
    page: 0,
    rowsPerPage: Utils.pageSize,
    organizations: [],
    organizationCount: 0,
    orgSearchTerm: "",
    filters: {},
    
    openModal: false,
    logoModal: false,
    filter: 'all',
    filterRole: 'all',
    edit: false,
    status: true,
    id: 0,
    anchor: null,
    open: false,
    openSnack: false,
    snackMsg: '',
    loading: true,
    showActions: false,
    openDeactivate: false,
    rolesList: [],

    apiUrl: URL.listServices,
    apiKey: '',
    services: [],
    capabilities: [],
    service: '',
    capability: [],
    viewMode: true,
    userSelect: false,
    user: '',
    organization: {},
    orgName: "",
    description: "",
    openCapModal: false,
    deleteOrgModal: false,
    deleteOrgId:null
  }

  controller = new window.AbortController();
  cleanup = null;

  componentDidMount() {
    this.props.updateSelectedTab("organization")
    this.cleanup = () => {
      this.controller.abort();
    };
    this.getOrganizations();
  }

  componentWillUnmount() {
    if (this.cleanup) this.cleanup();
    this.cleanup = null;
  }

  getOrganizations = (page, pageSize) => {
    const {orgSearchTerm, filters, rowsPerPage} = this.state
    this.setState({loading: true});
    
    if (page === undefined)
      page = 1

    if (pageSize === undefined)
      pageSize = rowsPerPage

    let params = {
      [Utils.pageQueryParam]: page,
      [Utils.pageSizeQueryParam]: pageSize
    };

    if (orgSearchTerm)
      params["search"] = orgSearchTerm

    if (!_.isEmpty(filters))
      params = {...params, ...filters}
    
    const queryParams = Utils.encodeQueryParams(params)
    GetRequest(URL.organizations + "?" + queryParams, (response) => {
      const {data: organizations, count: organizationCount} = response
      this.setState({
        organizations,
        organizationCount,
        loading: false
      }, () => {});
    }, (err) => {this.setState({loading: false})});
  };

  modalOpen = (value, refresh) => {
    this.setState({openModal: value});
    if (refresh) {
      this.getUsers();
      this.getGroups();
    }
  };

  logoModalOpen = (value) => {
    this.setState({logoModal: value});
  };

  handleOrgCreation = () => {
    this.getOrganizations();
  }

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage
    });
    
    this.getOrganizations(newPage + 1)
  };

  handleChangeRowsPerPage = (event) => {
    const pageSize = parseInt(event.target.value, 10)
    this.setState({
      page: 0,
      rowsPerPage: pageSize
    });

    this.getOrganizations(1, pageSize)
  };

  handleEditValue = () => {
    this.setState(prevState=> ({
      ...prevState,
      edit: false
    }))
  }

  handleEditOrg = (row) => {
    this.setOrganization(row)
    this.getApiData()

    this.setState(prevState=> ({
      ...prevState,
      edit: true
    }))
  };

  getCapabilities = (cap) => {
    const { service } = this.state;
    const params = {
      service: service,
    };
    let esc = encodeURIComponent;
    let query = Object.keys(params)
      .map(k => esc(k) + '=' + esc(params[k]))
      .join('&');
    GetRequest(URL.listCapabilities+ '?' + query, (data)=> {
      this.setState(prevState=> ({
        ...prevState,
        capabilities: data.payload,
      }));
      cap && this.setState({capability: cap})
    }, (err) => {} );
  };

  handleChangeService = (event) => {
    const { name, value } = event.target;
    this.setState(prevState => ({ ...prevState, [name]: value }), () => this.getCapabilities());
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(prevState => ({ ...prevState, [name]: value }));
  };

  handleSearch = (event) => {
    if (event.constructor.name === "SyntheticEvent") {
      const {name, value} = event.currentTarget;

      // Just set the search value in state. Additionally, call the api in the setState callback function,
      // upon clearing everything on search bar .
      this.setState(
        prevState => ({[name]: value}),
        () => {
          if (!value)
            this.getOrganizations(1, this.state.rowsPerPage);
        });
    }
    else {
      // Call api on hitting enter key.
      if (event.keyCode === Utils.enterKeyCode)
        this.getOrganizations(1, this.state.rowsPerPage)
    }
  }
  
  setOrganization = (organization) => {
    let service = ""
    let capability = []

    for (const [key, value] of Object.entries(organization.json_info)) {
      service = key
      capability = value
    }
    this.setState({
      organization,
      apiUrl: organization.api_url,
      apiKey: organization.api_key,
      orgName: organization.name,
      description: organization.description,
      service,
      capability
    })
  }

  getApiData = (refresh) => {
    const {apiUrl} = this.state;
    GetRequest(apiUrl,(data)=> {
      this.setState(prevState=> ({
        ...prevState,
        services: data.payload,
      }))
      refresh && this.setState({ service: '', capability: []})
    }, (err)=> {})
  };

  handleDelete(label) {
    let tempState = {...this.state};
    let tempCap = tempState['capability'];
    if (tempCap.indexOf(label.toLowerCase()) >= 0) {
      tempCap.splice(tempCap.indexOf(label.toLowerCase()), 1);
      this.setState({
        capability: tempCap
      })
    }
  }

  getChips(row) {
    return (<Fragment>
      <div className="mr-12">
        <Chip label={row} onDelete={(e) => {this.handleDelete(row)}} />
      </div>
    </Fragment>)
  }

  toggleCapabilitiesModal(bool) {
    if (this.state.capabilities.length === 0) {
      return;
    }
    this.setState({
      openCapModal: bool
    })
  }

  updateApiKey = (action) => {
    let payload = {}
    if (action === "revoke_api_key")
      payload["revoke_api_key"] = true
    else if (action === "generate_api_key")
      payload["generate_api_key"] = true
    else if (action === "generate_client_key")
      payload["generate_client_key"] = true

    PatchRequestV2(
      URL.organizations + "/" + this.state.organization.id + "/",
      this.controller.signal, payload,(data)=> {
        this.setState({apiKey: data.payload['api_key']});
      }, (err)=> {});
  }

  updateOrg = () => {
    const { service, capability, orgName, description } = this.state;
    const payload = {
      name: orgName,
      description,
      json_info: {
        [service]: capability,
      },
    };
    // this.setState(prevState => ({
    //   ...prevState,
    //   loading: true
    // }));

    PatchRequestV2(
      URL.organizations + '/' + this.state.organization.id + "/",
      this.controller.signal,
      payload,()=> {
        this.setState(prevState => ({
          ...prevState,
          edit: false
        }));

        this.getOrganizations();
    }, (err)=> {
        this.setState(prevState => ({
          ...prevState,
          loading: false
        }));
      });
  };

  onCapabilitiesSelected = (data) => {
    this.toggleCapabilitiesModal(false);
    if (data) {
      this.setState({
        capability: data
      })
    }
  }

  openDeleteOrgModal = (id) => {
    this.setState({deleteOrgId: id, deleteOrgModal: true})
  };

  closeDeleteOrgModal = () => {
    this.setState({deleteOrgModal: false})
  };

  deleteOrg = () => {
    this.setState({loading: true});
    const payload = {"status": "inactive"}
    const orgId = this.state.deleteOrgId

    PatchRequestV2(
      URL.organizations + '/' + orgId + "/",
      this.controller.signal,
      payload,()=> {
        this.setState(prevState => ({
          ...prevState,
          edit: false,
          loading: false
        }));
        this.getOrganizations();
    }, (err)=> {
        this.setState({loading: false});
      });

    this.closeDeleteOrgModal()
  };

  closeSnack = () => {
    this.setState({openSnack: false, snackMsg: ''});
  };

  render() {
    const {
      openModal, logoModal, page, rowsPerPage, edit, orgSearchTerm, openSnack, snackMsg, loading, organizations, apiUrl,
      apiKey, services, service, capabilities, capability, openCapModal, organization, orgName, description,
      deleteOrgModal, organizationCount
    } = this.state;
    const {t, classes, changeTab} = this.props;

    return (
      <Grid container direction="column" key="grid1" changeTab={changeTab}>
        {
          edit ?
            <React.Fragment>
              <Grid container direction="row" justify="space-between" alignItems="flex-start">
                <div className={classes.siteConfigHeading}>
                  <a href className="cursor-pointer" onClick={this.handleEditValue} style= {{marginRight: '20px'}}>{t('admin.org.title')} </a> >
                  <b style= {{marginLeft: '20px'}}>{organization.name}</b>
                </div>
                <div className={classes.updateButtons}>
                    <Button className={classes.updateButton} onClick={this.updateOrg} variant="outlined">{t('admin.org.edit-page.update-org-btn')}</Button>
                    <Button className={classes.updateButton} onClick={() => {this.openDeleteOrgModal(organization.id)}} variant="outlined">{t('admin.org.edit-page.del-org-btn')}</Button>
                </div>
              </Grid>
              <React.Fragment>
              <Grid item xs={7}>
              <Grid container direction="row" justify="space-between" alignItems="flex-start">
        <Typography variant="h6" style={{ color: '#5fafff'}}>{t('admin.org.edit-page.title-1')}</Typography>
              </Grid>
                {
                  loading?
                    <div className="login-loader"><CircularProgress className='loader' style={{ color: '#5fafff'}} size={20} /></div>:
                    <Fragment>
                      <Grid className={classes.innerGrid}>
                        <TextField
                          label={t('admin.org.edit-page.form.name')}
                          name="orgName"
                          id="orgName"
                          onChange={this.handleChange}
                          value={orgName || ''}
                          variant="outlined"
                          inputProps={{ 'padding': '10px' }}
                          className={classes.textInput}
                        />
                      </Grid>
                      <Grid className={classes.innerGrid}>
                        <TextField
                          label={t('admin.org.edit-page.form.desc')}
                          name="description"
                          id="description"
                          onChange={this.handleChange}
                          value={description || ''}
                          variant="outlined"
                          inputProps={{ 'padding': '10px' }}
                          helperText={t('admin.org.edit-page.form.char-limit')}
                          className={classes.textDesc}
                        />
                      </Grid>
                      <Grid className={classes.innerGrid}>
                        <div className={classes.title}><b>Logo</b></div>
                        <div className={classes.metaInfoDiv}>
                          <Typography className={classes.logoInfoText}>The logo will be displayed for all services at KPMG</Typography>

                          {/* After upload a tenant logo pls use 'uploadedLogo' classname like below*/}
                          {/*<img src={TenantLogo} alt="Logo" className={classes.uploadedLogo}/>*/}

                          <Button
                            className={classes.logoUploadButton}
                            onClick={() => this.logoModalOpen(true)}
                            variant="contained"
                            >
                            Select File To upload
                          </Button>
                          <Typography className={classes.logoInfoText}>The image file should be in PNG or GIF format, up to 260 x 120 pixels.</Typography>
                        </div>
                      </Grid>

                      <div className={classes.brd}></div>
                      <Grid container direction="row" justify="space-between" alignItems="flex-start" style= {{marginTop: '50px'}}>
                        <Typography variant="h6" style={{ color: '#5fafff'}}>API Configuration</Typography>
                      </Grid>
                      <Grid className={classes.innerGrid}>
                        <TextField
                          label='API URL'
                          name="apiUrl"
                          id="apiUrl"
                          onChange={this.handleChange}
                          value={apiUrl}
                          variant="outlined"
                          inputProps={{ 'padding': '10px' }}
                          className={classes.textInput}
                        />
                        <Button
                          variant="contained"
                          className={classes.refreshButton}
                          onClick={this.getApiData}>
                          {t('admin.org.edit-page.refresh-btn')}
                        </Button>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        style={{ 'marginTop': '20px' }}
                      >
                      <Grid className={classes.sideGrid}>
                        <div className={classes.title}><b>{t('admin.org.edit-page.serv-label')}</b></div>
                        <FormControl disabled={services.length === 0} className={classes.formControl}>
                          <Select
                            value={service}
                            variant='outlined'
                            onChange={this.handleChangeService}
                            input={<OutlinedInput name="service" id="service" className={classes.outlinedFilter}/>}
                            className={classes.select}
                          >
                            {
                              services && services.map(
                                (ser) =>
                                  <MenuItem key={ser.service} value={ser.service}>{ser.label}</MenuItem>
                              )
                            }
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid className={classes.capGrid}>
                        <div className={classes.title}><b>{t('admin.org.edit-page.cap-label')}</b>
                          {capability.length > 0 ?<span className="edit-cap" onClick={() => {this.toggleCapabilitiesModal(true)}}>{t('admin.org.edit-page.edit-btn')}</span>:null}
                        </div>
                        {
                          capability.length >0 ?
                            <div>
                              <div className="chips-row">
                                {
                                  capability.map(r => (
                                    this.getChips(r))
                                  )
                                }
                              </div>
                            </div> :
                            <div className="capButton" onClick={() => this.toggleCapabilitiesModal(true)}>
                              <span>Select Capabilities</span>
                              <KeyboardArrowRightIcon className="right-icon" />
                            </div>
                        }

                      </Grid>
                      </Grid>
                      <Grid className={classes.innerGrid}>
                        <div className={classes.title}><b>{t('admin.org.edit-page.refresh-btn')}</b></div>
                        <div className={classes.metaInfoDiv}>
                          <span className={classes.metaInfoKeys}>{t('admin.org.edit-page.api-key')}: </span>
                          {
                          apiKey ?
                            <Fragment>
                            <TextField
                            disabled
                            name="api"
                            id="api"
                            onChange={this.handleChange}
                            value={apiKey}
                            inputProps={{ 'padding': '10px' }}
                            className={classes.apiKeys}
                          />
                          <Button
                            className={classes.apiButton}
                            variant="contained"
                            onClick={() => this.updateApiKey("revoke_api_key")}>
                            Revoke
                          </Button>
                            </Fragment>
                          : <Button
                              className={classes.apiButton}
                              variant="contained"
                              onClick={() => this.updateApiKey('generate_api_key')}>
                              Generate API Key
                          </Button>
                        }<br/>
                          <span className={classes.metaInfoKeys}>{t('admin.org.edit-page.last-updated')}: {organization.updated_at}</span><br/>
                          <span className={classes.metaInfoKeys}>{t('admin.org.edit-page.last-tested')}: {organization.updated_at} </span>
                          <Button
                            className={classes.apiButton}
                            variant="contained"
                            >
                            {t('admin.org.edit-page.test-btn')}
                          </Button><br/>
                          <Button
                              className={classes.apiButton}
                              variant="contained"
                              onClick={() => this.updateApiKey('generate_client_key')}>
                              {t('admin.org.edit-page.gen-btn')}
                          </Button><br/>
                          <span className={classes.metaInfoKeys}>{t('admin.org.edit-page.stat-ok')}</span>
                        </div>
                      </Grid>
                    </Fragment>
                }
                </Grid>
              </React.Fragment>
            </React.Fragment>
            :
            <React.Fragment>
              <Grid container direction="row" justify="space-between" alignItems="flex-start">
                <Typography variant="h6">{t('admin.org.title')}</Typography>
                <Button variant="outlined" className={classes.button} onClick={() => this.modalOpen(true)}>
                  <GroupAddIcon className={classes.personIcon} />
                  {t('admin.org.create-org-btn')}</Button>
              </Grid>
              <Grid>
                <TextField
                  name="orgSearchTerm"
                  id="search"
                  onChange={this.handleSearch}
                  onKeyDown={this.handleSearch}
                  value={orgSearchTerm}
                  className={classes.searchBar}
                  variant="outlined"
                  placeholder={t('admin.org.placeholder')}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end">
                        {/*<IconButton aria-label="delete" onClick={this.clearSearch}>*/}
                        {/*  <ClearOutlinedIcon fontSize="small"/>*/}
                        {/*</IconButton>*/}
                        <SearchIcon style={{ color: 'grey'}}/>
                      </InputAdornment>
                  }}
                  inputProps={{
                    style: {
                      padding: '0.75rem 1rem'
                    }
                  }}
                />
              </Grid>
              <Grid
                container
                className={classes.innerGrid}
                direction="row"
                justify="space-between"
                alignItems="center">
                <b>{t('admin.org.subtitle')}</b>
              </Grid>
              <Fragment>
                {
                  loading ? <div className={classes.loader}>
                      <CircularProgress className={classes.loader} style={{color: '#5fafff'}} size={20} />
                    </div> :
                    <Grid>
                      <Table style={{tableLayout: 'fixed'}} className="users-table">
                        <TableHead>
                          <TableRow className={classes.tableHeader}>
                            <TableCell style={{width: '20%'}}><b> {t('admin.org.table.name')}</b></TableCell>
                            <TableCell style={{width: '13%'}}><b>{t('admin.org.table.org-id')}</b></TableCell>
                            <TableCell style={{width: '15%'}}><b>{t('admin.org.table.users')}</b></TableCell>
                            <TableCell style={{width: '20%'}}><b>{t('admin.org.table.date-created')}</b></TableCell>
                            <TableCell style={{width: '20%'}}><b>{t('admin.org.table.last-updated')}</b></TableCell>
                            <TableCell style={{width: '12%'}}><b>{t('admin.org.table.actions')}</b></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {organizations && organizations.map(row => (
                            <TableRow key={row.id}>
                              <TableCell style={{width: '20%'}} scope="row" className={classes.limitText}>{row.name}</TableCell>
                              <TableCell style={{width: '10%'}}>{row.id}</TableCell>
                              <TableCell style={{width: '15%'}}>{row.num_users}</TableCell>
                              <TableCell className="overflow-ellipsis" style={{width: '20%'}}><span>{moment.tz(row.created_at, getUserTimezone()).format("LLL")}</span></TableCell>
                              <TableCell className="overflow-ellipsis" style={{width: '20%'}}>{moment.tz(row.updated_at, getUserTimezone()).format("LLL")}</TableCell>
                              <TableCell style={{width: '15%'}}>
                              <IconButton href onClick={(e) => {e.preventDefault(); this.handleEditOrg(row)}}>
                                    <SettingsIcon className="margin"/>
                              </IconButton>

                                {row.name !== Utils.superadminOrg &&
                                  <Fragment>
                                  <IconButton href={`/admin/${row.id}`} target="_blank">
                                     <OpenInNewIcon className="margin"/>
                                  </IconButton>
                                  <IconButton href onClick={(e) => {e.preventDefault(); this.openDeleteOrgModal(row.id)}}>
                                        <DeleteIcon className="margin"/>
                                  </IconButton>
                                  </Fragment>
                                }
                              </TableCell>
                            </TableRow>
                          ))}
                          {/* {emptyRows > 0 && (
                            <TableRow style={{height: 48 * emptyRows}}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )} */}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              rowsPerPageOptions={Utils.rowsPerPageOptions}
                              colSpan={6}
                              count={organizationCount}
                              rowsPerPage={rowsPerPage}
                              labelRowsPerPage={t('admin.org.table.per-page')}
                              page={page}
                              SelectProps={{
                                inputProps: {'aria-label': 'Results per page'},
                                native: true
                              }}
                              onChangePage={this.handleChangePage}
                              onChangeRowsPerPage={this.handleChangeRowsPerPage}
                              style={{paddingTop: '20px'}}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </Grid>
                }
              </Fragment>
            </React.Fragment>
        }

        <Modal
          onClose={() => this.logoModalOpen(false)}
          open={logoModal}
          style={{overflow: "scroll"}}
          >
          <React.Fragment>
            <LogoUpload
              logoModalOpen={this.logoModalOpen}
              org = {this.state.organization}
            />
          </React.Fragment>
        </Modal>


        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          onClose={() => this.modalOpen(false)}
          open={openModal}
          className="create-org-modal"
          >
          <React.Fragment>
            <CreateOrganization
              modalOpen={this.modalOpen}
              refreshOrgList={this.handleOrgCreation}
            />
          </React.Fragment>
        </Modal>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          onClose={() => {}}
          open={openCapModal} >
          <React.Fragment>
            <AddCapabilities
              capabilities={capabilities}
              selectedCapabilities={capability}
              onCapabilitiesSelected={(data)=>{this.onCapabilitiesSelected(data)}}
            />
          </React.Fragment>
        </Modal>
        <Modal open={deleteOrgModal}>
          <React.Fragment>
            <div className={classes.modal} >
              <div style={{padding: '20px'}}>
                <div className={classes.modalSubject}>
                  <b>{t('admin.org.edit-page.modal.title')}</b>
                </div>
                <div className={classes.modalDescription}>
                  <div>{t('admin.org.edit-page.modal.sub')}</div>
                </div>
                <div className={classes.submitDelete}>
                  <Button className={classes.actionButton} onClick={() => this.closeDeleteOrgModal()}>{t('admin.org.edit-page.modal.cancel-btn')}</Button>
                  <Button className={classes.actionButton} autoFocus onClick={() => this.deleteOrg()}>{t('admin.org.edit-page.modal.remove-btn')}</Button>
                </div>
              </div>
            </div>
          </React.Fragment>
        </Modal>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          open={openSnack}
          autoHideDuration={3000}
          onClose={this.closeSnack}
          ContentProps={{
            'aria-describedby': 'message-id'
          }}
          message={<span id="message-id">{snackMsg}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.closeSnack}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </Grid>
    );
  }
}

export default withTranslation()(withStyles(styles)(Organization));
