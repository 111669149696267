import {withStyles} from '@material-ui/core';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import * as PropTypes from 'prop-types';
import React, {Component} from 'react';
import {GetRequest, PostRequest} from '../../../../common/requests';
import {URL} from '../../../../common/url';
import { withTranslation } from 'react-i18next';

const styles = {
  modal: {
    background: 'white',
    position: 'relative',
    width: '550px',
    top: '20%',
    left: '35%',
    textAlign: 'left'
  },
  modalTitle: {
    background: 'linear-gradient(70deg, #5159F8, #5EAAFF)',
    color: 'white',
    textAlign: 'left',
    padding: '20px'
  },
  modalContent: {
    padding: '20px'
  },
  selectService: {
    borderRadius: '10px',
    marginTop: '10px',
    padding: '0',
  },
  formInput: {
    margin: '10px',
    width: '95%'
  },
  submit: {
    textAlign: 'right',
    borderTop: '1px solid #E1E3E4',
    paddingTop: '16px'
  },
  label: {
    marginLeft: '10px !important'
  },
  actionButton: {
    marginRight: '10px',
    color: '#d3d3d3',
    fontSize: '16px',
    fontWeight: 'bold'
  },
};


class StepThree extends Component {
  static propTypes = {
    // modalOpen: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
  };

  state = {
    service: "",
    services: [],
    selectedServices: [],
    capability: [],
    submit: false,
    activeStep: 0,
    skipped: new Set()
  };


  controller = new window.AbortController();

  componentDidMount() {
    // this.getServices();
  }

  getServices = () => {
    GetRequest(URL.listServices, (data) => {
    const services = data.payload || [];
      this.setState({services, selectedServices: [services[0]]});
    }, (err) => {
      console.log(err);
    });
  };


  handleChangeService = (event) => {
    const { value } = event.target;
    this.setState(prevState => ({ ...prevState, selectedServices: value }));
    this.props.setServices(event)
  };

  handleAdd = () => {
    const {t, modalOpen} = this.props;
    const {selectedGroups} = this.state;
    const payload = {
      groups: selectedGroups,
      //role: status ? 'Admin' : 'User',
      status: t('super-admin.create-org-modal.act')
    };

    this.setState({submit: true}, () => {
      if (this.checkValidations()) {
        PostRequest(URL.onBoardingUser, this.controller.signal, payload, () => {
          modalOpen(false, true);
        }, (err) => {
          console.log(err);
        });
      }
    });
  };

  render() {
    const {t, classes} = this.props
    const {services} = this.props;
    const {selectedServices} = this.state

    return (
      <React.Fragment>
        <div className="stepThree">
          <Typography variant="subtitle1">
            <b>{t('super-admin.create-org-modal.step')} 3</b> {t('super-admin.create-org-modal.sel-service-1')}
          </Typography>

          <Typography variant="subtitle2" style={{marginTop: '20px'}}>
            <b>{t('super-admin.create-org-modal.ser')}</b>
          </Typography>

            <Select
              multiple
              value={selectedServices}
              label={t('super-admin.create-org-modal.sel-service')}
              onChange={this.handleChangeService}
              input={<Input id="select-multiple" />}
              className={classes.selectService}
              inputProps={{ id: 'fieldId' }}
              style={{width : '100%', textOverflow: 'ellipsis'}}
            >
              {services.length > 0 && services.map(service => (
                <MenuItem key={service.service} value={service.service} style={{width : '100%', textOverflow: 'ellipsis'}}>
                  {service.label}
                </MenuItem>
              ))}
            </Select>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(withStyles(styles)(StepThree));
