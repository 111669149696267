import {Paper} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import EmailIcon from '@material-ui/icons/Email';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import Visibility from '@material-ui/icons/Visibility';
import * as PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import BackGroundImage from '../assets/images/Login_Background.png';
import PoweredLogo from '../assets/images/poweredby.png';
import Footer from '../common/Footer';
import {login} from '../common/loginService';
import {PostRequest} from '../common/requests';
import {URL} from '../common/url';
import './custom.scss';

let brand = process.env.REACT_APP_ORG_BRAND
if( brand === undefined){
  brand = 'nirveda'
};

const styles = {
  paper: {
    padding: '72px 80px 26px 80px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'white',
    borderRadius: '30px',
    textAlign: 'center',
    boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.55)',
    //height: '400px'
  },
  iconColor: {
    color: '#5EAAFF !important'
  },
  avatar: {
    margin: '8px',
    height: '150px',
    paddingTop: '20px',
    paddingBottom: '15px',
    width: '100px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: '0.5rem',
  },
  submit: {
    margin: '24px 0 0 0',
    padding: '8px 15px 8px 13px',
    background: 'linear-gradient(70deg, #5EAAFF, #5159F8)',
    borderRadius: '8px',
    textTransform: 'unset',
    fontSize: '20px',
    fontWeight: '600',
    fontFamily: '\'Raleway\',sans-serif !important',
    // width: '184px'
  },
  backLogin: {
    margin: '24px 0 0 0',
    padding: '8px 20px',
    background: 'linear-gradient(70deg, #5EAAFF, #5159F8)',
    borderRadius: '8px',
    textTransform: 'unset',
    fontSize: '20px',
    fontWeight: '600'
  },

  disabledButton: {
    margin: '24px 0 0 0',
    padding: '8px 15px 8px 13px',
    background: 'linear-gradient(150deg, #a3a3a3, #353535)',
    borderRadius: '8px',
    textTransform: 'unset',
    color: 'white !important',
    fontSize: '20px',
    fontWeight: '600'
  },
  button: {
    display: 'none',
  },
  main: {
    background: '#6094FB',
    backgroundImage: `url(${BackGroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    padding: '8px',
  },
  circle: {
    margin: 'auto',
    background: 'white',
    width: '200px',
    borderTopLeftRadius: '200px',
    borderTopRightRadius: '200px',
    height: '100px',
    textAlign: 'center',
    boxShadow: '0 10px 15px 0 rgba(0, 0, 0, 0.55)',
  },
  container: {
    marginTop: '7%',
    maxWidth: '700px',
  },
  text: {
    width: '90%',
    marginRight: '1rem',
  },
  resetText: {
    width: '90%',
    marginTop: '3rem',
    marginBottom: '3rem',
    fontFamily: '\'Open Sans\', sans-serif !important'
  },
  testMessage: {
    fontSize: '28px',
    fontWeight: '500',
    color: '#1b1b1b',
    fontFamily: '\'Raleway\',sans-serif !important'
  },
  resetMessage: {
    color: "#1e2a5a",
    fontSize: '16px !important',
    marginTop: '0.5rem',
    fontFamily: '\'Open Sans\', sans-serif !important',
    width: '537px',
    lineHeight: '1.31',
    letterSpacing: 'normal'
  },
  buttonDiv: {
    padding: '32px 0'
  },
  icon: {
    color: '#959CAE'
  },
  errorIcon: {
    color: '#ef65b1',
    marginTop: '-1.5rem',
    marginLeft: '0.5rem'
  },
  focus: {
    color: 'green !important'
  },
  instructions: {
    margin: '1rem 0',
    fontFamily: '\'Open Sans\', sans-serif !important',
    color: '#1e2a5a'
  },
  resetHeading: {
    fontFamily: '\'Raleway\',sans-serif!important',
    fontsize: '28px',
    fontWeight: '500'
  },
  loaderDiv: {
    background: '#6094FB',
    backgroundImage: `url(${BackGroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    padding: '16px 24px',
    width: '184px',
    borderRadius: '8px',
    margin: '24px auto auto auto',
    display: 'flex',
    justifyContent: 'center'
  },
  tenantLogoBox:{
    maxWidth: '260px',
    marginTop: '-30px'
  },
  tenantPoweredBox:{
    marginBottom: '20px'
  }
};

class Reset extends React.Component{
  static propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  state={
    email: '',
    sentEmail: false,
    reset: false,
    password: '',
    confirmPassword: '',
    tempPassword: '',
    tempEmail: '',
    token: '',
    submit: false,
    emailError: '',
    showPassword: false,
    showConfirm: false,
    passwordError: '',
    confirmError: '',
    resetSubmit: false,
    loading: false,
    customLogo: false,
    instruction: 'Password criteria:\n' +
      'Should have at least one number\n' +
      'Should have at least one special character\n' +
      'Should have at least one uppercase letter\n' +
      'Should have at least one lowercase letter\n' +
      'Minimum length should be 8 characters\n' +
        'Special characters allowed\xa0 !,@,#,$,%,^,&,*'
  };

  controller = new window.AbortController();


  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    // evaluate based on ORG
    this.setState({
      customLogo:true
    })
    // const tempMail = urlParams.get('email');
    if(token){
      this.setState({reset: true, token});
    }
  }

  backToLogin = () => {
    const {history} = this.props;
    history.push('/login');
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(prevState => ({ ...prevState, [name]: value }),() => {
      this.checkValidations();
      this.checkPasswordValidation();
    });
  };

  resetClicked = () => {
    const { email } = this.state;
    const payload = {
      email: email.toLowerCase(),
    };

    this.setState({ submit: true,loading: true}, () => {
      if(this.checkValidations()){
        PostRequest(URL.forgotPassword, this.controller.signal, payload,()=> {
            this.setState(prevState => ({ ...prevState, sentEmail: true }));
          },
          (err)=> {
            this.setState({loading: false, sentEmail: true});
            // todo uncomment below lines if sent email to be false
            // this.setState({loading: false, sentEmail: false, emailError: err.message["Description"].toLowerCase()});
            // console.log(err.message["Description"]);
            console.log('err',err);
          });
      } else {
        this.setState({loading: false});
      }
    });
  };

  checkValidations = () => {
    const { submit, email} = this.state;
    const {t} = this.props;
    if (!submit) {
      return;
    }
    let valid = true;
    let emailError = "";

    if (email) {
      if (!Reset.validateEmail(email)) {
        emailError = t('mag.invalid-email-1');
        valid = false;
      }
    } else {
      emailError = t('mag.invalid-email-1');
      valid = false;
    }
    this.setState({emailError});
    return valid;
  };

  checkPasswordValidation = () => {
    const {t} = this.props;
    const { resetSubmit, password, confirmPassword} = this.state;
    if (!resetSubmit) {
      return;
    }
    let valid = true;
    let passwordError = "";
    let confirmError = "";

    if (password !== '') {
      if(password.length < 8) {
        passwordError = t('mag.invalid-email-1');
        valid = false;
      } else if(!Reset.validatePassword(password)){
        passwordError = t('mag.pwd-err-eli-1');
        valid = false;
      } else if(password !== confirmPassword){
        confirmError = t('mag.pwd-err-match');
        valid = false;
      }
    } else {
      passwordError = t('mag.pwd-err.generic');
      valid = false;
    }
    this.setState({passwordError, confirmError});
    return valid;
  };

  static validateEmail(email) {
    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  static validatePassword(p) {
    let re = /(?=.*\d)(?=.*[a-z])(?=.*[!@#$%^&*])(?=.*[A-Z]).{8,}/;
    return re.test(p);
  }


  resetPassword = () => {
    const { password, token } = this.state;
    const { history, change } = this.props;
    const payload = {
      token: token,
      proposed_password: password,
    };

    // if(change){
    //   payload["current_password"] = tempPassword
    // } else {
    //   payload["temp_password"] = tempPassword
    // }

    this.setState({resetSubmit: true,loading: true}, () => {
      if(this.checkPasswordValidation()){
        PostRequest(change ? URL.changePassword :URL.resetPassword, this.controller.signal, payload,(data)=> {
            this.setState({loading: false});
            // isLoggedIn() && logout();
            // history.push('/login');
              login(data.user_name,password, () => {
                history.push('/files');
              }, (error, msg) => {
                  history.push('/login');
              })
          },
          (err)=> {
            this.setState({loading: false});
            console.log('err',err);
          });
      } else {
        this.setState( { loading: false});
      }
    })
  };

  handleShowPassword = () => {
    const { showPassword } = this.state;
    this.setState({showPassword: !showPassword});
  };

  handleShowPasswordConfirm = () => {
    const { showConfirm } = this.state;
    this.setState({showConfirm: !showConfirm});
  };

  onKeyDown = (event, func) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      func();
    }
  };

  render() {
    const {t, classes} = this.props;
    const {sentEmail, email, reset, password, emailError, instruction,
      showPassword, confirmPassword, showConfirm, confirmError, passwordError, loading} = this.state;
    return (
      <React.Fragment>
        <div className={`${classes.main} login-main`}>
          <Container component="main" className={classes.container}>

            {(brand === 'nirveda') 
            ?
            <Paper className={classes.circle}>
              <a href='/'><img src={require(`../assets/images/${brand}-hero-logo.png`)} alt="Nirveda" className={classes.avatar}/></a>
            </Paper>
            : null}
            <Paper className={classes.paper}>

            {(brand !== 'nirveda')  
            ?
            <div className={classes.tenantPoweredBox}>
              <a href='/'>              
                <img src={require(`../assets/images/${brand}-hero-logo.png`)} alt="Brand Header" className={classes.tenantLogoBox}/>
                <img src={PoweredLogo} alt="Powered By Nirveda" className={classes.poweredBy}/>
              </a>
            </div> 
            : null}
              {
                reset ?
                  (
                    <React.Fragment>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                      >

                        <Typography component="h1" variant="h5" className={classes.resetHeading} style={{paddingLeft: '21px'}}>
                          {t('reset.title')}
                        </Typography>
                        <Tooltip title={instruction.split("\n").map((i,key) => {
                          return <div key={key}>{i}</div>;
                        })} >
                          <Button><InfoIcon className={classes.icon} /></Button>
                        </Tooltip>
                      </Grid>
                      <form className={classes.form} onSubmit={event => {event.preventDefault()}} noValidate>
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                        >
                          <TextField
                            variant="outlined"
                            margin="normal"
                            className={classes.text}
                            id="email"
                            label= {t('reset.pwd')}
                            type={showConfirm ? 'text' : 'password'}
                            name="password"
                            value={password}
                            onChange={this.handleChange}
                            onKeyDown={(e) => this.onKeyDown(e, this.resetPassword )}
                            autoFocus
                            helperText={passwordError}
                            error={passwordError !== ''}
                          />
                          {showConfirm ?
                            <Visibility className={classes.icon} onClick={this.handleShowPasswordConfirm}/> :
                            <Visibility style={{color: '#ebebeb'}} className={classes.icon} onClick={this.handleShowPasswordConfirm}/>}
                        </Grid>
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                        >
                          <TextField
                            variant="outlined"
                            margin="normal"
                            className={classes.text}
                            name="confirmPassword"
                            label="Confirm Password"
                            type={showPassword ? 'text' : 'password'}
                            id="confirmPassword"
                            autoComplete="current-password"
                            value={confirmPassword}
                            onChange={this.handleChange}
                            onKeyDown={(e) => this.onKeyDown(e, this.resetPassword )}
                            helperText={confirmError}
                            error={confirmError !== ''}
                          />
                          {showPassword ?
                            <Visibility className={classes.icon} onClick={this.handleShowPassword}/> :
                            <Visibility style={{color: '#ebebeb'}} className={classes.icon} onClick={this.handleShowPassword}/>}
                        </Grid>
                        <button
                          className={classes.button}
                          id="submit-button"
                          type="button"
                        />
                        {
                          loading ? <div className={classes.loaderDiv}><CircularProgress style={{ color: 'white'}} size={20} /></div>:
                              <label htmlFor="submit-button">
                                <div>
                                  <Button
                                      size="medium"
                                      variant="contained"
                                      color="primary"
                                      className={(passwordError || confirmError)? classes.disabledButton :classes.submit}
                                      href="#"
                                      type="submit"
                                      onClick={this.resetPassword}
                                      disabled={passwordError || confirmError || loading}
                                  >
                                    {t('reset.cont')}
                                  </Button>
                                </div>
                              </label>
                        }
                      </form>
                    </React.Fragment>
                  )
                  : (
                    sentEmail ?
                      <React.Fragment>
                        <Typography component="h1" variant="h6" style={{fontSize: '28px'}}>
                        {t('reset.pwd-rec')}
                        </Typography>
                        <Typography component="h1" variant="subtitle2" className={classes.instructions}>
                        {t('reset.txt1')} <b>{email}</b> {t('reset.txt2')}< br />
                        {t('reset.txt3')} <br />
                        </Typography>
                        <Typography variant="subtitle2" style={{fontFamily: '\'Open Sans\', sans-serif !important', color: '#1e2a5a'}}>
                        {t('reset.iss')} <b><a href="mailto:support@nirvedacognition.ai" className='contact-link'>{t('reset.cont-txt')} </a></b>{t('reset.supp')}
                        </Typography>
                        <div className={classes.buttonDiv}>
                          <Button
                            size="medium"
                            variant="contained"
                            color="primary"
                            className={classes.backLogin}
                            href="#"
                            onClick={this.backToLogin}
                          >
                            {t('reset.log')}
                          </Button>
                        </div>
                      </React.Fragment>
                      :
                      <React.Fragment>
                        <Typography component="h1" variant="h5" className={classes.testMessage}>
                        {t('reset.forgot-pwd')}
                        </Typography>
                        <Typography component="h1" variant="subtitle2" className={classes.resetMessage}>
                        {t('reset.recov-txt')}
                        </Typography>
                        <form className={classes.form} onSubmit={(event)=> event.preventDefault()} noValidate>
                          <Grid container
                            direction="row"
                            justify="center"
                            alignItems="center">
                                <TextField
                                  variant="outlined"
                                  margin="normal"
                                  className={classes.resetText}
                                  id="email"
                                  label="Email"
                                  name="email"
                                  autoComplete="email"
                                  placeholder="Email Address"
                                  helperText={emailError}
                                  error={emailError !== ''}
                                  value={email}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start"><EmailIcon style={{color: '#d3d3d3'}}/></InputAdornment>,
                                  }}
                                  onChange={this.handleChange}
                                  onKeyDown={(e) => this.onKeyDown(e, this.resetClicked )}
                                  autoFocus
                                />
                            {emailError !== '' ?
                              <ErrorIcon className={classes.errorIcon}/> :
                              null}
                          </Grid>
                          <button
                            className={classes.button}
                            id="submit-button"
                            type="button"
                          />
                          {
                            loading ? <div className={classes.loaderDiv}><CircularProgress style={{ color: 'white'}} size={20} /></div>:
                              <label htmlFor="submit-button">
                                <div>
                                  <Button
                                    size="medium"
                                    variant="contained"
                                    color="primary"
                                    className={emailError ? classes.disabledButton :classes.submit}
                                    href="#"
                                    type="submit"
                                    disabled={emailError !== '' || loading}
                                    onClick={this.resetClicked}
                                  >
                                    <span>{t('reset.reset-pwd')}</span>
                                  </Button>
                                </div>
                              </label>
                          }
                        </form>
                      </React.Fragment>
                  )
              }

            </Paper>
          </Container>
        </div>
        <Footer light/>
      </React.Fragment>
    );
  }
}

export default withTranslation()(withStyles(styles)(Reset));
