import {ClickAwayListener} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import DropDownIcon from '@material-ui/icons/ArrowDropDown';
import TickIcon from '@material-ui/icons/Done';
import _ from 'lodash';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import React, {Fragment} from 'react';
import {withTranslation} from 'react-i18next';
import {getUserTimezone} from '../../../common/loginService';
import Utils from '../../../utils';
import Detail from './detail';


class Details extends React.Component {

  constructor(props) {
    super(props);
    this.docs = null;
  }

  renderSimpleDetail = (key, value) => {
    const {classes} = this.props;
    return <Fragment>
      <div className={`${classes.fileInfoContainer} dataresult `}>
        <div className={classes.leftInfo}>{key}</div>
        <div className={classes.rightInfo}>{value}</div>
      </div>
    </Fragment>;
  };
  
  activateRow = (e, row) => {
    e.stopPropagation();
    const {goToPage, updateActiveDoc, editingEnabled} = this.props;

    // If field is being edited, don't highlight again.
    if (editingEnabled)
      return
    
    // jQuery('.highlightRow').removeClass('highlightRow');
    // // we now are using uuid and index as a class identifier
    // let activeRow = jQuery('#dataRow_' + row.uuid + '_' + row.index);
    // activeRow.addClass('highlightRow');

    /*
    Ideal Flow:
        update active doc
        log regions for highlight
        render doc
        on completion go to highlight

    */
    updateActiveDoc(row.uuid, row.regions, row.index);

    if (row.regions && row.index) {
      goToPage(row.regions, row.index);
    }

    return false;
  };

  generateDetails = () => {
    const {
      _this, t, documents, docData, doc_list, docIndex, classes, confidence, handleTableView, capabilities,
      selectedResult, resultTypes, open, pdfData, updateActiveDoc, setAggView, goToPage, enabledIndex, filterDetails,
      doneEditing, enableEditing, disableEditing, setEditingEnabled, aggTable
    } = this.props;

    let detailsList = [];
    if (doc_list.length > 1) {
      let collectionTitle = [];
      // We could refactor our left side data rendering to be completely different for different user types...
      if (capabilities.indexOf('property_search') > -1) {
        collectionTitle.push(
          <Typography variant="subtitle2" className={`${classes.title} dataresult`}><b>{t('result-viewer.summary')}</b></Typography>);
        collectionTitle.push(<hr />);
        if (pdfData !== undefined) {
          collectionTitle.push((pdfData['owner'] ? this.renderSimpleDetail('Owner', pdfData['owner']) : ''));
          collectionTitle.push((pdfData['address'] ? this.renderSimpleDetail('Address', pdfData['address']) : ''));
          collectionTitle.push((pdfData['apn'] ? this.renderSimpleDetail('APN', pdfData['apn']) : ''));
          collectionTitle.push((pdfData['apn'] ? this.renderSimpleDetail('Encumbrances', pdfData['encumbrances']) : ''));
        }
      } else {
        collectionTitle.push(
          <Typography variant="subtitle2" className={`${classes.title}`}><b>{t('result-viewer.col-info')}</b></Typography>);
      }

      let collectionDetails = <Fragment>
        <div className={`${classes.fileInfoContainer}  `}>
          <div className={classes.leftInfo}>{t('result-viewer.file-count')}</div>
          <div className={classes.rightInfo}>{doc_list.length}</div>
        </div>
      </Fragment>;

      detailsList.push(collectionTitle);
      detailsList.push(collectionDetails);

      // _.forEach(doc_list, (doc) => {
      //   let doc_detail = <Fragment>
      //     <div className={`${classes.fileInfoContainer}`}>
      //       <div className={classes.leftInfo}>{documents[doc].name.substring(0, 15)}</div>
      //       <div className={classes.rightInfo}>{documents[doc].document_status}</div>
      //     </div>
      //   </Fragment>;
      // });

      // render aggregate table below collection info
      if (aggTable !== null && aggTable !== undefined) {
        aggTable.label = t('result-viewer.comp-table');
        // let title = <Typography variant="subtitle2" className={`${classes.title} `}><b>{t('result-viewer.comp-table')}</b></Typography>;
        let det = <div
          className={`${classes.fileInfoContainer} `}
          key={Utils.genKey()}
          onClick={() => setAggView()}
        >
          <div className={classes.leftInfo}>{t('result-viewer.comp-table')}</div>
          <div className={classes.rightInfo}>{t('result-viewer.view-edit')}</div>
        </div>;
        detailsList.push(det);
      }

    } else {
      // only 1 document
      let genDetails = <Fragment>
        <Typography variant="subtitle2" className={`${classes.title} `}><b>{t('result-viewer.gen-info')}</b></Typography>
        <div
          className={`${classes.fileInfoContainer}  `}
          onClick={() => {}}>
          <div className={classes.leftInfo}>{t('result-viewer.file-type')}</div>
          <div className={classes.rightInfo}>{documents[doc_list[docIndex]].name && documents[doc_list[docIndex]].name.split('.').pop()}</div>
        </div>
        <div
          className={`${classes.fileInfoContainer}  `}
          onClick={() => {}}>
          <div className={classes.leftInfo}>{t('result-viewer.doc-type')}</div>
          <div className="resultContainer">
            {(selectedResult && resultTypes && resultTypes.length > 0) ?
              <React.Fragment>
                <span>{selectedResult.label}</span>
                <DropDownIcon onClick={() => this.handleClose(true)} className="resultDownIcon" />
                {open ?
                  <Paper className="resultPopper" elevation={2}>
                    <ClickAwayListener onClickAway={() => this.handleClose(false)}>
                      <ul>
                        {resultTypes.map(a => (
                          <li key={a.label} onClick={() => this.updateResult(a)}>
                            <span className="label">{a.label}</span>
                            {(a['result_type'] === selectedResult['result_type']) && <TickIcon className="tickIcon" />}
                          </li>
                        ))
                        }
                      </ul>
                    </ClickAwayListener>
                  </Paper>
                  : null
                }
              </React.Fragment>
              : (docData !== undefined) && Object.keys(docData).length === 1 ?
                <div><em>{Object.values(docData)[0].data.result_type}</em></div>
                : <div><em>{t('result-viewer.unknown-doc')}</em></div>
            }
          </div>
        </div>
        <div
          className={`${classes.fileInfoContainer} `}
          onClick={() => {}}>
          <div className={classes.leftInfo}>{t('result-viewer.date-recv')}</div>
          <div className={'rightInfo'}>
            {moment.tz(documents[doc_list[docIndex]]['status_changed_at'], getUserTimezone()).format('LLL')}
          </div>
        </div>
        <div
          className={`${classes.fileInfoContainer} `}
          onClick={() => {}}>
          <div className={classes.leftInfo}>{t('result-viewer.stat')}</div>
          <div className={'rightInfo'}>{documents[doc_list[docIndex]]['document_status']}</div>
        </div>
      </Fragment>;
      detailsList.push(genDetails);
    }

    // Render normal 'Details' header if only one doc
    if (doc_list.length === 1) {
      let builtDetails = <Fragment>
        <div className={`${classes.fileInfoContainer}`}>
          <Typography variant="subtitle2" className={classes.title}><b>{t('result-viewer.det')}</b></Typography>
        </div>
      </Fragment>;

      detailsList.push(builtDetails);
    }


    // console.clear()
    _.forEach(docData, (doc, key) => {
      if (doc.data === undefined || doc.data.extracted_data === undefined) {
        return;
      }

      let title = '';
      // log(docData[key])
      if (doc_list.length > 1) {
        title =
          <Typography variant="subtitle2" className={`${classes.title}`}><b>{documents[key].name} </b></Typography>;
      }
      let tableFlag = true;
      let rows = [];

      doc.data.extracted_data.map(row => {
        if (row.property_id !== 'table') {
          tableFlag = false;
          row.uuid = key;
          let det = <Detail
            activateRow={this.activateRow}
            uuid={key}
            row={row}
            classes={classes}
            enabledIndex={enabledIndex}
            filterDetails={filterDetails}
            doneEditing={doneEditing}
            genKey={Utils.genKey()}
            updateActiveDoc={updateActiveDoc}
            confidence={confidence}
            goToPage={goToPage}
            enableEditing={enableEditing}
            disableEditing={disableEditing}
            setEditingEnabled={setEditingEnabled}
          />;
          rows.push(det);
        }
        return true
      });

      if (!tableFlag) {
        detailsList.push(<hr />);
        detailsList.push(title);
        detailsList.push(rows);
      }
    });

    // generate table links
    _.forEach(docData, (doc, key) => {
      if (doc.data === undefined || doc.data.extracted_data === undefined) {
        return;
      }

      let title = '';
      if (doc_list.length > 1) {
        title =
          <Typography variant="subtitle2" className={`${classes.title}`}><b>{documents[key].name} - {t('result-viewer.tables')}</b></Typography>;
      }

      let tableFlag = false;
      let rows = [];
      doc.data.extracted_data.map(row => {
        if (row.property_id === 'table') {
          tableFlag = true;
          row.uuid = key; // associate the parent doc with the row

          let det = <div
            className={`${classes.fileInfoContainer} dataresult`}
            id={'dataRow_' + row.uuid + '_' + row.index}
            key={Utils.genKey()}
            onClick={(e) => {
              // this.activateRow(e, row);
              handleTableView(row, _this);
            }}
          >
            <div className={classes.leftInfo}>{t('result-viewer.table')}, Page {row.regions[0].page} - {row.label}</div>
            <div className={classes.rightInfo}>{t('result-viewer.view-edit')}</div>
          </div>;
          rows.push(det);
        }
        return true
      });
      if (tableFlag) {
        detailsList.push(title);
        detailsList.push(rows);
      }
    });

    return <div key ={Utils.genKey()} className={`${classes.fileContainer} container`} onScroll={() => {}}>{detailsList}</div>;
  };

	render() {
		let final_html = []
		final_html.push(this.generateDetails())	
        return final_html
    }
}

export default withTranslation()((Details))
