import {ClickAwayListener} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import Switch from '@material-ui/core/Switch';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ExportIcon from '@material-ui/icons/ImportExport';
import BackIcon from '@material-ui/icons/KeyboardArrowLeft';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';
import {PDFDownloadLink} from '@react-pdf/renderer';
import React from 'react';
import {CSVLink} from 'react-csv';
import {withTranslation} from 'react-i18next';
import {CsvStyledPdfDocument} from '../../../common/CsvStyledPdfDocument';
import {PdfDocument} from '../../../common/PdfDocument';
import Utils from '../../../utils';


const BlueSwitch = withStyles({
  switchBase: {
    color: 'white',
    '&$checked': {
      color: '#618bff'
    },
    '&$checked + $track': {
      backgroundColor: '#618bff'
    }
  },
  checked: {},
  track: {}
})(Switch);

class Header extends React.Component {

	state = {
		showExportMenu: false
	}

	handleBack = () => {
    // const {aggTable} = this.props

  		// TODO: Fix this
  		const { history} = this.props;
  		// changeTab('document')
  		history.push('/files')
  	}

	showMenu = () => {
		this.setState({
			showExportMenu: !this.state.showExportMenu
		})
	}

	closeMenu = () => {
		this.setState({
			showExportMenu: false,
		})
  }

	getExportMenu = () => {
    const {t, classes, confidence, csvData, pdfData, headers, fileData, capabilities, createName, toggleConfidence} = this.props

    return (
      <ClickAwayListener onClickAway={this.closeMenu}>
        <div className={classes.menuContainer}>

          <CSVLink
            data={csvData}
            headers={headers}
            className='csvLink'
            // filename={`${documents[doc_list[docIndex]].name.substring(0,documents[doc_list[docIndex]].name.lastIndexOf('.'))}_results.csv`}
            // make a better name if there is a single file, if it's a collection try to name it after the collection
            filename={createName(fileData.name? fileData.name: "AggregateFile", ".csv")}
          >
            <div className="menuItem menu-bottom-border">
              <div>{t('result-viewer.export_csv')}</div>
              <ExportIcon style={{marginRight: '12px'}}/>
            </div>
          </CSVLink>

          {(capabilities.includes('property_search')) &&
          <div className="menuItem optional property_search menu-bottom-border">
            <PDFDownloadLink
              document={<PdfDocument data={pdfData} />}
              headers={headers}
              className='csvLink'
              // fileName="property_search_report.pdf"
              fileName={createName(pdfData['address']? pdfData['address']: "Property Report", "")}
            >
              {({blob, url, loading, error}) => loading ? 'Loading...' : t('result-viewer.export_pdf')}
            </PDFDownloadLink>
            <ExportIcon style={{marginRight: '12px'}}/>
          </div>
          }

          {(capabilities.includes('tax_classifier')) &&
          <div className="menuItem optional tax_classifier menu-bottom-border">
            <PDFDownloadLink
              document={<CsvStyledPdfDocument data={pdfData} />}
              headers={headers}
              className='csvLink'
              fileName="tax_report.pdf"
              // fileName={createName(pdfData['address']? pdfData['address']: "Property Report", "")}
            >
              {({blob, url, loading, error}) => loading ? 'Loading...' : t('result-viewer.export_pdf')}
            </PDFDownloadLink>
            <ExportIcon style={{marginRight: '12px'}}/>
          </div>
          }
          <div className="menuItem">
            <div>{t('result-viewer.conf-score')}</div>

            <BlueSwitch
                checked={confidence}
                size='small'
                onChange={(e) => toggleConfidence(e)}
            />
          </div>
      </div>
    </ClickAwayListener>)
  }

	generateHeader = (data) => {
		const {showExportMenu} = this.state
		const {t, filterFlag, classes, onSearch, clearFilters, value} = this.props

		return (
      <div className={classes.leftHeader} key={Utils.genKey()}>
        {/* Start LEFT HEADER */}
        <div className='back-button'>
          <Button onClick={this.handleBack}><span className='back-icon'><BackIcon className="margin-auto" /></span>{t('result-viewer.back-btn')}</Button>
        </div>
        <div className={classes.searchButton}>
          <input className={classes.searchInput} id="autosuggest_input" placeholder={t('result-viewer.placeholder')} onChange={(e) => {onSearch(e)}} value={value} autoFocus={filterFlag} />
          {
            filterFlag?<span className="display-flex align-center color-99 search-margin" onClick={() => clearFilters()}><HighlightOffIcon/></span>:<SearchIcon className="color-99 search-margin"/>
          }
        </div>
        <div className={classes.menuIcon} >
          <MoreVertIcon className={classes.backIcon} onClick={this.showMenu}/>
          {showExportMenu ? this.getExportMenu() : null}
        </div>
        {/* End LEFT HEADER */}
      </div>
    /*
		TODO:
		Include the rest of the general info here, and then prepend the summary info above it if necessary,
		use the detail component to add info about the set of docs being looked at,
    */
    )
	}

	render() {
		let final_html = []
		final_html.push(this.generateHeader())
    	return final_html
  	}
}

export default withTranslation()((Header))
