import {makeStyles} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import {useTranslation} from 'react-i18next';
import PoweredLogo from '../../assets/images/poweredby.png';

let brand = process.env.REACT_APP_ORG_BRAND;
if (brand === undefined) {
  brand = 'nirveda';
}

const useStyles = makeStyles(theme => ({
  footer: {
    marginTop: 'auto',
    backgroundColor: 'white',
    height: '30px'
    //boxShadow: '0px 8px 17px'
  },
  text: {
    color: '#777777',
    fontSize: '11px',
    paddingLeft: theme.spacing(1),
    fontFamily: '\'Jura\', sans-serif !important',
    fontWeight: 'bold'
  },
  icon: {
    fontSize: '8px',
    marginTop: '2px'
  },
  lightFooter: {
    marginTop: 'auto',
    background: '#5E78FA',
    height: '30px',
    boxShadow: '0px 8px 17px'
  },
  poweredBy: {
    // margin: '10px auto 10px auto',

  },
  lightText: {
    color: '#7bc3ff',
    fontSize: '8px',
    paddingLeft: theme.spacing(1)
  },
  nirvedaPoweredBox: {
    maxWidth: '260px',
    marginBottom: '30px'
  },
  tenantPoweredBox: {
    margin: '10px auto 20px'
  }
}));

const Footer = (props) => {
  const {t} = useTranslation();
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container direction="row" justify="center" alignItems="center">
        {(window.location.pathname === '/login')
          ? null
          : <div>
            {(brand !== 'nirveda')
              ?
              <div className={classes.tenantPoweredBox}>
                {/*<img src={require(`../../assets/images/${brand}-hero-logo.png`)}/>*/}
                <img src={PoweredLogo} alt="Powered By Nirveda" className={classes.poweredBy} />
              </div>
              : null}
          </div>
        }
      </Grid>

      <Grid container direction="row" justify="center" alignItems="center" className={props.light ? classes.lightFooter : classes.footer}>
        {/*<CopyrightIcon className={classes.icon}/>
            <div className={props.light ? classes.lightText : classes.text}>{t('footer.title')}</div>
            <img src={PoweredLogo} alt="Powered By Nirveda" className={classes.poweredBy}/>
          */}

        {(brand === 'nirveda')
          ?
          <img src={PoweredLogo} alt="Powered By Nirveda" className={classes.poweredBy} />
          : null}

        {(brand !== 'nirveda')
          ?
          <div className={props.light ? classes.lightText : classes.text}>{t('footer.title')}</div>
          : null}
      </Grid>
    </React.Fragment>
  );
};
export default Footer;
