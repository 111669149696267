import React from 'react';
import {CssBaseline} from "@material-ui/core";
import {ToastContainer} from 'react-toastify';
import Routes from "./Routes";

import './App.scss';
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";


function App() {
  return (
      <React.Fragment>
        <ToastContainer />
        <CssBaseline />
        <div className="App min-width-app">
          <Routes/>
        </div>
      </React.Fragment>
  );
}

export default App;
