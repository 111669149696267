/**
 * Created by Akhtar on 11/05/2020.
 */

import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import React from 'react';


function EditColumnNameModal(props) {
  const {
    _this,
    t,
    classes,
    open,
    colDescription,
    toggleColDescriptionModal,
    setColumnDescription,
    colDescData,
    updateHeader,
  } = props

  return (
    <Modal open={open}>
      <React.Fragment>
        <div className="modalCustomHeader">
          <div className="modalTitle">Edit Column Name</div>
          <div style={{padding: '20px'}}>
            <TextField
              label="Name"
              autoFocus={true}
              className={classes.textInput}
              name="colDescription"
              id="colDescription"
              onChange={setColumnDescription}
              value={colDescription}
              style={{marginBottom: '38px'}}
            />
            <div className={classes.submitDelete}>
              <Button className={classes.actionButton} onClick={() => toggleColDescriptionModal()}>
                {t('result-viewer.modal.cancel')}
              </Button>
              <Button className={classes.saveButton} onClick={() => updateHeader(colDescData.params, colDescription, true, _this)}>
                {t('result-viewer.modal.save')}
              </Button>
            </div>
          </div>
        </div>
      </React.Fragment>
    </Modal>
  );
}

export default EditColumnNameModal;
