import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import TimeIcon from '@material-ui/icons/AccessTime';
import TaskIcon from '@material-ui/icons/CheckCircleOutlined';
import CloseIcon from '@material-ui/icons/Close';
import UploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorIcon from '@material-ui/icons/Error';
import {getDroppedOrSelectedFiles} from 'html5-file-selector';
import React from 'react';
import Dropzone from 'react-dropzone';
import {default as DropzoneUploader} from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import {toast} from 'react-toastify';
import {PostRequest, PutRequest, getPutHeaders} from '../../../common/requests';
import {URL} from '../../../common/url';
import './custom.scss';
import { withTranslation } from 'react-i18next';

//#KPMG hinge for upload headers based on whether we are in azure or not
let uploadRoute = process.env.REACT_APP_ORG_BRAND

const styles = {
  modal: {
    background: 'white',
    position: 'relative',
    width: '80%',
    height: '80%',
    // top: '10%',
    // left: '10%',
    textAlign: 'left',
    minHeight: '80vh',
    minWidth: '1000px',
    margin: 'auto'
  },
  minimizeModal: {
    bottom: '5%',
    left: '5%',
    position: 'absolute',
    background: 'white',
    zIndex: '999'
  },
  icon: {
    color: '#D3D9E1',
    height: '15rem',
    width: '15rem',
    margin: '0 auto',
    marginTop: '-2rem'
  },
  iconSmall: {
    color: '#D3D9E1',
    height: '10rem',
    width: '10rem',
  },
  content: {
    textAlign: 'center',
    paddingTop: '25px'
  },
  input: {
    display: 'none',
  },
  textDrop: {
    color: '#5fafff',
    fontWeight: 'bolder',
    fontSize: '2.5rem',
    marginTop: '-1.5rem',
    fontFamily: 'Raleway-ExtraBold, sans-serif !important'
  },
  closeButton: {
    // textAlign: 'right',
  },
  closeIcon: {
    fontSize: '40px',
    position: 'absolute !important',
    color: '#727272',
    right: '40px',
    top: '20px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  drag: {
    margin: '2rem'
  },
  button: {
    borderRadius: '15px',
    color: '#727272',
    padding: '5px 30px',
    fontFamily: 'OpenSans',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '1.42',
    letterSpacing: '0.48px'
  },
  browseButton: {
    borderRadius: '18px',
    color: '#727272',
    padding: '8px 2rem',
    fontFamily: 'OpenSans !important',
    fontSize: '15px',
    fontWeight: 600,
    lineHeight: '1',
    letterSpacing: '0.48px',
    border: 'solid 3px #e1e3e4'
  }
};

class FileUpload extends React.Component{

  state= {
    uploading: false,
    selectedFiles: [],
    uploadProgress: {},
    successfullyUploaded: false,
    drag: false,
    controller: {},
    minimize: false,
    content: false,
    selectedIndex: 0,
    open: false,
    processingMessageDisplayed: false,
  };

  controller = new window.AbortController();

  constructor(props) {
    super(props);
    this.minimizeRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    const node = this.minimizeRef;

    // Remove backdrop from Uplaod window
    if (this.state.minimize) {
      node.current.parentNode.style.position = "inherit"
    }
  }

  setDrag = (value) => {
    this.setState({drag: value});
  };

  handleDrop = (files) => {
    this.setState(prevState =>({
      drag: false,
      selectedFiles: prevState.selectedFiles.concat(files),
    }), () => {
      // this.uploadFiles(files);
    });
  };

  handleFiles = (event) => {
    const { files } = event.target;
    this.setState(prevState =>({
      selectedFiles: prevState.selectedFiles.concat(Object.values(files)),
    }), () => {
      // this.uploadFiles(Object.values(files));
    });
  };

  uploadFiles = async (selectedFiles) => {
    this.setState({ uploadProgress: {}, uploading: true });
    const promises = [];
    selectedFiles.forEach(file => {
      this.setState(prevState =>({ ...prevState, uploadProgress: {
        ...prevState.uploadProgress, [file.name]: { state: "progress" }
      }}));
      promises.push(this.sendRequest(file));
    });
    try {
      await Promise.all(promises);
      this.setState({ successfullyUploaded: true, uploading: false });
    } catch (e) {
      this.setState({ successfullyUploaded: false, uploading: false });
    }
  };

  sendRequest(file) {
    return new Promise((resolve, reject) => {
     const { location } = this.props;
     const payload= {
       document: location === '/' ? location+file.name : location+'/'+file.name,
     };

      PostRequest(URL.getUploadUrl, this.controller.signal,payload,(data) => {
        data.payload["put_signed_url"] &&
          PutRequest(data.payload["put_signed_url"],
            this.controller.signal,
            file ,
          (data) => {
            const copy = { ...this.state.uploadProgress };
            copy[file.name] = { state: "done" };
            this.setState({
              uploadProgress: copy,
              processingMessageDisplayed: false,
            });
            resolve(data);
        },
          (err) => {
            console.log(err);
            const copy = { ...this.state.uploadProgress };
            copy[file.name] = { state: "failed" };
            this.setState({ uploadProgress: copy });
            reject(err)
        },
            data.payload["headers"]
        )
      }, (error) => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "failed" };
        this.setState({ uploadProgress: copy });
        reject(error)
      });
    });
  }

  renderActions() {
    if (this.state.successfullyUploaded) {
      return (
        <button
          onClick={() =>
            this.setState({ selectedFiles: [], successfullyUploaded: false })
          }
        >
          Clear
        </button>
      );
    } else {
      return (
        <button
          disabled={this.state.selectedFiles.length < 0 || this.state.uploading}
          onClick={this.uploadFiles}
        >
          Upload
        </button>
      );
    }
  }

  renderProgress(file, small) {
    const uploadProgress = this.state.uploadProgress[file.name];
    // if (this.state.uploading || this.state.successfullyUploaded) {
      return (
        <div className="ProgressWrapper">
          <div className={small ? 'minBar' :"ProgressBar"} >
            {
              uploadProgress && uploadProgress.state === "progress" ?  <LinearProgress /> : null
            }
            {
              uploadProgress && uploadProgress.state === "done" ? <LinearProgress variant='determinate' value={100}/> : null
            }
            {
              uploadProgress && uploadProgress.state === "failed" ? <LinearProgress variant='determinate' value={0}/> : null
            }
          </div>
          {
            uploadProgress && uploadProgress.state === "done" ? <TaskIcon className={small ? 'minIcon' : 'CheckIcon'}/> : null
          }
          {
            uploadProgress && uploadProgress.state === "failed" ?
              <ErrorIcon style={{color: 'hotpink'}} className={small ? 'minIcon' : 'CheckIcon'}/> : null
          }
          {/*<Button href='#' variant='outlined' onClick={() => {this.clearFile(file.name)}}>clear</Button>*/}
        </div>
      );
    // }
  }

  clearFile = (name) => {
    const { controller, uploadProgress} = this.state;
    controller[name].abort();
    const copy = { ...uploadProgress };
    copy[name] = { state: "failed" };
    this.setState({ uploadProgress: copy });
  };

  minimize = () => {
    this.setState({minimize: true});
  };

  goToQueue = () => {
    const { changeTab, history } = this.props;
    changeTab('queue');
    history.push(`/queue`)
  };

  toggleMinContent = () => {
    this.setState(prevState => ({content: !prevState.content}))
  };

  getUploadParams = async ({ file, meta: { name } }) => {
    const { uploadUrl, headers, fileUrl } = await this.getUrl(name);

    let putHeaders = headers

    if(uploadRoute === 'kpmg'){
      // Special case for having to route files back to the api server

      putHeaders = getPutHeaders()
      // putHeaders['Content-Type'] = "multipart/form-data"    
    }


    return { body: file, meta: { fileUrl }, url: uploadUrl, method: 'PUT', headers: putHeaders}
  };

  handleChangeStatus = ({ meta }, status) => {
    // console.log(status, meta)
  };

  getUrl = (name) =>{
    return new Promise((resolve, reject) => {
      const { location } = this.props;
      const payload = {
        document: location === '/' ? location+name : location+'/'+name,
      };
      PostRequest(URL.getUploadUrl, this.controller.signal,payload, (data) => {
        data.payload["put_signed_url"] ?  resolve({
          uploadUrl: data.payload["put_signed_url"],
          headers: data.payload["headers"]
        }) : reject()
      }, () => reject)
    })
  };

  handleSubmit = (files, allFiles) => {
    // console.log(files.map(f => f.meta));
    // console.log(allFiles);
  };

  // options = ['Minimize', 'Cancel'];

  handleToggle = () => {
    const {open} = this.state;
    this.setState({open: !open})
  };

  handleMenuItemClick = (event, index) => {
    this.setState({ open: false, selectedIndex: index})
  };

  handleClose = () => {
    this.setState({ open: false})
  };

  minimizeClicked = (index) => {
    const { onClose } = this.props;
    index === 0 ? this.minimize() : onClose(false)
  };

  areAllFilesUploaded = (files) => {
    if (files && files.length > 0) {
      console.log("fileUploadChecker");
      const allFilesUploaded = files.filter(a => (a.meta.status === 'done')).length === files.length
      if (allFilesUploaded && !this.state.processingMessageDisplayed) {
        this.setState({processingMessageDisplayed: true}, () => {
          toast.info("Processing of your files has started")
        })
      }

      return allFilesUploaded
    }

    return false
  }

  anchorRef = React.createRef();

    getFilesFromEvent = e => {
        return new Promise(resolve => {
            getDroppedOrSelectedFiles(e).then(chosenFiles => {
                resolve(chosenFiles.map(f => f.fileObject))
            })
        })
    };

  render() {
    const { t } = this.props;
    const { classes, onClose } = this.props;
    const { drag, selectedFiles, minimize, content, selectedIndex } = this.state;

    const Layout = ({ input, previews, dropzoneProps, files, extra: { maxFiles } }) => {
      return (
        <React.Fragment>
          {
            minimize ?
              <div className="minTotal">
                <div className="minHeader">
                <span onClick={this.toggleMinContent} className="min-count">
                  {files.filter(a => (a.meta.status === 'done')).length} / {files.length}&nbsp;{t('file-upload.modal.file-upload-2.up-min')}
                </span>
                  <TimeIcon onClick={this.goToQueue} className="time-icon" />
                  <CloseIcon onClick={() => onClose(false)} className="close-icon" />
                </div>
                {
                  content ?
                    <div className="minContent">
                      {previews}
                    </div>
                    : null
                }
              </div>
              :
              <Grid container direction="row"
                    justify="flex-start"
                    alignItems="center" {...dropzoneProps}>
                <Grid className={classes.drag} item xs={3}>
                  <div >
                    {files.length < maxFiles && input}
                  </div>
                </Grid>
                <Grid item className='rightContainer'>
                  <div className="fileDetails">
                    <div className="fileHeader">
                      <span className='uploading-status'>{t('file-upload.modal.file-upload-2.title')}</span>
                      <span className='count'>
                    {files.filter(a => (a.meta.status=== 'done')).length} / {files.length}
                  </span>
                    </div>
                    <div className='fileContent'>
                      {previews}
                    </div>
                  </div>
                  <div className='actionDiv'>
                    {
                      this.areAllFilesUploaded(files) ?
                        <Button
                          variant="contained"
                          color="primary"
                          className="minimizeButton"
                          onClick={() => onClose(false)}>
                          {t('file-upload.modal.file-upload-2.close-btn')}
                        </Button>
                        :
                        <Button
                          variant="contained"
                          color="primary"
                          className="minimizeButton"
                          onClick={() => this.minimizeClicked(selectedIndex)}>
                          {t('file-upload.modal.file-upload-2.min-btn')}
                        </Button>
                    }
                  </div>
                </Grid>
              </Grid>
          }
        </React.Fragment>
      )
    };

    const Preview = ({ meta, fileWithMeta}) => {
      const { name, percent, status } = meta;
      const { cancel, remove } = fileWithMeta;
      return (
        <div className="Row">
          <span className="Filename">{name}</span>
          <div className="ProgressWrapper">
            <div className={content ? 'minBar' :"ProgressBar"} >
              <LinearProgress variant='determinate' value={percent}/>
            </div>
            {
              {
                'done':  <React.Fragment>
                  <TaskIcon className={content ? 'minIcon' : 'CheckIcon'} style={{margin:'auto'}}/>
                  <Button href='#' variant='outlined' className='clearBtn' onClick={remove}>Cancel</Button>
                </React.Fragment>,
                'error_upload':   <React.Fragment>
                  <span className='errorText'>Upload Failed.</span>
                      <ErrorIcon style={{color: 'hotpink'}} className={content ? 'minIcon' : 'CheckIcon'}/>
                </React.Fragment>
              }[status] ||  <React.Fragment>
                <TaskIcon className={content ? 'minIcon' : 'CheckIcon'}  style={{margin: 'auto', visibility: 'hidden'}}/>
                <Button href='#' variant='outlined' className='clearBtn' onClick={() => {cancel(); remove()}}>Cancel</Button>
              </React.Fragment>
            }
          </div>
        </div>
      )
    };

    const minPreview = ({ meta, fileWithMeta}) => {
      const { name, percent, status } = meta;
      const { cancel, remove } = fileWithMeta;
      return (
        <div className="Row">
          <span className="Filename">{name}</span>
          <div className="ProgressWrapper">
            <div className='minBar' >
              <LinearProgress variant='determinate' value={percent}/>
            </div>
            {
              {
                'done':  <React.Fragment>
                  <TaskIcon className='minIcon'/>
                  <DeleteIcon className='deleteIcon' onClick={remove}>Clear</DeleteIcon>
                </React.Fragment>,
                'error_upload':   <React.Fragment>
                  <ErrorIcon style={{color: 'hotpink'}} className='minIcon'/>
                </React.Fragment>
              }[status] ||  <React.Fragment>
                <TaskIcon style={{opacity: 0}}/>
                <DeleteIcon className='deleteIcon' onClick={() => {cancel(); remove()}}>Clear</DeleteIcon>
              </React.Fragment>
            }
          </div>
        </div>
      )
    };

    const Input = ({ accept, onFiles, getFilesFromEvent }) => {
      return (
        <div style={{margin: 'auto'}}>
          <UploadIcon className={classes.iconSmall}/>
          <div className="upload-files">{t('file-upload.modal.file-upload-2.label')}</div>
          <div className="upload-more">{t('file-upload.modal.file-upload-2.detail-txt')}</div>
            <label>
              <Button variant="outlined" component="span" className={`${classes.browseButton} opensans-font`}>
              {t('file-upload.modal.file-upload-2.upload-btn')}
              </Button>
              <input
                style={{display: 'none'}}
                type="file"
                accept={accept}
                multiple
                onChange={e => {
                  getFilesFromEvent(e).then(chosenFiles => {
                    onFiles(chosenFiles);
                  });
                }}
              />
            </label>
        </div>
      )
    };

    return(
      <div ref={this.minimizeRef} className={!minimize ? classes.modal : "minimizeModal"} boxShadow={3}>
        {
          selectedFiles.length > 0 ?
            <div className={minimize ? '' :classes.content}>
              <DropzoneUploader
                  getUploadParams={this.getUploadParams}
                  onChangeStatus={this.handleChangeStatus}
                  onSubmit={this.handleSubmit}
                  getFilesFromEvent={this.getFilesFromEvent}
                  PreviewComponent={minimize ? minPreview :Preview}
                  LayoutComponent={Layout}
                  accept='image/*, .pdf,.csv,.xls,.xlsx'
                  acceptedFiles='image/*, .pdf,.csv,.xls,.xlsx'
                  initialFiles={selectedFiles}
                  styles={{ dropzone: { overflow: "auto",border: "none", flexDirection: 'row' } }}
                  InputComponent={Input}
                  maxSizeBytes={20000000}
              />
            </div>
            :
            <div className='uploadContent'>
                <CloseIcon  onClick={()=> onClose(false)} className={classes.closeIcon}/>
                <Dropzone
                  onDrop={(e) => this.handleDrop(e)}
                  onDragOver={() => this.setDrag(true)}
                  onDragLeave={() => this.setDrag(false)}
                  accept=".pdf,.csv,.xls,.xlsx,image/*"
                  acceptedFiles='image/*, .pdf,.csv,.xls,.xlsx'
                  
                >
                  {({getRootProps}) => (
                      <div style={{width: '100%', height: '100%'}}>
                    <div {...getRootProps()} className={`initialUploadArea ${drag ? 'drag': ''}`}>
                        <UploadIcon className={classes.icon}/>
                        <div className={classes.textDrop}>{t('file-upload.modal.file-upload.label')}</div>
                        <div className="or-text">{t('file-upload.modal.file-upload.or-txt')}</div>
                    </div>
                        <div className='inputButton'>
                      <input
                        className={classes.input}
                        id="contained-button-file"
                        type="file"
                        accept=".pdf,.csv,.xls,.xlsx,image/*"
                        multiple
                        onChange={(e) => this.handleFiles(e)}
                      />
                      <label htmlFor="contained-button-file">
                        <Button variant="outlined" component="span" className={`${classes.browseButton} opensans-font`}>
                        {t('file-upload.modal.file-upload.upload-btn')}
                        </Button>
                      </label>
                        </div>
                      </div>
                  )}
                </Dropzone>
              </div>
        }
      </div>
    )
  }
}

export default withTranslation()(withStyles(styles)(FileUpload));
