import React, {Fragment} from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image, 
  Font
} from '@react-pdf/renderer';

import monospacePath from "../assets/fonts/Roboto_Mono/RobotoMono-Regular.ttf"
import Utils from '../utils';

let brand = process.env.REACT_APP_ORG_BRAND
if( brand === undefined){
  brand = 'nirveda'
};

Font.register({ family: "monospace", src: monospacePath})

const charsFit = 53

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
    margin: 15,
    padding: 15,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  summaryContainer: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent :'space-between',
    fontSize: 15,
    width: '90%',
    paddingBottom: 30,
    alignItems: 'center',
  },
  title: {
    fontSize: 15,
    fontWeight: 'bold',
    margin: '0 auto',
    textAlign: 'center',
  },
  summaryDetails: {
    display: "flex",
    flexDirection: "row",
  },
  topContainer: {
    display: "flex",
    flexDirection: "row",
  },
  image: {
    width: 150,
    // backgroundColor: '#70b7ff'
  },
  mainTitle: {
    fontSize: 20,
    alignItems: 'center',
    padding: '20'
  },
  key: {
    fontWeight: 600,
    fontSize: 12,
    width: '20%',
  },
  value: {
    fontSize: 12,
    padding: '0 0 3 3',
    width: '78%',
    alignItems: 'left',
    fontWeight: 400,
  },
  titleCont:{
    alignItems: 'center',
    width: '100%',
  },
  subTitle: {
    margin: '0 auto',
    alignItems: 'center',
    paddingTop: 50,
    paddingBottom: 25,
    textTransform: 'uppercase',
    fontSize: 15,
  },
  vote: {
    display: 'flex',
    flexDirection: 'row'
  },
  rating: {
    height: 10,
    width: 10
  },
  vote_text: {
    fontSize: 10
  },
  vote_pop: {
    fontSize: 10,
    padding: 2,
    backgroundColor: '#000',
    color: '#fff'
  },
  vote_pop_text: {
    fontSize: 10,
    marginLeft: 4
  },
  overviewContainer: {
    minHeight: 110
  },
  detailsFooter: {
    display: 'flex',
    flexDirection: 'row'
  },
  table: {
  },
  tableRow: {

  },
  tableCell: {
    fontFamily: "monospace",
  }
});

const getTableDimensions = (tableInfo) => {
  let height = tableInfo.length
  let width = tableInfo[0].elements.length

  return {height:height, width:width}
}

const truncateVal = (cellVal, charLimit) => {
  let dotLength = 0
  if (cellVal === undefined) {
    cellVal = ""
  }
  cellVal = cellVal.replace("\n", " ")
  let retVal = cellVal.length <= charLimit + dotLength? 
    cellVal: cellVal.slice(0, charLimit) + ""

  if (retVal.length < charLimit + dotLength) {
    let limit = charLimit + dotLength - retVal.length
    for(let i = 0; i < limit; i++ ) {
      retVal += " "
    }
  }
  return retVal
}


export function CsvStyledPdfDocument(props) {
  let {data} = props
  let docs = data.docs
  let owner = data.owner

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.header} key={Utils.genKey()}>
            <Image
              style={styles.image}
              src={require(`../assets/images/${brand}-logo.png`)}
            />
            <Text style={styles.mainTitle}>PDF Export</Text>
        </View>
        <View style={styles.topContainer} key={Utils.genKey()}>
            <Fragment><Text style={styles.key}>Document Count</Text><Text style={styles.value}>{docs.length}</Text></Fragment>
        </View>

        {docs
          ? docs.map((doc, index) => {
            let doc_title = <View style={styles.titleCont}><Fragment><Text style={styles.subTitle}>{doc.doc}</Text></Fragment></View>
            let fields = []
            doc.items.map((field, index2) => {
              let frag = <View key={Utils.genKey()} style={styles.summaryContainer}>
                <View style={styles.summaryDetails} key={index2}>
                  {<Fragment>
                      <Text style={styles.key}>{field.label}
                      </Text>
                      <Text style={styles.value}>
                      {
                        Array.isArray(field.value)? 
                          getTableDimensions(field.value)["width"] <= 5? 
                            <View style={styles.table} key={Utils.genKey()}>
                              {field.value.map((row, index, array) => 
                                <Text style={styles.tableRow}>
                                { row.elements.map(cell => 
                                    <Text style={styles.tableCell} key={Utils.genKey()}>
                                      {truncateVal(cell.value, Math.floor(charsFit / getTableDimensions(field.value)["width"]))}
                                    </Text>
                                  )}
                                  <Text>
                                    {"\n"}
                                  </Text>
                                </Text>
                              )}
                            </View>
                            : <Text>Table of width {getTableDimensions(field.value)["width"]} and height {getTableDimensions(field.value)["height"]}</Text>
                          : field.value
                      }
                      </Text>
                    </Fragment>
                  }
                </View>
              </View>
              fields.push(frag)
              return true
            })

            return [doc_title, fields]
          })
          : ''}
      </Page>
    </Document>
  );
}
