import _ from 'lodash';
import {toast} from 'react-toastify';
import {PatchRequestV2, PostRequest} from '../../../../common/requests';
import {URL} from '../../../../common/url';
import TableUtils from '../utils';
import {getAggTable, handleTableView} from './ResultViewerMethods';

let log = console.info.bind(window.console);


const ajaxUpdateData = (e, table, batchedit = false, _this) => {
  const {docData, selectedTableIndex, activeId, aggTable, cellEditVal} = _this.state;
  const originalCompareTable = {...aggTable};
  const originalData = {...docData};

  const updateList = [];
  const newValue = batchedit ? cellEditVal : e.value;
  const rowEditCount = _this.gridApi.getSelectedNodes().length;

  _this.gridApi.forEachNode(function (node) {
    let row_index = -1;
    const col_name = e.colDef === undefined ? e.column.colId : e.colDef.field;
    let col_index = findColumnIndex(col_name, _this);

    // multi select case
    if (node.selected === true && rowEditCount > 1) {
      row_index = node.data.row_index;
      if (typeof e.value !== 'object') {
        table.items[row_index].elements[col_index].value = newValue;
        updateList.push({row: row_index, column: col_index, value: newValue});
      } else if (e.value.ml_label !== undefined && e.value.roc !== undefined) { // catch the tax_classifier case
        let roc_index = Object.keys(e.data).indexOf('RoC');
        table.items[row_index].elements[col_index].value = e.value.label;
        table.items[row_index].elements[roc_index].value = e.value.roc;
        updateList.push({row: row_index, column: col_index, value: e.value.label});
        updateList.push({row: row_index, column: roc_index, value: e.value.roc});
      }

      // single select case
    } else if (node.selected === true && rowEditCount <= 1) {
      row_index = node.data.row_index;
      if (typeof e.value !== 'object') {
        table.items[row_index].elements[col_index].value = newValue;
        updateList.push({row: row_index, column: col_index, value: newValue});
      } else if (e.value.ml_label !== undefined && e.value.roc !== undefined) { // catch the tax_classifier case
        let roc_index = Object.keys(e.data).indexOf('RoC');
        table.items[row_index].elements[col_index].value = e.value.label;
        table.items[row_index].elements[roc_index].value = e.value.roc;
        updateList.push({row: row_index, column: col_index, value: e.value.label});
        updateList.push({row: row_index, column: roc_index, value: e.value.roc});
      }

      // single edit case with no selected nodes
    } else if (node.selected === false && node.rowIndex === e.rowIndex && rowEditCount <= 1) {
      row_index = node.data.row_index;
      if (typeof e.value !== 'object') {
        table.items[row_index].elements[col_index].value = newValue;
        updateList.push({row: row_index, column: col_index, value: newValue});
      } else if (e.value.ml_label !== undefined && e.value.roc !== undefined) { // catch the tax_classifier case
        let roc_index = Object.keys(e.data).indexOf('RoC');
        table.items[row_index].elements[col_index].value = e.value.label;
        table.items[row_index].elements[roc_index].value = e.value.roc;
        updateList.push({row: row_index, column: col_index, value: e.value.label});
        updateList.push({row: row_index, column: roc_index, value: e.value.roc});
      }
    }
  });

  const payload = {
    update_data: {
      update_list: updateList,
      table_index: selectedTableIndex
    }
  };

  //If an aggregate table was selected, don't send request to back end
  if (selectedTableIndex !== 0) {
    PatchRequestV2(
      URL.getFeedbackUrl + '/' + activeId + '/update_data',
      _this.controller.signal,
      payload,
      () => {
        log('save successful');
        getAggTable(_this);
        handleTableView(table, _this);
      },
      (err) => {
        _this.setState({docData: originalData});
      }
    );
  } else {
    updateCompareTableData(e, originalCompareTable, _this);
  }
}


const getTableStartIndex = (_this) => {
  // Compute the start index of each table in the aggregate table here.

  const {aggTable} = _this.state;
  let tableStartIndex = {};
  let prevDocId = aggTable.items[1].elements[0].value;
  let prevTableLabel = aggTable.items[1].elements[1].value;

  tableStartIndex[prevDocId] = [{
    docName: prevTableLabel,
    rowIndex: aggTable.items[1].row_index
  }];
  _.forEach(aggTable.items, item => {
    const docId = item.elements[0].value;
    const tableLabel = item.elements[1].value;

    if (prevTableLabel !== tableLabel) {
      if (prevDocId === docId) {
        tableStartIndex[docId].push({
          docName: tableLabel,
          rowIndex: item.row_index
        });
      } else {
        tableStartIndex[docId] = [{
          docName: tableLabel,
          rowIndex: item.row_index
        }];
      }
    }

    prevTableLabel = tableLabel;
    prevDocId = docId;
  });
  return tableStartIndex;
}


const getUpdateDataForCompareTable = (e, docUUID, Document, row_index, _this) => {
  let updateList = [];
  const {docData} = _this.state;

  let tabelLabel = Document.split('-');
  tabelLabel = tabelLabel[tabelLabel.length - 1].trim();
  const table = docData[docUUID].data.extracted_data.filter(obj => obj.label === tabelLabel)[0];
  const colList = table.items[0].elements.filter(obj => obj.value.toLowerCase().includes(e.column.colId.toLowerCase()));
  if (colList.length > 0) {
    const col_index = colList[0].column_index;

    if (typeof e.value !== 'object') {
      table.items[row_index].elements[col_index].value = e.value;
      updateList.push({row: row_index, column: col_index, value: e.value});
    } else if (e.value.ml_label !== undefined && e.value.roc !== undefined) { // catch the tax_classifier case
      let roc_index = Object.keys(e.data).indexOf('RoC');
      table.items[row_index].elements[col_index].value = e.value.label;
      table.items[row_index].elements[roc_index].value = e.value.roc;
      updateList.push({row: row_index, column: col_index, value: e.value.label});
      updateList.push({row: row_index, column: roc_index, value: e.value.roc});
    }
  }
  return {
    updateList,
    table_index: table.index
  };
}


const updateCompareTableData = (e, originalCompareTable, _this) => {
  // Fix for a bug which occurs when user closes modal mid-update
  // Probably need a better way of confirming the change or UI for preventing this
  if (!e.columnApi) {
    return;
  }

  const selectedRows = _this.gridApi.getSelectedRows();

  if (selectedRows.length > 0) {
    const {aggTable} = _this.state;
    const tableStartIndex = getTableStartIndex(_this);

    let updateDataObj = {};
    _.forEach(selectedRows, node => {
      const {uuid: docUUID, Document} = node;
      let {row_index} = node;

      const filteredList = tableStartIndex[docUUID].filter(obj => obj.docName === Document);
      if (filteredList.length > 0) {
        // Normalize row index to 1 based index.
        row_index = row_index - filteredList[0].rowIndex + 1;

        const update_obj = getUpdateDataForCompareTable(e, docUUID, Document, row_index, _this);
        if (update_obj.updateList.length > 0) {
          const keys = Object.keys(updateDataObj);
          if (keys.includes(docUUID)) {
            const fList = updateDataObj[docUUID].filter(obj => obj.table_index === update_obj.table_index);
            if (fList.length === 0)
              updateDataObj[docUUID].push({
                update_list: update_obj.updateList,
                table_index: update_obj.table_index
              });
            else {
              fList[0].update_list = [...fList[0].update_list, ...update_obj.updateList];
            }
          } else
            updateDataObj[docUUID] = [{
              update_list: update_obj.updateList,
              table_index: update_obj.table_index
            }];
        }
      }
    });

    const originalCompareTable = {...aggTable};
    const payload = {update_data: updateDataObj};
    PostRequest(URL.updateMultipleTables, _this.controller.signal, payload, () => {
        log('save successful');
        getAggTable(_this);
        // handleTableView(aggTable, _this)
      },
      (err) => {
        handleTableView(originalCompareTable, _this);
      }
    );
  } else {
    const {docData, aggTable} = _this.state;

    // Compute the start index of each table in the aggregate table here.
    let tableStartIndex = {};
    let prevTableLabel = aggTable.items[1].elements[1].value;
    tableStartIndex[prevTableLabel] = aggTable.items[1].row_index;
    _.forEach(aggTable.items, item => {
      const tableLabel = item.elements[1].value;
      if (prevTableLabel !== tableLabel)
        tableStartIndex[tableLabel] = item.row_index;

      prevTableLabel = tableLabel;
    });
    const columnName = e.colDef['headerName'];
    const {uuid: docUUID, Document} = e.node.data;

    // Normalize row index to 1 based index.
    let {row_index} = e.node.data;
    row_index = row_index - tableStartIndex[Document] + 1;

    let tabelLabel = Document.split('-');
    tabelLabel = tabelLabel[tabelLabel.length - 1].trim();

    const table = docData[docUUID].data.extracted_data.filter(obj => obj.label === tabelLabel)[0];
    const colList = table.items[0].elements.filter(obj => obj.value.toLowerCase().includes(columnName.toLowerCase()));
    if (colList.length === 0) {
      toast.error('Column does not exist in source table.');
      handleTableView(originalCompareTable, _this);
      return;
    }
    const col_index = colList[0].column_index;

    const updateList = [];
    if (typeof e.value !== 'object') {
      table.items[row_index].elements[col_index].value = e.newValue;
      updateList.push({row: row_index, column: col_index, value: e.newValue});
    } else if (e.value.ml_label !== undefined && e.value.roc !== undefined) { // catch the tax_classifier case
      log('single case tax classifier');
      let roc_index = Object.keys(e.data).indexOf('RoC');
      table.items[row_index].elements[col_index].value = e.value.label;
      table.items[row_index].elements[roc_index].value = e.value.roc;
      updateList.push({row: row_index, column: col_index, value: e.value.label});
      updateList.push({row: row_index, column: roc_index, value: e.value.roc});
    }

    const payload = {
      update_data: {
        update_list: updateList,
        table_index: table.index
      }
    };

    PatchRequestV2(
      URL.getFeedbackUrl + '/' + docUUID + '/update_data',
      _this.controller.signal,
      payload,
      () => {
        log('save successful');
        getAggTable(_this);
        handleTableView(table, _this);
      },
      (err) => {
        handleTableView(originalCompareTable, _this);
      }
    );
  }
}


const getUpdateData = (e, docUUID, Document, columnName, row_index, _this) => {
  let updateObj = {};
  const {docData, cellEditVal} = _this.state;

  let tabelLabel = Document.split('-');
  tabelLabel = tabelLabel[tabelLabel.length - 1].trim();

  const table = docData[docUUID].data.extracted_data.filter(obj => obj.label === tabelLabel)[0];
  const colList = table.items[0].elements.filter(obj => obj.value.toLowerCase().includes(columnName.toLowerCase()));
  if (colList.length > 0) {
    const col_index = colList[0].column_index;
    table.items[row_index].elements[col_index].value = cellEditVal;
    updateObj = {row: row_index, column: col_index, value: cellEditVal};

    //TODO Need to find a way to handle tax classifier case since we can't rely on the event object while batch
    // editing via context menu. The updated data is stored in _this.state.cellEditVal.
  }
  return {
    updateObj,
    table_index: table.index
  };
}


const findColumnIndex = (columnName, _this) => {
  /* Find index
    - Wierd thing going on here. Let's say we try to find index for column "A".
    - agData.column.colId would sometimes be "A", sometimes "A_1".
    - Similarly, the value for the column in the _this.gridColumnApi.columnController.columnDefs list
      is one of "A" or "A_1".
   */

  // Find index normally.
  let colIndex = _.findIndex(_this.gridColumnApi.columnController.columnDefs, function (o) { return o.field === columnName; });

  // OR find index by appending "_1" to columnName.
  if (colIndex === -1)
    colIndex = _.findIndex(_this.gridColumnApi.columnController.columnDefs, function (o) { return o.field === columnName + '_1'; });

  // OR find index by stripping off "_1" from the end of columnName.
  if (colIndex === -1)
    colIndex = _.findIndex(_this.gridColumnApi.columnController.columnDefs, function (o) { return o.field === columnName.substring(0, columnName.length - 2); });

  return colIndex;
}


export const addAgRow = (direction, _this) => {
  const selectedNodes = _this.gridApi.getSelectedNodes();
  const {docData, selectedTableIndex, activeId} = _this.state;
  const originalData = {...docData};

  if (selectedNodes.length === 1) {
    // Filter out tables from extracted_data. Max indices would be within
    // tables since addition/deletion of rows/columns happen only in tables.
    const tables = docData[activeId].data.extracted_data.filter(obj => obj.property_id.toLowerCase() === 'table');
    const maxIndex = TableUtils.getMaxIndexFromTables(tables);

    const table = docData[activeId].data.extracted_data.filter(obj => obj.index === selectedTableIndex)[0];
    const columnLength = table.items.filter(obj => obj.subtype.toLowerCase() === 'header_row')[0].elements.length;

    let rowIndex = selectedNodes[0].data.row_index;
    if (direction === 'below')
      rowIndex += 1;

    const tableRow = TableUtils.getDummyTableRow(maxIndex, rowIndex, columnLength);
    table.items.splice(rowIndex, 0, {...tableRow});

    // Reassign row indices
    _.forEach(table.items, (obj, i) => {
      obj.row_index = i;
    });

    const payload = {
      add_data: {
        data_type: 'row',
        row_index: rowIndex,
        table_row: tableRow,
        table_index: selectedTableIndex
      }
    };
    PatchRequestV2(
      URL.getFeedbackUrl + '/' + activeId + '/update_data',
      _this.controller.signal,
      payload,
      () => {
        handleTableView(table, _this);
      }, (err) => {
        _this.setState({docData: originalData});
      });
  }
}


export const addAgColumn = (agData, direction, _this) => {
  const {docData, selectedTableIndex, activeId} = _this.state;
  const originalData = {...docData};
  let columnName = agData.column.colId;

  let colIndex = findColumnIndex(columnName, _this);
  if (direction === 'right')
    colIndex += 1;

  // const table = docData[activeId].data.extracted_data.filter(obj => obj.index === selectedTableIndex)[0]

  const payload = {
    add_data: {
      data_type: 'column',
      table_index: selectedTableIndex,
      column_index: colIndex
    }
  };
  PostRequest(
    URL.modifyTable + '/' + activeId,
    _this.controller.signal,
    payload,
    (data) => {
      const updatedTable = data['table'];
      let table = _.filter(docData[activeId].data.extracted_data, obj => ((obj.index === updatedTable.index) && (obj.label === updatedTable.label)));
      if (table.length === 1) {
        table = table[0];
        table.items = updatedTable.items;
        _this.setState({docData}, () => {
          handleTableView(table, _this);
        });
      }
    }, (err) => {
      _this.setState({docData: originalData});
    });
}


export const setAsTableHeader = (_this) => {
  const selectedNodes = _this.gridApi.getSelectedNodes();
  const {docData, activeId, selectedTableIndex} = _this.state;

  if (selectedNodes.length === 1) {
    const node = selectedNodes[0];
    const tables = docData[activeId].data.extracted_data.filter(obj => obj.property_id.toLowerCase() === 'table');
    const table = tables.filter(obj => obj.index === selectedTableIndex)[0];
    const headerRow = {...table.items.filter(row => row.subtype === 'header_row')[0]};

    let nameColIndexMapping = {};
    _.forEach(headerRow.elements, (element, i) => {
      nameColIndexMapping[element.value] = element.column_index;
    });

    let colIndexNameMapping = {};
    _.map(node.data, (value, key) => {
      colIndexNameMapping[nameColIndexMapping[key]] = value;
    });

    _.forEach(headerRow.elements, (element, i) => {
      const val = colIndexNameMapping[element.column_index];
      element.value = (val === '') ? 'Header ' + element.column_index : val;
    });
    deleteAgRows({...headerRow}, _this);
  } else {
    toast.error('Only one row can be selected to replace the header values of the table');
  }
}


export const deleteAgColumn = (params, _this) => {
  const {docData, selectedTableIndex, activeId} = _this.state;
  const originalData = {...docData};

  const tables = docData[activeId].data.extracted_data.filter(obj => obj.property_id.toLowerCase() === 'table');
  const table = tables.filter(obj => obj.index === selectedTableIndex)[0];

  const headerRow = table.items.filter(obj => obj.subtype === 'header_row')[0];
  const dataRows = table.items.filter(obj => obj.subtype === 'data_row');

  const colIndex = findColumnIndex(params.column.colId, _this);

  // Copy value from given column to "Description" column in data rows
  // _.forEach(dataRows, (obj, index) => {
  //   obj.elements[descColumnIndex].value = obj.elements[colIndex].value
  // })

  // Delete the values from given column in data rows
  _.forEach(dataRows, (obj, index) => {
    obj.elements.splice(colIndex, 1);
  });

  // Reassign indices for data rows
  _.forEach(dataRows, (obj, i) => {
    _.forEach(obj.elements, (cell, index) => {
      cell.index = index;
      cell.column_index = index;
    });
  });

  // Delete the values from given column in header row
  headerRow.elements.splice(colIndex, 1);

  // Reassign indices for header row
  _.forEach(headerRow.elements, (cell, index) => {
    cell.index = index;
    cell.column_index = index;
  });

  const payload = {
    delete_data: {
      data_type: 'column',
      table_index: selectedTableIndex,
      column_index: colIndex
      // desc_column_index: descColumnIndex,
    }
  };
  PatchRequestV2(
    URL.getFeedbackUrl + '/' + activeId + '/update_data',
    _this.controller.signal,
    payload,
    () => {
      _this.setState({docData}, () => {handleTableView(table, _this);});
    }, (err) => {
      _this.setState({docData: originalData});
    });
}


export const deleteAgRows = (headerRow, _this) => {
  const {docData, selectedTableIndex, activeId} = _this.state;
  const originalData = {...docData};
  const selectedNodes = _this.gridApi.getSelectedNodes();

  if (selectedNodes.length > 0) {
    const selectedTableIndices = _.map(selectedNodes, (node) => node.data.row_index);
    const table = docData[activeId].data.extracted_data.filter(obj => obj.index === selectedTableIndex)[0];
    table.items = _.filter(table.items, (obj) => !_.includes(selectedTableIndices, obj.row_index));

    // Reassign row indices
    _.forEach(table.items, (obj, i) => {
      obj.row_index = i;
    });
    let payload = {};
    // Checking if headerRow is passed as an argument, in case of normal deletion of rows just pass an empty string ie "" as 1st argument (for headerRow)
    // If headerRow is passed an appropriate non empty value, that value will be used by agGrid to rewrite the table header as what you have passed in the headerRow object
    if (headerRow){
     payload = {
        delete_data: {
          data_type: 'row',
          row_indices: selectedTableIndices,
          table_index: selectedTableIndex,
          header_row: {...headerRow},
        }
      };
    } else {
     payload = {
        delete_data: {
          data_type: 'row',
          row_indices: selectedTableIndices,
          table_index: selectedTableIndex,
        }
      };
    }
    PatchRequestV2(
      URL.getFeedbackUrl + '/' + activeId + '/update_data',
      _this.controller.signal,
      payload,
      () => {
        _this.setState({docData}, () => {handleTableView(table, _this);});
      }, (err) => {
        _this.setState({data: originalData});
      });
  }
}


/**
 *Generates the name of the downloaded table
 *
 * @param {*} params {The parameter object used by the AGgrid getContextMenuItems function}
 * @param {*} extension {The file extension for the type to be used }
 * @memberof ResultViewer
 */
export const handleName = (params, extension, _this) => {
  let {activeDoc, activeId, docData, selectedTableIndex, isAggView} = _this.state;

  //We assume that all the documents being compared are in the same path
  let path = activeDoc.path;
  let docName;
  let tableName;

  if (isAggView) {
    docName = '';
    tableName = 'AggregateTable';
  } else {
    docName = activeDoc.name;
    tableName = docData[activeId].data.extracted_data.filter(obj => obj.index === selectedTableIndex)[0].label;
  }

  params['fileName'] = _this.createName(path, docName, extension, tableName);
};


export const updateHeader = (e, newValue, toggleModal = null, _this) => {
  /*
  The technical debt we have to deal with concerns the data structure for tables on the backend, the frontend
  in ag-grid and in state.  Ideally we don't have to update the data in the table 2-3 times in different formats
  Ideally we just use state and let react handle it.  This should be refactored
  */

  // hide the modal if coming from edit column header modal
  if (toggleModal !== null && toggleModal === true) {
    _this.toggleColDescriptionModal(false);
  }

  const {docData, selectedTableIndex, activeId} = _this.state;
  const table = docData[activeId].data.extracted_data.filter(obj => obj.index === selectedTableIndex)[0];

  // get current colId
  let columnName = '';
  columnName = e.column.colId;

  // Change value
  let makeCol = _this.gridColumnApi.getColumn(columnName);
  makeCol.colDef.headerName = newValue;

  _this.gridApi.refreshHeader();

  // find index
  let col_index = findColumnIndex(columnName, _this);

  let colDefs = _this.gridApi.columnController.columnDefs;
  // might need to throw a try catch here
  colDefs[col_index].headerName = newValue;
  colDefs[col_index].field = newValue;
  log(colDefs);
  _this.gridApi.setColumnDefs(colDefs);

  table.items[0].elements[col_index].value = newValue;

  let updateList = [];
  updateList.push({row: 0, column: col_index, value: newValue});

  const originalData = {...docData};
  const payload = {
    update_data: {
      update_list: updateList,
      table_index: selectedTableIndex
    }
  };

  PatchRequestV2(
    URL.getFeedbackUrl + '/' + activeId + '/update_data',
    _this.controller.signal,
    payload,
    () => {
      log('Header save successful');
      handleTableView(table, _this);
    },
    (err) => {
      _this.setState({docData: originalData});
    }
  );
}


export const updateData = (e, _this) => {
  /*
  Steps
    - capture updated cells
    - if there are more than 1 row update all rows for that column
    - if that column is the tax_cat column update both columns - the roc column too
    - if there are BOTH more than 1 row selected and the tax_cat column is edited,
      broadcast that value to all rows and the roc value to all rows
  */

  // Fix for a bug which occurs when user closes modal mid-update
  // Probably need a better way of confirming the change or UI for preventing this
  if (!e.columnApi) {
    return;
  }

  const {docData, selectedTableIndex, activeId, aggTable} = _this.state;

  const table = selectedTableIndex === 0
    ? aggTable
    : docData[activeId].data.extracted_data.filter(obj => obj.index === selectedTableIndex)[0];

  ajaxUpdateData(e, table, false, _this);
}


export const updateSelectedValuesOfAColumn = (e, ContextMenu, _this) => {
  const {docData, selectedTableIndex, activeId, aggTable, showEditCellsModal} = _this.state;

  if (ContextMenu === true && !_this.state.showEditCellsModal) {
    _this.setState({cellData: {params: e, ContextMenu}}, () => {
    });
  }

  if (selectedTableIndex === 0) {
    if (!showEditCellsModal) {
      _this.toggleEditCellsModal();
      return;
    }

    _this.toggleEditCellsModal();

    const tableStartIndex = getTableStartIndex(_this);
    const selectedRows = _this.gridApi.getSelectedNodes();
    let updateDataObj = {};
    _.forEach(selectedRows, node => {
      const columnName = e.column.colId;
      const {uuid: docUUID, Document} = node.data;
      let {row_index} = node.data;

      const filteredList = tableStartIndex[docUUID].filter(obj => obj.docName === Document);
      if (filteredList.length > 0) {
        // Normalize row index to 1 based index.
        row_index = row_index - filteredList[0].rowIndex + 1;

        const update_obj = getUpdateData(e, docUUID, Document, columnName, row_index, _this);
        if (Object.keys(update_obj.updateObj).length > 0) {
          const keys = Object.keys(updateDataObj);
          if (keys.includes(docUUID)) {
            const fList = updateDataObj[docUUID].filter(obj => obj.table_index === update_obj.table_index);
            if (fList.length === 0)
              updateDataObj[docUUID].push({
                update_list: update_obj.updateObj,
                table_index: update_obj.table_index
              });
            else {
              fList[0].update_list.push(update_obj.updateObj);
            }
          } else
            updateDataObj[docUUID] = [{
              update_list: [update_obj.updateObj],
              table_index: update_obj.table_index
            }];
        }
      }
    });

    const originalCompareTable = {...aggTable};
    const payload = {update_data: updateDataObj};
    PostRequest(URL.updateMultipleTables, _this.controller.signal, payload, () => {
        log('save successful');
        getAggTable(_this);
        handleTableView(aggTable, _this)
      },
      (err) => {
        handleTableView(originalCompareTable, _this);
      }
    );
  } else {
    /*
    Steps
      - capture updated cells
      - if there are more than 1 row update all rows for that column
      - if that column is the tax_cat column update both columns - the roc column too
      - if there are BOTH more than 1 row selected and the tax_cat column is edited,
        broadcast that value to all rows and the roc value to all rows
    */
    _this.toggleEditCellsModal();

    const table = docData[activeId].data.extracted_data.filter(obj => obj.index === selectedTableIndex)[0];
    if (showEditCellsModal)
      ajaxUpdateData(e, table, true, _this);
  }
}
