/**
 * Created by Akhtar on 17/01/2020.
 */


export default class Utils {
  static superadminOrg = "Nirveda"
  static pageSize = 5
  static rowsPerPageOptions = [5, 10, 25]
  static docPageSize = 10
  static docRowsPerPageOptions = [10, 20, 30]
  static pageQueryParam = "page"
  static pageSizeQueryParam = "page_size"
  static enterKeyCode = 13

  static getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  static getMilisecondsSinceEpoch() {
    const now = new Date()
    return Math.round(now.getTime())
  }

  static encodeQueryParams(params) {
    let esc = encodeURIComponent;
    return Object.keys(params)
      .map(k => esc(k) + '=' + esc(params[k]))
      .join('&');
  }

  static getDefaultValueIfUndefined = (value, defaultValue) => {
    return (typeof value === "undefined") ? defaultValue : value
  }

  static base64ToArrayBuffer = (base64) => {
     const binaryString = window.atob(base64);
     const binaryLen = binaryString.length;
     const bytes = new Uint8Array(binaryLen);
     for (let i = 0; i < binaryLen; i++) {
       bytes[i] = binaryString.charCodeAt(i);
     }
     return bytes;
   }

  static blobToFile = (blob) => {
    return new File(
      [blob],
      blob.name,
      {
        type: blob.type,
        lastModified: Date.now()
    });
  }

  static getFileType = (fileNameWithExtension) => {
    return fileNameWithExtension.split('.').slice(-1)[0]
  }

  static genKey = () => {
    return Math.floor(Math.random() * 1000000) + "_key"
  }

  static roundToTwo = num => {
    if (isNaN(parseFloat(num))) {
      // try to smartly extract any int or float from a string
      let regex = /[+-]?\d+(\.\d+)?/g;
      let nums = num.match(regex).map(function (v) {return parseFloat(v);});
      // we have a choice of how to collate multiple numbers in on line
      // we could sum them or ?
      num = nums[0];
    }
    return +(Math.round(num + 'e+2') + 'e-2');
  };

  // Was defined somewhere but not being used anywhere. Put it here for reference.
  static getFileExt = (data) => {
    let file_exts = []
    data["extracted_data"].filter(row => row.value).map((row)=>file_exts.push(row.value.split(".").pop().toLowerCase()) )
    let uniqs = file_exts.reduce((acc, val) => {
      acc[val] = acc[val] === undefined ? 1 : acc[val] += 1;
      return acc;
    }, {});
    return Object.entries(uniqs).map(elem=> ""+elem[0]+"("+elem[1]+")")
  }

  // Was defined somewhere but not being used anywhere. Put it here for reference.
  static titleCase = str => {
    str = str.toLowerCase();
    str = str.split(' ');
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(' '); // ["I'm", "A", "Little", "Tea", "Pot"].join(' ') => "I'm A Little Tea Pot"
  }
}
