/**
 * Created by Akhtar on 12/05/2020.
 */


import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DescriptionIcon from '@material-ui/icons/Description';
import BackIcon from '@material-ui/icons/KeyboardArrowLeft';
import MinusIcon from '@material-ui/icons/KeyboardArrowRight';
import RemoveIcon from '@material-ui/icons/Remove';
import React, {Fragment} from 'react';
import {Document, Page} from 'react-pdf';
import Utils from '../../../utils';


function RightContainer(props) {
  const {
    classes,
    open,
    loadingRight,
    fileURL,
    validImgTypes,
    activeDoc,
    pageNumber,
    numPages,
    scope,
    handleZoomDown,
    handleZoomPlus,
    handlePageChange,
    handleZoomDownImage,
    onDocumentLoadSuccess,
    closeNotifyModal,
    clearCanvas,
    goToPrevPage,
    goToNextPage,
    handleZoomPlusImage,
  } = props

  return (
    <div className={classes.rightContainer}>
      <div className={classes.paper}>
        <Fragment>
          {
            loadingRight || (activeDoc === null) ? <div className={classes.loader}>
                <CircularProgress className="loader" style={{color: '#5fafff'}} size={20} /></div> :
              <Fragment>
                {
                  fileURL !== '' ?
                    <div>
                      <div className={classes.fileContentHeader}>
                        <Typography className={classes.rightTitle}>{activeDoc.name}</Typography>
                        <div className={classes.rightMenu}>
                          {
                            activeDoc.name ?
                              activeDoc.name.split('.').pop() === 'pdf' ?
                                <div className={classes.zoomArea}>
                                  <div className={classes.paginationRow}>
                                    <Button className={classes.paginationButton} disabled={pageNumber === 1} onClick={goToPrevPage}><BackIcon fontSize={'small'} /></Button>
                                    <input defaultValue={pageNumber} name="pageNumber" key={Utils.genKey()} className={classes.zoomInput} onChange={handlePageChange} />
                                    <div className={classes.italicSmall}>of {numPages}</div>
                                    <Button className={classes.paginationButton} disabled={pageNumber === numPages} onClick={goToNextPage}><MinusIcon fontSize={'small'} /></Button>
                                  </div>
                                  <div style={{marginRight: '16px'}}>
                                    <Button className={classes.paginationButton} disabled={scope === 0.25} onClick={handleZoomDown}><RemoveIcon fontSize={'small'} className={classes.smallIcon} /></Button>
                                    <span className={`${classes.zoomInput} ${classes.borderBox}`} style={{maxWidth: '40px'}}>{scope * 100}%</span>
                                    <Button className={classes.paginationButton} disabled={scope >= 2} onClick={handleZoomPlus}><AddIcon fontSize={'small'} /></Button>
                                  </div>
                                </div>
                                : <div>
                                  {/* Logic to determine if zoom is supported, if it is not, disable onclick function */}
                                  <Button
                                    className={classes.paginationButton}
                                    disabled={scope === 0.25}
                                    onClick={validImgTypes.indexOf(activeDoc.name.split('.').pop()) >= 0 ? handleZoomDownImage : () => {}}>
                                    <RemoveIcon fontSize={'small'} className={classes.smallIcon} />
                                  </Button>
                                  <span className={`${classes.zoomInput} ${classes.borderBox}`} style={{maxWidth: '40px'}}>{scope * 100}%</span>
                                  <Button
                                    className={classes.paginationButton}
                                    disabled={scope >= 2}
                                    onClick={validImgTypes.indexOf(activeDoc.name.split('.').pop()) >= 0 ? handleZoomPlusImage : () => {}}>
                                    <AddIcon fontSize={'small'} />
                                  </Button>
                                </div>
                              : null
                          }
                        </div>
                      </div>
                      <div className="pageContent" > 
                        {
                          (() => {
                            // This was attached to .pageContent above >> onScroll={clearCanvas}


                            // This isn't optimal but I would much rather have legible switch statements than
                            // ternary operators that run on forever lol
                            switch (true) {
                              case (activeDoc.name && activeDoc.name.split('.').pop().toLowerCase() === 'pdf'):
                                return (
                                  <div id='nvDoc'>
                                    <Document file={fileURL} onLoadSuccess={onDocumentLoadSuccess} >
                                      <Page pageNumber={pageNumber} scale={scope} className="mainCanvasPage" />
                                    </Document>
                                  </div>
                                );
                              case (activeDoc.name && activeDoc.name.split('.').pop().toLowerCase() === 'jpg'):
                                return (
                                  <div className="mainCanvasPage" id='nvDoc'>
                                      <img id="imageRight" className="scope-100" src={fileURL} alt="#" />
                                      {/*<canvas />*/}
                                  </div>
                                );
                              case (activeDoc.name && activeDoc.name.split('.').pop().toLowerCase() === 'gif'):
                                return (
                                  <div className="mainCanvasPage" id='nvDoc'>
                                      <img id="imageRight" className="scope-100" src={fileURL} alt="#" />
                                      {/*<canvas />*/}
                                  </div>
                                );
                              case (activeDoc.name && activeDoc.name.split('.').pop().toLowerCase() === 'png'):
                                return (
                                  <div className="mainCanvasPage" id='nvDoc'>
                                      <img id="imageRight" className="scope-100" src={fileURL} alt="#" />
                                      {/*<canvas />*/}
                                  </div>
                                );
                              default:
                                return <div id="noFileIcon"><DescriptionIcon /></div>;
                            }
                          })()
                        }
                        
                      </div>
                      <Modal open={open}>
                        <React.Fragment>
                          <div className="modalCustomHeader">
                            <div style={{padding: '20px'}}>
                              <Typography variant="subtitle2">
                                <p> Your document, {activeDoc.name} has been submitted for reprocessing. </p>
                              </Typography>
                              <div className={classes.submitDelete}>
                                <Button className={classes.actionButton} onClick={() => closeNotifyModal()}>
                                  Ok
                                </Button>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      </Modal>
                    </div> : null
                }
              </Fragment>
          }
        </Fragment>
      </div>
    </div>
  );
}

export default RightContainer;
