import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import './index.css';
import './responsive.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import {BrowserRouter} from "react-router-dom";
import {LicenseManager} from "@ag-grid-enterprise/core";

let agGridKey = process.env.REACT_APP_AG_GRID_KEY
LicenseManager.setLicenseKey(agGridKey);

console.log(process.env.REACT_APP_CODE_ENV)
let codeEnv = process.env.REACT_APP_CODE_ENV

Sentry.init({dsn: "https://7c6a329843fd475689f4d4ea45daa7cf@sentry.io/1845181", environment: codeEnv});
let placeHolder = <div style={{width:'100%', margin: '100px auto', 'textAlign':'center'}}>
	Nirveda Cognitive Platform Loading...
{/*	<img style={{width: '50%', height:'auth', margin:'0 auto'}} src='https://app.nirvedacognition.ai/static/media/NirvedaCognition_Logos-37.83330ed5.png'/>*/}
	</div>

ReactDOM.render(<BrowserRouter>
  <Suspense fallback={(placeHolder)}><App /></Suspense>
</BrowserRouter>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
