import {withStyles} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import * as PropTypes from 'prop-types';
import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';

const styles = {
  modal: {
    background: 'white',
    position: 'relative',
    width: '550px',
    top: '20%',
    left: '35%',
    textAlign: 'left'
  },
  modalTitle: {
    background: 'linear-gradient(70deg, #5159F8, #5EAAFF)',
    color: 'white',
    textAlign: 'left',
    padding: '20px'
  },
  modalContent: {
    padding: '20px'
  },
  textInput: {
    '& input' :{
      padding: '10px',
    },
    marginTop: '20px',
    width: '100%'
  },
  connectButton: {
    background:'#618AFF',
    color: 'white',
    borderRadius: '8px',
    textTransform: 'none',
    fontSize: '14px',
    padding: '7px 24px'
  },
  submit: {
    textAlign: 'right',
    borderTop: '1px solid #E1E3E4',
    paddingTop: '16px'
  },
  label: {
    marginLeft: '10px !important'
  },
  actionButton: {
    marginRight: '10px',
    color: '#d3d3d3',
    fontSize: '16px',
    fontWeight: 'bold'
  }
};


class StepTwo extends Component {
  static propTypes = {
    // modalOpen: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
  };

  state = {
    ap_url: '',
    status: true,
    submit: false,
    apiUrlError: '',
    activeStep: 0,
    skipped: new Set()
  };


  controller = new window.AbortController();

  componentDidMount() {
    const {api_url} = this.props
    this.setState({
      ap_url: api_url,
      status: true,
      submit: false,
      apiUrlError: '',
      activeStep: 0,
      skipped: new Set()
    })
  }

  componentWillUnmount() {
    this.checkValidations()
    this.props.setApiUrl(this.state.api_url)
  }

  checkValidations() {
    const {t} = this.props;
    let valid = true;
    let apiUrlError = "";

    if (!this.state.api_url || this.state.api_url.trim() === '') {
      apiUrlError = t('msg.api-empty');
      valid = false;
    }

    this.setState({
      apiUrlError: apiUrlError,
    });

    return valid;
  }

  handleChange = (event) => {
    const {name, value} = event.target;
    this.setState(prevState => ({
      ...prevState,
      [name]: value
    }), () => this.checkValidations());

    this.props.setApiUrl(value)
  };

  // handleAdd = () => {
  //   const {modalOpen} = this.props;
  //   const {firstName, lastName, status, email, selectedGroups} = this.state;
  //   const payload = {
  //     name: firstName,
  //     family_name: lastName,
  //     email: email,
  //     groups: selectedGroups,
  //     role: status ? 'Admin' : 'User',
  //     status: 'Active'
  //   };
  //
  //   this.setState({submit: true}, () => {
  //     if (this.checkValidations()) {
  //       PostRequest(URL.onBoardingUser, this.controller.signal, payload, () => {
  //         modalOpen(false, true);
  //       }, (err) => {
  //         console.log(err);
  //       });
  //     }
  //   });
  // };

  render() {
    const {t, classes} = this.props;
    const {api_url, apiUrlError} = this.state;
    return (
        <React.Fragment>
            <div className="stepTwo">
            <Typography variant="subtitle1" style={{marginBottom: '20px'}}>
               <b>{t('super-admin.create-org-modal.step')} 2</b> {t('super-admin.create-org-modal.in-url')}
            </Typography>

            <Typography variant="subtitle2">
              <b>{t('super-admin.create-org-modal.step')}{t('super-admin.create-org-modal.in-url')}</b>
            </Typography>

            <Grid container spacing={1}>
                <Grid item xs={9}>
                    <TextField
                      label={t('super-admin.create-org-modal.in-url')}
                      name="api_url"
                      id="api_url"
                      value={api_url}
                      required
                      onChange={this.handleChange}
                      variant='outlined'
                      className={classes.textInput}
                      helperText={apiUrlError}
                      error={apiUrlError !== ''}
                    />
                </Grid>
                <Grid item xs={3}>
                  <Button variant="contained" style={{marginTop: '20px'}} color="primary" className={classes.connectButton}>{t('super-admin.create-org-modal.conn')}</Button>
                </Grid>
            </Grid>
            </div>
         </React.Fragment>
    );
  }
}

export default withTranslation()(withStyles(styles)(StepTwo));
