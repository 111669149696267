import {Paper, Table} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress  from '@material-ui/core/CircularProgress/CircularProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from '@material-ui/core/Modal';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import withStyles from '@material-ui/core/styles/withStyles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import BackIcon from '@material-ui/icons/KeyboardArrowLeft';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PersonIcon from '@material-ui/icons/PersonAdd';
import SearchIcon from '@material-ui/icons/Search';
import _ from 'lodash';
import React, {Component, Fragment} from 'react';
import {withTranslation} from 'react-i18next';
import ProfileImgBlue from '../../../assets/images/user_blue.png';
import ProfileImgGrey from '../../../assets/images/user_grey.png';
import {getUserName, getUserOrg} from '../../../common/loginService';
import {
  GetRequest,
  PatchRequest,
  PatchRequestV2,
  PostRequest
} from '../../../common/requests';
import {URL} from '../../../common/url';
import Utils from '../../../utils';
import CreateSuperAdmin from '../createSuperAdmin';

const styles = {
  formInput: {
    width: '40%'
  },
  filter: {
    minWidth: '175px',
    marginLeft: '1rem',
    background: '#F4F6F9',
  },
  groupSelect: {
    minWidth: '40%',
    maxWidth: '40%',
  },
  label: {
    // marginLeft: '10px !important'
  },
  button: {
    textTransform: 'unset',
    borderRadius: '30px',
    color: '#959CAE',
    fontSize: '14px',
    padding: '8px 16px'
  },
  updateButton: {
    color: '#686868',
    borderRadius: '8px',
    fontSize: '15px',
    fontWeight: '600',
    border: '1px solid #e1e3e4',
    padding: '10px 40px'
  },
  sendResetButton: {
    color: '#ffffff',
    backgroundColor: '#618bff',
    fontFamily: '\'Open Sans\', sans-serif !important',
    marginTop: '40px'
  },
  resetText: {
    color: '#1b1b1b',
    fontSize: '17px',
    fontFamily: '\'Open Sans\', sans-serif !important'
  },
  resetDescription: {
    color: '#686868',
    fontSize: '18px',
    fontFamily: '\'Open Sans\', sans-serif !important'
  },
  personIcon: {
    fontSize: '18px',
    marginRight: '10px'
  },
  mlAuto: {
    marginLeft: 'auto'
  },
  outlinedFilter: {
    padding: '1rem 0',
  },
  innerGrid: {
    marginTop: '2rem',
  },
  tableIcon:{
    marginRight: '0.5rem',
    marginBottom: '-0.5rem',
  },
  tableHeader: {
    borderBottom: '2px solid #e8e8e8'
  },
  editBack: {
    paddingLeft: 0,
    marginLeft: '-0.5rem',
  },
  titleHead: {
    marginTop: '1.5rem',
    marginBottom: '1rem',
  },
  titleMain: {
    marginTop: '2rem',
  },
  inputSide: {
    width: '100%'
  },
  firstGrid: {
    width: '40%',
    marginRight: '2rem'
  },
  searchBar: {
    width: '50%',
    paddingTop: '1rem',
  },
  limitText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
  },
  loader: {
    position: 'relative',
    left: 'calc(50% - 20px)',
    marginTop: '40px',
    marginRight: 'auto'
  },
  menuItem: {
    fontFamily: '\'Open Sans\', sans-serif !important',
    fontSize: '17px',
    color: '#141b2d',
    padding: '0 16px'
  },
  modal: {
    background: 'white',
    position: 'relative',
    width: '450px',
    top: '30%',
    left: '30%',
    textAlign: 'left',
  },
  modalSubject: {
    color: '#141b2d',
    fontSize: '21px',
    fontFamily: '\'Open Sans\', sans-serif !important',
    marginBottom: '24px'
  },
  modalDescription: {
    fontFamily: '\'Open Sans\', sans-serif !important',
    color: '#1e2a5a'
  },
  submitDelete: {
    textAlign: 'right',
    marginTop: '24px'
  },
  actionButton: {
    marginRight: '10px',
    color: '#000000',
    fontWeight: 'bold',
    fontSize: '15px',
    fontFamily: '\'Open Sans\', sans-serif !important'
  },
  modalItem: {
    '&:hover':{
      cursor: 'pointer',
    },
    display: 'flex'
  },
  modalText: {
    verticalAlign: 'super',
    textDecoration: 'none !important',
    color: '#141b2d',
    fontFamily: '\'Open Sans\', sans-serif !important',
    fontSize: '17px'
  },
  optionsFiles: {
    position: 'absolute',
    width: 'max-content',
    zIndex: '2',
    boxShadow: '0 5px 2px 0 rgba(209, 218, 229, 0.7)',
    border: '1px solid #e1e3e4',
    padding: '16px',
    borderRadius: '10px',
    fontFamily: '\'Open Sans\', sans-serif !important'
  },
  ActiveTag : {
    background: '#5fafff',
    marginLeft: '40px',
    marginRight: '32px',
    borderRadius: '20px',
    fontSize: '13px',
    color: 'white',
    fontFamily: '\'Open Sans\', sans-serif !important',
    padding: '4px 8px'
  },
  siteConfigHeading: {
    fontSize: '18px',
    fontFamily: 'Raleway !important',
    color: '#686868',
    fontWeight: '500',
    letterSpacing: '0.22px',
    lineHeight: '1.2'
  },
  userImage: {
    width: '80px'
  },
  avatarArea: {
    margin: '-20px 0 0 0',
    display: 'flex'
  },
  userName: {
    fontSize: '1rem',
    fontFamily: 'Raleway !important',
    margin: '20px 0 0 0'
  }
};

class SuperUser extends Component{

  state= {
    page: 0,
    rowsPerPage: Utils.pageSize,
    users: [],
    userCount: 0,
    userSearchTerm: "",
    filters: {},

    openModal: false,
    filter: 'all',
    filterRole: 'all',
    edit: false,
    selectedUser: {},
    firstName: '',
    lastName: '',
    email: '',
    groups: [],
    status: true,
    role: '',
    id: 0,
    anchor: null,
    open: false,
    totalGroups: [],
    openSnack: false,
    snackMsg: '',
    loading: true,
    firstNameError: false,
    lastNameError: false,
    resetPassword : false,
    resetPasswordModal: false,
    showActions : false,
    openDeactivate: false,
    rolesList: [],
  };
  controller = new window.AbortController();
  cleanup = null;

  componentDidMount() {
    this.props.updateSelectedTab("superuser")
    this.cleanup = () => {
      this.controller.abort();
    };
    this.getUsers();
    this.getGroups();
    this.getRolesList();
  }

  componentWillUnmount() {
    if (this.cleanup) this.cleanup();
    this.cleanup = null;
  }

  getUsers = (page, pageSize) => {
    const {userSearchTerm, filters, rowsPerPage} = this.state
    this.setState({usersLoading: true, loading: true});
    
    if (page === undefined)
      page = 1

    if (pageSize === undefined)
      pageSize = rowsPerPage
    
    let params = {
      superuser: true,
      org_id: getUserOrg(),
      [Utils.pageQueryParam]: page,
      [Utils.pageSizeQueryParam]: pageSize
    };

    if (userSearchTerm)
      params["search"] = userSearchTerm

    if (!_.isEmpty(filters))
      params = {...params, ...filters}

    const queryParams = Utils.encodeQueryParams(params)
    GetRequest(URL.users + '?' + queryParams, (response) => {
      const {data: users, count: userCount} = response
      this.setState({
        users,
        userCount,
        loading: false,
        usersLoading: false
      }, () => {
      });
    }, (err) => {
      this.setState({
        loading: false,
        usersLoading: false
      })
    });
  };

  getRolesList = () => {
    GetRequest(URL.getRoles,(data)=> {
      this.setState({rolesList: data.payload ? data.payload : []});
    }, (err)=> {console.log(err)})
  };

  getGroups = () => {
    this.setState({groupsLoading: true});
    const orgId = getUserOrg()

    GetRequest(URL.groups + "?org_id=" + orgId, (data) => {
      this.setState({
        groups: data.payload ? data.payload.reverse() : [],
        totalGroups: data.payload ? data.payload.reverse() : [],
        page: 0,
        groupsLoading: false
      });
    }, (err) => {
      console.log(err);
    });
  };

  modalOpen = (value, refresh) => {
    this.setState({openModal: value});
   if(refresh){
     this.getUsers();
     this.getGroups();
   }
  };
  
  handleSearch = (event) => {
    if (event.constructor.name === "SyntheticEvent") {
      const {name, value} = event.currentTarget;

      // Just set the search value in state. Additionally, call the api in the setState callback function,
      // upon clearing everything on search bar .
      this.setState(
        prevState => ({[name]: value}),
        () => {
          if (!value)
            this.getUsers(1, this.state.rowsPerPage);
        });
    }
    else {
      // Call api on hitting enter key.
      if (event.keyCode === Utils.enterKeyCode)
        this.getUsers(1, this.state.rowsPerPage)
    }
  }
  
  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage
    });
    
    this.getUsers(newPage + 1)
  };

  handleChangeRowsPerPage = (event) => {
    const pageSize = parseInt(event.target.value, 10)
    this.setState({
      page: 0,
      rowsPerPage: pageSize
    });
    
    this.getUsers(1, pageSize)
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'firstName') {
      this.setState({firstNameError: false})
    }
    if (name === 'lastName') {
      this.setState({lastNameError: false})
    }
    this.setState(prevState => ({ ...prevState, [name]: value }));
  };

  handleEditUser = (row) => {
    this.setState({
      edit: true,
      selectedUser: row,
      groups: row.group_ids,
      email: row.email,
      firstName: row["first_name"],
      lastName: row['last_name'],
      status: row.user_status.toLowerCase() ==='active',
      role: row["user_category"] === 'ClientAdmin',
      id: row.id,
      anchor: null,
      open: false,
      firstNameError: '',
      lastNameError: ''
    });
  };

  handleResetPassword = () => {
    this.setState({
      resetPassword: true,
      showActions: false
    });
  };

  toggleActionsDropdown = () => {
    this.setState(prevState => ({...prevState, showActions: !prevState.showActions}));
  };

  openDeactivateModal = () => {
    this.setState({
      openDeactivate: true,
      showActions: false
    });
  };

  closeDeactivateModal = () => {
    this.setState({
      openDeactivate: false
    });
  };

  closeActionsDropdown = () => {
    this.setState({
      showActions: false
    });
  };

  handleBack = () => {
    this.setState({edit: false, selectedUser: {}}, () => {
      this.getUsers()
    });
  };

  handleBackFromReset = () => {
    this.setState({resetPassword: false});
  };

  sendResetMail = () => {
    this.setState({resetPasswordModal: true});
  };

  closeResetPasswordModal = () => {
    this.setState({resetPasswordModal: false});
  };

  resetClicked = (email) => {
    const payload = {
      email: email.toLowerCase(),
    };

    this.setState({ submit: true,loading: true}, () => {
        PostRequest(URL.forgotPassword, this.controller.signal, payload,()=> {
              this.setState(prevState => ({ ...prevState, resetPasswordModal: false }));
            },
            (err)=> {
              this.setState({loading: false, resetPasswordModal: false});
              console.log('err',err);
            });
    });
  };

  updateUser = () => {
    const {t} = this.props;
    const {firstName, lastName, groups, id} = this.state;
    if (firstName === '' || firstName.trim().length === 0) {
      this.setState({firstNameError: true});
      return;
    }
    if (lastName === '' || lastName.trim().length === 0) {
      this.setState({lastNameError: true});
      return;
    }
    const payload = {
      first_name: firstName,
      last_name: lastName,
      groups
    };
    // if (openDeactivate) {
    //   if (status === true) {
    //     payload.user_status = "Inactive";
    //   } else {
    //     payload.user_status = "Active";
    //   }
    // }
    if (firstName && lastName) {
      PatchRequest(URL.users + '/' + id + "?org_id=" + getUserOrg(),
        this.controller.signal, payload, () => {
        this.handleBack();
        this.closeDeactivateModal();
        this.setState({openSnack: true, snackMsg: t('msg.update-ok')});
      }, (err) => {
        console.log(err);
        this.closeDeactivateModal();
        this.setState({openSnack: true, snackMsg: t('msg.update-fail')});
      });
    } else {
      let msg = '';
      if (!firstName && lastName) {
        msg = t('msg.first-name-empty-1');
      } else if (firstName && !lastName) {
        msg = t('msg.last-name-empty-1');
      } else {
        msg = t('msg.full-name-empty-1');
      }
      this.setState({openSnack: true, snackMsg: msg});
    }
  };

  deactivateUser = () => {
    const {t} = this.props;
    const payload = {is_active: false};
      PatchRequestV2(URL.users + '/' + this.state.id + "?org_id=" + getUserOrg(),
        this.controller.signal,
        payload, () => {
        this.handleBack();
        this.closeDeactivateModal();
        this.setState({openSnack: true, snackMsg: t('msg.update-ok')});
      }, (err) => {
        console.log(err);
        this.closeDeactivateModal();
        this.setState({openSnack: true, snackMsg: t('msg.update-fail')});
      });
  }

  handleGroup = (event) => {
    const { value } = event.target;
    this.setState(prevState => ({ ...prevState, groups: value }));
  };

  handleClick = (event) => {
    this.setState({anchor: event.currentTarget, open: true});
  };

  handleClose = () => {
    this.setState({anchor: null, open: false});
  };

  handleUserAdded = () => {
    this.getUsers();
  }

  closeSnack = () => {
    this.setState({openSnack: false, snackMsg: ''});
  };

  getGroupName = (id) => {
    const group = this.state.totalGroups.filter(obj => obj.id === id)[0];
    return group.name;
  };

  render() {
    const {openModal, page, rowsPerPage, users, userCount, userSearchTerm, edit, resetPassword, resetPasswordModal, showActions, openDeactivate,
    groups, email, firstName, lastName , status, totalGroups, openSnack, snackMsg, loading, firstNameError, lastNameError} = this.state;
    const {t, classes, changeTab } = this.props;
    
    return(
      <Grid container direction="column" key="grid1" changeTab={changeTab}>
        {
          edit ?
              <React.Fragment>
              {
                resetPassword ?
                    <React.Fragment>
                      <Grid container direction="row" justify="space-between" alignItems="flex-start">
                        <Button onClick={() => this.handleBackFromReset()} className={classes.editBack}><BackIcon/> {t('super-admin.org-details.user-edit.reset-pwd.back-btn')}</Button>
                      </Grid>
                      <Grid item  className={classes.titleMain}>
                        <Typography className={classes.resetText}>{t('super-admin.org-details.user-edit.reset-pwd.reset-pwd')}</Typography>
                        <Typography variant='h5' className={classes.titleMain}>{firstName + ' ' + lastName}</Typography>
                        <Typography className={classes.resetDescription}>{t('super-admin.org-details.user-edit.reset-pwd.reset-details')}</Typography>
                      </Grid>
                      <Grid>
                        <Button variant="outlined" className={`${classes.button} ${classes.updateButton} ${classes.sendResetButton}`}
                                onClick={() => this.sendResetMail()}>{t('super-admin.org-details.user-edit.reset-pwd.reset-btn')}</Button>
                      </Grid>
                    </React.Fragment>
                    :
                    <React.Fragment>
                    <Grid container direction="row" justify="space-between" alignItems="flex-start">
                    <div className={classes.siteConfigHeading}>
                          <a href className="cursor-pointer" onClick={this.handleEditValue} style= {{marginRight: '20px'}}>{t('super-admin.title1')}</a> >
                          <b style= {{marginLeft: '20px'}}>{firstName + ' ' + lastName}</b>
                        </div>
                    <div className={classes.updateButtons}>
                        <Button variant="outlined" className={`${classes.button} ${classes.updateButton}`}
                                    onClick={() => this.updateUser()}>{t('super-admin.org-details.user-edit.update-btn')}</Button>
                    </div>
                  </Grid>
                      <Grid item>
                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" className={classes.titleMain}>

                          <div className={classes.avatarArea}>
                                <div className="imgDiv">
                                    <img src={ProfileImgBlue}  alt="" className={classes.userImage}/>
                                </div>
                                <div className={classes.userName}><b>{firstName + ' ' + lastName}</b></div>
                          </div>
                          <div className={classes.ActiveTag}>{status === true ? t('admin.groups.grp-act') : t('admin.groups.grp-inact')}</div>
                          {
                            getUserName() === email ? null :
                                <ClickAwayListener onClickAway={this.closeActionsDropdown}>
                                  <div className="cursor-pointer">
                                  <IconButton href onClick={() => this.toggleActionsDropdown()}>
                                    <MoreHorizIcon style={{color: '#959daf'}} className="margin"/>
                                  </IconButton>
                                    {
                                      showActions ?
                                          <Paper className={classes.optionsFiles}>
                                            <div className={classes.modalItem} style={{paddingBottom: '10px'}}
                                                 onClick={() => this.handleResetPassword()}>
                                              <a href
                                                className={classes.modalText}
                                                style={{margin: 'auto 0 auto 4px', cursor: 'pointer'}}>
                                                {t('super-admin.org-details.user-edit.dropdown.reset')}</a>
                                            </div>
                                            <div className={classes.modalItem}
                                                 onClick={() => this.openDeactivateModal()}>
                                              <a href className={classes.modalText}
                                                style={{margin: 'auto 0 auto 4px', cursor: 'pointer'}}>
                                                {
                                                  status ? <span className={classes.modalText}>{t('super-admin.org-details.user-edit.dropdown.deactivate')}</span> :
                                                  <span className={classes.modalText}>{t('super-admin.org-details.user-edit.dropdown.activate')}</span>
                                                }
                                              </a>
                                            </div>
                                          </Paper> : null
                                    }
                                  </div>
                                </ClickAwayListener>
                          }
                        </Grid>
                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                          <Grid className={classes.firstGrid}>
                            <Typography variant='subtitle2' className={classes.titleHead}><b>{t('super-admin.org-details.user-edit.first-name')}</b></Typography>
                            <TextField
                                name="firstName"
                                id="firstName"
                                onChange={this.handleChange}
                                value={firstName}
                                required
                                className={classes.inputSide}
                                variant='outlined'
                                inputProps={{
                                  style: {
                                    padding: '10px'
                                  }
                                }}
                                helperText={firstNameError ? t('msg.first-name-empty') : null}
                                error={firstNameError !== ''}
                            />
                          </Grid>
                          <Grid className={classes.firstGrid}>
                            <Typography variant='subtitle2' className={classes.titleHead}><b>{t('super-admin.org-details.user-edit.last-name')}</b></Typography>
                            <TextField
                                id="lastName"
                                name="lastName"
                                onChange={this.handleChange}
                                defaultValue={lastName}
                                required
                                className={classes.inputSide}
                                variant='outlined'
                                inputProps={{
                                  style: {
                                    padding: '10px'
                                  }
                                }}
                                helperText={lastNameError ? t('msg.last-name-empty') : null}
                                error={lastNameError !== ''}
                            />
                          </Grid>
                        </Grid>
                        <Typography variant='subtitle2' className={classes.titleHead}><b>Email</b></Typography>
                        <TextField
                            id="email"
                            name="email"
                            onChange={this.handleChange}
                            defaultValue={email}
                            disabled
                            className={classes.formInput}
                            variant='outlined'
                            inputProps={{
                              style: {
                                padding: '10px'
                              }
                            }}
                        />
                        <Grid>
                          <Typography variant='subtitle2' className={classes.titleHead}><b>{t('super-admin.org-details.user-edit.group')}</b></Typography>
                          <FormControl className={classes.groupSelect}>
                            <Select
                              multiple
                              value={groups}
                              onChange={this.handleGroup}
                              input={<Input id="select-multiple" />}
                              className={classes.select}
                              renderValue={selected => (
                                <div className={classes.chips}>
                                  {selected.map(value => (
                                    <Chip key={value} label={this.getGroupName(value)} className={classes.chip} />
                                  ))}
                                </div>
                              )}
                            >
                              {totalGroups.length > 0 && totalGroups.map(group => (
                                <MenuItem key={groups.name} value={group.id}>{group.name}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </React.Fragment>
              }
              </React.Fragment>
            :
            <React.Fragment>
              <Grid container direction="row" justify="space-between" alignItems="flex-start">
                <Typography variant='h6'>{t('super-admin.title')}</Typography>
                <Button variant="outlined" className={classes.button} onClick={() => this.modalOpen(true)}>
                  <PersonIcon className={classes.personIcon} />
                  {t('super-admin.txt1')}</Button>
              </Grid>
              <Grid>
                <TextField
                  name="userSearchTerm"
                  id="search"
                  onChange={this.handleSearch}
                  onKeyDown={this.handleSearch}
                  value={userSearchTerm}
                  className={classes.searchBar}
                  variant='outlined'
                  placeholder={t('super-admin.org-details.search-placeholder-1')}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end">
                        {/*<IconButton aria-label="delete" onClick={this.clearSearch}>*/}
                        {/*  <ClearOutlinedIcon fontSize="small"/>*/}
                        {/*</IconButton>*/}
                        <SearchIcon style={{ color: 'grey'}}/>
                      </InputAdornment>
                  }}
                  inputProps={{
                    style: {
                      padding: '0.75rem 1rem'
                    }
                  }}
                />
              </Grid>
              <Grid container
                    className={classes.innerGrid}
                    direction="row"
                    justify="space-between"
                    alignItems="center">
                <b>{t('super-admin.all-sup-adm')}</b>
              </Grid>
              <Fragment>
                {
                  loading?<div className={classes.loader}><CircularProgress className='loader' style={{ color: '#5fafff'}} size={20} /></div>:
                    <Grid>
                      <Table style={{tableLayout:'fixed'}} className="users-table">
                        <TableHead>
                          <TableRow className={classes.tableHeader}>
                            <TableCell style={{width:'30%', paddingLeft : '0'}}><b>{t('super-admin.org-details.table.name')}</b></TableCell>
                            <TableCell style={{width:'30%'}}><b>{t('super-admin.org-details.table.email')}</b></TableCell>
                            <TableCell style={{width:'30%'}}><b>{t('super-admin.org-details.table.status')}</b></TableCell>
                            <TableCell style={{width:'10%'}}> </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {users && users.map(row => (
                            <TableRow key={row.id}>
                              <TableCell style={{width:'20%', paddingLeft : '7px'}} component="th" scope="row" className={classes.limitText}>
                              <div className="avatarDiv">
                                    <div className="imgDiv">
                                        <img src={row.user_status.toLowerCase() === 'active' ? ProfileImgBlue : ProfileImgGrey} alt="" className="userIcon"/>
                                    </div>
                                    <div className="user-name"><Tooltip title={row["first_name"] + ' '+ row['last_name']}><span>{row["first_name"] + ' '+ row['last_name']}</span></Tooltip></div>
                                </div>
                              </TableCell>
                              <TableCell className="overflow-ellipsis" style={{width: '20%'}}>
                                <Tooltip title={row.email}>
                                  <span>{row.email}</span>
                                </Tooltip>
                              </TableCell>
                              <TableCell style={{width: '10%'}}>{row.user_status.toLowerCase() === 'active' ? t('super-admin.org-details.act') : t('super-admin.org-details.inact')}</TableCell>
                              <TableCell style={{width: '10%',color: 'rgb(149, 157, 175)'}}>
                                <IconButton href style={{cursor: 'pointer'}} onClick={() => this.handleEditUser(row)}>
                                  {row.email !== getUserName() && <MoreVertIcon className="margin"/>}
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                          {/* {emptyRows > 0 && (
                            <TableRow style={{ height: 48 * emptyRows }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )} */}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              rowsPerPageOptions={Utils.rowsPerPageOptions}
                              colSpan={6}
                              count={userCount}
                              rowsPerPage={rowsPerPage}
                              labelRowsPerPage={t('super-admin.org-details.table.per-page')}
                              page={page}
                              SelectProps={{
                                inputProps: { 'aria-label': 'Results per page' },
                                native: true,
                              }}
                              onChangePage={this.handleChangePage}
                              onChangeRowsPerPage={this.handleChangeRowsPerPage}
                              style={{paddingTop: '20px'}}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </Grid>
                }
              </Fragment>
            </React.Fragment>
        }
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          onClose={() => this.modalOpen(false)}
          open={openModal}
          className="add-super-admin-modal"
          >
          <React.Fragment>
             <CreateSuperAdmin firstName=""
               lastName=""
               orgName=""
               email=""
               handleUserAdded={this.handleUserAdded}
               modalOpen={this.modalOpen}
             />
          </React.Fragment>
        </Modal>
        <Modal open={resetPasswordModal}>
          <React.Fragment>
            <div className={classes.modal} key="inputForm">
              <div style={{padding: '20px'}}>
                <div className={classes.modalSubject}>
                  {t('super-admin.org-details.reset-pwd-modal.title')}
                </div>
                <div className={classes.modalDesciption}>
                  <div>{t('super-admin.org-details.reset-pwd-modal.txt1')} {t('super-admin.org-details.reset-pwd-modal.txt2')} {firstName + ' ' + lastName}{t('super-admin.org-details.reset-pwd-modal.txt3')}</div>
                  <div>{t('super-admin.org-details.reset-pwd-modal.txt4')}<b>{email}.</b></div>
                </div>
                <div className={classes.submitDelete}>
                  <Button className={classes.actionButton} onClick={() => this.closeResetPasswordModal()}>{t('super-admin.org-details.reset-pwd-modal.modify-user.no')}</Button>
                  <Button className={classes.actionButton} autoFocus onClick={() => this.resetClicked(email)}>{t('super-admin.org-details.reset-pwd-modal.modify-user.yes')}</Button>
                </div>
              </div>
            </div>
          </React.Fragment>
        </Modal>
        <Modal open={openDeactivate}>
          <React.Fragment>
            <div className={classes.modal} key="inputForm" style={{width: '520px'}}>
              <div style={{padding: '20px'}}>
                <div className={classes.modalSubject}>
                {t('super-admin.org-details.reset-pwd-modal.txt1')}
                  {status ?
                      <span> {t('super-admin.org-details.reset-pwd-modal.deact')}</span> :
                      <span> {t('super-admin.org-details.reset-pwd-modal.act')}</span>
                  } {t('super-admin.org-details.reset-pwd-modal.usr')}
                </div>
                <div className={classes.modalDescription}>
                  <div>{t('super-admin.org-details.reset-pwd-modal.modify-user.txt1')} {status ?
                      <span> {t('super-admin.org-details.reset-pwd-modal.deact')}</span> :
                      <span> {t('super-admin.org-details.reset-pwd-modal.act')}</span>
                  } {t('super-admin.org-details.reset-pwd-modal.modify-user.txt2')}</div>
                  <div>{t('super-admin.org-details.reset-pwd-modal.modify-user.txt3')}</div>
                </div>
                <div className={classes.submitDelete}>
                  <Button className={classes.actionButton} onClick={() => this.closeDeactivateModal()}>{t('super-admin.org-details.reset-pwd-modal.modify-user.no')}</Button>
                  <Button className={classes.actionButton} onClick={() => this.deactivateUser()}>{t('super-admin.org-details.reset-pwd-modal.modify-user.yes')}</Button>
                </div>
              </div>
            </div>
          </React.Fragment>
        </Modal>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={openSnack}
          autoHideDuration={3000}
          onClose={this.closeSnack}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{snackMsg}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.closeSnack}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Grid>
    )
  }
}

export default withTranslation()(withStyles(styles)(SuperUser));
