import React, {Component} from "react";
import * as PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import {GetRequest, PostRequest} from "../../../common/requests";
import {URL} from "../../../common/url";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from '@material-ui/core/Chip';
import { withTranslation } from 'react-i18next';

const styles = {
  modal: {
    background: 'white',
    position: 'absolute',
    width: '450px',
    top: '5%',
    left: '50%',
    marginLeft: '-225px',
    textAlign: 'left',
  },
  modalTitle: {
    background: 'linear-gradient(70deg, #5159F8, #5EAAFF)',
    color: 'white',
    textAlign: 'left',
    padding: '20px',
  },
  modalContent: {
    padding: '20px',
  },
  formInput: {
    margin: '10px',
    width: '95%'
  },
  submit: {
    textAlign: 'right',
    borderTop: '1px solid #E1E3E4',
    paddingTop: '16px'
  },
  label: {
    marginLeft: '10px !important'
  },
  actionButton: {
    marginRight: '10px',
    color: '#d3d3d3',
    fontSize: '16px',
    fontWeight: 'bold'
  }
};

class ClientUser extends Component{
  static propTypes = {
    modalOpen: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
  };

  state = {
    firstName: '',
    lastName: '',
    email: '',
    status: true,
    groups: [],
    selectedGroups: [],
    submit: false,
    firstNameError: '',
    lastNameError: '',
    emailError: ''
  };
  controller = new window.AbortController();

  componentDidMount() {
    this.getGroups();
  }

  getGroups = () => {
    const {orgId} = this.props
    GetRequest(URL.groups + "?org_id=" + orgId,(data)=> {
      this.setState({groups: data.payload ? data.payload : []});
    }, (err)=> {console.log(err)})
  };

  checkValidations() {
    if (!this.state.submit) {
      return;
    }
    let valid = true;
    let emailError = "";
    let firstNameError = "";
    let lastNameError = "";
    const {t} = this.props;
    if (this.state.email) {
      if (!ClientUser.validateEmail(this.state.email)) {
        emailError = t('msg.invalid-email')
        valid = false;
      }
    } else {
      emailError = t('msg.empty-email')
      valid = false;
    }

    if (!this.state.firstName || this.state.firstName.trim() === '') {
      firstNameError = t('msg.first-name-empty')
      valid = false;
    }

    if (!this.state.lastName || this.state.lastName.trim() === '') {
      lastNameError = t('msg.last-name-empty')
      valid = false;
    }

    this.setState({
      emailError: emailError,
      firstNameError: firstNameError,
      lastNameError: lastNameError,
    });
    return valid;
  }
  static validateEmail(email) {
    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(prevState => ({ ...prevState, [name]: value }), () => this.checkValidations());
  };

  handleGroup = (event) => {
    const { value } = event.target;
    this.setState(prevState => ({ ...prevState, selectedGroups: value}));
  };

  handleAdd = () => {
    const {modalOpen} = this.props;
    const {firstName, lastName,  status, email, selectedGroups} = this.state;

    const payload = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      groups: selectedGroups,
      is_admin: status
    };
    // const orgId = this.props.match.params.id;

    this.setState({submit: true}, ()=> {
      if (this.checkValidations()) {
      PostRequest(
        URL.onBoardingUser + "?org_id=" + this.props.orgId,
        this.controller.signal,
        payload,()=> {
        modalOpen(false, true);
      }, (err)=> {console.log(err)});
    }});
  };

  getGroupName = (id) => {
    const group = this.state.groups.filter(obj => obj.id === id)[0]
    return group.name
  }

  render() {
    const { t, classes, modalOpen} = this.props;
    const {firstName, lastName, groups, status, email, selectedGroups, emailError, firstNameError, lastNameError} = this.state;
    return(
      <div className={classes.modal} key="inputForm">
        <div className={classes.modalTitle}>
          <Typography variant="h6" >
          {t('admin.client-user.add-new-user')}
          </Typography>
        </div>
        <div className={classes.modalContent}>
          <Typography variant="subtitle1" >
            <b>{t('admin.client-user.invite')}</b>
          </Typography>
          <Typography variant="subtitle2" >
          {t('admin.client-user.inv-txt')}
          </Typography>
          <form onSubmit={event => {event.preventDefault();}} noValidate>
            <TextField
              label={t('admin.client-user.first-name')}
              name="firstName"
              id="firstName"
              onChange={this.handleChange}
              value={firstName}
              required
              className={classes.formInput}
              helperText={firstNameError}
              error={firstNameError !== ''}
            />
            <TextField
              label={t('admin.client-user.last-name')}
              id="lastName"
              name="lastName"
              onChange={this.handleChange}
              defaultValue={lastName}
              required
              className={classes.formInput}
              helperText={lastNameError}
              error={lastNameError !== ''}
            />
            <TextField
              label="Email"
              id="email"
              name="email"
              onChange={this.handleChange}
              defaultValue={email}
              required
              className={classes.formInput}
              helperText={emailError}
              error={emailError !== ''}
            />
            <Grid>
              <FormControl className="groupSelect" disabled={!groups || groups.length === 0}>
                <InputLabel htmlFor="select-multiple">{t('admin.client-user.groups')}</InputLabel>

                <Select
                  multiple
                  value={selectedGroups}
                  label={t('admin.client-user.select-group')}
                  onChange={this.handleGroup}
                  input={<Input id="select-multiple" />}
                  className={classes.select}
                  inputProps={{ id: 'fieldId' }}
                  helperText={t('admin.client-user.help-txt')}
                  margin="normal"
                  variant="outlined"
                  renderValue={selected => (
                    <div className={classes.chips}>
                      {selected.map(value => (
                        <Chip key={value} label={this.getGroupName(value)} className={classes.chip} />
                      ))}
                    </div>
                  )}
                >
                  {groups.length > 0 && groups.map(group => (
                    <MenuItem
                    key={group.id}
                    value={group.id}
                    style={{textOverflow: 'ellipsis'}}>
                      {group.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <FormControlLabel className={classes.label}
              control={
                <Checkbox
                  color="primary"
                  checked={status}
                  onChange={(e) => {
                    const { checked } = e.target;
                    this.handleChange({
                      target: {
                        name: "status",
                        value: checked,
                      },
                    });
                  }} />
              }
              label={t('admin.client-user.admin')}
              labelPlacement="start"
            />
            <div className={classes.submit}>
              <Button className={classes.actionButton} onClick={() => modalOpen(false)}>{t('admin.client-user.cancel')}</Button>
              <Button onClick={this.handleAdd} className="successButton">{t('admin.client-user.add-new-user')}</Button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default withTranslation()(withStyles(styles)(ClientUser));
