import _ from 'lodash';

let log = console.info.bind(window.console);


export const refreshSearch = (_this) => {
  let flattenedValues = {};
  let doc_list = [];
  const {documents, activeDoc, docData, search, capabilities} = _this.state;


  // this function is specific to property search for now
  // need to refactor to make more general
  log('refresh');

  if (docData === undefined || docData === null || documents === null || documents === undefined) {
    return false;
  }

  for (let uuid in docData) {
    let doc = docData[uuid];
    let doc_type = '';
    if (capabilities.indexOf('property_search') < 0) {
      // let doc_type = doc.data.result_type;
    } else {
      // prop search specific functionality
      doc_type = _.filter(doc.data.extracted_data, (item) => {
        if (item.label === 'Document Name') {
          return item;
        }
      });

      try {
        doc_type = doc_type[0];
      } catch (err) {
        doc_type = {value: 'Document'};
      }
    }

    _.forEach(doc.data.extracted_data, (field) => {
      search.addDocument(field);
    });

    let fields = [];
    for (let i = 0; i < doc.data.extracted_data.length; ++i){
      const field = doc.data.extracted_data[i]

      search.addDocuments(field);

      if (['Document Name'].indexOf(field.label) < 0) {
        fields.push({
          label: field.label,
          value: field.value ? field.value : field.items
        });
      }

      flattenedValues[field.label.toLowerCase()] = field.value ? field.value : field.items; //flatten all values into 1 set
    };

    // Is this used?
    // let name = null
    // if (documents) {
    //   for (let elem in documents) {
    //     if (uuid === elem) {
    //       name = documents[elem].name
    //     }
    //   }
    // }

    if (capabilities.indexOf('property_search') < 0) {
      doc_list.push({doc: documents[uuid].name, items: fields});
    } else {
      // If this is prop search then build a document list that will be used to
      // generate the pdf
      const documentType = doc_type ? doc_type.value : 'Unknown Document Type';
      log(documentType);
      doc_list.push({
        doc: documentType,
        items: fields,
        // adding file id because it's cronological and can replace parsing date in a pinch
        file_id: documents[uuid].name
      });
    }
  }

  // order by file_id so newest data about prop is first
  doc_list = _.orderBy(doc_list, ['file_id'], ['desc']);


  let pdfData = {};
  let ordered = {};
  Object.keys(flattenedValues).sort().forEach(function (key) {
    ordered[key] = flattenedValues[key];
  });
  flattenedValues = ordered;

  // this finds the "owner" by matching against this array of terms
  // walking backwards from the most recent file to the oldest
  let owner_list = ['owner', 'grantee', 'trustor'];
  let owner = undefined;
  _.forEach(doc_list, (propDoc) => {
    if (owner !== undefined) {
      return true;
    }
    _.forEach(propDoc.items, (possibleOwner) => {
      if (owner_list.indexOf(possibleOwner.label.toLowerCase()) >= 0) {
        owner = possibleOwner.value;
        return true;
      }
    });
  });

  // Encumbrances are liens or judgements against the property :|
  let encumbrancesCount = 0;
  _.filter(doc_list, (doc) => {
    if (doc.doc.toLowerCase().includes('lien') || doc.doc.toLowerCase().includes('judgement'))
      ++encumbrancesCount;
  });

  pdfData['apn'] = flattenedValues['apn'];
  pdfData['address'] = flattenedValues['address'];

  // Temporarily using the path param to set the address
  if (flattenedValues['address'] === undefined && activeDoc !== null
    && activeDoc['path']) {
    pdfData['address'] = activeDoc.path.replace('/', '');
  }

  pdfData['owner'] = owner;
  pdfData['encumbrances'] = encumbrancesCount;
  pdfData['docs'] = doc_list;
  if (docData !== undefined) {
    pdfData['no_of_docs'] = Object.entries(docData).length;
  } else {
    log(docData);
    pdfData['no_of_docs'] = 1;
  }
  log(doc_list)

  _this.setState({
    search: search,
    pdfData: pdfData
  });
};
