/**
 * Created by Akhtar on 18/02/2020.
 */

import _ from 'lodash';
import Utils from '../../../utils';



export default class TableUtils {
  static getTableCellValue = (obj, slug) => {
    const value = (obj.hasOwnProperty("value")) ? obj.value : slug
    if (value === "")
      return slug

    // If value is present in obj but is undefined or empty string, return slug.
    return Utils.getDefaultValueIfUndefined(value, slug)
  }

  static getMaxIndexFromList = data => {
    let maxIndex = -1
    _.forEach(data, (item, idx) => {
      maxIndex = (item.index > maxIndex) ? item.index : maxIndex
    })
    return maxIndex
  }

  static getMaxIndexFromTable = table => {
    let maximumIndex = -1

    _.forEach(table.items, (item, idx) => {
      let maxIndex = TableUtils.getMaxIndexFromList(item.elements)
      maxIndex = (item.index > maxIndex) ? item.index : maxIndex
      maximumIndex = (maxIndex > maximumIndex) ? maxIndex : maximumIndex
    })

    return (maximumIndex > table.index) ? maximumIndex : table.index
  }

  static getMaxIndexFromTables = tables => {
    let maximumIndex = -1

    _.forEach(tables, (table, idx) => {
      let maxIndex = TableUtils.getMaxIndexFromTable(table)
      maximumIndex = (maxIndex > maximumIndex) ? maxIndex : maximumIndex
    })

    return maximumIndex
  }

  static createRowElements = (columnLength, maxIndex) => {
    maxIndex += 1
    let elements = []
    _.forEach(Array(columnLength).fill(), (_, i) => {
      const obj = {
        type: "field",
        index: maxIndex + i,
        value: "",
        regions: [{}],
        subtype: "cell",
        column_index: i
      }
      elements.push(obj)
    })

    return [...elements]
  }

  static getDummyTableRow = (maxIndex, rowIndex, columnLength) => {
    maxIndex += 1
    const row = {
      type: "item",
      index: maxIndex,
      subtype: "data_row",
      row_index: rowIndex,
      elements: TableUtils.createRowElements(columnLength, maxIndex)
    }

    return {...row}
  }

  static getIndexOfColumn = (columnDefs, colName) => {
    let descColumnIndex = -1

    for (let i = 0; i < columnDefs.length; ++i){
      if (columnDefs[i].headerName.toLowerCase().includes(colName.toLowerCase())) {
        descColumnIndex = i
        break
      }
    }
    return descColumnIndex
  }

  static getDummyColumnCell = (columnType) => {
    const cellData = {
      type: "field",
      index: 0,
      value: "",
      column_index: 0,
    }

    if (columnType && columnType.toLowerCase() === "header")
      cellData["subtype"] = "header"
    else
      cellData["subtype"] = "cell"

    return cellData
  }

  static getLabelColumnObject = (colList, rowIndex, gridApi) => {
    const row = gridApi.getDisplayedRowAtIndex(rowIndex)

    if (colList.length === 1)
      return {labelColumn: colList[0], valueColumn: colList[0], labelColumnIndex: 0, valueColumnIndex: 0}

    if (isNaN(Math.trunc(row.data[colList[0]])))
      return {labelColumn: colList[0], valueColumn: colList[1], labelColumnIndex: 0, valueColumnIndex: 1}

    if (isNaN(Math.trunc(row.data[colList[1]])))
      return {labelColumn: colList[1], valueColumn: colList[0], labelColumnIndex: 1, valueColumnIndex: 0}

    return null
  }

}

