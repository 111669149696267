import {withStyles} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import * as PropTypes from 'prop-types';
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import {GetRequest, PostRequest} from '../../../common/requests';
import {URL} from '../../../common/url';
import SearchFinalize from './searchFinalize';
import SearchOptions from './searchOptions';
import StateCounty from './stateCounty';

let log = console.info.bind(window.console)

const styles = {
  modal: {
    background: 'white',
    position: 'relative',
    width: '550px',
    top: '20%',
    left: '50%',
    marginLeft: '-275px',
    textAlign: 'left'
  },
  modalTitle: {
    background: 'linear-gradient(70deg, #5159F8, #5EAAFF) !important',
    color: 'white',
    textAlign: 'left',
    padding: '20px'
  },
  formInput: {
    margin: '10px',
    width: '95%'
  },
  submit: {
    textAlign: 'right',
    borderTop: '1px solid #E1E3E4',
    paddingTop: '16px'
  },
  label: {
    marginLeft: '10px !important'
  },
  actionButton: {
    marginRight: '10px',
    color: '#d3d3d3',
    fontSize: '16px',
    fontWeight: 'bold'
  },
  searchSteps: {
    width: '100%',
    background: 'e1e3e4'
  },
  searchPrevNext: {
    background: '#fff',
    width: '100%',
    padding: '10px',
    borderTop: '1px solid #ececec',
    position: 'absolute',
    bottom: '0',
    marginTop: '0',
    textAlign: 'right',
    button: {
      marginRight: '50px'
    }
  },
  nextButton: {
    marginRight: '50px',
    fontWeight: '600'
  }
};


class PropertySearch extends Component {
  static propTypes = {
    modalOpen: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
  };

  state = {
    state: "",
    county: "",
    street: "",
    city: "",
    zipcode: "",
    name: "",
    order_no: "",
    task_id: "",
    addressPickList: [],
    pickedAddressIndex: -1,
    docList: [],
    noOfDocs: 0,
    collection: "",
    showRadioDiv: false,
    nextEnabled: false,
    showAddressError: false,

    loading: false,
    status: true,
    submit: false,
    activeStep: 0,
    skipped: new Set()
  };

  controller = new window.AbortController();

  getFieldValue = (field) => {
    return this.state[field]
  }

  setPickedAddressIndex = (index) => {
    this.setState({pickedAddressIndex: index})
  }

  setNextEnabled = (value) => {
    this.setState({nextEnabled: value})
  }

  setCollection = () => {
    this.setState({collection: this.state.street})
    this.setNextEnabled(true)
  }

  toggleRadioDiv = () => {
    this.setState({showRadioDiv: !this.state.showRadioDiv})
  }

  getStepContent = (step) => {
    switch (step) {
      case 0:
        return <StateCounty handleChange={this.handleChange} />;
      case 1:
        return (
          <SearchOptions
            toggleRadioDiv={this.toggleRadioDiv}
            handleChange={this.handleChange}
            setPickedAddressIndex={this.setPickedAddressIndex}
            showRadioDiv={this.state.showRadioDiv}
            addressPickList={this.state.addressPickList}
            showAddressError={this.state.showAddressError}
            loading={this.state.loading}
          />);
      default:
        return (
          <SearchFinalize
            getFieldValue={this.getFieldValue}
            handleChange={this.handleChange}
            setCollection={this.setCollection}
            street={this.state.street}
            loading={this.state.loading}
          />);
    }
  };

  // This will pick up the name and values of any state changes
  handleChange = (event) => {
    const {name, value} = event.target;

    let stateValues = {
      [name]: value
    }
    if (name === "street")
      stateValues["collection"] = value

    this.setState(prevState => ({
      ...prevState,
      ...stateValues,
    }), () => {
      const {activeStep, state, county, street, collection} = this.state
      if (activeStep === 0) {
        const val = state.trim().length > 0 && county.trim().length > 0
        this.setNextEnabled(val)
      }

      if (activeStep === 1)
        this.setNextEnabled(street.trim().length > 0)

      if (activeStep === 2)
        this.setNextEnabled(collection.trim().length > 0)
    });
  };

  searchTitlepoint = () => {
    this.setState({showAddressError: false, loading: true})

    const {street, city, state, county} = this.state
    const payload= {
    	city,
    	state,
    	county,
    	address: street,
    };

    PostRequest(URL.searchTitlepoint, this.controller.signal, payload,(response) => {
      //Extract the necessary stuff from response.payload and set the state with the extracted variables
      log('returning titlepoint data')
      const {order_no, task_id, result_type, no_of_docs, data} = response.payload 

      //Check the result_type property in response Object 
      // If no results found, console log the error message and display the address error
      if (result_type === "no_result"){
        console.log('no >>>>>>> result')
        toast.error('No results found.')
        this.setState({showAddressError: true})
        return true
      }

      const setToState = {
        order_no,
        task_id,
        collection: street,
        addressPickList: (result_type === "pick_list" ? data : []),
        loading: false,
      }
      this.setState(setToState)
      

          
      if (result_type === "pick_list"){
        this.toggleRadioDiv()
      }

      // If we get the result_type as doc_list, we have got the final list of docs we need to pull from the titlepoint and process it in our queue
      // We get the doc list and no of docsand set our state with these vars
      else if (result_type === "doc_list"){
        this.setState({docList: data, noOfDocs: no_of_docs})
        this.nextStep()
      }
    }, (error) => {
      this.setState({loading: false})
      toast.error(error.message.detail)
    });
  }


  getDocList = () => {
    const {order_no, task_id, state, county, addressPickList, pickedAddressIndex} = this.state
    const payload = {
      state,
      county,
      pick: addressPickList[pickedAddressIndex],
    };

    const params = {
      order_no,
      task_id,
    };
    // create and encode a query with query params by adding the keys of params object as values in the query
    let esc = encodeURIComponent;
    let query = Object.keys(params)
      .map(k => esc(k) + '=' + esc(params[k]))
      .join('&');

    this.setState({loading: true})

    PostRequest(URL.getDocList + "?" + query, this.controller.signal, payload,(response) => {
      // get the values of order_no,task_id,noOfDocs,docList from response payload 
      const {order_no, task_id, no_of_docs, data} = response.payload
      const setToState = {
        order_no,
        task_id,
        noOfDocs: no_of_docs,
        docList: data,
        loading: false
      }
      this.setState(setToState)
      this.nextStep()
    }, (error) => {
      this.setState({loading: false})
      toast.error(error.message.detail)
    });

  }


  // This function makes a request to the import-files/ endpoint and gets all the docs in the list of docs we get from the getDocList()
  importFiles = () => {
    // Get the task_id and name of the collection where the docs need to be saved
    // the task_id will keep track of documents that are related to a picked address
    const {task_id, collection} = this.state
    const params = {
      task_id,
      collection,
    };
    let esc = encodeURIComponent;
    let query = Object.keys(params)
      .map(k => esc(k) + '=' + esc(params[k]))
      .join('&');


    this.setState({loading: true})
    GetRequest(URL.importFiles + "?" + query,(response) => {
      const {openSearchModal} = this.props;
      openSearchModal(false)
      this.setState({loading: false})
      this.props.getFiles()
    }, (error) => {
      toast.error(error.message.detail)
      this.setState({loading: false})
    });
  }


  /*for stepper start*/
  isStepOptional = step => {
    return step === 1;
  };

  isStepSkipped(step) {
    return this.state.skipped.has(step);
  }

  nextStep = () => {
    const {activeStep} = this.state;

    let {skipped} = this.state;
    if (this.isStepSkipped(activeStep)) {
      skipped = new Set(skipped.values());
      skipped.delete(activeStep);
    }
    this.setState({
      activeStep: activeStep + 1,
      nextEnabled: false,
      skipped
    });
  }

  handleNext = () => {
    const {activeStep, pickedAddressIndex} = this.state;
    // If its step 1 and pickedAddressIndex >=0, the call the getDocList() 
    if (activeStep === 1) {
      if (pickedAddressIndex >= 0)
        this.getDocList()
      else
        this.searchTitlepoint()
    }
    // If its step 2 collect all the docs from the picked address and push it to backend using importFiles()
    else if (activeStep === 2) {
      this.importFiles()
    }
    else
      this.nextStep()
  };

  handleBack = () => {
    const {activeStep} = this.state;
    this.setState({
      activeStep: activeStep - 1
    });
  };

  handleSkip = () => {
    const {activeStep} = this.state;
    if (!this.isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error('You can\'t skip a step that isn\'t optional.');
    }
    const skipped = new Set(this.state.skipped.values());
    skipped.add(activeStep);
    this.setState({
      activeStep: this.state.activeStep + 1,
      skipped
    });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0
    });
  };

  /*for stepper end*/

  render() {
    const {activeStep, nextEnabled} = this.state;
    const {t, classes, openSearchModal} = this.props;

    const steps = [
      t('property-search.property-search-modal.state-county'),
      t('property-search.property-search-modal.search-options'),
      t('property-search.property-search-modal.finalize')
    ];

    return (
      <div className={'modalInner'}>
        <div className={'modalBody'}>
          <div className={classes.modalContent}>
            <div className="searchSteps">
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const props = {};
                  const labelProps = {};
                  if (this.isStepSkipped(index)) {
                    props.completed = false;
                  }
                  return (
                    <Step key={label} {...props}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </div>
            <div className="searchstepForm">
              <div>
                <Typography className={classes.instructions}>
                  {this.getStepContent(activeStep)}
                </Typography>
                <div className={classes.searchPrevNext}>
                  <Button
                    onClick={() => openSearchModal(false)}
                    className={classes.nextButton}
                  >
                    {t('property-search.property-search-modal.cancel')}
                  </Button>

                  {nextEnabled &&
                    <Button
                      variant="raised"
                      color="primary"
                      onClick={() => this.handleNext()}
                      className={classes.nextButton}
                    >
                      {activeStep === steps.length - 1 ? t('property-search.property-search-modal.import') : t('property-search.property-search-modal.next')}
                    </Button>
                  }
                  {!nextEnabled &&
                    <Button
                      disabled
                      style={{color: "#959DAF"}}
                      variant="raised"
                      className={classes.nextButton}
                    >
                      {activeStep === steps.length - 1 ? t('property-search.property-search-modal.import') : t('property-search.property-search-modal.next')}
                    </Button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withStyles(styles)(PropertySearch));
