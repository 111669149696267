import React, {Fragment} from "react";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import { withTranslation } from 'react-i18next';


const styles = {
  modal: {
    background: 'white',
    position: 'relative',
    width: '450px',
    top: '20%',
    left: '35%',
    textAlign: 'left',
  },
  modalTitle: {
    background: 'linear-gradient(70deg, #5159F8, #5EAAFF)',
    color: 'white',
    textAlign: 'left',
    padding: '15px',
  },
  modalContent: {
    padding: '16px 16px 0 16px',
  },
  formInput: {
    margin: '10px',
    width: '95%'
  },
  submit: {
    textAlign: 'right',
    borderTop: '1px solid #E1E3E4'
  },
  actionButton: {
    marginRight: '10px',
  },
  modalFooter: {

  }
};


// Very low priority but this could be refactored to be simpler and more efficient. ps

class AddCapabilities extends React.Component{
  static propTypes = {

  };

  state = {
    value: null,
    capabilities: this.props.capabilities,
    selectedCapabilities: this.props.selectedCapabilities,
    allSelected: false
  };

  controller = new window.AbortController();

  handleChange(value) {
    let cap_value = value.toLowerCase().replace(/ /g, '_')
    let tempState = {...this.state};
    let tempCap = tempState['selectedCapabilities'];
    if(tempCap.indexOf(cap_value) >= 0) {
      tempCap.splice(tempCap.indexOf(cap_value), 1)
    } else {
      tempCap.push(cap_value);
    }
    this.setState({
      selectedCapabilities: tempCap
    })
  }

  checkIfSelected(cap) {
    const {selectedCapabilities} = this.state;
    for(let i =0; i< selectedCapabilities.length; i++) {
      if (selectedCapabilities[i] && selectedCapabilities[i].toLowerCase().replace(/ /g, '_') === cap.toLowerCase().replace(/ /g, '_')) {
        return true;
      }
    }
    return false;
  }

  getDetails(row) {
    return (<Fragment>
      <div className="check-box-cap">
        <Checkbox
          style={{color: !this.checkIfSelected(row.label) ? '#959daf' : '#5fafff'}}
          checked={this.checkIfSelected(row.label)}
          onClick={event => this.handleChange(row.label)}
        />
        <span>{row.label}</span>
      </div>
    </Fragment>)
  }

  submitCaps() {
    const {onCapabilitiesSelected} = this.props;
    onCapabilitiesSelected(this.state.selectedCapabilities);
  }

  closeModal() {
    const {onCapabilitiesSelected} = this.props;
    onCapabilitiesSelected(null);
  }

  toggleSelectAll() {
    const {selectedCapabilities, capabilities} = this.state;
    if (selectedCapabilities.length === capabilities.length) {
      this.setState({
        selectedCapabilities: []
      })
    } else {
      let tempState = {...this.state};
      let tempCap = tempState['capabilities'];
      let selectedTemp = [];
      for(let i=0; i< tempCap.length; i++) {
        selectedTemp.push(tempCap[i].label.toLowerCase().replace(' ', '_'))
      }
      this.setState({
        selectedCapabilities: selectedTemp
      });
    }
  }

  render() {
    const {t,classes} = this.props;
    const {capabilities} = this.state;
    return(
      <div className={classes.modal} key="inputForm">
        <div className={classes.modalTitle}>
          <Typography variant="h6" >
            {t('admin.add-cap-modal.title')}
          </Typography>
        </div>
        <div className={classes.modalContent}>
          <div className="select-cap-header">
            <div className="select-capabilities" >
            {t('admin.add-cap-modal.label')}
            </div>
            <div className="select-deselect" onClick={() => {this.toggleSelectAll()}}>
            {t('admin.add-cap-modal.select')}
            </div>
          </div>
          <div className="width-100">

            <div className="width-100">
              {
                capabilities.map(r => (
                  this.getDetails(r))
                )
              }
            </div>
          </div>
        </div>
        <div className="cap-modal-footer">
          <div className="cancel-btn" onClick={() => {this.closeModal()}}>{t('admin.add-cap-modal.cancel')}</div>
          <div className="confirm-btn" onClick={() => {this.submitCaps()}}>{t('admin.add-cap-modal.confirm')}</div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(withStyles(styles)(AddCapabilities));