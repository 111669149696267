const selectData = [
{"value":1,	"label":"3D Optical Profiler System",	"ml_label":"3d optical profiler system",	"roc":"1 yr"},
{"value":2,	"label":"Active Network Hardware",	"ml_label":"active network hardware",	"roc":"1 yr"},
{"value":3,	"label":"Advanced Driver-Assistance System",	"ml_label":"advanced driver-assistance system",	"roc":"1 yr"},
{"value":4,	"label":"Aerotors",	"ml_label":"aerotors",	"roc":"3 yrs"},
{"value":5,	"label":"Air Conditioning System",	"ml_label":"air conditioning system",	"roc":"3 yrs"},
{"value":6,	"label":"Air Respirator",	"ml_label":"air respirator",	"roc":"3 yrs"},
{"value":7,	"label":"Air Shower",	"ml_label":"air shower",	"roc":"3 yrs"},
{"value":8,	"label":"Air Supply Equipment",	"ml_label":"air supply equipment",	"roc":"3 yrs"},
{"value":9,	"label":"Aircraft Computer System",	"ml_label":"aircraft computer system",	"roc":"1 yr"},
{"value":10,	"label":"Aircraft Equipment",	"ml_label":"aircraft equipment",	"roc":"3 yrs"},
{"value":11,	"label":"Aircraft Maintenance System",	"ml_label":"aircraft maintenance system",	"roc":"1 yr"},
{"value":12,	"label":"Aircraft Navigation System",	"ml_label":"aircraft navigation system",	"roc":"1 yr"},
{"value":13,	"label":"Aircraft Servicing Equipment",	"ml_label":"aircraft servicing equipment",	"roc":"3 yrs"},
{"value":14,	"label":"Alarm System With Software",	"ml_label":"alarm system with software",	"roc":"1 yr"},
{"value":15,	"label":"Ambulance",	"ml_label":"ambulance",	"roc":"3 yrs"},
{"value":16,	"label":"Analyser",	"ml_label":"analyser",	"roc":"1 yr"},
{"value":17,	"label":"Angiography System",	"ml_label":"angiography system",	"roc":"3 yrs"},
{"value":18,	"label":"Annunciator Programmable Display",	"ml_label":"annunciator programmable display",	"roc":"3 yrs"},
{"value":19,	"label":"Anti-Counterfeit Pen",	"ml_label":"anti-counterfeit pen",	"roc":"3 yrs"},
{"value":20,	"label":"Assembly Platform",	"ml_label":"assembly platform",	"roc":"1 yr"},
{"value":21,	"label":"Assembly Support",	"ml_label":"assembly support",	"roc":"3 yrs"},
{"value":22,	"label":"Asset Tracker",	"ml_label":"asset tracker",	"roc":"1 yr"},
{"value":23,	"label":"Audio Accessories",	"ml_label":"audio accessories",	"roc":"3 yrs"},
{"value":24,	"label":"Audio Equipment",	"ml_label":"audio equipment",	"roc":"3 yrs"},
{"value":25,	"label":"Audio Visual Equipment",	"ml_label":"audio visual equipment",	"roc":"3 yrs"},
{"value":26,	"label":"Auto Refractometer",	"ml_label":"auto refractometer",	"roc":"1 yr"},
{"value":27,	"label":"Autoclave Machine",	"ml_label":"autoclave machine",	"roc":"3 yrs"},
{"value":28,	"label":"Automated Carpark System",	"ml_label":"automated carpark system",	"roc":"1 yr"},
{"value":29,	"label":"Automated Diagnosis Code",	"ml_label":"automated diagnosis code",	"roc":"1 yr"},
{"value":30,	"label":"Automated Entrance Barrier",	"ml_label":"automated entrance barrier",	"roc":"1 yr"},
{"value":31,	"label":"Automated Equipment",	"ml_label":"automated equipment",	"roc":"1 yr"},
{"value":32,	"label":"Automated Guided Vehicle",	"ml_label":"automated guided vehicle",	"roc":"1 yr"},
{"value":33,	"label":"Automated Machine",	"ml_label":"automated machine",	"roc":"1 yr"},
{"value":34,	"label":"Automated Main Cell Processing Unit",	"ml_label":"automated main cell processing unit",	"roc":"1 yr"},
{"value":35,	"label":"Automated Nucleic Acid Extraction System",	"ml_label":"automated nucleic acid extraction system",	"roc":"1 yr"},
{"value":36,	"label":"Automatic Perimeter",	"ml_label":"automatic perimeter",	"roc":"1 yr"},
{"value":37,	"label":"Automotive Service Equipment",	"ml_label":"automotive service equipment",	"roc":"3 yrs"},
{"value":38,	"label":"Av Accessories",	"ml_label":"av accessories",	"roc":"3 yrs"},
{"value":39,	"label":"Awning",	"ml_label":"awning",	"roc":"S14Q"},
{"value":40,	"label":"Back Lit Image",	"ml_label":"back lit image",	"roc":"3 yrs"},
{"value":41,	"label":"Bag",	"ml_label":"bag",	"roc":"3 yrs"},
{"value":42,	"label":"Balancing Machine",	"ml_label":"balancing machine",	"roc":"3 yrs"},
{"value":43,	"label":"Bank Note Counter",	"ml_label":"bank note counter",	"roc":"1 yr"},
{"value":44,	"label":"Battery Charger",	"ml_label":"battery charger",	"roc":"3 yrs"},
{"value":45,	"label":"Battery Monitoring System",	"ml_label":"battery monitoring system",	"roc":"3 yrs"},
{"value":46,	"label":"Bedding",	"ml_label":"bedding",	"roc":"Revenue"},
{"value":47,	"label":"Bicycle",	"ml_label":"bicycle",	"roc":"3 yrs"},
{"value":48,	"label":"Biometric Door Access",	"ml_label":"biometric door access",	"roc":"1 yr"},
{"value":49,	"label":"Blending Tank",	"ml_label":"blending tank",	"roc":"3 yrs"},
{"value":50,	"label":"Blinds",	"ml_label":"blinds",	"roc":"3 yrs"},
{"value":51,	"label":"Boat",	"ml_label":"boat",	"roc":"3 yrs"},
{"value":52,	"label":"Booking System",	"ml_label":"booking system",	"roc":"1 yr"},
{"value":53,	"label":"Buggy",	"ml_label":"buggy",	"roc":"3 yrs"},
{"value":54,	"label":"Building",	"ml_label":"building",	"roc":"No Claim"},
{"value":55,	"label":"Building Automation System",	"ml_label":"building automation system",	"roc":"1 yr"},
{"value":56,	"label":"Bus",	"ml_label":"bus",	"roc":"3 yrs"},
{"value":57,	"label":"Cable",	"ml_label":"cable",	"roc":"tba"},
{"value":58,	"label":"Cable Locator",	"ml_label":"cable locator",	"roc":"3 yrs"},
{"value":59,	"label":"Camera",	"ml_label":"camera",	"roc":"3 yrs"},
{"value":60,	"label":"Camera Accessories",	"ml_label":"camera accessories",	"roc":"3 yrs"},
{"value":61,	"label":"Canopy",	"ml_label":"canopy",	"roc":"S14Q"},
{"value":62,	"label":"Capacitor Bank",	"ml_label":"capacitor bank",	"roc":"3 yrs"},
{"value":63,	"label":"Card Access System",	"ml_label":"card access system",	"roc":"1 yr"},
{"value":64,	"label":"Card Reader",	"ml_label":"card reader",	"roc":"1 yr"},
{"value":65,	"label":"Cardiac Image Managment System",	"ml_label":"cardiac image managment system",	"roc":"1 yr"},
{"value":66,	"label":"Carpet",	"ml_label":"carpet",	"roc":"3 yrs"},
{"value":67,	"label":"Cash Box System With Software",	"ml_label":"cash box system with software",	"roc":"1 yr"},
{"value":68,	"label":"Cashcode Bill Validator",	"ml_label":"cashcode bill validator",	"roc":"1 yr"},
{"value":69,	"label":"Cctv Software",	"ml_label":"cctv software",	"roc":"1 yr"},
{"value":70,	"label":"Ceiling Works",	"ml_label":"ceiling works",	"roc":"S14Q"},
{"value":71,	"label":"Charging Booth",	"ml_label":"charging booth",	"roc":"3 yrs"},
{"value":72,	"label":"Chemical Dosing System",	"ml_label":"chemical dosing system",	"roc":"3 yrs"},
{"value":73,	"label":"Chemical Storage Shed",	"ml_label":"chemical storage shed",	"roc":"3 yrs"},
{"value":74,	"label":"Cleaning Equipment",	"ml_label":"cleaning equipment",	"roc":"3 yrs"},
{"value":75,	"label":"Cleaning Service",	"ml_label":"cleaning service",	"roc":"Revenue"},
{"value":76,	"label":"Collapsible Tank",	"ml_label":"collapsible tank",	"roc":"3 yrs"},
{"value":77,	"label":"Compactus",	"ml_label":"compactus",	"roc":"3 yrs"},
{"value":78,	"label":"Compliant Pin Vision System",	"ml_label":"compliant pin vision system",	"roc":"3 yrs"},
{"value":79,	"label":"Computer",	"ml_label":"computer",	"roc":"1 yr"},
{"value":80,	"label":"Computer Aided Machine",	"ml_label":"computer aided machine",	"roc":"1 yr"},
{"value":81,	"label":"Computer Network Equipment",	"ml_label":"computer network equipment",	"roc":"1 yr"},
{"value":82,	"label":"Computer Network Peripherals",	"ml_label":"computer network peripherals",	"roc":"1 yr"},
{"value":83,	"label":"Computer Peripherals",	"ml_label":"computer peripherals",	"roc":"1 yr"},
{"value":84,	"label":"Computerised Lens Meter",	"ml_label":"computerised lens meter",	"roc":"1 yr"},
{"value":85,	"label":"Condolences",	"ml_label":"condolences",	"roc":"Revenue"},
{"value":86,	"label":"Console",	"ml_label":"console",	"roc":"3 yrs"},
{"value":87,	"label":"Construction Equipment",	"ml_label":"construction equipment",	"roc":"3 yrs"},
{"value":88,	"label":"Consumables",	"ml_label":"consumables",	"roc":"Revenue"},
{"value":89,	"label":"Container Office",	"ml_label":"container office",	"roc":"No Claim"},
{"value":90,	"label":"Control Panel",	"ml_label":"control panel",	"roc":"3 yrs"},
{"value":91,	"label":"Conveyor System",	"ml_label":"conveyor system",	"roc":"3 yrs"},
{"value":92,	"label":"Coolant Tank",	"ml_label":"coolant tank",	"roc":"3 yrs"},
{"value":93,	"label":"Cooler Box",	"ml_label":"cooler box",	"roc":"3 yrs"},
{"value":94,	"label":"Cooling Tower",	"ml_label":"cooling tower",	"roc":"3 yrs"},
{"value":95,	"label":"Copier Charge",	"ml_label":"copier charge",	"roc":"Revenue"},
{"value":96,	"label":"Crane",	"ml_label":"crane",	"roc":"3 yrs"},
{"value":97,	"label":"Curtain",	"ml_label":"curtain",	"roc":"3 yrs"},
{"value":98,	"label":"Customised Fencing System",	"ml_label":"customised fencing system",	"roc":"tba"},
{"value":99,	"label":"Data Acquisition System",	"ml_label":"data acquisition system",	"roc":"1 yr"},
{"value":100,	"label":"Data Logger",	"ml_label":"data logger",	"roc":"1 yr"},
{"value":101,	"label":"Data Processing Equipment",	"ml_label":"data processing equipment",	"roc":"1 yr"},
{"value":102,	"label":"Data Storage",	"ml_label":"data storage",	"roc":"1 yr"},
{"value":103,	"label":"Datatrace Reader",	"ml_label":"datatrace reader",	"roc":"3 yrs"},
{"value":104,	"label":"Decorations",	"ml_label":"decorations",	"roc":"Revenue"},
{"value":105,	"label":"Decorative Lighting",	"ml_label":"decorative lighting",	"roc":"tba"},
{"value":106,	"label":"Decorative Structures",	"ml_label":"decorative structures",	"roc":"tba"},
{"value":107,	"label":"Demountable Partition",	"ml_label":"demountable partition",	"roc":"3 yrs"},
{"value":108,	"label":"Die",	"ml_label":"die",	"roc":"3 yrs"},
{"value":109,	"label":"Digital Inspection System",	"ml_label":"digital inspection system",	"roc":"3 yrs"},
{"value":110,	"label":"Digital Surveillance System",	"ml_label":"digital surveillance system",	"roc":"1 yr"},
{"value":111,	"label":"Digital Time Zone",	"ml_label":"digital time zone",	"roc":"1 yr"},
{"value":112,	"label":"Digital Voting System",	"ml_label":"digital voting system",	"roc":"1 yr"},
{"value":113,	"label":"Dispenser",	"ml_label":"dispenser",	"roc":"3 yrs"},
{"value":114,	"label":"Disposable Syringes",	"ml_label":"disposable syringes",	"roc":"Revenue"},
{"value":115,	"label":"Dmx Control Equipment",	"ml_label":"dmx control equipment",	"roc":"1 yr"},
{"value":116,	"label":"Docking System",	"ml_label":"docking system",	"roc":"3 yrs"},
{"value":117,	"label":"Door",	"ml_label":"door",	"roc":"S14Q"},
{"value":118,	"label":"Door Bell",	"ml_label":"door bell",	"roc":"3 yrs"},
{"value":119,	"label":"Drum",	"ml_label":"drum",	"roc":"3 yrs"},
{"value":120,	"label":"Drumlifter",	"ml_label":"drumlifter",	"roc":"3 yrs"},
{"value":121,	"label":"Dustbin",	"ml_label":"dustbin",	"roc":"3 yrs"},
{"value":122,	"label":"Electrical Equipment",	"ml_label":"electrical equipment",	"roc":"3 yrs"},
{"value":123,	"label":"Electrical Switchgear",	"ml_label":"electrical switchgear",	"roc":"3 yrs"},
{"value":124,	"label":"Electrical Works",	"ml_label":"electrical works",	"roc":"S14Q"},
{"value":125,	"label":"Electricity Supply Installation",	"ml_label":"electricity supply installation",	"roc":"S14Q"},
{"value":126,	"label":"Electromechanical Surgical Unit",	"ml_label":"electromechanical surgical unit",	"roc":"3 yrs"},
{"value":127,	"label":"Electronic Labelling System",	"ml_label":"electronic labelling system",	"roc":"3 yrs"},
{"value":128,	"label":"Emergency Call System",	"ml_label":"emergency call system",	"roc":"3 yrs"},
{"value":129,	"label":"Employee Meals",	"ml_label":"employee meals",	"roc":"Revenue"},
{"value":130,	"label":"Energy Monitoring System",	"ml_label":"energy monitoring system",	"roc":"3 yrs"},
{"value":131,	"label":"Equipment Parts",	"ml_label":"equipment parts",	"roc":"3 yrs"},
{"value":132,	"label":"Escalator",	"ml_label":"escalator",	"roc":"3 yrs"},
{"value":133,	"label":"E-Scooter",	"ml_label":"e-scooter",	"roc":"3 yrs"},
{"value":134,	"label":"Exercise Machine",	"ml_label":"exercise machine",	"roc":"3 yrs"},
{"value":135,	"label":"Extended Warranty",	"ml_label":"extended warranty",	"roc":"Revenue"},
{"value":136,	"label":"Facsimile Machine",	"ml_label":"facsimile machine",	"roc":"1 yr"},
{"value":137,	"label":"Fire Alarm And Fire Fighting Installation",	"ml_label":"fire alarm and fire fighting installation",	"roc":"3 yrs"},
{"value":138,	"label":"Fire Certificate",	"ml_label":"fire certificate",	"roc":"Revenue"},
{"value":139,	"label":"Fire Extinguisher",	"ml_label":"fire extinguisher",	"roc":"Revenue"},
{"value":140,	"label":"Fire Truck",	"ml_label":"fire truck",	"roc":"3 yrs"},
{"value":141,	"label":"Flooring Works",	"ml_label":"flooring works",	"roc":"S14Q"},
{"value":142,	"label":"Forced Air Cooling System",	"ml_label":"forced air cooling system",	"roc":"3 yrs"},
{"value":143,	"label":"Forklift",	"ml_label":"forklift",	"roc":"3 yrs"},
{"value":144,	"label":"Freight Container",	"ml_label":"freight container",	"roc":"3 yrs"},
{"value":145,	"label":"Freight, Installation Cost",	"ml_label":"freight, installation cost",	"roc":"tba"},
{"value":146,	"label":"Fuel Tank System",	"ml_label":"fuel tank system",	"roc":"3 yrs"},
{"value":147,	"label":"Furnace",	"ml_label":"furnace",	"roc":"3 yrs"},
{"value":148,	"label":"Furniture",	"ml_label":"furniture",	"roc":"3 yrs"},
{"value":149,	"label":"Galileo G-Assembly",	"ml_label":"galileo g-assembly",	"roc":"3 yrs"},
{"value":150,	"label":"Galileo Injector",	"ml_label":"galileo injector",	"roc":"3 yrs"},
{"value":151,	"label":"Game Table",	"ml_label":"game table",	"roc":"3 yrs"},
{"value":152,	"label":"Gas Detector",	"ml_label":"gas detector",	"roc":"3 yrs"},
{"value":153,	"label":"Gas Monitoring System",	"ml_label":"gas monitoring system",	"roc":"3 yrs"},
{"value":154,	"label":"Gas Supply Equipment",	"ml_label":"gas supply equipment",	"roc":"3 yrs"},
{"value":155,	"label":"Gel Dispenser With Software",	"ml_label":"gel dispenser with software",	"roc":"1 yr"},
{"value":156,	"label":"General Lighting",	"ml_label":"general lighting",	"roc":"S14Q"},
{"value":157,	"label":"Generator",	"ml_label":"generator",	"roc":"3 yrs"},
{"value":158,	"label":"Gifts",	"ml_label":"gifts",	"roc":"Revenue"},
{"value":159,	"label":"Glue Vision System",	"ml_label":"glue vision system",	"roc":"3 yrs"},
{"value":160,	"label":"Golden Unit",	"ml_label":"golden unit",	"roc":"3 yrs"},
{"value":161,	"label":"Golf Equipment",	"ml_label":"golf equipment",	"roc":"3 yrs"},
{"value":162,	"label":"Gondola",	"ml_label":"gondola",	"roc":"3 yrs"},
{"value":163,	"label":"Gps Devices",	"ml_label":"gps devices",	"roc":"3 yrs"},
{"value":164,	"label":"Graphic Terminal",	"ml_label":"graphic terminal",	"roc":"1 yr"},
{"value":165,	"label":"Green Mark Certification",	"ml_label":"green mark certification",	"roc":"Revenue"},
{"value":166,	"label":"Grid Magnet",	"ml_label":"grid magnet",	"roc":"3 yrs"},
{"value":167,	"label":"Handpunch Terminal",	"ml_label":"handpunch terminal",	"roc":"1 yr"},
{"value":168,	"label":"Hands Free Cpr System",	"ml_label":"hands free cpr system",	"roc":"3 yrs"},
{"value":169,	"label":"Hangar",	"ml_label":"hangar",	"roc":"3 yrs"},
{"value":170,	"label":"Harddisk Duplicator",	"ml_label":"harddisk duplicator",	"roc":"1 yr"},
{"value":171,	"label":"Hearing Aid Fitting Software",	"ml_label":"hearing aid fitting software",	"roc":"1 yr"},
{"value":172,	"label":"Hoist",	"ml_label":"hoist",	"roc":"3 yrs"},
{"value":173,	"label":"Hospital Bed",	"ml_label":"hospital bed",	"roc":"3 yrs"},
{"value":174,	"label":"Human Milk Bank",	"ml_label":"human milk bank",	"roc":"3 yrs"},
{"value":175,	"label":"Hydraulic Dock Leveller",	"ml_label":"hydraulic dock leveller",	"roc":"3 yrs"},
{"value":176,	"label":"Hydraulic Hooklift Container",	"ml_label":"hydraulic hooklift container",	"roc":"3 yrs"},
{"value":177,	"label":"Image Management System",	"ml_label":"image management system",	"roc":"1 yr"},
{"value":178,	"label":"Imaging Module System",	"ml_label":"imaging module system",	"roc":"1 yr"},
{"value":179,	"label":"Immovable Partition",	"ml_label":"immovable partition",	"roc":"S14Q"},
{"value":180,	"label":"Incubator",	"ml_label":"incubator",	"roc":"1 yr"},
{"value":181,	"label":"Industrial Freezer",	"ml_label":"industrial freezer",	"roc":"3 yrs"},
{"value":182,	"label":"Industrial Oven",	"ml_label":"industrial oven",	"roc":"3 yrs"},
{"value":183,	"label":"Infant Care Equipment",	"ml_label":"infant care equipment",	"roc":"3 yrs"},
{"value":184,	"label":"Inspection Equipment",	"ml_label":"inspection equipment",	"roc":"3 yrs"},
{"value":185,	"label":"Inspection Light",	"ml_label":"inspection light",	"roc":"3 yrs"},
{"value":186,	"label":"Insurance For Renovation Works",	"ml_label":"insurance for renovation works",	"roc":"S14Q"},
{"value":187,	"label":"Intercom System",	"ml_label":"intercom system",	"roc":"3 yrs"},
{"value":188,	"label":"Internet Charges",	"ml_label":"internet charges",	"roc":"Revenue"},
{"value":189,	"label":"Interventional Neuroradiology X-Ray System",	"ml_label":"interventional neuroradiology x-ray system",	"roc":"1 yr"},
{"value":190,	"label":"Inverters",	"ml_label":"inverters",	"roc":"3 yrs"},
{"value":191,	"label":"Ip Telephony System",	"ml_label":"ip telephony system",	"roc":"1 yr"},
{"value":192,	"label":"Ip Television",	"ml_label":"ip television",	"roc":"1 yr"},
{"value":193,	"label":"Jig",	"ml_label":"jig",	"roc":"3 yrs"},
{"value":194,	"label":"Kettlebell",	"ml_label":"kettlebell",	"roc":"3 yrs"},
{"value":195,	"label":"Kitchen Equipment",	"ml_label":"kitchen equipment",	"roc":"3 yrs"},
{"value":196,	"label":"Laboratory Equipment",	"ml_label":"laboratory equipment",	"roc":"3 yrs"},
{"value":197,	"label":"Ladder",	"ml_label":"ladder",	"roc":"3 yrs"},
{"value":198,	"label":"Landscaping",	"ml_label":"landscaping",	"roc":"No Claim"},
{"value":199,	"label":"Learning Kits",	"ml_label":"learning kits",	"roc":"3 yrs"},
{"value":200,	"label":"Legal Agreement",	"ml_label":"legal agreement",	"roc":"tba"},
{"value":201,	"label":"Lens Production Machine",	"ml_label":"lens production machine",	"roc":"3 yrs"},
{"value":202,	"label":"Lift",	"ml_label":"lift",	"roc":"3 yrs"},
{"value":203,	"label":"Lift Shaft",	"ml_label":"lift shaft",	"roc":"No Claim"},
{"value":204,	"label":"Lifting Magnet",	"ml_label":"lifting magnet",	"roc":"3 yrs"},
{"value":205,	"label":"Light Box",	"ml_label":"light box",	"roc":"3 yrs"},
{"value":206,	"label":"Light Source",	"ml_label":"light source",	"roc":"3 yrs"},
{"value":207,	"label":"Lightcure System",	"ml_label":"lightcure system",	"roc":"3 yrs"},
{"value":208,	"label":"Lighting Tower",	"ml_label":"lighting tower",	"roc":"3 yrs"},
{"value":209,	"label":"Lightning Protector",	"ml_label":"lightning protector",	"roc":"3 yrs"},
{"value":210,	"label":"Loader",	"ml_label":"loader",	"roc":"3 yrs"},
{"value":211,	"label":"Logistic Support Robot",	"ml_label":"logistic support robot",	"roc":"1 yr"},
{"value":212,	"label":"Lorry",	"ml_label":"lorry",	"roc":"3 yrs"},
{"value":213,	"label":"Machine With Software",	"ml_label":"machine with software",	"roc":"1 yr"},
{"value":214,	"label":"Machinery",	"ml_label":"machinery",	"roc":"3 yrs"},
{"value":215,	"label":"Magnetic Door Holder",	"ml_label":"magnetic door holder",	"roc":"3 yrs"},
{"value":216,	"label":"Magnifier Lamp",	"ml_label":"magnifier lamp",	"roc":"3 yrs"},
{"value":217,	"label":"Maintenance Charge",	"ml_label":"maintenance charge",	"roc":"Revenue"},
{"value":218,	"label":"Maintenance Of Equipment",	"ml_label":"maintenance of equipment",	"roc":"Revenue"},
{"value":219,	"label":"Mammography System",	"ml_label":"mammography system",	"roc":"1 yr"},
{"value":220,	"label":"Mannequin",	"ml_label":"mannequin",	"roc":"3 yrs"},
{"value":221,	"label":"Marking Machine",	"ml_label":"marking machine",	"roc":"3 yrs"},
{"value":222,	"label":"Measuring Equipment",	"ml_label":"measuring equipment",	"roc":"3 yrs"},
{"value":223,	"label":"Measuring Equipment With Software",	"ml_label":"measuring equipment with software",	"roc":"1 yr"},
{"value":224,	"label":"Medical Diagnostic Tool",	"ml_label":"medical diagnostic tool",	"roc":"3 yrs"},
{"value":225,	"label":"Medical Equipment",	"ml_label":"medical equipment",	"roc":"3 yrs"},
{"value":226,	"label":"Medical Training Solution",	"ml_label":"medical training solution",	"roc":"3 yrs"},
{"value":227,	"label":"Medical Training Solution With Software",	"ml_label":"medical training solution with software",	"roc":"1 yr"},
{"value":228,	"label":"Metal Detector",	"ml_label":"metal detector",	"roc":"3 yrs"},
{"value":229,	"label":"Microprocessor",	"ml_label":"microprocessor",	"roc":"1 yr"},
{"value":230,	"label":"Microscope",	"ml_label":"microscope",	"roc":"3 yrs"},
{"value":231,	"label":"Mirror",	"ml_label":"mirror",	"roc":"3 yrs"},
{"value":232,	"label":"Mobile Phone",	"ml_label":"mobile phone",	"roc":"1 yr"},
{"value":233,	"label":"Monitoring Surveillance System",	"ml_label":"monitoring surveillance system",	"roc":"1 yr"},
{"value":234,	"label":"Motor Cycle",	"ml_label":"motor cycle",	"roc":"3 yrs"},
{"value":235,	"label":"Mould",	"ml_label":"mould",	"roc":"3 yrs"},
{"value":236,	"label":"Moving Fee",	"ml_label":"moving fee",	"roc":"tba"},
{"value":237,	"label":"Mower",	"ml_label":"mower",	"roc":"3 yrs"},
{"value":238,	"label":"Multi Purpose Kiosk",	"ml_label":"multi purpose kiosk",	"roc":"1 yr"},
{"value":239,	"label":"Mural",	"ml_label":"mural",	"roc":"tba"},
{"value":240,	"label":"Navigation Light",	"ml_label":"navigation light",	"roc":"3 yrs"},
{"value":241,	"label":"Negative Pressure Control System",	"ml_label":"negative pressure control system",	"roc":"3 yrs"},
{"value":242,	"label":"Nerve Monitor Machine",	"ml_label":"nerve monitor machine",	"roc":"1 yr"},
{"value":243,	"label":"Network Communication Equipment",	"ml_label":"network communication equipment",	"roc":"1 yr"},
{"value":244,	"label":"Nibp Monitor With Printer",	"ml_label":"nibp monitor with printer",	"roc":"1 yr"},
{"value":245,	"label":"Office Equipment",	"ml_label":"office equipment",	"roc":"3 yrs"},
{"value":246,	"label":"Ophthalmic Equipment",	"ml_label":"ophthalmic equipment",	"roc":"3 yrs"},
{"value":247,	"label":"Ophthalmic Laser",	"ml_label":"ophthalmic laser",	"roc":"1 yr"},
{"value":248,	"label":"Optometry Equipment",	"ml_label":"optometry equipment",	"roc":"3 yrs"},
{"value":249,	"label":"Other Vehicle",	"ml_label":"other vehicle",	"roc":"tba"},
{"value":250,	"label":"Pager",	"ml_label":"pager",	"roc":"3 yrs"},
{"value":251,	"label":"Painting Works",	"ml_label":"painting works",	"roc":"S14Q"},
{"value":252,	"label":"Paintings",	"ml_label":"paintings",	"roc":"tba"},
{"value":253,	"label":"Pallet",	"ml_label":"pallet",	"roc":"3 yrs"},
{"value":254,	"label":"Pallet Magazine Loader",	"ml_label":"pallet magazine loader",	"roc":"3 yrs"},
{"value":255,	"label":"Pallet Truck",	"ml_label":"pallet truck",	"roc":"3 yrs"},
{"value":256,	"label":"Paperless Recorder",	"ml_label":"paperless recorder",	"roc":"1 yr"},
{"value":257,	"label":"Patient Communication System",	"ml_label":"patient communication system",	"roc":"3 yrs"},
{"value":258,	"label":"Patient Handling Equipment",	"ml_label":"patient handling equipment",	"roc":"3 yrs"},
{"value":259,	"label":"Pest Control",	"ml_label":"pest control",	"roc":"Revenue"},
{"value":260,	"label":"Phone Appointment System",	"ml_label":"phone appointment system",	"roc":"1 yr"},
{"value":261,	"label":"Physiotherapy Equipment",	"ml_label":"physiotherapy equipment",	"roc":"3 yrs"},
{"value":262,	"label":"Pick Up",	"ml_label":"pick up",	"roc":"3 yrs"},
{"value":263,	"label":"Pictures",	"ml_label":"pictures",	"roc":"tba"},
{"value":264,	"label":"Piping System",	"ml_label":"piping system",	"roc":"tba"},
{"value":265,	"label":"Plasma Surface Treatment System",	"ml_label":"plasma surface treatment system",	"roc":"3 yrs"},
{"value":266,	"label":"Portable Light",	"ml_label":"portable light",	"roc":"3 yrs"},
{"value":267,	"label":"Portable Ramps",	"ml_label":"portable ramps",	"roc":"3 yrs"},
{"value":268,	"label":"Portable Toilet",	"ml_label":"portable toilet",	"roc":"3 yrs"},
{"value":269,	"label":"Pos Terminal System",	"ml_label":"pos terminal system",	"roc":"1 yr"},
{"value":270,	"label":"Powder Blending Plant",	"ml_label":"powder blending plant",	"roc":"3 yrs"},
{"value":271,	"label":"Power Automation",	"ml_label":"power automation",	"roc":"1 yr"},
{"value":272,	"label":"Power Magnetization Unit",	"ml_label":"power magnetization unit",	"roc":"3 yrs"},
{"value":273,	"label":"Power Supply",	"ml_label":"power supply",	"roc":"3 yrs"},
{"value":274,	"label":"Preliminaries",	"ml_label":"preliminaries",	"roc":"S14Q"},
{"value":275,	"label":"Prime Mover",	"ml_label":"prime mover",	"roc":"3 yrs"},
{"value":276,	"label":"Printer",	"ml_label":"printer",	"roc":"1 yr"},
{"value":277,	"label":"Private Car",	"ml_label":"private car",	"roc":"No Claim"},
{"value":278,	"label":"Probe",	"ml_label":"probe",	"roc":"1 yr"},
{"value":279,	"label":"Probe Card",	"ml_label":"probe card",	"roc":"1 yr"},
{"value":280,	"label":"Production Line",	"ml_label":"production line",	"roc":"3 yrs"},
{"value":281,	"label":"Production Tools",	"ml_label":"production tools",	"roc":"3 yrs"},
{"value":282,	"label":"Professional Fees",	"ml_label":"professional fees",	"roc":"No Claim"},
{"value":283,	"label":"Programming Equipment",	"ml_label":"programming equipment",	"roc":"1 yr"},
{"value":284,	"label":"Prototype Equipment",	"ml_label":"prototype equipment",	"roc":"3 yrs"},
{"value":285,	"label":"Pump",	"ml_label":"pump",	"roc":"3 yrs"},
{"value":286,	"label":"Pvc Flexible Pipe",	"ml_label":"pvc flexible pipe",	"roc":"Revenue"},
{"value":287,	"label":"Quarantine Cage",	"ml_label":"quarantine cage",	"roc":"3 yrs"},
{"value":288,	"label":"Queuing System",	"ml_label":"queuing system",	"roc":"1 yr"},
{"value":289,	"label":"Rack",	"ml_label":"rack",	"roc":"3 yrs"},
{"value":290,	"label":"Radar Vision System",	"ml_label":"radar vision system",	"roc":"1 yr"},
{"value":291,	"label":"Radiodetection Equipment",	"ml_label":"radiodetection equipment",	"roc":"3 yrs"},
{"value":292,	"label":"Ramp",	"ml_label":"ramp",	"roc":"No Claim"},
{"value":293,	"label":"Rechargeable Battery",	"ml_label":"rechargeable battery",	"roc":"3 yrs"},
{"value":294,	"label":"Refrigerant",	"ml_label":"refrigerant",	"roc":"Revenue"},
{"value":295,	"label":"Refuse Disposal",	"ml_label":"refuse disposal",	"roc":"Revenue"},
{"value":296,	"label":"Reinstatement Costs",	"ml_label":"reinstatement costs",	"roc":"Revenue"},
{"value":297,	"label":"Religious Altar",	"ml_label":"religious altar",	"roc":"tba"},
{"value":298,	"label":"Relocation Of Plant And Machinery",	"ml_label":"relocation of plant and machinery",	"roc":"3 yrs"},
{"value":299,	"label":"Removal Fee",	"ml_label":"removal fee",	"roc":"Revenue"},
{"value":300,	"label":"Renovation Works",	"ml_label":"renovation works",	"roc":"S14Q"},
{"value":301,	"label":"Rental Of Equipment",	"ml_label":"rental of equipment",	"roc":"Revenue"},
{"value":302,	"label":"Re-Painting Works",	"ml_label":"re-painting works",	"roc":"Revenue"},
{"value":303,	"label":"Repairs",	"ml_label":"repairs",	"roc":"Revenue"},
{"value":304,	"label":"Repeater Lights",	"ml_label":"repeater lights",	"roc":"3 yrs"},
{"value":305,	"label":"Retarder Proofer",	"ml_label":"retarder proofer",	"roc":"1 yr"},
{"value":306,	"label":"Robotic Cleaner",	"ml_label":"robotic cleaner",	"roc":"1 yr"},
{"value":307,	"label":"Robotic Hospital Bed",	"ml_label":"robotic hospital bed",	"roc":"1 yr"},
{"value":308,	"label":"Robotic Machine",	"ml_label":"robotic machine",	"roc":"1 yr"},
{"value":309,	"label":"Robotic Pallet",	"ml_label":"robotic pallet",	"roc":"1 yr"},
{"value":310,	"label":"Robotic Process Automation",	"ml_label":"robotic process automation",	"roc":"1 yr"},
{"value":311,	"label":"Robotic Tooling",	"ml_label":"robotic tooling",	"roc":"1 yr"},
{"value":312,	"label":"Roller Shutter",	"ml_label":"roller shutter",	"roc":"S14Q"},
{"value":313,	"label":"Roller Stand",	"ml_label":"roller stand",	"roc":"3 yrs"},
{"value":314,	"label":"Safe Box",	"ml_label":"safe box",	"roc":"3 yrs"},
{"value":315,	"label":"Safety Barrier",	"ml_label":"safety barrier",	"roc":"3 yrs"},
{"value":316,	"label":"Safety Equipment",	"ml_label":"safety equipment",	"roc":"3 yrs"},
{"value":317,	"label":"Sanitary Fittings",	"ml_label":"sanitary fittings",	"roc":"S14Q"},
{"value":318,	"label":"Scaffold",	"ml_label":"scaffold",	"roc":"3 yrs"},
{"value":319,	"label":"Scanclimber",	"ml_label":"scanclimber",	"roc":"3 yrs"},
{"value":320,	"label":"Scanner",	"ml_label":"scanner",	"roc":"1 yr"},
{"value":321,	"label":"Scientific Equipment With Software",	"ml_label":"scientific equipment with software",	"roc":"1 yr"},
{"value":322,	"label":"Scope",	"ml_label":"scope",	"roc":"3 yrs"},
{"value":323,	"label":"Sculpture",	"ml_label":"sculpture",	"roc":"tba"},
{"value":324,	"label":"Seabed Survey Equipment",	"ml_label":"seabed survey equipment",	"roc":"1 yr"},
{"value":325,	"label":"Security Film",	"ml_label":"security film",	"roc":"tba"},
{"value":326,	"label":"Security System",	"ml_label":"security system",	"roc":"3 yrs"},
{"value":327,	"label":"Self Dumping Bucket",	"ml_label":"self dumping bucket",	"roc":"3 yrs"},
{"value":328,	"label":"Servingware",	"ml_label":"servingware",	"roc":"3 yrs"},
{"value":329,	"label":"Showcase",	"ml_label":"showcase",	"roc":"3 yrs"},
{"value":330,	"label":"Shower Panels",	"ml_label":"shower panels",	"roc":"S14Q"},
{"value":331,	"label":"Side Post Parasol",	"ml_label":"side post parasol",	"roc":"3 yrs"},
{"value":332,	"label":"Signage",	"ml_label":"signage",	"roc":"3 yrs"},
{"value":333,	"label":"Silo",	"ml_label":"silo",	"roc":"3 yrs"},
{"value":334,	"label":"Simulation Kit",	"ml_label":"simulation kit",	"roc":"3 yrs"},
{"value":335,	"label":"Simulator",	"ml_label":"simulator",	"roc":"3 yrs"},
{"value":336,	"label":"Slit Lamp",	"ml_label":"slit lamp",	"roc":"3 yrs"},
{"value":337,	"label":"Small Tools",	"ml_label":"small tools",	"roc":"Renewal Basis"},
{"value":338,	"label":"Software",	"ml_label":"software",	"roc":"1 yr"},
{"value":339,	"label":"Software-Defined Vehicle",	"ml_label":"software-defined vehicle",	"roc":"1 yr"},
{"value":340,	"label":"Solar Marine Hazard Light",	"ml_label":"solar marine hazard light",	"roc":"3 yrs"},
{"value":341,	"label":"Solar Panel System",	"ml_label":"solar panel system",	"roc":"3 yrs"},
{"value":342,	"label":"Stacker",	"ml_label":"stacker",	"roc":"3 yrs"},
{"value":343,	"label":"Stand Alone Lamp",	"ml_label":"stand alone lamp",	"roc":"3 yrs"},
{"value":344,	"label":"Stationery",	"ml_label":"stationery",	"roc":"Revenue"},
{"value":345,	"label":"Storage Equipment",	"ml_label":"storage equipment",	"roc":"3 yrs"},
{"value":346,	"label":"Storage Fee",	"ml_label":"storage fee",	"roc":"Revenue"},
{"value":347,	"label":"Strobe Light",	"ml_label":"strobe light",	"roc":"3 yrs"},
{"value":348,	"label":"Strolley Bag",	"ml_label":"strolley bag",	"roc":"3 yrs"},
{"value":349,	"label":"Super Insulated Vacuum Line",	"ml_label":"super insulated vacuum line",	"roc":"3 yrs"},
{"value":350,	"label":"Surgical Robot",	"ml_label":"surgical robot",	"roc":"1 yr"},
{"value":351,	"label":"Survey Equipment",	"ml_label":"survey equipment",	"roc":"1 yr"},
{"value":352,	"label":"Tableware",	"ml_label":"tableware",	"roc":"3 yrs"},
{"value":353,	"label":"Tape Library",	"ml_label":"tape library",	"roc":"1 yr"},
{"value":354,	"label":"Taxi",	"ml_label":"taxi",	"roc":"3 yrs"},
{"value":355,	"label":"Telephone Charges",	"ml_label":"telephone charges",	"roc":"Revenue"},
{"value":356,	"label":"Telephone System",	"ml_label":"telephone system",	"roc":"3 yrs"},
{"value":357,	"label":"Telescope",	"ml_label":"telescope",	"roc":"3 yrs"},
{"value":358,	"label":"Temperature Monitoring System",	"ml_label":"temperature monitoring system",	"roc":"1 yr"},
{"value":359,	"label":"Test Card",	"ml_label":"test card",	"roc":"3 yrs"},
{"value":360,	"label":"Test Equipment",	"ml_label":"test equipment",	"roc":"3 yrs"},
{"value":361,	"label":"Test Equipment With Software",	"ml_label":"test equipment with software",	"roc":"1 yr"},
{"value":362,	"label":"Thermal Imager",	"ml_label":"thermal imager",	"roc":"1 yr"},
{"value":363,	"label":"Thermal Temperature Scanning System",	"ml_label":"thermal temperature scanning system",	"roc":"3 yrs"},
{"value":364,	"label":"Tiling Works",	"ml_label":"tiling works",	"roc":"S14Q"},
{"value":365,	"label":"Time Recorder",	"ml_label":"time recorder",	"roc":"3 yrs"},
{"value":366,	"label":"Toiletries Dispenser",	"ml_label":"toiletries dispenser",	"roc":"3 yrs"},
{"value":367,	"label":"Toolings",	"ml_label":"toolings",	"roc":"Replacement Basis"},
{"value":368,	"label":"Tracker",	"ml_label":"tracker",	"roc":"3 yrs"},
{"value":369,	"label":"Traffic Alert And Collision Avoidance System",	"ml_label":"traffic alert and collision avoidance system",	"roc":"3 yrs"},
{"value":370,	"label":"Traffic Light",	"ml_label":"traffic light",	"roc":"No Claim"},
{"value":371,	"label":"Trailer",	"ml_label":"trailer",	"roc":"3 yrs"},
{"value":372,	"label":"Transferred Assets",	"ml_label":"transferred assets",	"roc":"tba"},
{"value":373,	"label":"Transformer",	"ml_label":"transformer",	"roc":"3 yrs"},
{"value":374,	"label":"Tricycle",	"ml_label":"tricycle",	"roc":"3 yrs"},
{"value":375,	"label":"Trolley",	"ml_label":"trolley",	"roc":"3 yrs"},
{"value":376,	"label":"Truck",	"ml_label":"truck",	"roc":"3 yrs"},
{"value":377,	"label":"Ultrasound Machine",	"ml_label":"ultrasound machine",	"roc":"1 yr"},
{"value":378,	"label":"Ups",	"ml_label":"ups",	"roc":"3 yrs"},
{"value":379,	"label":"Utilities Meter",	"ml_label":"utilities meter",	"roc":"3 yrs"},
{"value":380,	"label":"Utility Charges",	"ml_label":"utility charges",	"roc":"Revenue"},
{"value":381,	"label":"Van",	"ml_label":"van",	"roc":"3 yrs"},
{"value":382,	"label":"Vault",	"ml_label":"vault",	"roc":"3 yrs"},
{"value":383,	"label":"Vehicle Camera System",	"ml_label":"vehicle camera system",	"roc":"tba"},
{"value":384,	"label":"Vehicle Inspection System",	"ml_label":"vehicle inspection system",	"roc":"3 yrs"},
{"value":385,	"label":"Video Conference System",	"ml_label":"video conference system",	"roc":"1 yr"},
{"value":386,	"label":"Video Inspection Probe",	"ml_label":"video inspection probe",	"roc":"1 yr"},
{"value":387,	"label":"Visitor Management System",	"ml_label":"visitor management system",	"roc":"1 yr"},
{"value":388,	"label":"Vital Signs Monitor",	"ml_label":"vital signs monitor",	"roc":"1 yr"},
{"value":389,	"label":"Walkie Talkie",	"ml_label":"walkie talkie",	"roc":"3 yrs"},
{"value":390,	"label":"Wall Works",	"ml_label":"wall works",	"roc":"S14Q"},
{"value":391,	"label":"Wallpaper",	"ml_label":"wallpaper",	"roc":"S14Q"},
{"value":392,	"label":"Waste Disposal Unit",	"ml_label":"waste disposal unit",	"roc":"3 yrs"},
{"value":393,	"label":"Waste Pump System",	"ml_label":"waste pump system",	"roc":"3 yrs"},
{"value":394,	"label":"Waste Treatment System",	"ml_label":"waste treatment system",	"roc":"3 yrs"},
{"value":395,	"label":"Water Chiller System",	"ml_label":"water chiller system",	"roc":"3 yrs"},
{"value":396,	"label":"Water Purifier",	"ml_label":"water purifier",	"roc":"3 yrs"},
{"value":397,	"label":"Water System",	"ml_label":"water system",	"roc":"S14Q"},
{"value":398,	"label":"Water Treatment System",	"ml_label":"water treatment system",	"roc":"3 yrs"},
{"value":399,	"label":"Website",	"ml_label":"website",	"roc":"1 yr"},
{"value":400,	"label":"Weight Height Measuring Machine With Bmi System",	"ml_label":"weight height measuring machine with bmi system",	"roc":"1 yr"},
{"value":401,	"label":"Wheelchair",	"ml_label":"wheelchair",	"roc":"3 yrs"},
{"value":402,	"label":"Whiteboard",	"ml_label":"whiteboard",	"roc":"3 yrs"},
{"value":403,	"label":"Wireless Ultrasonic Measuring Station",	"ml_label":"wireless ultrasonic measuring station",	"roc":"1 yr"},
{"value":404,	"label":"Wireless Urinary Flow Meter",	"ml_label":"wireless urinary flow meter",	"roc":"1 yr"},
{"value":405,	"label":"Wiring Harness Design",	"ml_label":"wiring harness design",	"roc":"3 yrs"},
{"value":406,	"label":"Work Assist Vehicle",	"ml_label":"work assist vehicle",	"roc":"3 yrs"},
{"value":407,	"label":"Workshop Equipment",	"ml_label":"workshop equipment",	"roc":"3 yrs"},
{"value":408,	"label":"X-Ray System",	"ml_label":"x-ray system",	"roc":"3 yrs"},
{"value":409,	"label":"Electrical Door Operating System",	"ml_label":"electrical door operating system",	"roc":"3 yrs"},
{"value":410,	"label":"Business Events Expenditure",	"ml_label":"business events expenditure",	"roc":"Revenue"},
{"value":411,	"label":"Interactive White Board",	"ml_label":"interactive white board",	"roc":"1 yr"},
{"value":412,	"label":"Apportionment",	"ml_label":"apportionment",	"roc":"tba"},
{"value":413,	"label":"Other",	"ml_label":"other",	"roc":"No Claim"}
];


export default	 selectData;
// export default {selectData, descColumn, mlColumn, rocColumn, rocOptions};

