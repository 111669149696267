import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import {withStyles} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import ViewList from '@material-ui/icons/ViewList';
import * as PropTypes from 'prop-types';
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {getRole} from '../common/loginService';
import {GetRequest} from '../common/requests';
import {URL} from '../common/url';
import Group from './components/Group';
import Stats from './components/Stats';
import User from './components/User';
import './custom.css';

const styles ={
  adminMain: {
    padding: '8px 56px 16px 56px',
  },
  contentGrid:{
    paddingLeft: '40px',
    marginTop: '20px'
   },
  content:{
    background: '#FFFFFF',
    padding: '40px 56px 40px 56px',
    minHeight: '75vh',
  },
  titleHead:{
    padding: '16px 0',
  },
  menuItem:{
    fontSize: '12px',
    '&:focus': {
      outline: "none",
    },
  },
  menuText:{
    paddingLeft: '16px',
    fontSize: '17px',
    lineHeight: '1.29',
    color: '#1b1b1b',
    fontFamily: 'Raleway !important',
    fontWeight: '500'
  },
  menu: {
    padding: '24px 0 0 px',
  },
  active: {
    fontWeight: 'bold !important',
  },
  icon: {
    color: '#5EAEFF',
    paddingTop: '6px'
  },
  // Super admin style

  workMain: {
    padding: '0 56px 3rem 56px',
  },
  supercontentGrid:{
    paddingLeft: '15px',
   },
  titleTabs: {
    margin: 'auto 90px 0 auto',
    cursor: 'pointer',
    fontFamily: 'Raleway !important',
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '1.2',
    letterSpacing: '-0.32px',
    color: '#1e2a5a'
  },
  tabHeader: {
    paddingLeft: '50px',
    margin: '0rem 0 2.5rem 0',
    display: 'flex'
  },
  activeTab: {
    borderBottom: '4.9px solid #5EAEFF',
    fontWeight: 'bold',
  },
  disableTab: {
    color: '#b7b7b7',
    cursor: 'default !important'
  },
  mainTitle: {
    // fontFamily: '\'Raleway\',sans-serif!important',
    fontWeight: '600',
    color: '#1e2a5a',
    margin: '0.6rem 0 2rem 0',
    fontFamily: 'Raleway !important',
    fontSize: '1.8rem'
  },
  testPadding: {
      paddingLeft: '0'
  },
  listIcon: {
    width: '50px',
    height: '50px',
    background: 'white',
    padding: '10px',
    border: '1px solid #e1e3e4',
    cursor: 'pointer'
  },
  right20: {
    marginRight: '20px'
  },
};


class Admin extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  data = {
    firstName: '',
    lastName: '',
    orgName: '',
    email: '',
    status: true,
    showEmail: '',
    userAdd: false,
    view: false,
  };

  state = {
   data: {
     ...this.data,
   },
   selectedTab: 'user',
   openModal: false,
   role: getRole(),
   key: 1,
  };
  accessTabs = [
    {
      displayName: this.props.t('admin.tabs.users'),

      displayIcon: <PersonIcon />,
      name: 'user',
      click: true
    },
    {
      displayName: this.props.t('admin.tabs.groups'),
      displayIcon: <GroupIcon />,
      name: 'group',
      click: true
    },
    {
      displayName: this.props.t('admin.tabs.stats'),
      displayIcon: <EqualizerIcon />,
      name: 'stats',
      click: true
    },
  ];

  componentDidMount() {
    const orgId = this.props.match.params.id;
    this.getOrganization(orgId)
  }

  getOrganization = (orgId) => {
    this.setState({loading: true});
    GetRequest(URL.organizations + "/" + orgId + "/", (data) => {
      this.setState({
        organization: data.payload,
        loading: false
      }, () => {});
    }, (err) => {
      console.log(err);
    });
  };

  changeTab = (value, keyUpdate) => {
     this.setState({selectedTab: value});
     if(value === 'user'){
       this.setState({data: this.data});
     }
     if(keyUpdate){
       this.setState(prevState => ({ key: prevState.key +1, view: false}));
     }
   };

  changeData = (key, value) => {
     if(key === 'data'){
       this.setState({data: this.data});
     } else {
       this.setState(prevState => ({
         ...prevState,
         data: { ...prevState.data, [key]: value },
       }));
     }
   };

  changeDisplayType = (value) => {
    this.setState({displayType: value})
  };

  render() {
    const {t, classes } = this.props;
    const { selectedTab, role, key, organization, displayType } =  this.state;
    const orgId = this.props.match.params.id;

    return(
      <div className={classes.adminMain}>
        <Grid container>
          <Grid item xs={12} className={classes.titleHead} style={{display: 'flex'}}>
            <Typography variant='h5' className={classes.mainTitle}>{t('super-admin.org-details.user-edit.admin')} - {organization ? organization.name : ""}</Typography>
            {selectedTab === "user" &&
              <Grid item style={{marginLeft: 'auto', display: 'flex', paddingTop: '15px'}}>
                <Tooltip title='Grid view'>
                  <div className={`${classes.listIcon} ${classes.right20}`} onClick={()=>this.changeDisplayType('module')}
                       style={{color: displayType === 'module' ? '#959daf' : 'd3d3d3',boxShadow: displayType === 'module' ? '0 8px 8px 0 #d1dae5' : ''}} >
                    <i className="fa fa-th-large grid-icon" style={{color: displayType === 'module' ? '#959daf' : '#d3d3d3'}} aria-hidden="true"/>
                  </div>
                </Tooltip>
                <Tooltip title='List view'>
                  <ViewList className={classes.listIcon} onClick={()=>this.changeDisplayType('list')}
                            style={{color: displayType !== 'module' ? '#959daf' : '#d3d3d3',boxShadow: displayType !== 'module' ? '0 8px 8px 0 #d1dae5' : ''}} />
                </Tooltip>
              </Grid>
            }
          </Grid>
          <Grid item xs={12} sm={2} className="menu">
            <MenuList className={classes.testPadding}>
            {
              this.accessTabs.map((tab) =>
                <MenuItem button={tab.click} component='li'
                          onClick={()=> tab.click ? this.changeTab(tab.name, true): this.handleSite()}
                          className={`${classes.menuItem} ${tab.name === selectedTab ? classes.active : null}`}
                          key={t('\''.concat(tab.displayName,'\''))}>
                  <span className={classes.icon}>{tab.displayIcon}</span>
                  <div className={`${classes.menuText} ${tab.name === selectedTab ? classes.active : null}`}>{tab.displayName}</div>
                </MenuItem>
              )
            }
            </MenuList>
          </Grid>
          <Grid item xs={12} sm={10} className={classes.contentGrid}>
            <Box boxShadow={3} className={classes.content}>
              {
                {
                  'user': (
                    <React.Fragment>
                      {
                        ((role === "ClientAdmin") || (role === "NirvedaSuperAdmin")) && <User orgId={orgId} displayType={displayType} />
                      }
                      {
                        role === "ClientUser" && <div style={{textAlign: 'center'}}><div style={{fontSize: '16px', fontWeight: 'bold'}}>{t('msg.access-denied')}</div><div>{t('msg.access-deny-details')}</div></div>
                      }
                    </React.Fragment>
                  ),
                  'group': <Group key={key} orgId={orgId} displayType={displayType} />,
                  'stats': <Stats key={key} orgId={orgId} displayType={displayType} />
                }[selectedTab]
              }
            </Box>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withTranslation()(withStyles(styles)(Admin));
