import {withStyles} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import * as PropTypes from 'prop-types';
import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';


const styles = {
  modal: {
    background: 'white',
    position: 'relative',
    width: '550px',
    top: '20%',
    left: '35%',
    textAlign: 'left'
  },
  modalTitle: {
    background: 'linear-gradient(70deg, #5159F8, #5EAAFF)',
    color: 'white',
    textAlign: 'left',
    padding: '20px'
  },
  modalContent: {
    padding: '20px'
  },
  formInput: {
    margin: '10px',
    width: '95%'
  },
  submit: {
    textAlign: 'right',
    borderTop: '1px solid #E1E3E4',
    paddingTop: '16px'
  },
  label: {
    marginLeft: '10px !important'
  },
  actionButton: {
    marginRight: '10px',
    color: '#d3d3d3',
    fontSize: '16px',
    fontWeight: 'bold'
  }
};


class StepOne extends Component {
  static propTypes = {
    // modalOpen: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
  };

  state = {
    organizationName: '',
    description: '',
    submit: false,
    organizationNameError: '',
    activeStep: 0,
    skipped: new Set()
  };

  handleName = (event) => {
    console.log(event)
    const {name, value} = event.target;
    this.setState(prevState => ({
      ...prevState,
      [name]: value
    }), () => this.checkValidations());

    this.props.setName(value)
  };

  handleDescription = (event) => {
    console.log(event)
    const {name, value} = event.target;
    this.setState(prevState => ({
      ...prevState,
      [name]: value
    }), () => this.checkValidations());

    this.props.setDesc(value)
  };

  controller = new window.AbortController();

  componentDidMount() {
    const {name, description} = this.props
    this.setState({
      organizationName: name,
      description: description,
      submit: false,
      organizationNameError: '',
      activeStep: 0,
      skipped: new Set()
    })
  }

  componentWillUnmount() {
    // console.log(this.state.organizationName)
    // console.log(this.state.description)
    // this.handleChange()
    this.checkValidations()
    this.props.setName(this.state.organizationName)
    this.props.setDesc(this.state.description)
  }

  checkValidations() {
    let valid = true;
    const {t} = this.props;
    let organizationNameError = "";

    if (!this.state.organizationName || this.state.organizationName.trim() === '') {
      organizationNameError = t('msg.empty-org');
      this.setState(prevState => ({...prevState, organizationNameError}))
      valid = false;
    }
    return valid;
  }

  // handleAdd = () => {
  //   const {modalOpen} = this.props;
  //   const {firstName, lastName, status, email, selectedGroups} = this.state;
  //   const payload = {
  //     name: firstName,
  //     family_name: lastName,
  //     email: email,
  //     groups: selectedGroups,
  //     role: status ? 'Admin' : 'User',
  //     status: 'Active'
  //   };
  //
  //   this.setState({submit: true}, () => {
  //     if (this.checkValidations()) {
  //       PostRequest(URL.onBoardingUser, this.controller.signal, payload, () => {
  //         modalOpen(false, true);
  //       }, (err) => {
  //         console.log(err);
  //       });
  //     }
  //   });
  // };

  render() {
    const {t, classes} = this.props;
    const {organizationName, description} = this.state;

    return (
        <React.Fragment>
          <Typography variant="subtitle1" >
            <b>{t('super-admin.create-org-modal.step')} 1</b>{t('super-admin.create-org-modal.title')}
          </Typography>

          <TextField label={t('super-admin.create-org-modal.org-name-label')}
            name="organizationName"
            id="organizationName"
            value={organizationName}
            required
            onChange={this.handleName}
            className={classes.formInput}
            helperText={this.props.nameError}
            error={this.props.nameError !== ''}
          />
          <Typography variant="subtitle2" style={{marginTop: '20px'}}>
             <b>{t('super-admin.create-org-modal.desc')}</b>
          </Typography>
          <TextField
            name="description"
            id="description"
            value={description}
            onChange={this.handleDescription}
            className="description-box"
            style={{marginBottom: '38px'}}
            variant="outlined"
            inputProps={{ maxLength: 100 }}
            helperText={t('super-admin.create-org-modal.char-limit')}
            multiline
          />
        </React.Fragment>
    );
  }
}

export default withTranslation()(withStyles(styles)(StepOne));
