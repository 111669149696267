import _ from 'lodash';
import {Paper, Table} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from '@material-ui/core/Modal';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import withStyles from '@material-ui/core/styles/withStyles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import BackIcon from '@material-ui/icons/KeyboardArrowLeft';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import SearchIcon from '@material-ui/icons/Search';
import PersonIcon from '@material-ui/icons/PersonAdd';
// import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import React, {Component, Fragment} from 'react';
import ProfileImgBlue from '../../../assets/images/user_blue.png';
import ProfileImgGrey from '../../../assets/images/user_grey.png';
import {withTranslation} from 'react-i18next';
import {getUserName} from '../../../common/loginService';
import {
  GetRequest,
  PatchRequestV2,
  PostRequest
} from '../../../common/requests';
import {URL} from '../../../common/url';
import ClientUser from '../clientUser';
import Utils from '../../../utils';

const styles = {
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  formInput: {
    width: '40%'
  },
  filter: {
    minWidth: '175px',
    marginLeft: '1rem',
    background: '#F4F6F9',
  },
  groupSelect: {
    minWidth: '40%',
    maxWidth: '40%',
  },
  label: {
    // marginLeft: '10px !important'
  },
  button: {
    textTransform: 'unset',
    borderRadius: '30px',
    color: '#959CAE',
    fontSize: '14px',
    padding: '8px 16px'
  },
  updateButton: {
    color: '#686868',
    borderRadius: '8px',
    fontSize: '15px',
    fontWeight: '600',
    border: '1px solid #e1e3e4',
    padding: '10px 40px'
  },
  sendResetButton: {
    color: '#ffffff',
    backgroundColor: '#618bff',
    fontFamily: '\'Open Sans\', sans-serif !important',
    marginTop: '40px'
  },
  resetText: {
    color: '#1b1b1b',
    fontSize: '17px',
    fontFamily: '\'Open Sans\', sans-serif !important'
  },
  resetDescription: {
    color: '#686868',
    fontSize: '18px',
    fontFamily: '\'Open Sans\', sans-serif !important'
  },
  personIcon: {
    fontSize: '18px',
    marginRight: '10px'
  },
  mlAuto: {
    marginLeft: 'auto'
  },
  outlinedFilter: {
    padding: '1rem 0',
  },
  innerGrid: {
    marginTop: '2rem',
  },
  tableIcon: {
    marginRight: '0.5rem',
    marginBottom: '-0.5rem',
    marginLeft: '-0.5rem',
  },
  userIcon: {
    marginRight: '0.5rem',
    marginBottom: '-0.5rem',
    marginLeft: '-0.1rem',
  },
  tableHeader: {
    borderBottom: '2px solid grey'
  },
  editBack: {
    paddingLeft: 0,
    marginLeft: '-0.5rem',
  },
  titleHead: {
    marginTop: '1.5rem',
    marginBottom: '1rem',
  },
  titleMain: {
    marginTop: '2rem',
  },
  inputSide: {
    width: '100%'
  },
  firstGrid: {
    width: '40%',
    marginRight: '2rem'
  },
  searchBar: {
    width: '50%',
    paddingTop: '1rem',
  },
  limitText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
  },
  loader: {
    position: 'relative',
    left: 'calc(50% - 20px)',
    marginTop: '40px',
    marginRight: 'auto'
  },
  editIcon: {
    color: '#959DAF',
    cursor: 'pointer'
  },
  menuItem: {
    fontFamily: '\'Open Sans\', sans-serif !important',
    fontSize: '17px',
    color: '#141b2d',
    padding: '0 16px'
  },
  modal: {
    background: 'white',
    position: 'relative',
    width: '450px',
    top: '30%',
    left: '30%',
    textAlign: 'left',
  },
  modalSubject: {
    color: '#141b2d',
    fontSize: '21px',
    fontFamily: '\'Open Sans\', sans-serif !important',
    marginBottom: '24px'
  },
  modalDescription: {
    fontFamily: '\'Open Sans\', sans-serif !important',
    color: '#1e2a5a'
  },
  submitDelete: {
    textAlign: 'right',
    marginTop: '24px'
  },
  actionButton: {
    marginRight: '10px',
    color: '#000000',
    fontWeight: 'bold',
    fontSize: '15px',
    fontFamily: '\'Open Sans\', sans-serif !important'
  },
  modalItem: {
    '&:hover': {
      cursor: 'pointer',
    },
    display: 'flex'
  },
  modalText: {
    verticalAlign: 'super',
    textDecoration: 'none !important',
    color: '#141b2d',
    fontFamily: '\'Open Sans\', sans-serif !important',
    fontSize: '17px'
  },
  optionsFiles: {
    position: 'absolute',
    width: 'max-content',
    zIndex: '2',
    boxShadow: '0 5px 2px 0 rgba(209, 218, 229, 0.7)',
    border: '1px solid #e1e3e4',
    padding: '16px',
    borderRadius: '10px',
    fontFamily: '\'Open Sans\', sans-serif !important',
    left: '-135px',
    top: '34px'
  },
  ActiveTag: {
    background: '#5fafff',
    marginLeft: '40px',
    marginRight: '32px',
    borderRadius: '20px',
    fontSize: '13px',
    color: 'white',
    fontFamily: '\'Open Sans\', sans-serif !important',
    padding: '2px 4px',
    marginTop: '2px'
  }
};

class User extends Component{

  state = {
    page: 0,
    rowsPerPage: Utils.pageSize,
    userList: [],
    userCount: 0,
    groupList: [],
    groupCount: 0,
    userSearchTerm: "",
    filters: {},
    openModal: false,
    filterRole: 'all',
    filterActive: 'all',
    edit: false,
    selectedUser: {},
    firstName: '',
    lastName: '',
    email: '',
    groups: [],
    status: true,
    role: '',
    id: 0,
    anchor: null,
    open: false,
    openSnack: false,
    snackMsg: '',
    loading: true,
    firstNameError: false,
    lastNameError: false,
    resetPassword : false,
    resetPasswordModal: false,
    showActions : false,
    openDeactivate: false,
  };

  controller = new window.AbortController();
  cleanup = null;

  componentDidMount() {
    this.cleanup = () => {
      this.controller.abort();
    };

    this.getUsers();
    this.getGroups();
  }

  componentWillUnmount() {
    if (this.cleanup) this.cleanup();
    this.cleanup = null;
  }

  updateUserSearchTerm = (userSearchTerm) => {
    this.setState(prevState => ({ userSearchTerm }));
  }

  updateFilters = (filters) => {
    this.setState(prevState => ({ filters }));
  }

  getUsers = (page, pageSize) => {
    const {userSearchTerm, filters, rowsPerPage} = this.state
    this.setState({loading: true})

    if (page === undefined)
      page = 1

    if (pageSize === undefined)
      pageSize = rowsPerPage

    let params = {
      org_id: this.props.orgId,
      [Utils.pageQueryParam]: page,
      [Utils.pageSizeQueryParam]: pageSize
    };

    if (userSearchTerm)
      params["search"] = userSearchTerm

    if (!_.isEmpty(filters))
      params = {...params, ...filters}

    const queryParams = Utils.encodeQueryParams(params)
    GetRequest(URL.users + "?" + queryParams,(response)=> {
      const {data: userList, count: userCount} = response
      this.setState({
        userList,
        userCount,
        loading: false,
      }, () => {});
    }, (err)=> {this.setState({loading: false})})
  };

  getGroups = (page, pageSize) => {
    this.setState({loading: true})

    if (page === undefined)
      page = 1
    if (pageSize === undefined)
      // pageSize is set to very large value to get all the groups for the organization.
      pageSize = 10000

    const params = {
      org_id: this.props.orgId,
      [Utils.pageQueryParam]: page,
      [Utils.pageSizeQueryParam]: pageSize
    };
    const queryParams = Utils.encodeQueryParams(params)

    GetRequest(URL.groups + "?" + queryParams,(response)=> {
      const {data: groupList, count: groupCount} = response
      this.setState({
        groupList,
        groupCount,
        loading: false,
      }, () => {});
    }, (err)=> {this.setState({loading: false})})
  };

  getGroupName = (id) => {
    const group = this.state.groupList.filter(obj => obj.id === id)[0]
    return group.name
  }

  modalOpen = (value, refresh) => {
    this.setState({openModal: value});
    if(refresh){
      this.getUsers();
      this.getGroups();
   }
  };

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage
    });

    this.getUsers(newPage + 1)
  };

  handleChangeRowsPerPage = (event) => {
    const pageSize = parseInt(event.target.value, 10)
    this.setState({
      page: 0,
      rowsPerPage: pageSize
    });

    this.getUsers(1, pageSize)
  };

  handleSearch = (event) => {
    if (event.constructor.name === "SyntheticEvent") {
      const {name, value} = event.currentTarget;

      // Just set the search value in state. Additionally, call the api in the setState callback function,
      // upon clearing everything on search bar .
      this.setState(
        prevState => ({[name]: value}),
        () => {
          if (!value)
            this.getUsers(1, this.state.rowsPerPage);
        });
    }
    else {
      // Call api on hitting enter key.
      if (event.keyCode === Utils.enterKeyCode)
        this.getUsers(1, this.state.rowsPerPage)
    }
  }

  // clearSearch = () => {
  //   if (this.state.userSearchTerm) {
  //     this.setState(
  //       prevState => ({userSearchTerm: ""}),
  //       () => {
  //         this.getUsers(1, this.state.rowsPerPage)
  //       });
  //   }
  // }

  handleFilters = (event) => {
    const { name, value } = event.target;
    let {filters} = this.state

    const roleFilters = {
      name: "role__name__iexact",
      clientadmin: "Admin",
      clientuser: "User",
    }

    const activeFilters = {
      name: "is_active",
      active: true,
      inactive: false,
    }

    if (name === "filterRole"){
      if (value === "all")
        delete filters[roleFilters.name]
      else
        filters[roleFilters.name] = roleFilters[value]
    }

    if (name === "filterActive") {
      if (value === "all")
        delete filters[activeFilters.name]
      else
        filters[activeFilters.name] = activeFilters[value]
    }

    this.setState(
      prevState => ({[name]: value, filters}),
      () => {
        this.getUsers(1, this.state.rowsPerPage)
      });
  };

  handleChange = (event) => {
    const { name, value } = event.target;

    if (name === 'firstName') {
      this.setState({firstNameError: false})
    }
    if (name === 'lastName') {
      this.setState({lastNameError: false})
    }

    this.setState(prevState => ({ ...prevState, [name]: value }));
  };

  handleEditUser = (row) => {
    this.setState({
      edit: true,
      selectedUser: row,
      groups: row.group_ids,
      email: row.email,
      firstName: row["first_name"],
      lastName: row['last_name'],
      status: row.user_status.toLowerCase() === 'active',
      role: row["is_admin"],
      id: row.id,
      anchor: null,
      open: false,
      firstNameError: '',
      lastNameError: ''
    });
  };

  handleResetPassword = () => {
    this.setState({
      resetPassword: true,
      showActions: false
    });
  };

  toggleActionsDropdown = () => {
    this.setState(prevState => ({...prevState, showActions: !prevState.showActions}));
  };

  openDeactivateModal = () => {
    this.setState({
      openDeactivate: true,
      showActions: false
    });
  };

  closeDeactivateModal = () => {
    this.setState({
      openDeactivate: false
    });
  };

  closeActionsDropdown = () => {
    this.setState({
      showActions: false
    });
  };

  handleBack = () => {
    this.setState({edit: false, selectedUser: {}}, () => {
      this.getUsers()
    });
  };

  handleBackFromReset = () => {
    this.setState({resetPassword: false});
  };

  sendResetMail = () => {
    this.setState({resetPasswordModal: true});
  };

  closeResetPasswordModal = () => {
    this.setState({resetPasswordModal: false});
  };

  resetClicked = (email) => {
    const payload = {
      email: email.toLowerCase(),
    };

    this.setState({submit: true, loading: true}, () => {
      PostRequest(URL.forgotPassword, this.controller.signal, payload, () => {
          this.setState(prevState => ({...prevState, resetPasswordModal: false, resetPassword: false}));
        },
        (err) => {
          this.setState({loading: false, resetPasswordModal: false});
          console.log('err', err);
        });
    });
  };

  updateUser = () => {
    const {firstName, lastName, groups, role, id} = this.state;
    const {t} = this.props;

    if (firstName === '' || firstName.trim().length === 0) {
      this.setState({firstNameError: true});
      return;
    }
    if (lastName === '' || lastName.trim().length === 0) {
      this.setState({lastNameError: true});
      return;
    }
    const payload = {
      first_name: firstName,
      last_name: lastName,
      groups,
      is_admin: role
    };
    // if (openDeactivate) {
    //   if (status === true) {
    //     payload.user_status = "Inactive";
    //   } else {
    //     payload.user_status = "Active";
    //   }
    // }
    if (firstName && lastName) {
      PatchRequestV2(URL.users + "/" + id + "?org_id=" + this.props.orgId,
        this.controller.signal,
        payload, () => {
        this.handleBack();
        this.closeDeactivateModal();
        this.setState({openSnack: true, snackMsg: t('msg.update-ok')});
      }, (err) => {
        console.log(err);
        this.closeDeactivateModal();
        this.setState({openSnack: true, snackMsg: t('msg.update-fail')});
      });
    } else {
      let msg = '';
      if (!firstName && lastName) {
        msg = t('msg.first-name-empty-1');
      } else if (firstName && !lastName) {
        msg = t('msg.last-name-empty-1');
      } else {
        msg = t('full.first-name-empty-1');
      }
      this.setState({openSnack: true, snackMsg: msg});
    }
  };

  deactivateUser = () => {
    const {t} = this.props;
    const payload = {is_active: false};
      PatchRequestV2(URL.users + "/" + this.state.id + "?org_id=" + this.props.orgId,
        this.controller.signal,
        payload, () => {
        this.handleBack();
        this.closeDeactivateModal();
        this.setState({openSnack: true, snackMsg: t('msg.update-ok')});
      }, (err) => {
        console.log(err);
        this.closeDeactivateModal();
        this.setState({openSnack: true, snackMsg: t('msg.update-fail')});
      });
  }

  handleGroup = (event) => {
    const { value } = event.target;
    this.setState(prevState => ({ ...prevState, groups: value }));
  };

  handleClick = (event) => {
    this.setState({anchor: event.currentTarget, open: true});
  };

  handleClose = () => {
    this.setState({anchor: null, open: false});
  };

  closeSnack = () => {
    this.setState({openSnack: false, snackMsg: ''});
  };

  displayGrid = () => {
    const {page, rowsPerPage, userCount, userList} = this.state
    const {t} = this.props

    return <Fragment>
      <Grid
          container
          spacing={10}
          direction="row"
          justify="flex-start"
          alignItems="center">
          {userList && userList.map(user =>
            <Grid item key={user.id} xs={6} sm={3}>
                <div className="gridbox">
                <a className="flyLink" href  onClick={() => this.handleEditUser(user)}>
                  {user.email !== getUserName() && <span></span>}</a>
                  <div className="userimgDiv">
                  <img src={user.user_status.toLowerCase() === 'active' ? ProfileImgBlue : ProfileImgGrey} alt="" className="griduserIcon"/>
                  </div>
                  <div className="overflow-ellipsis griduserName">
                    <Tooltip title={user['first_name'] + ' ' + user['last_name']}><span>{user['first_name'] + ' ' + user['last_name']}</span></Tooltip>
                  </div>
                <Tooltip title={user.email}>
                  <div className="overflow-ellipsis userEmail">{user.email}</div>
                </Tooltip>
                 <div className="userCat">{user.user_category}</div>
                </div>
            </Grid>
          )}
        </Grid>
        <div>
          <TablePagination
            rowsPerPageOptions={Utils.rowsPerPageOptions}
            colSpan={6}
            count={userCount}
            rowsPerPage={rowsPerPage}
            style={{borderBottom: '0', paddingTop: '20px', display: 'block'}}
            labelRowsPerPage={t('admin.org-details.table.per-page')}
            page={page}
            SelectProps={{
              inputProps: {'aria-label': 'Results per page'},
              native: true
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </div>
    </Fragment>
  }

  displayList = () => {
    const {page, rowsPerPage, userCount, userList} = this.state
    const {t, classes} = this.props

    return <Grid>
      <Table style={{tableLayout: 'fixed'}} className="users-table">
        <TableHead>
          <TableRow className={classes.tableHeader}>
            <TableCell
              style={{
                width: '20%',
                paddingLeft: '0'
              }}><b>{t('admin.org-details.table.user')}</b></TableCell>
            <TableCell style={{width: '15%'}}><b>{t('admin.org-details.table.role')}</b></TableCell>
            <TableCell style={{width: '25%'}}><b>{t('admin.org-details.table.group')}</b></TableCell>
            <TableCell style={{width: '20%'}}><b>{t('admin.org-details.table.email')}</b></TableCell>
            <TableCell style={{width: '10%'}}><b>{t('admin.org-details.table.status')}</b></TableCell>
            <TableCell style={{width: '10%'}}><b>{t('admin.org-details.table.action')}</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userList && userList.map(row => (
            <TableRow key={row.id}>
              <TableCell
                style={{
                  width: '20%',
                  paddingLeft: '0'
                }} component="th" scope="row" className={classes.limitText}>
                <div className="avatarDiv">
                  <div className="imgDiv" style={{marginLeft: '7px'}}>
                  <img src={row.user_status.toLowerCase() === 'active' ? ProfileImgBlue : ProfileImgGrey} alt="" className="userIcon"/>
                  </div>
                  <div className="user-name">
                    <Tooltip title={row['first_name'] + ' ' + row['last_name']}><span>{row['first_name'] + ' ' + row['last_name']}</span></Tooltip>
                  </div>
                </div>
              </TableCell>
              <TableCell style={{width: '15%'}}>{row.user_category}</TableCell>
              <TableCell style={{width: '25%'}} className="overflow-ellipsis">
                <Tooltip title={row.groups.toString()}><span>{row.groups.toString()}</span></Tooltip></TableCell>
              <TableCell className="overflow-ellipsis" style={{width: '20%'}}>
                <Tooltip title={row.email}>
                  <span>{row.email}</span>
                </Tooltip>
              </TableCell>
              <TableCell style={{width: '10%'}}>{row.user_status.toLowerCase() === 'active' ? 'Active' : 'Inactive'}</TableCell>
              <TableCell style={{width: '10%', textAlign : 'center', color: 'rgba(0, 0, 0, 0.4)'}}>
                <span style={{"cursor": "pointer"}} onClick={() => this.handleEditUser(row)}>
                  {row.email !== getUserName() &&
                  <IconButton>
                    <VisibilityIcon className="margin"/>
                  </IconButton>
                  }
                </span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={Utils.rowsPerPageOptions}
              colSpan={6}
              count={userCount}
              rowsPerPage={rowsPerPage}
              style={{borderBottom: '0', paddingTop: '20px'}}
              labelRowsPerPage={t('admin.org-details.table.per-page')}
              page={page}
              SelectProps={{
                inputProps: {'aria-label': 'Results per page'},
                native: true
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Grid>;
  }

  render() {
    const {openModal, edit, filterRole, filterActive, userSearchTerm, resetPassword, resetPasswordModal, showActions, openDeactivate,
    groups, email ,role, firstName, lastName , status, groupList, openSnack, snackMsg, loading, firstNameError, lastNameError} = this.state;
    const { t , classes, orgId, displayType } = this.props;

    return(
      <Grid container direction="column" key="grid1">
        {
          edit ?
              <React.Fragment>
              {
                resetPassword ?
                    <React.Fragment>
                      <Grid container direction="row" justify="space-between" alignItems="flex-start">
                        <Button onClick={() => this.handleBackFromReset()} className={classes.editBack}><BackIcon/> {t('admin.org-details.user-edit.reset-pwd.back-btn')}</Button>
                      </Grid>
                      <Grid item  className={classes.titleMain}>
                        <Typography className={classes.resetText}>{t('admin.org-details.user-edit.reset-pwd.reset-pwd')}</Typography>
                        <Typography variant='h5' className={classes.titleMain}>{firstName + ' ' + lastName}</Typography>
                        <Typography className={classes.resetDescription}>{t('admin.org-details.user-edit.reset-pwd.reset-details')}</Typography>
                      </Grid>
                      <Grid>
                        <Button variant="outlined" className={`${classes.button} ${classes.updateButton} ${classes.sendResetButton}`}
                                onClick={() => this.sendResetMail()}>{t('admin.org-details.user-edit.reset-pwd.reset-btn')}</Button>
                      </Grid>
                    </React.Fragment>
                    :
                    <React.Fragment>
                      <Grid container direction="row" justify="space-between" alignItems="flex-start">
                        <div className='back-button'>
                          <Button onClick={this.handleBack}><span className='back-icon'><BackIcon className="margin-auto" /></span>{t('admin.org-details.user-edit.reset-pwd.back-btn')}</Button>
                        </div>
                        <Button variant="outlined" className={`${classes.button} ${classes.updateButton}`}
                                onClick={() => this.updateUser()}>{t('admin.org-details.user-edit.update-btn')}</Button>
                      </Grid>
                      <Grid item>
                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" className={classes.titleMain}>
                          <div className="username">{firstName + ' ' + lastName}</div>
                          <div className={classes.ActiveTag}>{status === true ? 'ACTIVE' : 'INACTIVE'}</div>
                          {
                            getUserName() === email ? null :
                                <ClickAwayListener onClickAway={this.closeActionsDropdown}>
                                  <div style={{position: 'relative'}}>
                                  <div className="useraction">
                                    <MoreHorizIcon style={{color: '#959daf'}}
                                                   onClick={() => this.toggleActionsDropdown()}/></div>
                                    {
                                      showActions ?
                                          <Paper className={classes.optionsFiles}>
                                            <div className={classes.modalItem} style={{paddingBottom: '10px'}}
                                                 onClick={() => this.handleResetPassword()}>
                                              <a href="javascript:void(0)" className={classes.modalText} style={{margin: 'auto 0 auto 4px'}}>{/* eslint-disable-line */}
                                              {t('admin.org-details.user-edit.dropdown.reset')}</a>
                                            </div>
                                            <div className={classes.modalItem}
                                                 onClick={() => this.openDeactivateModal()}>
                                              <a href="javascript:void(0)" className={classes.modalText} style={{margin: 'auto 0 auto 4px'}}>  {/* eslint-disable-line */}
                                                {
                                                  status ? <span className={classes.modalText}>{t('admin.org-details.user-edit.dropdown.deactivate')}</span> :
                                                      <span className={classes.modalText}>{t('admin.org-details.user-edit.dropdown.activate')}</span>
                                                }
                                              </a>
                                            </div>
                                          </Paper> : null
                                    }
                                  </div>
                                </ClickAwayListener>
                          }
                        </Grid>
                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                          <Grid className={classes.firstGrid}>
                            <Typography variant='subtitle2' className={classes.titleHead}><b>{t('admin.org-details.user-edit.first-name')}</b></Typography>
                            <TextField
                                name="firstName"
                                id="firstName"
                                onChange={this.handleChange}
                                value={firstName}
                                required
                                className={classes.inputSide}
                                variant='outlined'
                                inputProps={{
                                  style: {
                                    padding: '10px'
                                  }
                                }}
                                helperText={firstNameError ? t('msg.first-name-empty') : null}
                                error={firstNameError !== ''}
                            />
                          </Grid>
                          <Grid className={classes.firstGrid}>
                            <Typography variant='subtitle2' className={classes.titleHead}><b>{t('admin.org-details.user-edit.last-name')}</b></Typography>
                            <TextField
                                id="lastName"
                                name="lastName"
                                onChange={this.handleChange}
                                defaultValue={lastName}
                                required
                                className={classes.inputSide}
                                variant='outlined'
                                inputProps={{
                                  style: {
                                    padding: '10px'
                                  }
                                }}
                                helperText={lastNameError ? t('msg.last-name-empty') : null}
                                error={lastNameError !== ''}
                            />
                          </Grid>
                        </Grid>
                        <Typography variant='subtitle2' className={classes.titleHead}><b>{t('admin.org-details.user-edit.email')}</b></Typography>
                        <TextField
                            id="email"
                            name="email"
                            onChange={this.handleChange}
                            defaultValue={email}
                            disabled
                            className={classes.formInput}
                            variant='outlined'
                            inputProps={{
                              style: {
                                padding: '10px'
                              }
                            }}
                        />
                        <Grid>
                          <Typography variant='subtitle2' className={classes.titleHead}><b>{t('admin.org-details.user-edit.group')}</b></Typography>
                          <FormControl className={classes.groupSelect}>
                            <Select
                                multiple
                                value={groups}
                                onChange={this.handleGroup}
                                input={<Input id="select-multiple"/>}
                                className={classes.select}
                                renderValue={selected => (
                                  <div className={classes.chips}>
                                    {selected.map(value => (
                                      <Chip key={value} label={this.getGroupName(value)} className={classes.chip} />
                                    ))}
                                  </div>
                                )}
                            >
                              {groupList.length > 0 && groupList.map((group, id) => (
                                  <MenuItem key={id} value={group.id}>{group.name}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        {
                          <React.Fragment>
                            <Typography variant='subtitle2' className={classes.titleHead}><b>{t('admin.org-details.user-edit.role')}</b></Typography>
                            <FormControlLabel className={classes.label}
                              control={
                                <Checkbox
                                    color="primary"
                                    checked={role}
                                    onChange={(e) => {
                                      const {checked} = e.target;
                                      this.handleChange({
                                        target: {
                                          name: "role",
                                          value: checked,
                                        },
                                      });
                                    }}/>
                              }
                              label={t('admin.org-details.user-edit.admin')}
                              labelPlacement="end"
                            />
                          </React.Fragment>
                        }
                      </Grid>
                    </React.Fragment>
              }
              </React.Fragment>
            :
            <React.Fragment>
              <Grid container direction="row" justify="space-between" alignItems="flex-start">
                <Typography variant='h6'>{t('admin.org-details.title')}</Typography>
                <Button variant="outlined" className={classes.button} onClick={() => this.modalOpen(true)}>
                  <PersonIcon className={classes.personIcon} />
                  {t('admin.org-details.add-user-btn')}</Button>
              </Grid>
              <Grid>
                <TextField
                  name="userSearchTerm"
                  id="search"
                  onChange={this.handleSearch}
                  onKeyDown={this.handleSearch}
                  value={userSearchTerm}
                  className={classes.searchBar}
                  variant='outlined'
                  placeholder={t('admin.org-details.search-placeholder')}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end">
                        {/*<IconButton aria-label="delete" onClick={this.clearSearch}>*/}
                        {/*  <ClearOutlinedIcon fontSize="small"/>*/}
                        {/*</IconButton>*/}
                        <SearchIcon style={{ color: 'grey'}}/>
                      </InputAdornment>
                  }}
                  inputProps={{
                    style: {
                      padding: '0.75rem 1rem'
                    }
                  }}
                />
              </Grid>
              <Grid container
                    className={classes.innerGrid}
                    direction="row"
                    justify="space-between"
                    alignItems="center">
                <b>{t('admin.org-details.sub')}</b>
                <Grid item>
                  <FormControl className={classes.filter}>
                    <Select
                      variant='outlined'
                      value={filterRole}
                      className="filterSelect"
                      onChange={this.handleFilters}
                      input={<OutlinedInput name="filterRole" id="age" className={classes.outlinedFilter}/>}
                    >
                      <MenuItem className={classes.menuItem} value='all'>{t('admin.org-details.role-dropdown.any')} { filterRole === 'all' ? <span className={classes.mlAuto}></span> : ''}</MenuItem>
                      <MenuItem className={classes.menuItem} value='clientadmin'>{t('admin.org-details.role-dropdown.admin')} { filterRole === 'clientadmin' ? <span className={classes.mlAuto}></span> : ''}</MenuItem>
                      <MenuItem className={classes.menuItem} value='clientuser'>{t('admin.org-details.role-dropdown.user')} { filterRole === 'clientuser' ? <span className={classes.mlAuto}></span> : ''}</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl className={classes.filter}>
                    <Select
                      variant='outlined'
                      value={filterActive}
                      className="filterSelect"
                      onChange={this.handleFilters}
                      input={<OutlinedInput name="filterActive" id="age-simple" className={classes.outlinedFilter}/>}
                    >
                      <MenuItem className={classes.menuItem} value='all' >{t('admin.org-details.users-dropdown.all')}
                        { filterActive === 'all' ? <span className={classes.mlAuto}></span> : ''}</MenuItem>
                      <MenuItem className={classes.menuItem} value="active">{t('admin.org-details.users-dropdown.active')}
                        { filterActive === 'active' ? <span className={classes.mlAuto}></span> : ''}</MenuItem>
                      <MenuItem className={classes.menuItem} value="inactive">{t('admin.org-details.users-dropdown.inactive')}
                        { filterActive === 'inactive' ? <span className={classes.mlAuto}></span> : ''}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Fragment>
                {
                  loading
                    ? <div className={classes.loader}><CircularProgress className='loader' style={{ color: '#5fafff'}} size={20} /></div>
                    :
                    <Grid>
                      {displayType === 'module'
                        ? <Grid className={`${classes.gridView} pageContent1 ${classes.innerGrid}`}>
                            {this.displayGrid()}
                          </Grid>

                        : <Grid className={`${classes.gridView} pageContent1 ${classes.innerGrid}`}>
                            {this.displayList()}
                          </Grid>
                      }
                    </Grid>
                }
              </Fragment>
            </React.Fragment>
        }
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          onClose={() => this.modalOpen(false)}
          open={openModal}
          className="new-user-modal"
          >
          <React.Fragment>
             <ClientUser
               modalOpen={this.modalOpen}
               orgId={orgId}
             />
          </React.Fragment>
        </Modal>
        <Modal open={resetPasswordModal}>
          <React.Fragment>
            <div className={classes.modal} key="inputForm">
              <div style={{padding: '20px'}}>
                <div className={classes.modalSubject}>
                  Confirm Password Reset
                </div>
                <div className={classes.modalDescription}>
                  <div>Are you sure you want to reset {firstName + ' ' + lastName}'s password?</div>
                  <div>Reset instructions will be sent to <b>{email}.</b></div>
                </div>
                <div className={classes.submitDelete}>
                  <Button className={classes.actionButton} onClick={() => this.closeResetPasswordModal()}>NO</Button>
                  <Button className={classes.actionButton} onClick={() => this.resetClicked(email)}>YES</Button>
                </div>
              </div>
            </div>
          </React.Fragment>
        </Modal>
        <Modal open={openDeactivate}>
          <React.Fragment>
            <div className={classes.modal} key="inputForm" style={{width: '520px'}}>
              <div style={{padding: '20px'}}>
                <div className={classes.modalSubject}>
                  
                  {status ?
                      <span> {t('admin.org-details.user-edit.modify-user.modal-title-deact')}</span> :
                      <span> {t('admin.org-details.user-edit.modify-user.modal-title-act')}</span>
                  } user?
                </div>
                <div className={classes.modalDescription}>
                  <div>{t('admin.org-details.user-edit.modify-user.txt1')}{status ?
                      <span> {t('admin.org-details.user-edit.modify-user.modal-title-act')}</span> :
                      <span> {t('admin.org-details.user-edit.modify-user.modal-title-deact')}</span>
                  } {t('admin.org-details.user-edit.modify-user.txt2')}</div>
                  <div>{t('admin.org-details.user-edit.modify-user.txt3')}</div>
                </div>
                <div className={classes.submitDelete}>
                  <Button className={classes.actionButton} onClick={() => this.closeDeactivateModal()}>{t('admin.org-details.user-edit.modify-user.no')}</Button>
                  <Button className={classes.actionButton} onClick={() => this.deactivateUser()}>{t('admin.org-details.user-edit.modify-user.yes')}</Button>
                </div>
              </div>
            </div>
          </React.Fragment>
        </Modal>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={openSnack}
          autoHideDuration={3000}
          onClose={this.closeSnack}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{snackMsg}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.closeSnack}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Grid>
    )
  }
}

export default withTranslation()((withStyles(styles)(User)));
