import _ from 'lodash';
import {toast} from 'react-toastify';
import Utils from '../../../../utils';
import TableUtils from '../utils';

let log = console.info.bind(window.console);


export const renderChart = (e, selectedColumn = null, _this) => {
  let range = _this.gridApi.getCellRanges();
  let selectedColumns = [];

  // If column name is passed in argument, give it preference.
  if (selectedColumn !== null) {
    try {
      let column = _this.gridColumnApi.getColumn(selectedColumn);
      if (column === null) {
        toast.error('Column not found for creating chart');
        return false;
      }
      selectedColumns = [column];
      _this.gridApi.clearRangeSelection();
      _this.gridApi.deselectAll();
      _this.gridApi.deselectAllFiltered();
      let rowCount = _this.gridApi.getDisplayedRowCount();
      _this.gridApi.addCellRange({
        columns: selectedColumns,
        rowStartIndex: 0,
        rowEndIndex: rowCount - 1
      });
      _this.gridApi.refreshCells();
      range = _this.gridApi.getCellRanges();
      log(range);
    } catch (ex) {
      toast.error('Something went wrong!');
      return;
    }
  } else { // get the users selection
    try {
      selectedColumns = range[0].columns;
    } catch (err) {
      if (selectedColumn === null) {
        toast.error('No column specified for creating chart');
        return false;
      }
    }
  }

  const startRowIndex = range[0].startRow.rowIndex;
  const endRowIndex = range[0].endRow.rowIndex;

  let cols = [];
  let chartTitle = '';
  _.forEach(selectedColumns, function (col, i) {
    cols.push(col.colId);
    chartTitle += col.colId + ' vs ';
  });
  chartTitle = chartTitle.substring(0, chartTitle.length - 3);

  let colValues = {};
  const labelColumnObject = TableUtils.getLabelColumnObject(cols, startRowIndex, _this.gridApi);
  if (labelColumnObject) {
    for (let i = startRowIndex; i <= endRowIndex; ++i) {
      const row = _this.gridApi.getDisplayedRowAtIndex(i);
      const value = row.data[labelColumnObject.valueColumn];
      let key = row.data[labelColumnObject.labelColumn];
      if (cols.length === 1)
        key = labelColumnObject.labelColumn;

      try {
        colValues[key].push(value);
      } catch (e) {
        if (e instanceof TypeError)
          colValues[key] = [value];
      }
    }
  }

  log(colValues);
  let values = [];

  if (Object.keys(colValues).length === 1) {
    let index = Object.keys(colValues);
    let list = colValues[index[0]];

    let dist = list.reduce((acc, item) => {
      acc[item] = (acc[item] || 0) + 1;
      return acc;
    }, {});
    _.forEach(dist, (val, key) => {
      let y = val / list.length * 100.0;
      y = Utils.roundToTwo(y);
      values.push({x: key, y});
    });

  } else if (Object.keys(colValues).length > 1) {
    for (let key in colValues) {
      let y = 0;
      _.forEach(colValues[key], v => {
        // This is an opportunity to extract numeric data out of a string intelligently
        // const val = Math.trunc(v)
        log(v);
        const val = Utils.roundToTwo(v);
        if (!isNaN(val))
          y += val;
      });
      let obj = {x: key, y};
      values.push(obj);
    }
  }

  values.sort((a, b) => (a.y > b.y) ? -1 : 1);
  log(values);

  let chartData = {
    label: chartTitle,
    values
  };
  let chartLegends = [];
  _.map(chartData.values, obj => {
    chartLegends.push({...obj, state: true});
  });
  _this.setState({chartData, chartLegends});
  _this.toggleChart(true);
  log('render chart');
};


/*
  Function to hide and show various sections of the pie chart 
  */
export const toggleChartForData = (obj, _this) => {

  const {chartData, chartLegends} = _this.state;
  let index = -1;
  _.map(chartData.values, (value, i) => {
    if ((value.x === obj.x) && (value.y === obj.y))
      index = i;
  });

  if (index >= 0)
    chartData.values.splice(index, 1);
  else
    chartData.values.push({...obj});

  _.map(chartLegends, value => {
    if ((value.x === obj.x) && (value.y === obj.y))
      value.state = !value.state;
  });
  _this.setState({chartData});
};
