import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import DatePicker from "react-datepicker";
import { withTranslation } from 'react-i18next';

import React, {Component} from 'react';
import {GetRequest} from '../../../common/requests';
import {URL} from '../../../common/url';

const styles = {
  personIcon: {
    fontSize: '18px',
    marginRight: '10px'
  },
  buttonAdd: {
    textTransform: 'unset',
    borderRadius: '30px',
    color: '#959CAE',
    marginLeft: '1rem',
    fontSize: '14px',
    padding: '10px 16px'
  },
  buttonRectangle: {
    textTransform: 'unset',
    borderRadius: '6px',
    fontSize: '15px',
    color: 'white',
    background: 'linear-gradient(70deg, #5EAAFF, #5159F8)',
    marginLeft: '1rem',
    padding: '12px 24px',
    border: '0px'
  },
  buttonRectangleDate: {
    borderRadius: '6px',
    fontSize: '15px',
    color: '#555',
    padding: '12px 24px'
  },
  updateButton: {
    fontSize: '15px',
    padding: '16px 24px',
    lineHeight: 1,
    textTransform: 'unset',
    borderRadius: '8px',
    color: '#959CAE',
    marginLeft: '1rem',
    fontWeight: '600 !important'
  },
  statClick: {
    '&:hover': {
      cursor: 'pointer',
      background: '#F4F6F9'
    }
  },
  statBread: {
    '&:hover': {
      cursor: 'pointer',
      outline: 'none'
    }
  },
  headTitle: {
    marginTop: '1rem',
    marginBottom: '1rem'
  },
  nameTitle: {
    marginTop: '2rem'
  },
  description: {
    marginTop: '1rem',
    marginBottom: '2rem'
  },
  modal: {
    background: 'white',
    position: 'relative',
    width: '450px',
    top: '30%',
    left: '35%',
    textAlign: 'left'
  },
  modalSubject: {
    color: '#141b2d',
    fontSize: '21px',
    fontFamily: '\'Open Sans\', sans-serif !important',
    marginBottom: '24px'
  },
  modalDescription: {
    fontFamily: '\'Open Sans\', sans-serif !important',
    color: '#1e2a5a'
  },
  submit: {
    textAlign: 'right',
    marginTop: '24px'
  },
  actionButton: {
    marginRight: '10px',
    color: '#000000',
    fontWeight: 'bold',
    fontSize: '15px',
    fontFamily: '\'Open Sans\', sans-serif !important'
  },
  detailsTitle: {
    fontFamily: 'Raleway !important',
    marginBottom: '1rem',
    marginTop: '1rem',
    fontSize: '36px',
    fontWeight: '600',
    color: '#1b1b1b',
    lineHeight: '1.2',
    letterSpacing: '0.36px',
    textAlign: 'left'
  },
  statName: {
    fontSize: '17px',
    fontWeight: 'bold',
    lineHeight: '1.29',
    fontFamily: 'OpenSans'
  },
  editStatDescription: {
    marginTop: '1.5rem'
  },
  editTitle: {
    marginBottom: '1rem',
    marginTop: '1rem'
  },
  editDescription: {
    width: '70%',
    marginTop: '1rem'
  },
  editName: {
    marginTop: '1rem'
  },
  tableHeader: {
    borderBottom: '2px solid grey'
  },
  colHeader: {
    fontSize: '15px !important',
    // fontFamily: 'OpenSans !important',
    color: '#686868',
    fontWeight: 'bold'
  },
  userCount: {
    marginBottom: '1rem'
  },
  editBack: {
    paddingLeft: 0,
    marginLeft: '-0.5rem'
  },
  helperText: {
    marginRight: '30%',
    textAlign: 'right',
    fontSize: '15px',
    color: '#686868',
    marginTop: '0.5rem'
  },
  nameHelperText: {
    textAlign: 'left',
    fontSize: '15px',
    color: 'hotpink'
  },
  tableIcon: {
    marginRight: '0.5rem',
    marginBottom: '-0.5rem'
  },
  searchBar: {
    width: '50%',
    paddingTop: '1rem'
  },
  loader: {
    position: 'relative',
    left: 'calc(50% - 20px)',
    marginTop: '40px',
    marginRight: 'auto'
  }
};

class Stat extends Component {
  state = {
    stats: [],
    edit: false,
    name: '',
    description: '',
    search: '',
    nameError: '',
    startDate: new Date(),
    endDate: new Date(),
    showStat: false
  };

  controller = new window.AbortController();
  cleanup = null;

  componentDidMount() {
    this.cleanup = () => {
      this.controller.abort();
    };
  }

  componentWillUnmount() {
    if (this.cleanup) this.cleanup();
    this.cleanup = null;
  }

  handleChangeStartDate = (date) => {
    this.setState({
      startDate: date
    });
  };

  handleChangeEndDate = (date) => {
    this.setState({
      endDate: date
    });
  };

  handleChange = (event) => {
    const {name, value} = event.target;
    if (name === 'name') {
      this.setState({nameError: false});
    }
    this.setState(prevState => ({...prevState, [name]: value}));
  };

  formatDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  };

  getStats = (data) => {
    //To be written
    const {orgId} = this.props;
    const startDate = this.formatDate(this.state.startDate);
    const endDate = this.formatDate(this.state.endDate);
    this.setState({statsLoading: true});
    GetRequest(URL.getStatsUrl + '?org_id=' + orgId + '&start_date=' + startDate + '&end_date=' + endDate, (data) => {
      if (data.payload) {
        this.setState(
          {
            stats: data.payload ? data.payload : [],
            page: 0,
            statsLoading: false
          }
        );
        this.displayData(data.payload);
      } else {
        this.setState({statsLoading: false});
      }
    }, (err) => {
      this.setState({statsLoading: false});
    });
  };

  displayData = (data) => {
    this.setState({
      showStat: true
    });
  };

  render() {
    const {stats, search, showStat, startDate, endDate } = this.state;
    const {t, classes } = this.props;
    const DisabledCustomInput = ({ value, onClick }) => (
      <Button variant="outlined" className={classes.buttonRectangleDate} onClick={onClick}>
        {value}
      </Button>
    );

    return (
      <Grid container direction="column" key="grid1">
      <Grid>
          <TextField
              name="search"
              id="search"
              onChange={this.handleChange}
              value={search}
              className={classes.searchBar}
              variant='outlined'
              placeholder={t('stats.ph-search')}
              InputProps={{
                  endAdornment: <InputAdornment position="end"><SearchIcon style={{ color: 'grey'}}/></InputAdornment>
              }}
              inputProps={{
                  style: {
                    padding: '0.75rem 1rem'
                  }
              }}
          />
      </Grid>
      <br/>
      <br/>
      <div>
          <Typography variant="subtitle1" className="overflow-ellipsis" style={{maxWidth: '50%'}}><b>{t('stats.heading')} (</b><i>{t('stats.heading-helper')}</i><b>)</b> </Typography>
      </div>
      <br/>
      <Grid container direction="column">
          <Grid container direction="row" alignContent="space-between" alignItems="flex-end" justifycontent="space-between">
              <Grid>
                  <Typography className="overflow-ellipsis" ><b>{t('stats.label-start-date')}</b> </Typography>
                  <DatePicker selected={this.state.startDate} onChange={this.handleChangeStartDate} customInput={<DisabledCustomInput />} placeholderText="Select Start Date" />
                  &nbsp;
              </Grid>
              <Grid>
                  <Typography className="overflow-ellipsis"><b>{t('stats.label-end-date')}</b> </Typography>
                  <DatePicker selected={this.state.endDate} onChange={this.handleChangeEndDate} customInput={<DisabledCustomInput />} placeholderText="Select End Date" />
                  &nbsp;
              </Grid>
              <Grid>
                  <Button variant="outlined" className={classes.buttonRectangle} onClick={() => this.getStats()}><b>{t('stats.get-stats-btn')}</b></Button>
              </Grid>
              {showStat ? (
                  <Grid container direction="row">
                      <div>
                          <span>
                              <Typography variant="subtitle1" className="overflow-ellipsis">{t('stats.docs-proc')} { startDate.toString() } {t('stats.and')} { endDate.toString() } {t('stats.is')} <b>{ stats.documents_processed }</b>.</Typography>
                          </span>
                      </div>
                  </Grid>
              ) : (
                  <Grid>
                      <div><span></span></div>
                  </Grid>
              )
              }
          </Grid>
      </Grid>
      </Grid>
    );
  }
}

export default withTranslation()(withStyles(styles)(Stat));
