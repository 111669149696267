import {withStyles} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import jQuery from 'jquery';
import * as PropTypes from 'prop-types';
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';

const styles = {
  searchLeft:{
    background :'#f4f6f9',
    height: '415px',
    padding: '40px'
  },
  mainTitle: {
    fontWeight: '600',
    color: '#5fafff',
    margin: '1.5rem 0 0.5rem 0',
    fontSize: '1.5rem'
  },
  innerTitle: {
    fontWeight: '600',
    color: '#1b1b1b',
    margin: '1.5rem 0 0.5rem 0',
    fontSize: '0.9rem',
    textAlign: 'left'
  },
  subTitle: {
    fontWeight: '600',
    color: '#999999',
    margin: '0.5rem 0 2rem 0',
    fontSize: '0.9rem'
  },
  searchRight:{
    padding: '45px 45px 0 45px',
    textAlign: 'left',
    position: 'relative',
    minHeight: '400px',
  },
  textInput:{
    width: '100%',
    padding: '10px 0!important',
  },
  fullWidth:{
    width: '100%'
  },
  listSection:{
    color: '#999999',
    margin: '0.5rem 0 2rem 0',
    fontSize: '0.9rem'
  },
  textLabel:{
    fontSize:'18px',
    fontWeight:'bold',
    color: '#292929!important'
  },
  checkInput:{
    margin: '0 0 25px 0px'
  },
  loaderBox:{
    position: 'absolute',
    background: 'white',
    left: '0',
    top: '0',
    width: '100%',
    height: '100%'
  },
  loaderBoxInner:{
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: '50px',
    height: '50px',
    marginLeft: '-25px',
    marginTop: '-25px',
  }
};

class SearchFinalize extends Component {
  static propTypes = {
    // modalOpen: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
  };

  state = {
    submit: false,
    activeStep: 0,
    showCollection: false,
  };

  placeDocument = (value) => {
    this.setState({showCollection: value, showAPN: !value}, () => {
      jQuery("#collection")[0].value = this.props.street
    });
    this.props.setCollection()
  };

  render() {
    const {t, classes, getFieldValue, handleChange, loading} = this.props;
    const {showCollection} = this.state;

    return (
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <div className={classes.searchLeft}>
            <Typography variant='h5' className={classes.mainTitle}>{t('property-search.property-search-modal.finalize')}</Typography>
            <Typography variant="h6" className={classes.subTitle}>{t('property-search.property-search-modal.step')} 3</Typography>
          </div>
        </Grid>
          <Grid item xs={8}>
            <div className={classes.searchRight}>
              {loading
                ? <div className={classes.loaderBox}>
                    <div className={classes.loaderBoxInner}><CircularProgress size={30} /></div>
                  </div>
                : <React.Fragment>
                    <FormControlLabel
                      value="end"
                      className={classes.checkInput}
                      control={<Checkbox color="primary" />}
                      label={t('property-search.property-search-modal.step-finalize.collection-check')}
                      labelPlacement="end"
                      onClick={() => this.placeDocument(true)}
                    />
                    {showCollection &&
                    <FormControl className={classes.fullWidth}>
                      <InputLabel shrink className={classes.textLabel}>{t('property-search.property-search-modal.step-finalize.collection-name')}</InputLabel>
                      <TextField
                        name="collection"
                        id="collection"
                        required
                        onChange={handleChange}
                        variant='outlined'
                        className={'textInput'}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                      />
                    </FormControl>
                    }

                    <Typography variant='h5' className={classes.innerTitle}>{t('property-search.property-search-modal.step-finalize.summary')}</Typography>
                    <div className={classes.listSection}>
                      <List>
                        <ListItem>1. {getFieldValue("county")}, {getFieldValue("state")}</ListItem>
                        <ListItem>2. {getFieldValue("street")}</ListItem>
                        <ListItem>3. Number of Documents to Import : {getFieldValue("noOfDocs")}</ListItem>
                      </List>
                    </div>
                </React.Fragment>
              }
            </div>
          </Grid>
      </Grid>
    );
  }
}

export default withTranslation()(withStyles(styles)(SearchFinalize));
