import CircularProgress from '@material-ui/core/CircularProgress';
import jQuery from 'jquery';
import _ from 'lodash';
import {withStyles} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as PropTypes from 'prop-types';
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';

const styles = {
  searchLeft: {
    background: '#f4f6f9',
    height: '415px',
    padding: '40px'
  },
  mainTitle: {
    fontWeight: '600',
    color: '#5fafff',
    margin: '1.5rem 0 0.5rem 0',
    fontSize: '1.5rem'
  },
  subTitle: {
    fontWeight: '600',
    color: '#999999',
    margin: '0.5rem 0 2rem 0',
    fontSize: '0.9rem'
  },
  textLabel: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#292929!important'
  },
  youMean: {
    fontSize: '13px',
    color: '#a6a6a6',
    marginBottom: '10px'
  },
  searchRight: {
    padding: '45px 45px 0 45px',
    position: 'relative',
    minHeight: '400px'
  },
  textInput: {
    width: '100%',
    padding: '10px 0!important'
  },
  fullWidth: {
    width: '100%',
    position: 'relative'
  },
  buttonArea: {
    width: '100%',
    paddingBottom: '20px',
    display: 'flex'
  },
  addressButton: {
    border: '1px solid #f2f3f3',
    boxShadow: 'none',
    color: '#36426d',
    textTransform: 'none',
    width: '40%'
  },
  apnButton: {
    background: 'none',
    border: '1px solid #f2f3f3',
    boxShadow: 'none',
    color: '#36426d',
    textTransform: 'none',
    width: '40%'
  },
  buttonActive:{
    background: '#e4edf9',
    boxShadow: 'none',
    color: '#36426d',
    textTransform: 'none',
    width: '40%'
  },
  orWidth: {
    width: '20%',
    textAlign: 'center'
  },
  streetSuggestion:{
    width: '100%',
    textAlign: 'left',
    background: '#fff',
    padding: '25px',
    position: 'absolute',
    top: '60px',
    left : '0',
    borderRadius: '8px',
    zIndex: '999'
  },
  addressSuggestion:{
    fontWeight: '600',
    color: '#999999',
    margin: '0.6rem 0 0 0',
    fontSize: '12px!important'
  },
  labelCheck:{
    margin: '0 0 0.6rem 0',
    fontSize: '0.8rem !important',
        '& span:last-child':{
             fontSize: '0.8rem !important',
             fontWeight: '600',
             color: '#707070',
             marginLeft: '10px'
        }
  },
  errorAddress:{
    fontSize: '0.6rem !important',
    color: '#f282bf',
    textAlign: 'right',
    margin: '-8px 0 0 0',
    padding: '0'
  },
  loaderBox:{
    position: 'absolute',
    background: 'white',
    left: '0',
    top: '0',
    width: '100%',
    height: '100%'
  },
  loaderBoxInner:{
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: '50px',
    height: '50px',
    marginLeft: '-25px',
    marginTop: '-25px',
  }
};

class SearchOptions extends Component {
  static propTypes = {
    // modalOpen: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
  };

  state = {
    status: true,
    submit: false,
    activeStep: 1,
    skipped: new Set(),
    showAddress: false,
    showAPN: false,
  };

  controller = new window.AbortController();

  componentDidMount() {
    this.setState({
      submit: false,
      activeStep: 0,
      skipped: new Set()
    });
  }

  toggleAddress = (value) => {
    this.setState({showAddress: value, showAPN: !value});
  };

  toggleAPN = (value) => {
    this.setState({showAPN: value, showAddress: !value});
  };

  // This function picks up the address from the radio button selection of the address from the pick_list 
  handleRadio = (event) => {
    const value = event.target.value
    const {addressPickList, handleChange, setPickedAddressIndex, toggleRadioDiv} = this.props

    jQuery("#street")[0].value = value
    handleChange(event)
// Using loadash pick up the address from the pick_list using the find()
    const data = _.find(addressPickList, obj => obj.address === value);
    // Then pick the index of the address picked
    const pickedAddressIndex = _.indexOf(addressPickList, data);
    setPickedAddressIndex(pickedAddressIndex)  //Set the picked address index
    toggleRadioDiv()    //Close the radio div
  };

  handleRadioToggle = () => {
    const {addressPickList, toggleRadioDiv} = this.props

    if (addressPickList.length > 0){
      toggleRadioDiv()
    }
  };

  render() {
    const {t, classes, addressPickList, street, showRadioDiv, handleChange, showAddressError, loading} = this.props;
    const {showAddress, showAPN} = this.state;

    return (
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <div className={classes.searchLeft}>
            <Typography variant='h5' className={classes.mainTitle}>{t('property-search.property-search-modal.search-options')}</Typography>
            <Typography variant="h6" className={classes.subTitle}>{t('property-search.property-search-modal.step')} 2 </Typography>
          </div>
        </Grid>

          <Grid item xs={8}>
            <div className={classes.searchRight}>
            {loading
              ? <div className={classes.loaderBox}>
                  <div className={classes.loaderBoxInner}><CircularProgress size={30} /></div>
                </div>
              :
              <React.Fragment>
                  <div className={classes.buttonArea}>
                    <Button
                      className={`${showAddress ? classes.buttonActive : classes.addressButton}`}
                      onClick={() => this.toggleAddress(true)}>
                      {t('property-search.property-search-modal.step-search-options.address')}
                    </Button>
                    <span className={classes.orWidth}> OR </span>
                    <Button
                      className={`${showAPN ? classes.buttonActive : classes.apnButton}`}
                      onClick={() => this.toggleAPN(true)}>
                      {t('property-search.property-search-modal.step-search-options.apn')}
                    </Button>
                  </div>
                  {showAddress &&
                  <div className={classes.showAddress}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel shrink className={classes.textLabel}>{t('property-search.property-search-modal.step-search-options.address-form-label.street')}</InputLabel>
                      <TextField
                        name="street"
                        id="street"
                        required
                        onClick={this.handleRadioToggle}
                        onChange={handleChange}
                        variant='outlined'

                        className={'textInput'}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                      />
                      {showRadioDiv &&
                      <Box
                        boxShadow={5}
                        className={classes.streetSuggestion}
                      >
                        <Typography className={classes.youMean}>Did you mean one of the following?</Typography>

                        <RadioGroup
                          className={classes.addressSuggestion}
                          aria-label="Address"
                          value={street}
                          name="street"
                          onChange={this.handleRadio}>
                          {addressPickList.map((pick, i) =>
                            <FormControlLabel
                              key={i}
                              value={pick.address}
                              control={<Radio color="default" size="small" />}
                              label={pick.address}
                              className={classes.labelCheck}
                            />)
                          }
                        </RadioGroup>
                      </Box>
                      }
                      {showAddressError &&
                      <div className={classes.errorAddress}>No results found, Please check the address.</div>}
                    </FormControl>

                    <FormControl className={classes.fullWidth}>
                      <InputLabel shrink className={classes.textLabel}>{t('property-search.property-search-modal.step-search-options.address-form-label.city')}</InputLabel>
                      <TextField
                        name="city"
                        id="city"
                        required
                        onChange={handleChange}
                        variant='outlined'
                        className={'textInput'}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                      />
                    </FormControl>

                    <FormControl className={classes.fullWidth}>
                      <InputLabel shrink className={classes.textLabel}>{t('property-search.property-search-modal.step-search-options.address-form-label.zip-code')}</InputLabel>
                      <TextField
                        name="zipcode"
                        id="zipcode"
                        required
                        onChange={handleChange}
                        variant='outlined'
                        className={'textInput'}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                      />
                    </FormControl>

                    <FormControl className={classes.fullWidth}>
                      <InputLabel shrink className={classes.textLabel}>{t('property-search.property-search-modal.step-search-options.address-form-label.name-optional')}</InputLabel>
                      <TextField
                        name="name"
                        id="name"
                        required
                        onChange={handleChange}
                        variant='outlined'
                        className={'textInput'}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                      />
                    </FormControl>
                  </div>
                  }
                  {
                    showAPN &&
                    <div>
                    </div>
                  }
                </React.Fragment>
              }
            </div>
          </Grid>
      </Grid>
    );
  }
}

export default withTranslation()(withStyles(styles)(SearchOptions));
