import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import {NavLink} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Tooltip from '@material-ui/core/Tooltip';
import FolderIcon from '@material-ui/icons/Folder';
import QueueIcon from '@material-ui/icons/AccessTime';
import Badge from '@material-ui/core/Badge';
import PersonIcon from '@material-ui/icons/Person';
import {toast} from 'react-toastify';


import './custom.css';
import {getRole, getUserOrg, logout,} from "../loginService";
import withStyles from "@material-ui/core/styles/withStyles";
import * as PropTypes from "prop-types";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import { withTranslation } from 'react-i18next';
import {GetRequest} from '../requests';
import {URL} from '../url';

let brand = process.env.REACT_APP_ORG_BRAND
if( brand === undefined){
  brand = 'nirveda'
};


const styles = {
  header: {
    marginTop: 'auto',
    backgroundColor: '#FFFFFF',
    height: '70px',
    boxShadow: '1px -2px 20px 6px #DEE8F3',
    position: 'fixed'
  },
  title: {
    color: 'black',
  },
  toolbar: {
    justifyContent: 'space-between',
    paddingRight: '56px',
    paddingLeft: '56px',
    paddingTop: '7px'
  },
  buttons: {
    color: 'black',
    display: 'inline-flex',
    marginLeft: '10px',
    '&:hover': {
      cursor: "pointer",
      backgroundColor: 'rgba(0,0,0,0.04)',
      borderRadius: '100%'
    },
  },
  logo: {
    width: '205px',
    height: 'auto',
    marginLeft: '-8px',
  },
  menu: {
    position: 'absolute',
    background: 'white',
    color: 'black',
    width: '150px',
    top: '67px',
    zIndex: '2',
    right: '24px',
      // boxShadow: '0 5px 2px 0 rgba(209, 218, 229, 0.7)',
      // border: 'solid 1px #e1e3e4',
      borderRadius: '10px',
    '& a' :{
      textDecoration: 'none',
    },
  },
  button2: {
    color: 'black',
    display: 'inline-flex',
    marginLeft: '10px',
    '&:hover': {
      cursor: "pointer",
      backgroundColor: 'rgba(0,0,0,0.04)',
      borderRadius: '100%'
    },
  },
  link:{
    color: '#141b2d',
    fontSize: '17px',
    fontFamily: 'OpenSans',
    fontWeight: 'normal'
  },
  customBadge:{
    right: 6,
    top: 11,
  }
};
class Header extends React.Component{
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };
  state={
    openMenu: false,
    org: null,
  };

  componentDidMount() {
    const orgId = getUserOrg()
    this.getOrganization(orgId)

  }

  toggleMenu = () => {
    this.setState(prevState=>({ ...prevState, openMenu: !prevState.openMenu}))
  };
  handleClickAway = () => {
    this.setState({
      openMenu: false,
    });
  };

  getButtonClass() {
    if (['files','queue','results','trash'].some(a => document.URL.includes(a))) {
      return "";
    } else {
      return "active";
    }
  }

  getOrganization = (orgId) => {
    this.setState({loading: true});
    GetRequest(URL.organizations + "/" + orgId + "/", (data) => {
      this.setState({
        org: data.payload,
        loading: false
      }, () => {});
    }, (err) => {
      toast.error(err.message.user_action)
    });
  };

  checkActive = () => ['files', 'queue', 'results', 'trash'].some(a => document.URL.includes(a));

  render() {
    const {t, classes, badgeNotification} = this.props;
    const {openMenu, org, } = this.state;
    const orgId = getUserOrg()

    return(
      <div>
        <AppBar position="static" className={classes.header}>
          <Toolbar className={classes.toolbar}>
            <NavLink to="/files" ><img 
            id="img-click" 
            src={require(`../../assets/images/${brand}-logo.png`)} 
            alt="Nirveda Cognition" 
            className={classes.logo}
            />
            </NavLink>
            <Grid>
            <Tooltip title="Files">
                <NavLink to="/files" className={classes.buttons} id="dashboard-icon">
                <FolderIcon className="margin"/>
              </NavLink>
            </Tooltip>
            <Tooltip title="Queue">
              <NavLink to="/queue" className={classes.buttons}  id="queue-icon">
                {badgeNotification
                  ? <Badge color="secondary" variant="dot" classes={{ badge: classes.customBadge }}>
                      <QueueIcon className="margin"/>
                    </Badge>
                  : <QueueIcon className="margin" />
                }
              </NavLink>
              </Tooltip>
                <div className={`${classes.button2} ${this.getButtonClass()}`}>
                  <PersonIcon onClick={this.toggleMenu} className="margin"/>
                  {openMenu ? (
                      <ClickAwayListener onClickAway={this.handleClickAway}><div>
                    <Paper className={classes.menu} elevation={8}>
                      <List component="nav" aria-label="Secondary mailbox folders">
                        <NavLink to='/account' onClick={this.toggleMenu}>
                          <ListItem button component='div'>
                            <ListItemText primary={t('main-nav-dropdown.acc-settings')} className='settings-menu'/>
                          </ListItem>
                        </NavLink>
                        {
                          getRole() === 'ClientUser' ? null : getRole() === 'ClientAdmin' ?
                            <React.Fragment>
                              <NavLink to={`/admin/${orgId}`}  onClick={this.toggleMenu}>
                                <ListItem button component='div'>
                                  <ListItemText primary="Admin Settings" className='settings-menu'/>
                                </ListItem>
                              </NavLink>
                              {org.api_access &&
                                <NavLink to='/documentation' onClick={this.toggleMenu}>
                                  <ListItem button component='div'>
                                    <ListItemText primary="API Docs" className='settings-menu' />
                                  </ListItem>
                                </NavLink>
                              }
                            </React.Fragment>
                            :
                              <React.Fragment>
                                {/*<NavLink to={`/admin/${orgId}`} onClick={this.toggleMenu}>*/}
                                {/*  <ListItem button component='div'>*/}
                                {/*    <ListItemText primary="Admin Settings" className='settings-menu' />*/}
                                {/*  </ListItem>*/}
                                {/*</NavLink>*/}
                                <NavLink to='/superadmin/orgs' onClick={this.toggleMenu}>
                                  <ListItem button component='div'>
                                    <ListItemText primary={t('main-nav-dropdown.super-adm-settings')} className='settings-menu' />
                                  </ListItem>
                                </NavLink>
                                <NavLink to='/documentation' onClick={this.toggleMenu}>
                                  <ListItem button component='div'>
                                    <ListItemText primary={t('main-nav-dropdown.api-docs')} className='settings-menu' />
                                  </ListItem>
                                </NavLink>
                              </React.Fragment>
                        }
                        <NavLink to='/login' onClick={logout}>
                          <ListItem button component='div'>
                            <ListItemText primary={t('main-nav-dropdown.sign-out')} className='settings-menu'/>
                          </ListItem>
                        </NavLink>
                      </List>
                    </Paper></div></ClickAwayListener>
                  ) : null}
                </div>
            </Grid>
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}
export default withTranslation()(withStyles(styles)(Header));
