/**
 * Created by Akhtar on 28/10/2019.
 */


import {withStyles} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
//import HorizontalLabelPositionBelowStepper from '../../../common/stepper';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import * as PropTypes from 'prop-types';
import React, {Component} from 'react';
import {GetRequest, PostRequest} from '../../../common/requests';
import {URL} from '../../../common/url';
import StepFour from './stepFour';
import StepOne from './stepOne';
import StepThree from './stepThree';
import StepTwo from './stepTwo';
import { withTranslation } from 'react-i18next';

const styles = {
  modal: {
    background: 'white',
    position: 'relative',
    width: '550px',
    top: '20%',
    left: '50%',
    marginLeft: '-275px',
    textAlign: 'left'
  },
  modalTitle: {
    background: 'linear-gradient(70deg, #5159F8, #5EAAFF) !important',
    color: 'white',
    textAlign: 'left',
    padding: '20px'
  },
  modalContent: {
    padding: '20px'
  },
  formInput: {
    margin: '10px',
    width: '95%'
  },
  submit: {
    textAlign: 'right',
    borderTop: '1px solid #E1E3E4',
    paddingTop: '16px'
  },
  label: {
    marginLeft: '10px !important'
  },
  actionButton: {
    marginRight: '10px',
    color: '#d3d3d3',
    fontSize: '16px',
    fontWeight: 'bold'
  }
};


class CreateOrganization extends Component {
  static propTypes = {
    modalOpen: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
  };

  state = {
    name: "",
    description: "",
    ai_api_url: '',
    services: [],
    capabilities: [],
    selectedServices: [],
    selectedCapabilities: [],
    organizationName: '',
    email: '',
    status: true,
    submit: false,
    nameError: '',
    apiUrlError: '',
    emailError: '',
    activeStep: 0,
    skipped: new Set(),
  };

  controller = new window.AbortController();

  componentDidMount() {
    this.getServices();
  }

  getServices = () => {
    GetRequest(URL.listServices, (data) => {
    const services = data.payload || [];
      this.setState({services, selectedServices: []});
    }, (err) => {
      console.log(err);
    });
  };

  getCapabilities = (capabilityList) => {
    this.setState(prevState => ({ ...prevState, capabilities: capabilityList }));
  }

  checkApiUrlValidation() {
    let valid = true;
    let apiUrlError = "";
    const {t} = this.props;
    if (!this.state.ai_api_url || this.state.ai_api_url.trim() === '') {
      apiUrlError = t('msg.api-empty');
      this.setState(prevState => ({...prevState, apiUrlError}))
      valid = false;
    }

    return valid;
  }

  checkNameValidation() {
    const {t} = this.props;
    let valid = true;
    let nameError = "";

    if (!this.state.name || this.state.name.trim() === '') {
      nameError = t('msg.empty-org');
      this.setState(prevState => ({...prevState, nameError}))
      valid = false;
    }
    return valid;
  }


  // checkValidations() {
  //   if (!this.state.submit) {
  //     return;
  //   }
  //   let valid = true;
  //   let emailError = "";
  //   let organizationNameError = "";
  //   let apiUrlError = "";
  //
  //   if (this.state.email) {
  //     if (!CreateOrganization.validateEmail(this.state.email)) {
  //       emailError = "Email is not valid";
  //       valid = false;
  //     }
  //   } else {
  //     emailError = "Email cannot be empty";
  //     valid = false;
  //   }
  //
  //   if (!this.state.organizationName || this.state.organizationName.trim() === '') {
  //     organizationNameError = "organization name cannot be empty";
  //     valid = false;
  //   }
  //
  //   if (!this.state.apiUrl || this.state.apiUrl.trim() === '') {
  //     apiUrlError = "API URL cannot be empty";
  //     valid = false;
  //   }
  //
  //   this.setState({
  //     emailError: emailError,
  //     organizationNameError: organizationNameError,
  //     apiUrlError: apiUrlError,
  //   });
  //   return valid;
  // }

  static validateEmail(email) {
    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  getStepContent = (step, sHandler, cHandler, nameHandler, descHandler, apiUrlHandler, cListHandler) => {
    const {services, name, nameError, description, ai_api_url, apiUrlError} = this.state

    switch (step) {
      case 0:
        return <StepOne name={name} description={description} nameError={nameError} setName={nameHandler} setDesc={descHandler}/>;
      case 1:
        return <StepTwo ai_api_url={ai_api_url} apiUrlError={apiUrlError} setApiUrl={apiUrlHandler}/>;
      case 2:
        return <StepThree services={services} setServices={sHandler} />;
      default:
        return <StepFour services={services} setCapabilities={cHandler} setCapList={cListHandler}/>;
    }
  }

  handleServices = (event) => {
    const { value } = event.target;
    const services = this.state.services.filter(service => value.includes(service.service));
    this.setState(prevState => ({ ...prevState, selectedServices: services }));
  }

  handleCapabilities = (event) => {
    const { value } = event.target;
    const capabilities = this.state.capabilities.filter(cap => value.includes(cap.capability));
    this.setState(prevState => ({ ...prevState, selectedCapabilities: capabilities }));
  }

  handleName = (name) => {
    this.setState(prevState => ({ ...prevState, name}));
  }

  handleDescription = (description) => {
    this.setState(prevState => ({ ...prevState, description}));
  }

  handleApiUrl = (api_url) => {
    this.setState(prevState => ({ ...prevState, api_url}));
  }

  // handleErrors = (error) => {
  //   let errors = this.state.errors
  //   if (error.hasOwnProperty("nameError"))
  //     errors.nameError = error.nameError
  //   if (error.hasOwnProperty("apiUrlError"))
  //     errors.apiUrlError = error.apiUrlError
  //
  //   this.setState(prevState => ({ ...prevState, errors}));
  // }

  handleChange = (event) => {
    const {name, value} = event.target;
    this.setState(prevState => ({
      ...prevState,
      [name]: value
    }), () => this.checkValidations());
  };

  handleGroup = (event) => {
    const {value} = event.target;
    this.setState(prevState => ({...prevState, selectedGroups: value}));
  };

  handleAdd = () => {
    const {modalOpen, refreshOrgList} = this.props;
    const {name, description, api_url, selectedServices, selectedCapabilities} = this.state;

    let json_info = {}
    for (let ser of selectedServices){
      json_info[ser.service] = []

      for (let cap of selectedCapabilities) {
        if (cap.service === ser.service) {
          let val = json_info[ser.service]
          val.push(cap.capability)
          json_info[ser.service] = val
        }
      }
    }

    const payload = {
      name,
      description,
      api_url,
      json_info
    };

    PostRequest(URL.organizations, this.controller.signal, payload, () => {
      modalOpen(false);
      refreshOrgList()
    }, (err) => {
      console.log(err);
    });
  }

  /*for stepper start*/

  isStepOptional = step => {
    return step === 1;
  };

  isStepSkipped(step) {
    return this.state.skipped.has(step);
  }

  handleNext = () => {
    const { activeStep } = this.state;

    if (activeStep === 0 && this.state.name.length === 0) {
      this.checkNameValidation()
      return
    }

    if(activeStep === 1 && this.state.api_url.length === 0) {
      this.checkApiUrlValidation()
      return
    }

    if (activeStep === 3)
      this.handleAdd()
    else {
      let {skipped} = this.state;
      if (this.isStepSkipped(activeStep)) {
        skipped = new Set(skipped.values());
        skipped.delete(activeStep);
      }
      this.setState({
        activeStep: activeStep + 1,
        skipped,
      });
    }
  };

  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep - 1,
    });
  };

  handleSkip = () => {
    const { activeStep } = this.state;
    if (!this.isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }
    const skipped = new Set(this.state.skipped.values());
    skipped.add(activeStep);
    this.setState({
      activeStep: this.state.activeStep + 1,
      skipped,
    });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  /*for stepper end*/

  render() {
    const steps = ['', '', '', ''];

    const { activeStep } = this.state;
    const {t, classes} = this.props;

    return (
      <div className={classes.modal} key="inputForm">
        <div className={classes.modalTitle}>
          <Typography variant="h6">
            {t('super-admin.create-org-modal.title-main')}
          </Typography>
        </div>
        <div className={classes.modalContent}>
          <div className="orgSteps">
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const props = {};
                const labelProps = {};
                if (this.isStepSkipped(index)) {
                  props.completed = false;
                }
                return (
                  <Step key={label} {...props}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>
        <div className="stepForm">
          {/*{activeStep === steps.length ? (*/}
          {/*  <div style={{textAlign: 'center'}}>*/}
          {/*    <Typography className={classes.instructions}>*/}
          {/*      <h5>All steps completed - you&quot;re finished</h5>*/}
          {/*    </Typography>*/}
          {/*    <Button onClick={this.handleReset} className={classes.button}>*/}
          {/*      Reset*/}
          {/*    </Button>*/}
          {/*  </div>*/}
          {/*) : (*/}
            <div>
              <Typography className={classes.instructions}>
                {this.getStepContent(
                  activeStep,
                  this.handleServices,
                  this.handleCapabilities,
                  this.handleName,
                  this.handleDescription,
                  this.handleApiUrl,
                  this.getCapabilities
                )}
              </Typography>
              <div className="prevNext">
                <Button
                  disabled={activeStep === 0}
                  onClick={this.handleBack}
                  className={classes.button}
                >
                  {t('super-admin.create-org-modal.prev')}
                </Button>

                <Button
                  variant="raised"
                  color="primary"
                  onClick={this.handleNext}
                  className={classes.button}
                >
                  {activeStep === steps.length - 1 ? t('super-admin.create-org-modal.create') : t('super-admin.create-org-modal.next')}
                </Button>
              </div>
            </div>
        </div>

        </div>
      </div>
    );
  }
}

export default withTranslation()(withStyles(styles)(CreateOrganization));
