export default {
  tree: {
    base: {
      listStyle: 'none',
      backgroundColor: '#F4F6F9',
      margin: 0,
      padding: 0,
      color: 'grey',
      fontSize: '14px',
      maxWidth: '100%',
      maxHeight: 'calc(100vh - 451px)',
    },
    node: {
      base: {
        position: 'relative',
      },
      link: {
        cursor: 'pointer',
        position: 'relative',
        padding: '0px 5px',
        display: 'block',
        color: '#1b1b1b',
        fontWeight: 'normal',
      },
      activeLink: {
        background: '#F4F6F9',
        color: '#1b1b1b',
        fontWeight: 'normal',
      },
      toggle: {
        base: {
          position: 'relative',
          display: 'inline-block',
          verticalAlign: 'top',
          marginLeft: '-5px',
          height: '24px',
          width: '24px'
        },
        wrapper: {
          position: 'absolute',
          top: '50%',
          left: '50%',
          margin: '-7px 0 0 -7px',
          height: '14px'
        },
        height: 14,
        width: 14,
        arrow: {
          fill: '#403C42',
          strokeWidth: 0
        }
      },
      header: {
        base: {
          display: 'inline-block',
          verticalAlign: 'top',
          color: '#1b1b1b'
        },
        connector: {
          width: '2px',
          height: '12px',
          borderLeft: 'solid 2px black',
          borderBottom: 'solid 2px black',
          position: 'absolute',
          top: '0px',
          left: '-21px'
        },
        title: {
          lineHeight: '24px',
          verticalAlign: 'middle'
        }
      },
      subtree: {
        listStyle: 'none',
        paddingLeft: '19px'
      },
      loading: {
        color: '#E2C089'
      }
    }
  },
  container: {
    writable: true,
  }
};