/**
 * Created by Akhtar on 11/05/2020.
 */

import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import React from 'react';


function EditTableCellModal(props) {
  const {
    _this,
    t,
    open,
    batchEdit,
    classes,
    cellEditVal,
    cellData,
    toggleEditCellsModal,
    updateSelectedValuesOfAColumn,
    setCellEditValue,
  } = props

  return (
    <Modal open={open}>
      <React.Fragment>
        <div className="modalCustomHeader">
          <div className="modalTitle">{t('result-viewer.modal.title')}</div>
          <div style={{padding: '20px'}}>
            <span>{t('result-viewer.modal.subtitle_1')}</span>
            <span><b>{batchEdit.noOfRows}</b></span>
            <span>{t('result-viewer.modal.subtitle_2')}</span>
            <span><b>{batchEdit.column}</b></span>

            <TextField
              label="Value"
              className={classes.textInput}
              autoFocus={true}
              inputProps={{ maxLength: 64 }}
              name="cellEditVal"
              id="outlined-basic"
              onChange={setCellEditValue}
              value={cellEditVal}
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  updateSelectedValuesOfAColumn(cellData.params, cellData.ContextMenu, _this)
                }
              }}
            />
            <div className={classes.submitDelete}>
              <Button className={classes.actionButton} onClick={() => toggleEditCellsModal()}>{t('result-viewer.modal.cancel')}</Button>
              <Button  className={classes.saveButton} type="submit" onClick={() => updateSelectedValuesOfAColumn(cellData.params, cellData.ContextMenu, _this)}>
                {t('result-viewer.modal.save')}
              </Button>
            </div>
          </div>
        </div>
      </React.Fragment>
    </Modal>
  );
}

export default EditTableCellModal;
