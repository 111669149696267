/**
 * Created by Akhtar on 12/05/2020.
 */

import CircularProgress from '@material-ui/core/CircularProgress';
import React, {Fragment} from 'react';
import Utils from '../../../utils';
import Details from './details';
import Header from './header';


function LeftContainer(props) {
  const {
    _this,
    classes,
    loadingLeft,
    documents,
    aggTable,
    activeDoc,
    createName,
    csvData,
    pdfData,
    history,
    filterDetails,
    clearFilters,
    filterFlag,
    confidence,
    onSearch,
    value,
    toggleConfidence,
    handleBack,
    showExportMenu,
    docData,
    capabilities,
    updateActiveDoc,
    doc_list,
    docIndex,
    goToPage,
    handleTableView,
    disableEditing,
    enableEditing,
    doneEditing,
    setAggView,
    editingEnabled,
    setEditingEnabled,
  } = props

  return (
    <div className={classes.leftContainer} id='leftContainer'>
      <div className={`${classes.paper} left-side-custom`}>
        <div className={classes.content}>
          {loadingLeft || (documents === null)
          ? <div className={classes.loader}><CircularProgress className='loader' style={{ color: '#5fafff'}} size={20} /></div>
          : <Fragment>
              <Header
                fileData = {aggTable? aggTable: activeDoc}
                capabilities = {capabilities}
                createName = {(name, extension) => createName(activeDoc.path, name, extension)}
                classes = {classes}
                csvData = {csvData}
                pdfData = {pdfData}
                history = {history}
                filterDetails = {filterDetails}
                clearFilters = {clearFilters}
                filterFlag = {filterFlag}
                genKey={Utils.genKey}
                confidence = {confidence}
                onSearch = {onSearch}
                value = {value}
                toggleConfidence = {toggleConfidence}
                handleBack = {handleBack}
                showExportMenu = {showExportMenu}
              />
              <Details
                _this={_this}
                docData = {docData}
                aggTable = {aggTable}
                pdfData = {pdfData}
                activeDoc = {activeDoc}
                capabilities = {capabilities}
                updateActiveDoc = {updateActiveDoc}
                documents = {documents}
                doc_list = {doc_list}
                docIndex = {docIndex}
                genKey={Utils.genKey}
                classes = {classes}
                confidence = {confidence}
                goToPage = {goToPage}
                handleTableView = {handleTableView}
                disableEditing = {disableEditing}
                enableEditing = {enableEditing}
                doneEditing = {doneEditing}
                editingEnabled = {editingEnabled}
                setEditingEnabled = {setEditingEnabled}
                setAggView = {setAggView}
              />
            </Fragment>
          }
        </div>
      </div>
      <div id='middleDiv' />
    </div>
  );
}

export default LeftContainer;
