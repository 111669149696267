import {withStyles} from '@material-ui/core';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import {Refresh, RemoveRedEye} from '@material-ui/icons';
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import DeleteIcon from '@material-ui/icons/Delete';
import * as PropTypes from 'prop-types';
import React from 'react';
import {getUserOrg, getUserTimezone} from '../../../common/loginService';
import {GetRequest, PatchRequest} from '../../../common/requests';
import {URL} from '../../../common/url';
import { withTranslation } from 'react-i18next';
import QueueIcon from '@material-ui/icons/Queue';

const styles = {
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 1000,
    },
    card: {
        // boxShadow: '0 4px 1px 0 rgba(209, 218, 229, 0.7)',
        background: 'white',
        width: '100%',
        padding: '2rem 3rem',
        // minHeight: 'calc(100vh - 180px)',
        height: 'calc(100vh - 254px)',
        // border: '1px solid #e1e3e4'
    },
    p0: {
        padding: '0'
    },
    roundButton: {
        padding: '0 1rem',
        borderRadius: '50px',
        textTransform: 'none'
    },
    processingQueue: {
        color: '#1b1b1b',
        fontSize: '22px',
        fontWeight: '600',
        textAlign: 'left',
        marginBottom: '16px'
    },
    tableHead: {
        fontSize: '15px',
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#686868'
    },
    tableBody: {
        fontSize: '15px',
        textAlign: 'center',
        color: '#686868'
    },
    tableBodyRow: {
        overflow:'auto',
        maxHeight:'calc(100vh - 400px)',
        width:'100%',
        display: 'block'
    },
    fileName: {
        fontSize: '15px',
        fontWeight: '600',
        color: '#1e2a5a',
        textAlign: 'left',
        width: '20%',
        wordBreak: 'break-all',
    },
    dropdownContent: {
        position: 'absolute',
        zIndex: '2',
        background: 'white',
        width: '130px',
        textAlign: 'left',
        borderRadius: '10px',
        color: '#1b1b1b',
        fontSize: '16px',
        cursor: 'pointer',
        left: '-36px',
        top: '41px'
    },
    completedBorder: {
        // paddingBottom: '16px',
        // width: '70%',
        borderBottom: '1px solid #e1e3e4',
        // margin: '16px auto',
    },
    openSansFamily: {
        fontFamily: '\'Open Sans\', sans-serif !important'
    },
    tableHeadBorder: {
        // display: 'block',
        borderBottom: '1px solid #e1e3e4',
        boxShadow: '0 4px 1px 0 rgba(209, 218, 229, 0.7)'
    },
  queueScroll: {
        maxHeight: 'calc(100% - 167px)',
      minHeight: 'calc(100% - 140px)',
      width : '100%',
    overflow: 'auto'
  },
  scrollTable: {
    width: '100%'
  },
  tableHeader: {
    width: '100%',
    overflow: 'auto'
  },
    popHover: {
        '&:hover':{
           background: 'rgba(0, 0, 0, 0.08);',
            cursor: 'pointer',
        },
        padding: '0.75rem',
        fontSize: '16px',
        color: '#1b1b1b',
        fontWeight: 'normal'
    }
};

function createData(filename, folder, status, timestamp) {
    return { filename, folder, status, timestamp };
}

class Queue extends React.Component {

    static propTypes = {
        changeTab: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
    };

    state = {
        selected: [],
        page: 0,
        rowsPerPage : 10,
        queueData: [],
        openDropdown: false,
        loading: false,
        displayPage: 0
    };
    controller = new window.AbortController();

    componentDidMount() {
        const {changeTab} = this.props;
        changeTab('queue');
        this.refreshData()
    }

    isSelected = (name) => this.state.selected.indexOf(name) !== -1;

    static getDateString(string) {
        let date = new Date(string);
        return date.toString().slice(4, 24);
    }

    handleAllSelect() {
        let newSelected = [];
        const rows = this.state.queueData;
        if (this.state.selected.length !== rows.length) {
            newSelected = rows.map(row => { /* eslint-disable-line */
                if(row['document_status'] !=='Processing'){
                    return row.id;
                }
            });
        }
        this.setState(() => ({selected: newSelected}))
    }

    clearClicked = (id) => {
        const { queueData } = this.state;
        const filteredData = queueData.filter(a => (a.id !== id));
        this.setState({queueData: filteredData});
        const payload = {
            // id: id,
            operation: "clear",
            assign_to : "",
            move_to : "",
        };
        PatchRequest(URL.documents + '/' + id, this.controller.signal, payload, () => {
            this.refreshData();
        }, (err) => { console.log(err)})
    };

    clearAll = () => {
        const { selected, queueData } = this.state;
        const filteredData = queueData.filter(a=> !selected.includes(a.id));
        this.setState({selected: [], queueData: filteredData, displayPage: Math.floor(this.state.queueData.length/this.state.rowsPerPage + 1)});
        selected.forEach(a => {
          this.clearClicked(a);
        })
    };

    viewFile = (id) => {
        const { history, changeTab } = this.props;
        changeTab('result');
        history.push(`/results?id=${id}`)
    };

    refreshData() {
        const params = {
            org_id: getUserOrg(),
            queued: true,
            trash: false,
            user_visit: true
        };
        let esc = encodeURIComponent;
        let query = Object.keys(params)
          .map(k => esc(k) + '=' + esc(params[k]))
          .join('&');
        this.setState({loading: true});
        GetRequest(URL.documents + '?' + query, (data)=> {
            // this.setData(data.payload);
            this.setState({payload: data.payload, queueData: data.payload ? data.payload.filter(a=> a.name !== 'ai.nirveda') : [], loading: false,
                displayPage: Math.floor((data.payload ? data.payload.filter(a=> a.name !== 'ai.nirveda') : []).length/this.state.rowsPerPage + 1)});
        }, (err)=> {console.log(err);
            this.setState({loading: false});
        });
    }

    setData(data) {
        let rows = [];
        var i;
        for (i = 0; i < data.length; i++) {
            let row = data[i];
            rows.push(createData(row.name, row.path, row['document_status'], row['status_changed_at']));
        }
        this.setState(() => ({queueData:  rows, displayPage: Math.floor(this.state.queueData.length/this.state.rowsPerPage + 1)}));
    }

    handleSelect(status) {
        let newSelected = [];
        const rows = this.state.queueData;
        rows.forEach(row => {
            if (row['document_status'] === status) {
                newSelected = newSelected.concat(row.id);
            }
        });
        this.setState(() => ({selected: newSelected, openDropdown: false}));
    }

    handleClick(name) {
        const selectedIndex = this.state.selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(this.state.selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(this.state.selected.slice(1));
        } else if (selectedIndex === this.state.selected.length - 1) {
            newSelected = newSelected.concat(this.state.selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
              this.state.selected.slice(0, selectedIndex),
              this.state.selected.slice(selectedIndex + 1),
            );
        }
        this.setState(() => ({selected: newSelected}))
    };

    handleChangePage = (event, newPage) => {
        this.setState(() => ({page: newPage, displayPage: Math.floor(this.state.queueData.length/this.state.rowsPerPage + 1)}));
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({
          rowsPerPage: parseInt(event.target.value, 10)
        });
    };

    toggleDropdown = () => {
        this.setState(prevState => ({...prevState, openDropdown: !prevState.openDropdown}));
    };
    handleClickAway = () => {
        this.setState({
            openDropdown: false,
        });
    };
    render() {
        const { t, classes } = this.props;
        const {openDropdown} = this.state;
        return(
          <Box boxShadow={2} className={classes.card}>
              <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '8px'}}>
              <div className={classes.processingQueue}>{t('queue.title')}</div>
                  <Button variant="outlined"
                          className={classes.roundButton} href="#"
                          onClick={event => this.refreshData()}
                          style={{height: '36px'}}>
                      <Refresh style={{marginRight: '0.5rem',color: '#999999'}}/><span style={{color: '#999999'}}>{t('queue.refresh-btn')}</span>
                  </Button>
              </div>
            <div className={classes.tableHeader}>
              <Table className={classes.table} style={{tableLayout: 'fixed'}}>
                <TableHead>
                  <TableRow className={classes.tableHeadBorder}>
                    <TableCell padding="checkbox" style={{width: '10%', paddingLeft: '0'}}>
                      <div style={{display: 'flex'}}>
                        <Checkbox
                          onClick={event => this.handleAllSelect()}
                          checked={this.state.selected.length !== 0 && this.state.selected.length === this.state.queueData.length}
                          indeterminate={this.state.selected.length > 0 && this.state.selected.length < this.state.queueData.length}
                          style={{color: this.state.selected.length > 0 ? '#959daf' : '', padding: '0'}}
                        />
                          <div style={{position: 'relative', display: 'flequeueScrollx'}}>
                            <ArrowDownIcon onClick={event => this.toggleDropdown()} style={{cursor: 'pointer'}}/>
                            {openDropdown ? (
                                <ClickAwayListener onClickAway={this.handleClickAway}><div>
                              <Paper elevation={8} className={classes.dropdownContent}>
                                <div className={`${classes.completedBorder} ${classes.popHover}`} onClick={event => this.handleSelect('Complete')}>Completed</div>
                                <div onClick={event => this.handleSelect('Failed')} className={classes.popHover}>Failed</div>
                              </Paper></div></ClickAwayListener>) : null}
                          </div>
                        {/* <DeleteIcon onClick={this.clearAll} style={{display : this.state.selected.length > 0 ? 'flex' : 'none', color: '#1e2a5a', marginTop: '2px'}} /> */}
                      </div>
                    </TableCell>
                    <TableCell className={classes.tableHead} style={{width: '20%', textAlign: 'left', paddingLeft: '34px'}}>{t('table.file-name')}</TableCell>
                    <TableCell className={classes.tableHead} style={{width: '10%'}}>{t('table.folder')}</TableCell>
                    <TableCell className={classes.tableHead} style={{width: '10%'}}>{t('table.status')}</TableCell>
                    <TableCell className={classes.tableHead} style={{width: '25%'}}>{t('table.time-stamp')}</TableCell>
                    <TableCell className={classes.tableHead} style={{width: '25%'}}>{t('table.action')}</TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </div>
              <div className={`${classes.queueScroll} custom-scrollbar `}>
                <Table className={`${classes.table} ${classes.scrollTable} `} style={{tableLayout: 'fixed'} }>
                  <TableHead style={{display: 'none'}}>
                    <TableRow className={classes.tableHeadBorder}>
                      <TableCell padding="checkbox" style={{width: '10%'}}>
                        <div style={{display: 'flex'}}>
                          <Checkbox
                            onClick={event => this.handleAllSelect()}
                            checked={this.state.selected.length !== 0 && this.state.selected.length === this.state.queueData.length}
                            indeterminate={this.state.selected.length > 0 && this.state.selected.length < this.state.queueData.length}
                            style={{color: this.state.selected.length > 0 ? '#959daf' : '', padding: '0 0 0 9px'}}
                          />
                          <ClickAwayListener onClickAway={() => this.handleClickAway}>
                            <div style={{position: 'relative', display: 'flex'}}>
                              <ArrowDownIcon onClick={event => this.toggleDropdown()} style={{cursor: 'pointer'}}/>
                              {openDropdown ? (
                                <div className={classes.dropdownContent}>
                                  <p className={`${classes.completedBorder} ${classes.openSansFamily}`} onClick={event => this.handleSelect('Complete')}>{t('queue.comp')}</p>
                                  <p onClick={event => this.handleSelect('Processing')} className={classes.openSansFamily}>{t('queue.proc')}</p>
                                </div>) : null}
                            </div>
                          </ClickAwayListener>
                          <DeleteIcon onClick={this.clearAll} style={{display : this.state.selected.length > 0 ? 'flex' : 'none', color: '#1e2a5a', marginTop: '2px'}} />
                        </div>
                      </TableCell>
                      <TableCell className={`${classes.tableHead} overflow-ellipsis`} style={{width: '20%', textAlign: 'left', paddingLeft: '34px'}}>{t('table.file-name')}</TableCell>
                      <TableCell className={`${classes.tableHead} overflow-ellipsis`} style={{width: '10%'}}>{t('table.folder')}</TableCell>
                      <TableCell className={`${classes.tableHead} overflow-ellipsis`} style={{width: '10%'}}>{t('table.status')}</TableCell>
                      <TableCell className={`${classes.tableHead} overflow-ellipsis`} style={{width: '25%'}}>{t('table.time-stamp')} Stamp</TableCell>
                      <TableCell className={`${classes.tableHead} overflow-ellipsis`} style={{width: '25%'}}>{t('table.action')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.queueData && this.state.queueData
                      .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                      .map((row, index) => {
                        const isItemSelected = this.isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                            style={{height: '60px'}}
                          >
                            <TableCell padding="checkbox" style={{width: '11%', paddingLeft: '0', borderBottom: (index === this.state.rowsPerPage -1) || (row.id === this.state.queueData[this.state.queueData.length - 1].id) ? 'none' : ''}}>
                              <Checkbox
                                style={{color: isItemSelected ? '#959daf' : '', paddingLeft: '0'}}
                                disabled={row['document_status'] === 'Processing'}
                                checked={isItemSelected}
                                inputProps={{ 'aria-labelledby': labelId }}
                                onClick={event => this.handleClick(row.id)}
                              />
                            </TableCell>
                            <TableCell id={labelId} className={`${classes.fileName} overflow-ellipsis`}
                                       style={{cursor: row['document_status']==='Complete' ? 'pointer' : 'default', borderBottom: (index === this.state.rowsPerPage -1) || (row.id === this.state.queueData[this.state.queueData.length - 1].id) ? 'none' : ''}}
                                       onClick={() => row['document_status'] === 'Complete' ? this.viewFile(row.uuid) : null}>
                              <Tooltip title={row["name"]}><span>{row.name}</span></Tooltip>
                            </TableCell>
                            <TableCell style={{width: '10%', borderBottom: (index === this.state.rowsPerPage -1) || (row.id === this.state.queueData[this.state.queueData.length - 1].id) ? 'none' : ''}} className={`${classes.tableBody}`}>
                              <Tooltip title={row["path"]}><span className='overflow-ellipsis display-block'>{row.path ? row.path : '-'}</span></Tooltip>
                            </TableCell>
                            <TableCell style={{width: '10%', borderBottom: (index === this.state.rowsPerPage -1) || (row.id === this.state.queueData[this.state.queueData.length - 1].id) ? 'none' : ''}} className={`${classes.tableBody} overflow-ellipsis1`}>
                              {/*<Tooltip title={row["document_status"]}><span>*/}
                                {row['document_status']}
                                {/*</span></Tooltip>*/}
                            </TableCell>
                            <TableCell style={{width: '25%', borderBottom: (index === this.state.rowsPerPage -1) || (row.id === this.state.queueData[this.state.queueData.length - 1].id) ? 'none' : ''}} className={`${classes.tableBody} overflow-ellipsis`}>
                              {moment.tz(row['status_changed_at'], getUserTimezone()).format("LLL")}
                            </TableCell>
                            <TableCell style={{width: '25%', borderBottom: (index === this.state.rowsPerPage -1) || (row.id === this.state.queueData[this.state.queueData.length - 1].id) ? 'none' : ''}}>
                              <div style={{display: 'flex', alignItems: 'center'}}>
                                <RemoveRedEye
                                  style={{cursor: row['document_status']==='Complete' ? 'pointer' : 'default',marginRight: '175px', marginLeft: 'auto', color: row['document_status'] === 'Complete' ? '#959daf' : '#e0e0e0'}}
                                  onClick={() => row['document_status'] === 'Complete' ? this.viewFile(row.uuid) : null}
                                />
                                {/* <Button
                                  variant="outlined"
                                  className={`${classes.roundButton} ${row['document_status'] === 'Processing' ? 'disableButton' : ''}`}
                                  style={{marginRight: 'auto', fontSize: '15px',color: '#999999'}}
                                  href="#"
                                  onClick={() =>  row['document_status'] === 'Processing' ? null : this.clearClicked(row.id)}
                                >Fear</Button> */}
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {this.state.queueData.length === 0 && (
                      <TableRow style={{ height: 200 }}>
                        {this.state.loading ? <TableCell colSpan={6} style={{textAlign: 'center'}}>{t('queue.load')}</TableCell>
                          : <TableCell colSpan={6} style={{textAlign: 'center'}}><QueueIcon style={{color: '#959daf', fontSize: '3rem', textAlign: 'center',}}/> <br />{t('queue.queue-empty')}<br /> {t('queue.check')}</TableCell>}
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={this.state.queueData.length}
                rowsPerPage={this.state.rowsPerPage}
                labelRowsPerPage={t('file-management.per-page')}
                page={this.state.page}
                labelDisplayedRows={({ page})=> `${page + 1} of ${this.state.displayPage}`}
                SelectProps={{
                    inputProps: {'aria-label': 'Results per page'},
                    native: true
                  }}
                className={classes.borderNone}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
          </Box>
        )
    }

}

export default withTranslation()(withStyles(styles)(Queue));
