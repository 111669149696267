import {withStyles} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {getRole} from '../common/loginService';
import Organization from './components/Organization';
import SuperUser from './components/SuperUser';
import { withTranslation } from 'react-i18next';

const styles ={
  adminMain: {
    padding: '24px 5% 16px 5%',
  },
  contentGrid:{
    paddingLeft: '0',
   },
  content:{
    background: '#FFFFFF',
    padding: '40px 56px 40px 56px',
    minHeight: '75vh',
  },
  titleHead:{
    padding: '0',
  },
  menuItem:{
    fontSize: '12px',
    '&:focus': {
      outline: "none",
    },
  },
  menuText:{
    paddingLeft: '16px',
    fontSize: '17px',
    lineHeight: '1.29',
    color: '#1b1b1b',
    fontFamily: 'Raleway !important',
    fontWeight: '500'
  },
  menu: {
    padding: '24px 0 0 8px',
  },
  active: {
    fontWeight: 'bold !important',
  },
  icon: {
    color: '#5EAEFF',
    paddingTop: '6px'
  },

  // Super admin style
 workMain: {
    padding: '0 56px 3rem 56px',
  },
  titleTabs: {
    margin: 'auto 90px 0 auto',
    cursor: 'pointer',
    fontFamily: 'Raleway !important',
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '1.2',
    letterSpacing: '-0.32px',
    color: '#1e2a5a'
  },
  header4:{
    fontSize: '1 em',
    color: '#212a51',
    fontWeight: '600'
  },
  tabHeader: {
    paddingLeft: '50px',
    margin: '32px 0 2.5rem 0',
    display: 'flex'
  },
  activeTab: {
    borderBottom: '4.9px solid #5EAEFF',
    fontWeight: 'bold',
  },
  disableTab: {
    color: '#b7b7b7',
    cursor: 'default !important'
  },
  mainTitle: {
    // fontFamily: '\'Raleway\',sans-serif!important',
    fontWeight: '600',
    color: '#1e2a5a',
    margin: '1.5rem 0 1.1rem 0',
    fontFamily: 'Raleway !important',
    fontSize: '1.8rem'
  },
  listIcon: {
    width: '50px',
    height: '50px',
    background: 'white',
    padding: '10px',
    border: '1px solid #e1e3e4',
    cursor: 'pointer'
  },
  right20: {
    marginRight: '20px'
  },
};


class SuperAdmin extends React.Component{

  data = {
    firstName: '',
    lastName: '',
    orgName: '',
    email: '',
    status: true,
    showEmail: '',
    userAdd: false,
    view: false,
  };
   state = {
     data: {
       ...this.data,
     },
     selectedTab: 'organization',
     currentLocation: '/',
     openModal: false,
     role: getRole(),
     key: 1,
   };

  componentDidMount() {
    if(document.URL.includes("orgs")){
      this.setState({selectedTab: 'organization'})
    }
    else if(document.URL.includes("users")){
      this.setState({selectedTab: 'superuser'})
    }
  }

  changeTab = (value, refresh) => {
    const {history} = this.props;
    const {selectedTab} = this.state;
    if(selectedTab !== value){
      this.setState({selectedTab: value});
      if(refresh){
        value === 'organization' ?
          history.push('/superadmin/orgs') :
          history.push('/superadmin/users')
      }
    }
  };

  updateSelectedTab = (value) => {
    this.setState({selectedTab: value})
  }

  changeLocation = (value) => {
    this.setState({currentLocation: value})
  };

  changeData = (key, value) => {
    if (key === 'data') {
      this.setState({data: this.data});
    } else {
      this.setState(prevState => ({
        ...prevState,
        data: {...prevState.data, [key]: value}
      }));
    }
  };

  render() {
    const {t, classes } = this.props;
    const { selectedTab } = this.state;

    return(
      <div className={classes.workMain}>
        <Grid container>
          <Grid container className='workspace-padding-top'>
            <Grid item xs={12} sm={3} className={`${classes.titleHead}`}>
              <Typography variant='h5' className={classes.mainTitle}>{t('super-admin.title1')}</Typography>
            </Grid>
            <Grid className='' item xs={12} sm={9} style={{display: 'flex'}}>
              <Grid item className={classes.tabHeader} style={{marginRight: 'auto'}}>
                <span
                  className={`${selectedTab === 'organization' ? classes.activeTab : null} ${classes.titleTabs} title-tabs-margin`}
                  onClick={()=>this.changeTab('organization', true)}>
                  {t('super-admin.orgs')}
                </span>
                <span
                  className={`${selectedTab === 'superuser' ? classes.activeTab : null} ${classes.titleTabs} position-relative`}
                  onClick={()=>this.changeTab('superuser', true)}>
                  {t('super-admin.title')}
                </span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.contentGrid}>
            <Box boxShadow={2} className={classes.content}>
              <Switch>
                <Route
                  path="/superadmin/orgs"
                  exact
                  render={(props) =>
                    <Organization changeTab={this.changeTab} updateSelectedTab={this.updateSelectedTab} {...props} />}
                />
                <Route
                  path="/superadmin/users"
                  exact
                  render={(props) =>
                    <SuperUser changeTab={this.changeTab} updateSelectedTab={this.updateSelectedTab} {...props} />}
                />
              </Switch>
            </Box>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withTranslation()(withStyles(styles)(SuperAdmin));
