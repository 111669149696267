import SelectTimezone from '@capaj/react-select-timezone/build/SelectTimezone';
import {withStyles} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button/Button';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import Grid from '@material-ui/core/Grid/Grid';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/core/SvgIcon/SvgIcon';
import TextField from '@material-ui/core/TextField/TextField';
import Typography from '@material-ui/core/Typography/Typography';
import CheckCircle from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import React, {Fragment} from 'react';
import {withTranslation} from 'react-i18next';
import PersonImage from '../assets/images/user_blue.png';
import {getUserLanguage, getUserTimezone, logout} from '../common/loginService';
import {GetRequest, PatchRequestV2} from '../common/requests';
import {URL} from '../common/url';
import './custom.scss';

const styles = {
  parentContainer: {

  },

  mainTitle: {
    fontWeight: '600',
    color: '#1e2a5a',
    margin: '0 0 2rem 0',
    fontFamily: 'Raleway !important',
    fontSize: '1.8rem'
  },
  content:{
    background: '#FFFFFF',
    padding: '20px 56px 40px 56px',
    minHeight: '75vh'
  },
  firstGrid: {
    width: '40%',
    marginRight: '2rem'
  },
  filter: {
    minWidth: '175px',
    background: '#F4F6F9',
  },
  titleHead: {
    marginTop: '0.6rem',
    marginBottom: '0.5rem',
    fontSize: '1rem'
  },
  inputSide: {
    width: '100%'
  },
  gridRow: {
    width: '100%',
    marginBottom: '24px'
  },
  loader: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    marginRight: 'auto'
  },
  createButton: {
    color: 'white',
    background: 'linear-gradient(70deg, #5EAAFF, #5159F8)',
    textTransform: 'unset',
    // padding: '0.5rem 2rem',
    borderRadius: '8px',
    fontFamily: 'Raleway !important',
    // fontSize: '20px',
    fontWeight: 'bold'
  },
};

class Account extends React.Component {
  state = {
    lastNameError: false,
    firstNameError: false,
    firstName: ' ',
    lastName: ' ',
    currentPassword: '',
    newPassword: '',
    confirmedPassword: '',
    groups: '',
    email: '',
    role: '',
    status: null,
    organisation: '',
    loading: true,
    userDetails: null,
    resetSubmit: false,
    passwordError: null,
    saved: false,
    selectedTimezone: '',
    selectedLanguage: ''
  };
  controller = new window.AbortController();

  componentDidMount() {
    this.getUserDetails();
    this.setState({selectedTimezone: getUserTimezone()})
    this.setState({selectedLanguage: getUserLanguage()})
  }

  getUserDetails() {
    GetRequest(URL.auth, (data) => {
      let userDetails = data.payload;
      if (userDetails) {
        this.setState({
          firstName: userDetails['first_name'],
          lastName: userDetails['last_name'],
          organisation: userDetails['organization'].name,
          role: userDetails['user_role'],
          status: userDetails['user_status'],
          email: userDetails.email,
          loading: false,
          userDetails: userDetails
        })
      }
    }, (err) => {
      this.setState({loading: false});
    })

  //   const params = {
  //     user_name: getUserName(),
  //   };
  //   this.setState({loading: true});
  //   let esc = encodeURIComponent;
  //   let query = Object.keys(params)
  //       .map(k => esc(k) + '=' + esc(params[k]))
  //       .join('&');
  //   GetRequest(URL.users + '?' + query ,(data)=> {
  //     // this.setState({userDetails: data.payload[0] ? data.payload[0] : [], loading: false});
  //     let userDetails = data.payload[0];
  //     if (userDetails) {
  //       this.setState({
  //         firstName: userDetails['first_name'],
  //         lastName: userDetails['last_name'],
  //         organisation: userDetails['organization_name'],
  //         role: userDetails['user_role'],
  //         status: userDetails['user_status'],
  //         email: userDetails.email,
  //         loading: false,
  //         userDetails: userDetails
  //       })
  //     }
  //   }, (err)=> {
  //     this.setState({loading: false});
  //     console.log(err)})
  // }
  }

  handleChange = (event) => {
    const { name, value } = event.target;

    if (name === 'currentPassword' || name === 'newPassword' || name === 'confirmedPassword') {
      this.setState({
        passwordError: null
      })
    }
    this.setState(prevState => ({ ...prevState, [name]: value }));
  };

  updateUser = () => {
    const {firstName, lastName} = this.state;
    const { t } = this.props;
    if (firstName === '' || firstName.trim().length === 0) {
      this.setState({firstNameError: true});
      return;
    }
    if (lastName === '' || lastName.trim().length === 0) {
      this.setState({lastNameError: true});
      return;
    }
    const payload = {
      first_name: firstName,
      last_name: lastName,
    };
    if (firstName && lastName) {
      PatchRequestV2(
        URL.auth,
        this.controller.signal,
        payload, (data) => {
          if (data) {
            this.getUserDetails();
          }
          this.setState({openSnack: true, snackMsg: t('msg.update-ok')});
        }, (err) => {
          console.log(err);
          this.setState({openSnack: true, snackMsg: t('msg.update-fail')});
        });
    } else {
      let msg = '';
      if (!firstName && lastName) {
        msg = t('msg.first-name-empty-1');
      } else if (firstName && !lastName) {
        msg = t('msg.last-name-empty-1');
      } else {
        msg = t('msg.full-name-empty-1');
      }
      this.setState({openSnack: true, snackMsg: msg});
    }
  };

  resetPassword = () => {
    const { newPassword, currentPassword } = this.state;
    const payload = {
      proposed_password: newPassword,
      password: currentPassword
    };

    this.setState({resetSubmit: true}, () => {
      if(this.checkPasswordValidation()){
        PatchRequestV2( URL.updatePassword,
          this.controller.signal, payload,(data)=> {
            logout()
            this.props.history.push('/login');
          },
          (err)=> {
            this.setState({loading: false, openSnack: true, snackMsg: err.message.detail});
            console.log('err',err);
          });
      } else {
        this.setState( { loading: false});
      }
    })
  };

  checkPasswordValidation = () => {
    const { resetSubmit, newPassword, confirmedPassword} = this.state;
    const { t } = this.props;
    if (!resetSubmit) {
      return;
    }
    let valid = true;
    let passwordError = "";

    if (newPassword !== '') {
      if(newPassword.length < 8) {
        passwordError = t('msg.pwd-err-chars');
        valid = false;
      } else if(!Account.validatePassword(newPassword)){
        passwordError = t('msg.pwd-err-eli');
        valid = false;
      } else if(newPassword !== confirmedPassword){
        passwordError = t('msg.pwd-err-match');
        valid = false;
      }
    } else {
      passwordError = t('msg.pwd-err-generic');
      valid = false;
    }
    this.setState({passwordError: passwordError});
    return valid;
  };

  static validatePassword(p) {
              
    let re = /(?=.*\d)(?=.*[a-z])(?=.*[!@#$%^&*])(?=.*[A-Z]).{8,}/;
    return re.test(p);
  }

  closeSnack = () => {
    this.setState({openSnack: false, snackMsg: ''});
  };

  handleTimezoneChange = selectedTimezone => {
    const { t } = this.props;
    const payload = {
      "timezone": selectedTimezone
    }

    PatchRequestV2(
      URL.auth,
      this.controller.signal,
      payload, (data) => {
        if (data)
          localStorage.setItem("user-timezone", data.payload.user_timezone);
        this.setState({openSnack: true, snackMsg: t('msg.tz-success')});
      }, (err) => {
        console.log(err);
        this.setState({openSnack: true, snackMsg: t('msg.tz-fail')});
      });

    this.setState({ selectedTimezone })
  };

  langSetup = (e) => {
    const {t, i18n} = this.props;
    e.preventDefault();
    i18n.changeLanguage(e.target.value);
    const payload = {
      "language": e.target.value
    }

    PatchRequestV2(
      URL.auth,
      this.controller.signal,

      payload, (data) => {
        if (data)
          localStorage.setItem("user-language", data.payload.user_language);
        this.setState({openSnack: true, snackMsg: t('msg.lang-success')});
      }, (err) => {
        console.log(err);
        this.setState({openSnack: true, snackMsg: t('msg.lang-fail')});
      });
      this.setState({ selectedLanguage: e.target.value })
}

  render() {
    const {firstNameError, lastNameError, firstName, lastName, currentPassword, newPassword,
      role, groups, organisation, status, email, loading, passwordError, saved,
      confirmedPassword, openSnack, snackMsg, selectedTimezone, selectedLanguage} = this.state;
    const {t, classes } = this.props;

    return(
      <div className='parent-container'>
        <Typography variant='h5' className={classes.mainTitle}>{t('account.title')}</Typography>
        <Box boxShadow={3} className={classes.content}>
          {
            loading?<div className={classes.loader}><CircularProgress className='loader' style={{ color: '#5fafff'}} size={20} /></div>:
              <Fragment>
                <div className='section'>
                  <div className='sub-header'>{t('account.main-form.subtitle')}</div>
                  <Button variant="outlined" className='update-btn' onClick={() => this.updateUser()}>{t('account.update-btn')}</Button>
                </div>
                <div className='avatar-div'>
                  <div className='img-div'>
                    <img src={PersonImage} alt="person" className='user-icon'/>
                  </div>
                  <div className='username'>{firstName + ' ' + lastName}</div>
                  <div className='account-status'>{status?t(status).toUpperCase():''}</div>
                </div>
                <div>
                  <Grid className={classes.gridRow} container direction="row" justify="flex-start" alignItems="flex-start">
                    <Grid className={classes.firstGrid}>
          <Typography variant='subtitle2' className={classes.titleHead}><b>{t('account.main-form.first-name')}</b></Typography>
                      <TextField
                        name="firstName"
                        id="firstName"
                        onChange={this.handleChange}
                        value={firstName}
                        required
                        className={classes.inputSide}
                        variant='outlined'
                        inputProps={{
                          style: {
                            padding: '10px'
                          }
                        }}
                        helperText={firstNameError ? t('msg.first-name-empty') : null}
                        error={firstNameError !== ''}
                      />
                    </Grid>
                    <Grid className={classes.firstGrid}>
                      <Typography variant='subtitle2' className={classes.titleHead}><b>{t('account.main-form.last-name')}</b></Typography>
                      <TextField
                        id="lastName"
                        name="lastName"
                        onChange={this.handleChange}
                        value={lastName}
                        required
                        className={classes.inputSide}
                        variant='outlined'
                        inputProps={{
                          style: {
                            padding: '10px'
                          }
                        }}
                        helperText={lastNameError ? t('msg.last-name-empty') : null}
                        error={lastNameError !== ''}
                      />
                    </Grid>
                  </Grid>

                  <Grid className={classes.gridRow} container direction="row" justify="flex-start" alignItems="flex-start">
                    <Grid className={classes.firstGrid}>
                      <Typography variant='subtitle2' className={classes.titleHead}><b>{t('account.main-form.email')}</b></Typography>
                      <div className='email'>{email}</div>
                    </Grid>
                    <Grid className={classes.firstGrid}>
                      <Typography variant='subtitle2' className={classes.titleHead}><b>{t('account.main-form.org-name')}</b></Typography>
                      <div className='email'>{organisation}</div>
                    </Grid>
                  </Grid>

                  <Grid className={classes.gridRow} container direction="row" justify="flex-start" alignItems="flex-start">
                    <Grid className={classes.firstGrid}>
                      <Typography variant='subtitle2' className={classes.titleHead}><b>{t('account.main-form.grp')}</b></Typography>
                      <div className='email'>{groups}</div>
                    </Grid>
                    <Grid className={classes.firstGrid}>
                      <Typography variant='subtitle2' className={classes.titleHead}><b>{t('account.main-form.role')}</b></Typography>
                      <div className='email'>{role}</div>
                    </Grid>
                  </Grid>

                  <Grid className={classes.gridRow} container direction="row" justify="flex-start" alignItems="flex-start">
                    <Grid className={classes.firstGrid}>
                      <Typography variant='subtitle2' className={classes.titleHead}><b>{t('account.main-form.tz')}</b></Typography>
                      <SelectTimezone
                        value={selectedTimezone}
                        isClearable // allows user to have null value in this select
                        guess // this will fill the input with user's timezone guessed by moment.
                        onChange={(val) => this.handleTimezoneChange(val)}
                      />
                    </Grid>
                    <Grid className={classes.firstGrid}>
                      <Typography variant='subtitle2' className={classes.titleHead}><b>{t('account.main-form.lang')}</b></Typography>
                     <Select
                      variant='outlined'
                      className={classes.filterSelect}
                      value={selectedLanguage}
                      onChange={this.langSetup}
                      input={<OutlinedInput name="filter" id="age-simple" className={classes.outlinedFilter}/>}
                      style={{
                          width: '100%',
                          textOverflow: 'ellipsis',
                          paddingLeft: '0px'
                        }}
                    >
                    <MenuItem
                        value="en"
                        style={{
                        width: '100%',
                        textOverflow: 'ellipsis'
                      }}>
                        {t('account.main-form.en')}
                    </MenuItem>
                    <MenuItem
                        value="es"
                        style={{
                        width: '100%',
                        textOverflow: 'ellipsis'
                      }}>
                        {t('account.main-form.es')}
                    </MenuItem>
                    </Select>
                    </Grid>
                  </Grid>
                </div>
                <hr />
                <div className='section reset-section'>
                  <div className='sub-header'>{t('account.reset-pwd-form.title')}</div>
                </div>
                <div style= {{fontSize: '0.7rem', color : '#686868', lineHeight: '1.1rem'}}>
                    <Typography>Password criteria : </Typography>
                    <ul style= {{margin: '0', paddingLeft: '20px'}}>
                        <li>Should have at least one number</li>
                        <li>Should have at least one special character</li>
                        <li>Should have at least one uppercase letter</li>
                        <li>Should have at least one lowercase letter</li>
                        <li>Minimum length should be 8 characters</li>
                        <li>Special characters allowed !,@,#,$,%,^,&,*</li>
                    </ul>
                  </div>

                <Grid className={classes.gridRow} container direction="row" justify="flex-start" alignItems="flex-start">
                  <Grid className={classes.firstGrid}>
                    <Typography variant='subtitle2' className={classes.titleHead}><b>{t('account.reset-pwd-form.curr-pwd')}</b></Typography>
                    <TextField
                      name="currentPassword"
                      id="currentPassword"
                      placeholder="**********"
                      onChange={this.handleChange}
                      defaultValue={currentPassword}
                      autoComplete="new-password"
                      required
                      className={classes.inputSide}
                      variant='outlined'
                      type='password'
                      inputProps={{
                        style: {
                          padding: '10px'
                        }
                      }}
                      error={firstNameError !== ''}
                    />
                  </Grid>
                  <Grid className={classes.firstGrid}>
                    <Typography variant='subtitle2' className={classes.titleHead}><b>{t('account.reset-pwd-form.new-pwd')}</b></Typography>
                    <TextField
                      id="newPassword"
                      name="newPassword"
                      onChange={this.handleChange}
                      defaultValue={newPassword}
                      placeholder="**********"
                      required
                      className={classes.inputSide}
                      variant='outlined'
                      type='password'
                      helperText={t('msg.pwd-helper')}
                      inputProps={{
                        style: {
                          padding: '10px'
                        }
                      }}
                      error={lastNameError !== ''}
                    />
                  </Grid>
                </Grid>

                <Grid className={classes.gridRow} container direction="row" justify="flex-start" alignItems="flex-start">
                  <Grid className={classes.firstGrid}>
                    <Typography variant='subtitle2' className={classes.titleHead}><b>{t('account.reset-pwd-form.cnf-new-pwd')}</b></Typography>
                    <TextField
                      name="confirmedPassword"
                      id="confirmedPassword"
                      onChange={this.handleChange}
                      defaultValue={confirmedPassword}
                      placeholder="**********"
                      required
                      type="password"
                      className={classes.inputSide}
                      variant='outlined'
                      inputProps={{
                        style: {
                          padding: '10px'
                        }
                      }}
                    />
                  </Grid>
                  <Grid style={{marginTop: 'auto', display: 'flex'}}>
                    <Button variant="contained" disabled={!newPassword || !confirmedPassword || !currentPassword || passwordError} color="primary" className='reset-btn' onClick={() => this.resetPassword()}>{t('account.reset-pwd-form.title')}</Button>
                    {
                      passwordError?<div className='error-desc'><ErrorIcon className="error-icon"/>{passwordError}</div>:
                        <Fragment>
                          {
                            saved?<div className='error-desc'><CheckCircle className="done-icon"/><span className="margin-auto">Saved !</span></div>:null
                          }
                        </Fragment>
                    }
                  </Grid>
                </Grid>
              </Fragment>
          }
          </Box>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={openSnack}
          autoHideDuration={3000}
          onClose={this.closeSnack}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{snackMsg}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.closeSnack}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </div>
    )
  }
}
export default withTranslation()(withStyles(styles) (Account));
