/**
 * Created by Akhtar on 11/05/2020.
 */

import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';
import {AgGridReact} from 'ag-grid-react';
import React from 'react';
import {PieChart} from 'react-d3-components';
import {Rnd} from 'react-rnd';


function AgGridTable(props) {
  const {
    _this,
    t,
    classes,
    openModal,
    openActionMenu,
    modalOpen,
    showChart,
    winWidth,
    winHeight,
    onResizeStop,
    chartData,
    chartLegends,
    toggleChartForData,
    toggleChart,
    minHeight,
    closeFolder,
    onTableSearch,
    handleClick,
    anchorEl,
    handleCloseMenu,
    isAggView,
    processing,
    setProcessingIds,
    activeId,
    doc_list,
    toggleMenu,
    currentIdProcessing,
    gridApi,
    gridColumnApi,
    gridOptions,
    handleName,
    tableName,
    locked,
    columnDefs,
    rowData,
    modules,
    statusBar,
    getContextMenuItems,
    getColumnMenuItems,
    updateData,
    rowDoubleClicked,
    cellClicked,
    defaultColDef,
    tooltip,
    setGridOptions,
  } = props

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableScrollLock={true}
      onClose={(value) => modalOpen(false, _this)}
      className={"modalBox"}
      open={openModal} >
      <React.Fragment>
        {showChart &&
          <Rnd
            // d3 Chart
            className="chartDiv"
            default={{
              x: winWidth * .05,
              y: winHeight * .05,
              height: winHeight * .5,
              width: winWidth * .8,
            }}
            minHeight={250}
            maxHeight={winHeight*0.85}
            disableDragging={false}
            onResizeStop={e => onResizeStop(e)}
            enableResizing={{
              bottom: true,
              bottomLeft: true,
              bottomRight: true,
              left: true,
              right: true,
              top: true,
              topLeft: true,
              topRight: true,
            }}
            >
            <div className={`${classes.actionButton} chart-label`}>
              {chartData.label}
            </div>

            <div className={classes.actionButton} onClick={value => toggleChart(false, _this)}>
              <CloseIcon className="close-modal" />
            </div>

            <div className="pieChart">
              {chartData &&
                <React.Fragment>
                  <div className="chartLeft">
                    <PieChart
                      data={chartData}
                      width={600}
                      height={300}
                      innerRadius={20}
                      outerRadius={150}
                      // labelRadius={100}
                      hideLabels={false}
                      margin={{top: 10, bottom: 10, left: 100, right: 100}}
                      tooltipOffset={{top: 100, left: 150}}
                      tooltipHtml={tooltip}
                      tooltipContained={false}
                      tooltipMode={'element'}
                      sort={null}
                    />
                  </div>
                  <div className="chartRight">
                  {
                    chartLegends.map(obj => {
                      let checked = obj.state ? true : false;
                      return (
                        <div className="chart-line" onClick={() => toggleChartForData({...obj})}>
                          <Checkbox color="primary" checked={checked} />
                          <label>{obj.x} <b>({obj.y})</b></label>
                        </div>
                      )
                    })
                  }
                  </div>
                  <div style={{clear: "both"}}></div>
                </React.Fragment>
              }
            </div>
          </Rnd>
        }

        <Rnd
          // Table Drawer
          default={{
            x: 56,
            // y: 300,
            height: Math.floor(window.outerHeight * 0.5)
          }}
          minHeight={minHeight}
          disableDragging='Enable'
          enableResizing={{ top: true }}
          maxHeight={winHeight*0.85} // <<< PLEASE DO NOT CHANGE THIS...
          className="bottomPush"
          >
            <div className={classes.modal}>
            <div className="handler"></div>
            <div className={classes.actionButton} onClick={() => closeFolder()}>
              <CloseIcon className="close-modal" /></div>
              <div className={classes.modalDescription}>
                <div className="ag-theme-material" style={{height: '100%', width: "auto"}}>
                  <div className={classes.searchButton}>
                    <input className={classes.searchInput} id="filter-text-box" placeholder={t('result-viewer.placeholder')} onInput={() => onTableSearch()}/>
                    <SearchIcon className="color-99 search-margin"/>
                  </div>

                  <div className={classes.menuIcon}>
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={handleClick}
                      PaperProps={{
                        style: {
                          maxHeight: 48 * 4.5
                        }
                      }}
                    >
                      <MoreVertIcon className="margin"/>
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={openActionMenu}
                        onClose={handleCloseMenu}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right'
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right'
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            //Handle if we are trying to classify an entire table
                            if (isAggView && !processing.length) {
                              setProcessingIds(doc_list, _this);
                            } else if (!currentIdProcessing && !isAggView) {
                              setProcessingIds([activeId], _this);
                            }
                            toggleMenu();
                            handleCloseMenu();
                          }}
                        >
                          {/* If we want to close the overflow menu onclicking the classify menu  */}
                          {/* onClick={() => {this.generateTask(activeId);this.toggleMenu()}}> */}
                          {
                            currentIdProcessing
                              ? t('result-viewer.classifying')
                              : t('result-viewer.classify-table')
                          }
                        </MenuItem>

                        {/*<MenuItem onClick={() => {this.renderChart(true, 'RoC'); this.handleCloseMenu()}}>*/}
                        {/*    {t('result-viewer.chart-roc')}*/}
                        {/*</MenuItem>*/}
                        {/*<MenuItem onClick={() => {this.renderChart(true, 'Tax_Category');this.handleCloseMenu()}}>*/}
                        {/*    {t('result-viewer.chart-cat')}*/}
                        {/*</MenuItem>*/}

                        <MenuItem
                          onClick={() => {
                            let params = {api: gridApi, columnApi: gridColumnApi, params: gridOptions};
                            handleName(params, '.csv', _this);
                            gridApi.exportDataAsCsv(params);
                            handleCloseMenu();
                          }}
                        >
                          {t('result-viewer.export_csv')}
                        </MenuItem>

                        <MenuItem
                          onClick={() => {
                            let params = {api: gridApi, columnApi: gridColumnApi, params: gridOptions};
                            handleName(params, '.xlsx', _this);
                            gridApi.exportDataAsExcel(params);
                            handleCloseMenu();
                          }}
                        >
                          {t('result-viewer.export_xls')}
                        </MenuItem>
                      </Menu>
                  </div>
                  <div style={{"clear": "both"}} />

                  <h3
                    style={{
                      display: 'flex',
                      fonfFamily: 'Raleway',
                      // width: "50%",
                      // margin: "0 auto",
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: 'rgba(0, 0, 0, .7)'
                    }}>{tableName}
                    {locked
                      ? <LockOutlinedIcon style={{color: 'gray', marginLeft: '5px'}} />
                      : null
                    }
                  </h3>
                  <div style={{"clear": "both"}} />

                  <AgGridReact
                    columnDefs={columnDefs}
                    onRowDoubleClicked={rowDoubleClicked}
                    onCellClicked={cellClicked}
                    defaultColDef={defaultColDef}  //defaultColDef: contains column properties all columns will inherit.
                    rowData={rowData}
                    modules={modules}
                    // deltaRowDataMode={true}
                    animateRows={true}
                    statusBar={statusBar}
                    sideBar={false}
                    suppressFieldDotNotation={true}
                    enableRangeSelection={true}
                    enableCharts= {true}
                    popupParent={window.document.getElementById("root")}
                    allowContextMenuWithControlKey={true}
                    getContextMenuItems={params => getContextMenuItems(params, _this)}
                    getMainMenuItems= {params => getColumnMenuItems(params, _this)}
                    rowHeight={45}
                    rowSelection="multiple"
                    onGridReady={params => setGridOptions(params)}
                    onCellValueChanged={(e) => {updateData(e, _this)}}
                    // onSortChanged={e => {this.onSortChanged(e)}}
                    // groupHeaders="true" // Grouping multiple headers under a single column group
                    >
                  </AgGridReact>
                </div>
            </div>
          </div>
         </Rnd>
      </React.Fragment>
    </Modal>
  );
}

export default AgGridTable;
