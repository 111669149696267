import {withStyles} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MenuList from '@material-ui/core/MenuList';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ArrowDown from '@material-ui/icons/ArrowDownward';
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowUp from '@material-ui/icons/ArrowUpward';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import FolderIcon from '@material-ui/icons/Folder';
import DownloadIcon from '@material-ui/icons/GetApp';
import ImageIcon from '@material-ui/icons/Image';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ArrowIcon from '@material-ui/icons/KeyboardArrowRight';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import EyeIcon from '@material-ui/icons/RemoveRedEye';
import RestoreIcon from '@material-ui/icons/Restore';
import SearchIcon from '@material-ui/icons/Search';
import SubjectIcon from '@material-ui/icons/Subject';
import _ from 'lodash';
import * as PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import DropdownMenu, {NestedDropdownMenu} from 'react-dd-menu';
import {withTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import {Treebeard} from 'react-treebeard';
import FolderImage from '../../../assets/images/folder.png';
import UploadImage from '../../../assets/images/upload.png';
import {getFirstTime, getUserOrg, getUserOrgCapabilities} from '../../../common/loginService';
import {GetRequest, PatchRequestV2, PostRequest, PutRequest} from '../../../common/requests';

import {URL} from '../../../common/url';
import Utils from '../../../utils';
import FileUpload from '../FileUpload';
import PropertySearch from '../PropertySearch';

import './custom.scss';
import './dropDown.scss';
import style from './treeStyle';

let log = console.info.bind(window.console)

const styles = {
  root: {
    backgroundColor: 'transparent'
  },

  // paper: {
  //   backgroundColor: "transparent",
  //   boxShadow: "none",
  //   overflow: "hidden"
  // },
  mainGrid: {
    // margin: '8px',
  },
  innerGrid: {
    // margin: '8px',
  },
  contentGrid: {
    paddingLeft: '40px',
    marginTop: '104px'
  },
  content: {
    background: '#FFFFFF',
    padding: '0 5rem 2rem 5rem',
    minHeight: 'calc(100vh - 262px)',
    position: 'relative',
    overflow: 'auto'
    // height: 'calc(100vh - 267px)'
  },
  titleHead: {
    padding: '16px'
  },
  menuItem: {
    fontSize: '12px',
    width: '15vw',
    cursor: 'pointer',
    alignItems: 'start',
    overflow: 'auto',
    '&:focus': {
      outline: 'none',
      fontWeight: 'bold'
    }
  },
  menuText: {
    paddingLeft: '16px'
  },
  menu: {
    padding: '40px 0 0 0',
    marginTop: '114px'
  },
  button: {
    color: 'white',
    background: 'linear-gradient(70deg, #5EAAFF, #5159F8)',
    textTransform: 'unset',
    padding: '0.5rem 2rem',
    borderRadius: '8px'
  },
  createButton: {
    color: 'white',
    textTransform: 'unset',
    // padding: '0.5rem 2rem',
    borderRadius: '8px',
    fontFamily: 'Raleway !important',
    // fontSize: '20px',
    fontWeight: 'bold'
  },
  dropdownContent: {
    position: 'absolute',
    zIndex: '2',
    background: 'white',
    width: '202px',
    textAlign: 'center',
    borderRadius: '10px',
    color: '#1b1b1b',
    fontSize: '16px',
    cursor: 'pointer',
    top: '21px'
  },
  dropdownList: {
    position: 'absolute',
    zIndex: '2',
    background: 'white',
    width: '150px',
    whiteSpace: 'nowrap',
    textAlign: 'left',
    borderRadius: '10px',
    color: '#1b1b1b',
    fontSize: '16px',
    cursor: 'pointer',
    left: '-36px',
    top: '41px'
  },
  completedBorder: {
    borderBottom: '1px solid #e1e3e4'
  },
  completedListBorder: {
    borderBottom: '1px solid #e1e3e4'
  },
  eachOption: {
    margin: 0,
    justifyContent: 'left',
    fontSize: '16px',
    color: '#1b1b1b',
    fontWeight: 'normal',
    display: 'flex',
    padding: '1rem 16px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    }
  },
  openSansFamily: {
    fontFamily: '\'Open Sans\', sans-serif !important'
  },
  paper: {
    // padding: '16px',
    textAlign: 'center'
    // color: theme.palette.text.secondary,
  },
  menu1: {
    position: 'absolute',
    background: 'white',
    color: 'black',
    zIndex: '2',
    borderRadius: '10px'
  },
  createDiv: {
    display: 'inline-block'
  },
  textField: {
    '& input': {
      padding: '10px'
    }
  },
  itemFolder: {
    alignItems: 'center',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    //padding: '3rem 1rem',
    borderRadius: '0.5rem',
    border: '1px solid #e1e3e4',
    color: '#999999',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  textFolder: {
    color: '#1b1b1b',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: '14px',
    fontWeight: '600',
    maxWidth: '50%',
    display: 'inline-block'
  },
  itemFile: {
    borderRadius: '0.5rem',
    border: '1px solid #e1e3e4',
    position: 'relative'
  },
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  gridView: {
    paddingTop: ''
  },
  documentsScroll: {
    maxHeight: 'calc(100vh - 407px)',
    overflowY: 'scroll',
    overflowX: 'hidden',
    paddingRight: '24px'
  },
  backButton: {
    padding: 0,
    marginTop: '5px',
    display: 'block'
  },
  title: {
    marginTop: '10px'
  },
  icon: {
    color: '#5EAEFF'
  },
  modal: {
    background: 'white',
    position: 'relative',
    width: '550px',
    top: '20%',
    left: '30%',
    textAlign: 'left'
  },
  modalSubject: {
    color: '#141b2d',
    fontSize: '20px',
    fontFamily: '\'Open Sans\', sans-serif !important',
    marginBottom: '24px'
  },
  modalDescription: {
    fontFamily: '\'Open Sans\', sans-serif !important',
    color: '#1e2a5a'
  },
  submitDelete: {
    textAlign: 'right',
    marginTop: '24px'
  },
  modalTitle: {
    background: 'linear-gradient(70deg, #5159F8, #5EAAFF)',
    color: 'white',
    textAlign: 'left',
    padding: '15px'
  },
  modalContent: {
    padding: '20px'
  },
  formInput: {
    margin: '10px',
    width: '95%'
  },
  submit: {
    textAlign: 'right'
  },
  actionButton: {
    marginRight: '10px',
    ':focus': {
      cursor: 'none'
    },
    color: '#000000',
    fontWeight: 'bold',
    fontSize: '15px',
    fontFamily: '\'Open Sans\', sans-serif !important'
  },
  folderArea: {
    // marginTop: '1.5rem',
    marginBottom: '1rem'
  },
  fileIcon: {
    width: '5rem',
    height: '5rem',
    marginBottom: '1rem',
    color: '#CDDDFC',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  optionsFiles: {
    position: 'absolute',
    width: '135px',
    zIndex: '2',
    // boxShadow: '0 5px 2px 0 rgba(209, 218, 229, 0.7)',
    boxShadow: '0 4px 6px 0 rgba(184, 191, 196, 0.75)',
    border: '1px solid #e1e3e4',
    // padding: '16px',
    borderRadius: '10px',
    top: '-5px',
    right: '1.7rem'
  },
  optionsFolders: {
    position: 'absolute',
    width: '135px',
    zIndex: '2',
    // boxShadow: '0 5px 2px 0 rgba(209, 218, 229, 0.7)',
    boxShadow: '0 4px 6px 0 rgba(184, 191, 196, 0.75)',
    border: '1px solid #e1e3e4',
    // padding: '16px',
    borderRadius: '10px',
    top: '-5px',
    right: '5rem'
  },
  moreIcon: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  modalItem: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    },
    padding: '0.65rem 0.25rem',
    textAlign: 'left',
    display: 'flex'
  },
  modalText: {
    verticalAlign: 'super',
    textDecoration: 'none !important',
    color: '#1b1b1b',
    minWidth: '120px',
    margin: 'auto',
    fontSize: '13px'
  },
  tableHeadBorder: {
    borderBottom: '1px solid #e1e3e4',
    boxShadow: '0 4px 1px 0 rgba(209, 218, 229, 0.7)'
  },
  fileName: {
    fontSize: '14.4px',
    fontWeight: '600',
    color: '#1e2a5a',
    textAlign: 'left',
    display: 'flex',
    wordBreak: 'break-all'
    // width: '50%'
  },
  longFileName: {
    marginTop: 'auto',
    marginLeft: '8px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontWeight: 'bold',
    whiteSpace: 'nowrap'
  },
  borderNone: {
    borderBottom: 'none'
  },
  rowBorderBottom: {
    cursor: 'pointer',
    borderBottom: '1px solid rgba(224, 224, 224, 1)'
  },
  tableBody: {
    fontSize: '15px',
    textAlign: 'center',
    color: '#686868',
    fontFamily: '\'Open Sans\', sans-serif !important'
  },
  tableHead: {
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#686868',
    fontFamily: '\'Open Sans\', sans-serif !important'
  },
  alignLeft: {
    textAlign: 'left'
  },
  downLoader: {
    position: 'relative',
    left: 'calc(55%)'
  },
  loader: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    marginRight: 'auto',
    top: 'calc(50% - 20px)'
  },
  separatorBorder: {
    width: '100%',
    borderBottom: '1px solid #e1e3e4',
    margin: '56px auto'
  },
  searchBar: {
    width: '50%',
    paddingTop: '1rem'
  },
  fileCount: {
    margin: '1.5rem auto 1rem 1rem',
    fontSize: '16px',
    fontWeight: 'normal',
    // fontFamily: 'OpenSans !important',
    color: '#686868'
  },
  marLeft: {
    padding: '0',
    marginLeft: '32px'
  },
  filesHeading: {
    fontSize: '1rem',
    color: '#1b1b1b',
    fontWeight: 'bold',
    marginBottom: '24px'
  },
  line: {
    height: '1px',
    backgroundColor: '#e1e3e4',
    margin: '2rem 0',
    width: '100%'
  },
  textUnderline: {
    borderBottom: '2px solid #5EAFFF',
    padding: '0 5px'
  },
  popHover: {
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.08);',
      cursor: 'pointer'
    },
    padding: '0.75rem',
    fontSize: '16px',
    color: '#1b1b1b',
    fontWeight: 'normal'
  },
  folderMenu: {
    position: 'absolute',
    top: '10px',
    right: '10px'
  }
};

class FileManagement extends React.Component{

  static propTypes = {
    trash: PropTypes.bool.isRequired,
    changeLocation: PropTypes.func.isRequired,
    changeTab: PropTypes.func.isRequired,
    currentLocation: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
  };

  state = {
    data: {},
    openCreate: false,
    hideBackdrop: true,
    uploadModal: false,
    searchModal: false,
    presentLocation: '/',
    presentFolder: '',
    folderChildren: [],
    flowStack: [],
    payload: [],
    folderName: '',
    openNew: false,
    trash: false,
    trashFiles: [],
    activeId: -1,
    activeIdTrash: -1,
    selected: [],
    moveModal: false,
    tempData: {},
    loading: true,
    folderTree: {},
    search: '',
    sortAlpha: false,
    sortTime: false,
    rowsPerPage: 30,
    page: 0,
    deleteFolderId: -1,
    deleteDirectory: false,
    displayPage: 0,
    displayTrashPage: 0,
    files: [],
    folders: [],
    openDropdown: false,
    deleteFolderModal: false,
    deleteForeverModal: false,
    firstTimeModal: false,
    mouseX: null,
    mouseY: null,
    downloadLoading: false,
    deleteMultipleFilesModal: false,
    capabilities: [],
  };

  controller = new window.AbortController();

  mainDecorators ={
    Toggle: (props) => {
      const { style } = props;
      return (
        <div style={style.base} className='display-none'>
          <div style={style.wrapper}>
            <ArrowIcon />
          </div>
        </div>
      );
    },
    Header: (props) => {
      return (
        <div className='display-header' style={props.style}>
          <FolderIcon className='display-inline blue-icons'/>
          <span className={`title-text file-mng-items ${this.state.trash ? '' : 'fontBold'}`}>{props.node.name}</span>
        </div>
      );
    },
  };

  decorators ={
    Toggle: (props) => {
      const { style } = props;
      return (
        <div style={style.base}>
          <div style={style.wrapper}>
            <ArrowIcon className='nodeArrowIcon'/>
          </div>
        </div>
      );
    },
    Header: (props) => {
      const {cursor} = this.state;
      // const {name, status_changed_at} = cursor;
      let active = false;
      if(JSON.stringify(cursor) === JSON.stringify(props.node) ){
          active = true
      }
      return (
        <div style={props.style} className='display-inline-block max-width-100'>
          <Tooltip title={props.node.name}><div className='display-flex'>
          <FolderIcon className='display-inline nodeFolderIcon'/>
          <span style={active ? { fontWeight: 'bold' } : { fontWeight: 'normal' }} className='nodeText'>&nbsp;{props.node.name}</span>
          </div></Tooltip>
        </div>
      );
    },
    Container: (props) => {
      const {style, decorators, terminal, onClick, node} = props;
      return (
        <div onClick={onClick}  style={node.active ? {...style.container} : {...style.link}} className='mainHeader'>
          {
            !terminal ?
              <React.Fragment>
                <decorators.Toggle {...props} style={style.toggle}/>
                <decorators.Header {...props} style={style.header}/>
              </React.Fragment> : null
          }
        </div>
      );
    }
  };

  receivedOnKeyDown = (event) => {
    if (event.keyCode === 27) {
      this.openUploadModal(false)
    }
  }

  static getDateString = (string) => {
    let date = new Date(string);
    return date.toString().slice(4, 24).substring(0,11);
  }

  componentDidMount = () => {
    const {trash,changeTab} = this.props;
    changeTab('document');
    const isTrue = (getFirstTime() === 'true');
    if(isTrue){
      this.setState({firstTimeModal: true})
    }
    if(trash){
      this.setState({trash}, this.getTrashFiles)
    } else {
      this.setState({trash}, this.getFiles)
    }
  
    // Attaching our org capabilities as classes so we can use css
    // to hide / show UI elements based on capability
    let capabilities = getUserOrgCapabilities()
    capabilities = JSON.parse(capabilities)
    capabilities = capabilities["document-extractor"]
    _.forEach(capabilities, (cap) => {
      document.body.classList.add(cap);
    })

    this.setState(prevState => ({...prevState, capabilities}))
  }

  getFilesRecursively = (children, files) => {
    _.forEach(children, (obj, i) => {
      if (obj.hasOwnProperty("children")){
        this.getFilesRecursively(obj.children, files)
      } else {
        if (obj.name !== "ai.nirveda"){
          const {id, name, uuid} = obj
          files.push({id, name, uuid})
        }
      }
    })
    return files
  }

  handleAllSelect() {
    let newSelected = [];
    const folders = [];
    let displayFiles = [];
    let displayFolders = [];
    this.state.trashFiles.forEach((child) => {
      folders.push(child);
      if(child.children){
        displayFolders.push(child);
      } else {
        displayFiles.push(child);
      }
    });
    displayFiles = displayFiles.filter(a=> a.name !== 'ai.nirveda');
    if (this.state.selected.length !== folders.length) {
      newSelected = folders.map((folder, index) => {
        return folder;
      });
    }
    this.setState(() => ({selected: newSelected, files: displayFiles, folders: displayFolders,
      displayPage: Math.floor((displayFiles.length + displayFolders.length)/this.state.rowsPerPage + 1)
    }))
  }

  handleAllSelectFiles = () => {
    let newSelected = [];
    let displayFiles = [];

    this.state.files.forEach((child) => {
      displayFiles.push(child);
    });

    displayFiles = displayFiles.filter(a=> a.name !== 'ai.nirveda');
    if (this.state.selected.length !== this.state.files.length) {
      newSelected = this.state.files.map((file, index) => {
        return file.uuid;
      });
    }
    this.setState(() => ({selected: newSelected, files: displayFiles,
      displayPage: Math.floor((displayFiles.length + this.state.folders.length)/this.state.rowsPerPage + 1)
    }))
  }

  isSelected = (name) => this.state.selected.indexOf(name) !== -1;

  getFileType = (folder, size)=>{
    if(folder.name.split('.').slice(-1)[0]==='pdf')
        {return <PictureAsPdfIcon style={{color: '#d3d3d3',height:'auto', width: `${size}`}}/>}
    else if(['jpg', 'png', 'gif'].includes(folder.name.split('.').slice(-1)[0]))
        {return <ImageIcon style={{color: '#d3d3d3',height:'auto', width: `${size}`}}/>}
    else {return <FileIcon style={{color: '#d3d3d3',height:'auto', width: `${size}`}}/>}
  }

  handleClick(name) {
    const selectedIndex = this.state.selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selected.slice(1));
    } else if (selectedIndex === this.state.selected.length - 1) {
      newSelected = newSelected.concat(this.state.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
          this.state.selected.slice(0, selectedIndex),
          this.state.selected.slice(selectedIndex + 1),
      );
    }
    this.setState({selected: newSelected});
  }

  getFiles = () => {
    const params = {
      org_id: getUserOrg(),
      trash: false,
    };
    this.setState({
      loading: true
    });
    let esc = encodeURIComponent;
    let query = Object.keys(params)
      .map(k => esc(k) + '=' + esc(params[k]))
      .join('&');
    GetRequest(URL.documents + '?' + query, (data)=> {
      if(data.payload){
        const list = this.arrangeIntoTree(data.payload);
        list[0].name = this.props.t('file-management.side-nav-txt.file-mgmt');
        list[0].uuid = 0;
        list[0].decorators = this.mainDecorators;
        list[0].status_changed_at = new Date();
        let displayFiles = [];
        let displayFolders = [];
        list[0].children.forEach((child) => {
          if(child.children){
            displayFolders.push(child);
          } else {
            displayFiles.push(child);
          }
        });
        displayFiles = displayFiles.filter(a=> a.name !== 'ai.nirveda');

        displayFolders = _.orderBy(displayFolders, ["id", "name"], ["desc", "asc"])
        displayFiles = _.orderBy(displayFiles, ["id", "name"], ["desc", "asc"])
        this.setState({data: list[0], tempData: {...list[0].children},
          flowStack: [],
          // presentLocation: '/',
          presentFolder: list[0].name,
          folderChildren: list[0].children,
          payload: data.payload,
          files: displayFiles,
          folders: displayFolders,
          displayPage: Math.floor((displayFiles.length + displayFolders.length)/this.state.rowsPerPage + 1)
        }, this.goToLocation);
      } else {
        this.setState({loading: false});
      }
    }, (err)=> {this.setState({loading: false});console.log(err)});
  }

  goToLocation = () => {
    const { currentLocation } = this.props;
    const { presentLocation } = this.state;
    if(presentLocation !== '/'){
      presentLocation.substring(1).split('/').forEach(a=> {
        this.handleFolder(a)
      })
    } else if(currentLocation !== '/'){
      currentLocation.substring(1).split('/').forEach(a=> {
        this.handleFolder(a)
    })
    }
    this.setState({loading: false});
  }

  arrangeIntoTree(data) {
    let tree = [];
    for (let i = 0; i < data.length; i++) {
      let path = [];
      let fileName = data[i].name;
      let finalFilename = '';

      if(data[i].path === null){
        data[i].path = ''
      }
      if(data[i].path !== "/"){
        path = data[i].path.split('/');
      }

      let currentLevel = tree;
      for (let j = 0; j < path.length; j++) {
        let part = path[j];
        let existingPath = findWhere(currentLevel, 'name', part);

        if (existingPath) {
          currentLevel = existingPath.children;
        } else {
          let newPart = {
            name: part,
            uuid: data[i].uuid,
            children: [],
            status_changed_at: data[i].status_changed_at,
          };
          currentLevel.push(newPart);
          currentLevel = newPart.children;
        }
      }
      let count = {};
      const nameArray = currentLevel.map(a=>a.name);
      nameArray.forEach(b => {
        if(b.substring(b.lastIndexOf('.')) === fileName.substring(fileName.lastIndexOf('.'))){
          if(b.substring(0,b.lastIndexOf('.')).includes(fileName.substring(0,fileName.lastIndexOf('.')))){
            if(Object.keys(count).includes(fileName.substring(0,fileName.lastIndexOf('.')))){
              count[fileName.substring(0,fileName.lastIndexOf('.'))] = count[fileName.substring(0,fileName.lastIndexOf('.'))] +1;
            } else {
              count[fileName.substring(0,fileName.lastIndexOf('.'))] = 1;
            }
          }
        }
      });
      if(count[fileName.substring(0,fileName.lastIndexOf('.'))] && count[fileName.substring(0,fileName.lastIndexOf('.'))] > 0 ){
        finalFilename = fileName.substring(0,fileName.lastIndexOf('.')) + '(' + count[fileName.substring(0,fileName.lastIndexOf('.'))] + ')' + fileName.substring(fileName.lastIndexOf('.'));
      } else {
        finalFilename = fileName
      }
      currentLevel.push({
        name: data[i].name === 'ai.nirveda' ? data[i].name : finalFilename,
        children: [],
        uuid: data[i].uuid,
        status_changed_at: data[i].status_changed_at
      })
    }
    makeLeafs(tree[0], this.decorators);
    return tree;

    function findWhere(array, key, value) {
      let t = 0;
      while (t < array.length && array[t][key] !== value) { t++; }

      if (t < array.length) {
        return array[t]
      } else {
        return false;
      }
    }

    function makeLeafs(tree, decorators){
      if(tree.children){
        if(tree.children.length === 0){
          tree.decorators = {Header: () => null};
          delete tree.children;
        } else {
          tree.decorators = decorators;
          tree.children.forEach( (child) => {
              makeLeafs(child, decorators);
            }
          )
        }
      }
      else return
    }
  }

  structureTree = (paths) => {
    const items = [];
    for(let i = 0, l = paths.length; i < l; i++) {
      let path = paths[i];
      let name = path[0];
      let rest = path.slice(1);
      let item = null;
      for(let j = 0, m = items.length; j < m; j++) {
        if(items[j].title === name) {
          item = items[j];
          break;
        }
      }
      if(item === null) {
        item = {title: name, submenu: []};
        items.push(item);
      }
      if(rest.length > 0) {
        item.submenu.push(this.structureTree([rest])[0]);
      }
    }
    return items;
  }

  pathToTree = (paths) => {
    paths = paths.filter(a => a !== '');
    paths = paths.map(function(path) { return path.substring(1).split('/'); });
    const items = this.structureTree(paths);
   this.setState({folderTree: items})
  }

  onToggle = (node, toggled) => {
    const {cursor, data} = this.state;
    if (cursor) {
      this.setState(() => ({cursor, active: false}));
    }
    node.active = true;
    if (node.children) {
      node.toggled = toggled;
    }

    if(true){
      let pathArray = find(data, node);
      let location = '';
      let temp = {...data};
      const stack = [];

      for(let i = 0; i< pathArray.length ; i++){
        stack.push(temp);
        temp = temp.children[pathArray[i]];
        location = location +'/'+ temp.name;
      }
      let displayFiles = [];
      const displayFolders = [];
      node.children.forEach((child) => {
        if(child.children){
          displayFolders.push(child);
        } else {
          displayFiles.push(child);
        }
      });
      displayFiles = displayFiles.filter(a=> a.name !== 'ai.nirveda');
      this.setState(() => ({
        presentFolder: node.name,
        presentLocation: location === '' ? '/' : location,
        flowStack: stack,
        folderChildren: node.children,
        files: displayFiles,
        folders: displayFolders,
        displayPage: Math.floor((displayFiles.length + displayFolders.length)/this.state.rowsPerPage + 1)
      }));
    }

    function find(struct, cmp) {
      if (struct.name === cmp.name) {
        return [];
      } else if (struct.children) {
        for (let i = 0; i < struct.children.length; i++) {
          let path = find(struct.children[i], cmp);
          if (path !== null) {
            path.unshift(i);
            return path;
          }
        }
      }
      return null;
    }
    this.setState(() => ({page: 0, cursor: node, data: Object.assign({}, data)}));
  }

  toggleCreate = () => {
    this.setState(prevState => ({...prevState, openCreate: !prevState.openCreate}));
  }

  handleClickAway = () => {
    this.setState({
      openCreate: false,
      openDropdown: false
    });
  }

  openSearchModal = (value) => {
    this.setState({
      searchModal: value,
      openCreate: false,
    });
  }

  openUploadModal = (value) => {
    this.setState({
      uploadModal: value,
      openCreate: false,
    }, () => !value && this.getFiles());
  }

  handleFolder = (name) => {
    const {presentFolder, folderChildren, flowStack} = this.state;
    const nextChildren = folderChildren.filter(folder => (folder.name === name));

    flowStack.push({
      name: presentFolder,
      children: folderChildren,
    });

    let path = '/';

    flowStack.forEach(name => {
      if (name.name !== 'File Management') {
        path = path + name.name + '/';
      }
    });

    let displayFiles = [];
    let displayFolders = [];

    try {
      path = path + nextChildren[0].name;

      nextChildren[0].children.forEach((child) => {
        if (child.children) {
          displayFolders.push(child);
        } else {
          displayFiles.push(child);
        }
      });
    } catch (e) {}

    displayFiles = displayFiles.filter(a => {
      if (a.hasOwnProperty("name")) {
        return a.name !== 'ai.nirveda'
      }
      return false
    });

    if (nextChildren.length > 0) {
      this.setState(prevState => ({
        ...prevState,
        cursor: nextChildren[0],
        presentFolder: nextChildren[0].name,
        folderChildren: nextChildren[0].children,
        flowStack: flowStack,
        presentLocation: path,
        page: 0,
        files: displayFiles,
        folders: displayFolders,
        displayPage: Math.floor((displayFiles.length + displayFolders.length) / this.state.rowsPerPage + 1)
      }))
    }
  }

  handleCrumb = (index) => {
    const { flowStack } = this.state;
    const tempStack = [...flowStack];
    const length = tempStack.length;
    if(index === flowStack.length){
      return
    }
    let folder = '';
    let child = [];
    for(let i = 0; i < (length - index); i++){
      folder = flowStack[flowStack.length -1].name;
      child = flowStack[flowStack.length -1].children;
      flowStack.pop();
    }

    let path = '/';

    flowStack.forEach(name => {
      if(name.name!=='File Management'){
        path = path + name.name+ '/';
      }
    });

    if(folder !== 'File Management'){
      path = path + folder;
    }

    this.setState(prevState => ({
      ...prevState,
      presentFolder:folder,
      folderChildren: child,
      flowStack: flowStack,
      presentLocation: path,
    }))
  }

  handleBack = () => {
    const { flowStack } = this.state;
    const folder = flowStack[flowStack.length -1].name;
    const child = flowStack[flowStack.length -1].children;
    flowStack.pop();

    let path = '/';
    flowStack.forEach(name => {
      if(name.name!=='File Management'){
        path = path + name.name+ '/';
      }
    });

    if(folder !== 'File Management'){
      path = path + folder;
    }

    this.setState(prevState => ({
      ...prevState,
      presentFolder:folder,
      folderChildren: child,
      flowStack: flowStack,
      presentLocation: path,
    }))
  }

  handleFile = (folder) => {
    const { changeLocation, history, changeTab } = this.props;
    const { presentLocation } = this.state;
    changeLocation(presentLocation);
    changeTab('result');
    history.push(`/results?id=${folder.uuid}`)
  }

  handleFiles = (directory) => {
    const { setResultViewerFiles }  = this.props;
    const files = this.getFilesRecursively(directory.children, [])
    setResultViewerFiles(files)
    console.log(files);
    if (files.length > 0)
      this.handleFile(files[0])
  }

  createFolder = () => {
    this.closeFolder();

    /***************************************************************************************/
    /* Below logic sets created folder in the state variable. Disabled for now.
    /* Set it by fetching it from api in the uploadTempFile function.
    /***************************************************************************************/

    // const { presentLocation, data, folderName } = this.state;
    // const object = {...data};
    // const array = presentLocation.substring(1).split('/');

    // if(array.length === 0 || array[0] === '') {
    //   if(Object.keys(data).length !== 0) {
    //     data.children.push({
    //     name: folderName,
    //     children: [],
    //       decorators: this.decorators,
    //   });
    //   this.setState({data, folderName: ''})
    //   } else {
    //     data.uuid = 0;
    //     data.name = 'File Management';
    //     data.children = [{ name: folderName, children: [], decorators: this.decorators,}];
    //     this.setState({data , folderName: '', presentFolder: data.name, folderChildren: data.children}) ;
    //   }
    //   this.setState({data, folderName: ''})
    // } else {
    //   let temp = object.children;
    //   for( let i = 0; i < array.length ; i++){
    //     let elem = array[i];
    //     temp = temp.find(a => a.name===elem).children;
    //   }
    //   temp.push({
    //     name: folderName,
    //     children: [],
    //     decorators: this.decorators,
    //   });
    //   this.setState({data: object, folderName: ''})
    // }

    const { presentLocation, folderName } = this.state;
    let tempLocation ;
    if(presentLocation === '/'){
      tempLocation = '/' + folderName
    } else {
      tempLocation = presentLocation + '/' + folderName
    }
    this.uploadTempFile(tempLocation)
  }

  uploadTempFile = (location) => {
    const payload= {
      document: location + '/ai.nirveda',
    };
    PostRequest(URL.getUploadUrl, this.controller.signal,payload,(data) => {
      data.payload["put_signed_url"] &&
      PutRequest(data.payload["put_signed_url"], this.controller.signal, new File([""], "ai.nirveda"),
        (respData) => {
          this.getFiles()
        },
          (err) => {
        console.log(err)
      },
          data.payload['headers'])
    }, (error) => {console.log(error)});
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(prevState => ({ ...prevState, [name]: value }));
    if(name === 'search' && this.state.page !== 0){
      this.setState({page: 0})
    }
  }

  handleSort = (name) => {
    this.setState(prevState => ({ ...prevState, [name]: !prevState[name] }));
  }

  openFolder = () => {
    const {trash} = this.state;
    this.setState({openNew: !trash, openCreate: false});
  }

  closeFolder = () => {
    this.setState({openNew: false});
  }

  showMoveModal = (value) => {
    this.setState({moveModal: value})
  }

  openDeleteFileModal = (folder, deleteDirectory) => {
    const delDir = !!deleteDirectory
    let uuid = delDir ? folder.children.filter(child => child.name === "ai.nirveda")[0].uuid : folder.uuid

    this.setState({
      activeId: -1,
      deleteFolderId: uuid,
      deleteFolderModal: true,
      deleteDirectory: delDir
    });
  }

  openMultipleDeleteFilesModal = () => {
    this.setState({deleteMultipleFilesModal: true});
  }

  closeMultipleDeleteFilesModal = () => {
    this.setState({deleteMultipleFilesModal: false})
  }

  closeDeleteModal = () => {
    this.setState({deleteFolderModal: false})
  }

  openFirstTimeModal = (uuid) => {
    this.setState({deleteFolderId: uuid, firstTimeModal: true, activeId: -1});
  }

  closeFirstTimeModal = () => {
    this.setState({firstTimeModal: false})
    localStorage.setItem('is-first-time', false);
  }

  openDeleteForeverModal = (uuid) => {
    this.setState({deleteFolderId: uuid, deleteForeverModal: true, activeIdTrash: -1});
  }

  closeDeleteForeverModal = () => {
    this.setState({deleteForeverModal: false})
  }

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
      displayPage: Math.floor((this.state.files.length + this.state.folders.length)/this.state.rowsPerPage + 1)
    })}

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      displayPage: Math.floor((this.state.files.length + this.state.folders.length)/parseInt(event.target.value, 10) + 1)
    })
  }

  handleChangeTrashPage = (event, newPage) => {
    this.setState({
      page: newPage,
      displayPage: Math.floor((this.state.trashFiles.length)/this.state.rowsPerPage + 1)
    })}

  handleChangeTrashRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      displayTrashPage: Math.floor((this.state.trashFiles.length)/parseInt(event.target.value, 10) + 1)
    })
  }

  moveToLocation = (e, location) => {

    e.stopPropagation();
    this.setState({loading: true});
    const { activeId} = this.state;

    log(activeId)
    log("////////////////////////////")
    const payload = {
      operation: "move_to",
      assign_to : "",
      move_to : location,
    };
    PatchRequestV2(URL.documents + '/' + activeId, this.controller.signal, payload, () => {
      this.getFiles();
      this.setActive(-1);
    }, (err) => {
      console.log(err);
      this.setState({loading: false});})
  }

  restore = ( file) => {
    this.setState({loading: true});
    const payload = {
      // uuid: file.uuid,
      operation: "move_to",
      assign_to : "",
      move_to : "restore",
    };
    PatchRequestV2(URL.documents + '/' + file.uuid, this.controller.signal, payload, () => {
      this.getTrashFiles();
      this.setActiveTrash(-1);
    }, (err) => { console.log(err);
      this.setState({loading: false});})
  }

  removeFile = (uuid) => {
    this.closeDeleteModal();
    this.setState({loading: true});
    const payload = {
      // uuid: uuid,
      operation: "softdelete",
      assign_to : "",
      move_to : "",
    };
    PatchRequestV2(URL.documents + '/' + uuid, this.controller.signal, payload, () => {
      this.getFiles();
      this.setActive(-1);
      // this.closeDeleteModal();
    }, (err) => {
      console.log(err);
      this.closeDeleteModal();
      this.setState({loading: false});
    })
  }

  removeFileTrash = (uuid) => {
    if (uuid < 0) {
      this.updateFiles('delete');
      return;
    }
    this.setState({loading: true});
    const payload = {
      // uuid: uuid,
      operation: "harddelete",
      assign_to : "",
      move_to : "",
    };
    this.closeDeleteForeverModal();
    PatchRequestV2(URL.documents + '/' + uuid, this.controller.signal, payload, (data) => {
      this.getTrashFiles();
      this.setActiveTrash(-1);
      // this.closeDeleteForeverModal();
    }, (err) => { console.log(err);
      this.setState({loading: false});
      this.closeDeleteForeverModal();
    })
  }

  dropDown(a, path) {
    if (typeof a.children === 'undefined') {
      return;
    }
    return (
      <React.Fragment key={a.name}>
        {a.children.length > 0 ? <React.Fragment>
            <li className="nested-li" onClick={(e) => this.moveToLocation(e, path + a.name)}>
              <div className="subDrop">
                <FolderIcon className="nodeFldIcon" />
                <a href>{a.name}</a>
              </div>
              <NestedDropdownMenu toggle={<button className="rightFloat"><ArrowIcon /></button>} direction="right">
                {a.children.map(b => this.dropDown(b, path + a.name + '/'))}
              </NestedDropdownMenu></li>
          </React.Fragment>
          :
          <li className="normal-li" onClick={(e) => this.moveToLocation(e, path + a.title)}>{a.title}</li>}
      </React.Fragment>
    );
  }

  dropDownMultiple(a, path) {
    if (typeof a.children === 'undefined') {
      return;
    }
    return (
      <React.Fragment key={a.name}>
        {a.children.length > 0 ? <React.Fragment>
            <li className="nested-li" onClick={(e) => this.moveMultipleFiles(e, path + a.name)}>
              <div className="subDrop">
                <FolderIcon className="nodeFldIcon" />
                <a href>{a.name}</a>
              </div>
              <NestedDropdownMenu toggle={<button className="rightFloat"><ArrowIcon /></button>} direction="right">
                {a.children.map(b => this.dropDownMultiple(b, path + a.name + '/'))}
              </NestedDropdownMenu></li>
          </React.Fragment>
          :
          <li className="normal-li" onClick={(e) => this.moveMultipleFiles(e, path + a.title)}>{a.title}</li>}
      </React.Fragment>
    );
  }

  dropDownDir(a, path) {
    if (typeof a.children === 'undefined') {
      return;
    }
    return (
      <React.Fragment key={a.name}>
        {a.children.length > 0 ? <React.Fragment>
            <li className="nested-li" onClick={(e) => this.moveToDirectory(e, path + a.name)}>
              <div className="subDrop">
                <FolderIcon className="nodeFldIcon" />
                <a href>{a.name}</a>
              </div>
              <NestedDropdownMenu toggle={<button className="rightFloat"><ArrowIcon /></button>} direction="right">
                {a.children.map(b => this.dropDownDir(b, path + a.name + '/'))}
              </NestedDropdownMenu></li>
          </React.Fragment>
          :
          <li className="normal-li" onClick={(e) => this.moveToDirectory(e, path)}>{a.title}</li>}
      </React.Fragment>
    );
  }

  moveToDirectory = (e, location) => {
    e.stopPropagation();
    this.setState({loading: true});
    const { activeId } = this.state;
    const payload = {
      operation: "move_to",
      assign_to : "",
      move_to : location,
    };
    PatchRequestV2(URL.directory + '/' + activeId + "/", this.controller.signal, payload, () => {
      this.getFiles();
      this.setActive(-1);
    }, (err) => {
      console.log(err);
      this.setState({loading: false});})
  }

  removeDirectory = (uuid) => {
    this.closeDeleteModal();
    this.setState({loading: true});
    const payload = {
      operation: 'softdelete',
      assign_to: '',
      move_to: ''
    };
    PatchRequestV2(URL.directory + '/' + uuid + '/', this.controller.signal, payload, () => {
      this.getFiles();
      this.setActive(-1);
      // this.closeDeleteModal();
    }, (err) => {
      console.log(err);
      this.closeDeleteModal();
      this.setState({loading: false});
    });
  }

  downloadFile = (file) => {
    const documentId = file.uuid
    const fileType = "application/" + Utils.getFileType(file.name)
    const fileName = file.name

    this.setState(prevState => ({...prevState, downloadLoading: true}))
    GetRequest(URL.getFileUrl + '/' + documentId, (data)=> {
      const bytes = Utils.base64ToArrayBuffer(data.payload.file_obj);
      const currentBlob = new Blob([bytes], {type: fileType});
      const blobUrl = window.URL.createObjectURL(currentBlob)
      if (blobUrl) {
        const link = document.createElement('a');
        link.href = blobUrl
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
      this.setState(prevState => ({...prevState, downloadLoading: false}))
    },(error)=> {
      this.setState(prevState => ({...prevState, downloadLoading: false}))
    });
  }

  downloadFiles = (directory) => {
    let files = []

    if (directory instanceof Array)
      files = directory
    else
      files = this.getFilesRecursively(directory.children, [])

    _.forEach(files, (file => {
      this.downloadFile(file)
    }))
  }

  getDownloadDiv = (loading, folder) => {
    const {t, classes} = this.props

    return (
      <div className={classes.modalText} onClick={() => this.downloadFiles(folder)}>
        <span style={{"float": "left"}}>
          {t('file-management.content.file-mgmt.doc-content-txt.doc-file-op-drop.download')}
        </span>
        { loading
          ? <div className={classes.downLoader}>
              <CircularProgress className='loader' style={{color: '#5fafff'}} size={15} />
            </div>
          : null
        }
      </div>
    )
  }

  downloadMultipleFiles = () => {
    const {selected, files} = this.state
    const selectedFiles = files.filter(obj => selected.includes(obj.uuid))
    this.downloadFiles(selectedFiles)
  }

  compareMultipleFiles = (directory=null) => {
    let idsCommaSeparated = ""
    const {selected, files} = this.state
    let selectedFiles = []

    if (!directory.hasOwnProperty('children')){
      selectedFiles = files.filter(obj => selected.includes(obj.uuid))
      selectedFiles.map((file) => {
        idsCommaSeparated+= ","+file.uuid;
        return true
      })      
    } else {
      selectedFiles = directory.children
      selectedFiles.map((file) => {
        if(file.name !== 'ai.nirveda'){
          idsCommaSeparated+= ","+file.uuid;
        }
        return true
      })
    }

    idsCommaSeparated = idsCommaSeparated.slice(1, idsCommaSeparated.length)
    // console.log("List of ids", idsCommaSeparated)
    const {history} = this.props
    history.push(`/results?id=${idsCommaSeparated}`)
  }


  removeMultipleFiles = () => {
    this.setState({openDropdown: false, loading: true});
    const selectedFiles = this.state.selected;

    this.closeMultipleDeleteFilesModal();
    const payload = {
      operation: 'softdelete',
      ids: selectedFiles,
      assign_to: '',
      move_to: ''
    };
    PostRequest(URL.documents, this.controller.signal, payload, () => {
      this.getFiles();
    }, (err) => {
      console.log(err);
      this.closeMultipleDeleteFilesModal();
      this.setState({loading: false});
    });
  }

  moveMultipleFiles = (e, location) => {
    this.setState({openDropdown: false, loading: true});
    this.showMoveModal('false');
    const selectedFiles = this.state.selected;

    e.stopPropagation();
    const payload = {
      operation: "move_to",
      assign_to : "",
      move_to : location,
      ids: selectedFiles
    };
    PostRequest(URL.documents, this.controller.signal, payload, () => {
      this.setState(prevState => ({selected: []}))
      this.getFiles();
    }, (err) => {
      console.log(err);
      this.setState({loading: false});})
  }

  displayCompare = () => {
    const {capabilities} = this.state

    // Return true for "tax_classifier" capability
    return (capabilities.length > 0 && capabilities.indexOf("tax_classifier") > -1)
  }

  displayListOfDocuments(children) {
    const { t, classes } = this.props;
    const { data, activeId, payload, sortAlpha, sortTime, page, rowsPerPage, openDropdown, moveModal, downloadLoading} = this.state;

    let files = [];
    const folders = [];
    children.forEach((child) => {
      if(child.children){
        folders.push(child)
      } else {
        files.push(child)
      }
    });
    files = files.filter(a=> a.name !== 'ai.nirveda');
    // this.setState({files: files, folders: folders});
    sortTime && files.reverse();
    sortAlpha && folders.reverse();
    // sortTime && folders.reverse();
    return(
        <React.Fragment>
          {(files.length === 0 && folders.length === 0)
            ? <div className="iconBox">
                <InsertDriveFileIcon style={{color: '#959daf', fontSize: '5rem', textAlign: 'center',}}  /><br />
                Looks like you don't have any documents here.<br />Click <b>"New"</b> to upload new documents.
              </div>
            : <React.Fragment>
                <div style={{display: 'flex'}}>
                <div className={classes.filesHeading}>{t('file-management.content.file-mgmt.doc-content-txt.files')}</div>
                <div className={classes.fileCount} style={{margin: '0 1rem', paddingTop: '2px'}}>{files.length}</div>
              </div>
                <Table className={classes.table} style={{tableLayout: 'fixed'}}>
                <TableHead>
                  <TableRow className={classes.tableHeadBorder}>
                    <TableCell padding="checkbox" style={{width: '20%'}} className={classes.tableHead}>
                      <div style={{display: 'flex'}}>
                        <Checkbox
                          className={`${classes.marLeft} `}
                          onClick={event => this.handleAllSelectFiles()}
                          // checked={this.state.selected.length !== 0 && this.state.selected.length === files.length + folders.length}
                          // indeterminate={this.state.selected.length > 0 && this.state.selected.length < files.length + folders.length}
                          checked={this.state.selected.length !== 0 && this.state.selected.length === files.length}
                          indeterminate={this.state.selected.length > 0 && this.state.selected.length < files.length}
                          style={{color: this.state.selected.length > 0 ? '#959daf' : '',}}
                        />

                        <div style={{position: 'relative', display: 'flequeueScrollx'}}>
                          <ArrowDownIcon onClick={event => this.toggleDropdown()} style={{cursor: 'pointer'}} />
                          {openDropdown ? (
                            <ClickAwayListener onClickAway={this.handleClickAway}>
                              <div>
                                <Paper elevation={8} className={classes.dropdownContent}>
                                  <div
                                    className={classes.popHover} onClick={() => this.showMoveModal(true)} style={{
                                    textAlign: 'left',
                                    display: 'flex'
                                  }}>
                                    <FolderIcon style={{color: '#959daf', marginRight: '6px'}} />&nbsp;
                                    <DropdownMenu
                                      isOpen={moveModal}
                                      close={() => null}
                                      menuAlign="left"
                                      toggle={
                                        <div className={classes.modalText}>
                                          {t('file-management.content.file-mgmt.doc-content-txt.doc-file-op-drop.move')}
                                          <ArrowIcon className="moveIcon" style={{float: 'right'}} />
                                        </div>}
                                    >
                                      <li className="normal-li" onClick={e => this.moveMultipleFiles(e, '')}>
                                        {t('file-management.home')}
                                      </li>
                                      {data.children.map(a => this.dropDownMultiple(a, '/'))}
                                    </DropdownMenu>
                                  </div>

                                  <div
                                    className={`${classes.modalItem} ${classes.popHover}`} style={{
                                    textAlign: 'left',
                                    display: 'flex'
                                  }}>
                                    <DownloadIcon style={{color: '#959daf'}} />

                                    <div className={classes.modalText} onClick={this.downloadMultipleFiles}>
                                    <span style={{marginLeft: '-8px'}}>
                                      {t('file-management.content.file-mgmt.doc-content-txt.doc-file-op-drop.download')}
                                    </span>
                                      {downloadLoading
                                        ? <div className={classes.downLoader}>
                                          <CircularProgress className='loader' style={{color: '#5fafff'}} size={15} />
                                        </div>
                                        : null
                                      }
                                    </div>
                                  </div>
                                </Paper>
                              </div>
                            </ClickAwayListener>) : null}
                        </div>
                        <Tooltip title="Delete">
                          <DeleteIcon
                            onClick={this.openMultipleDeleteFilesModal} 
                            style={{
                            display: this.state.selected.length > 0 ? 'flex' : 'none',
                            color: '#1e2a5a',
                            marginTop: '2px',
                            marginRight: '10px',
                            cursor: 'pointer'
                          }} />
                        </Tooltip>
                        {!this.displayCompare() &&
                          <Tooltip title="Summary" className={`optional property_search`}>
                            <SubjectIcon
                              onClick={this.compareMultipleFiles}
                              style={{
                                display: this.state.selected.length > 0 ? 'flex' : 'none',
                                color: '#1e2a5a',
                                marginTop: '2px',
                                marginRight: '10px',
                                cursor: 'pointer'
                              }} />
                          </Tooltip>
                        }
                        {this.displayCompare() &&
                          <Tooltip title="Compare" className={`optional tax_classifier`}>
                            <DoneAllIcon
                              onClick={this.compareMultipleFiles}
                              style={{
                                display: this.state.selected.length > 0 ? 'flex' : 'none',
                                color: '#1e2a5a',
                                marginTop: '2px',
                                marginRight: '10px',
                                cursor: 'pointer'
                              }} />
                          </Tooltip>
                        }
                      </div>
                    </TableCell>
                    <TableCell className={classes.tableHead} style={{width: '30%', textAlign: 'left'}}>
                      <div className='sortDiv'>
                        <div onClick={() => this.handleSort('sortAlpha')} className='sortItem'>
                          <div className={classes.textUnderline} style={{fontSize: '15px'}}>{t('table.file-name')}</div>
                          <div className='display-flex'>{sortAlpha ? <ArrowUp className='sortIcon' /> :
                            <ArrowDown className='sortIcon' />}</div>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell className={classes.tableHead} style={{width: '30%', textAlign: 'center'}}>
                      <div className='sortDiv centerDiv'>
                        <div onClick={() => this.handleSort('sortTime')} className='sortItem' style={{margin: '0 auto'}}>
                          <div className={classes.textUnderline} style={{fontSize: '15px'}}>{t('table.last-mod')}</div>
                          <div className='display-flex'>{sortTime ? <ArrowUp className='sortIcon' /> :
                            <ArrowDown className='sortIcon' />}</div>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                {folders.length > 0 ? (<TableBody>
                  {folders.map((folder, index) => {
                      const isItemSelected = this.isSelected(folder.name);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          className={classes.rowBorderBottom}
                          // hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={folder.name + index}
                          selected={isItemSelected}
                          style={{height: '60px'}}>
                          <TableCell padding="checkbox" style={{width: '10%'}} className={`${classes.tableHead} ${classes.borderNone} ${classes.alignLeft}`}>
                            <Checkbox
                              disabled
                              className={classes.marLeft}
                              style={{color: isItemSelected ? '#959daf' : '',}}
                              checked={isItemSelected}
                              inputProps={{'aria-labelledby': labelId}}
                              onClick={event => this.handleClick(folder.uuid)}
                            />
                          </TableCell>
                          <TableCell
                            className={`${classes.fileName} ${classes.borderNone}`}
                            onClick={() => this.handleFolder(folder.name)}>
                            <FolderIcon style={{color: '#d3d3d3'}} />&nbsp;<span
                            className={classes.longFileName}>{folder.name}</span></TableCell>
                          <TableCell
                            style={{width: '20%'}}
                            onClick={() => this.handleFolder(folder.name)}
                            className={`${classes.tableBody} ${classes.borderNone}`}>{FileManagement.getDateString(folder.status_changed_at)}
                          </TableCell>
                          <TableCell style={{width: '10%'}} className={`${classes.tableBody} ${classes.borderNone}`}>
                          <div className='position-relative'>
                            <IconButton  onClick={() => this.setActive(folder)}>
                              <MoreHorizIcon style={{color: '#959daf'}}/>
                              </IconButton>
                              {
                                activeId === this.getDirectoryId(folder) ?
                                  <ClickAwayListener onClickAway={() => this.setActive(-1)}>
                                    <div>
                                      <Paper className={classes.optionsFolders}>

                                        {!this.displayCompare() &&
                                        <div className={`${classes.modalItem} optional property_search`} onClick={() => this.compareMultipleFiles(folder)}>
                                          <SubjectIcon style={{color: '#959daf', marginRight: '6px'}} />&nbsp;
                                          <div className={classes.modalText}>
                                            {t('file-management.content.file-mgmt.doc-content-txt.doc-file-op-drop.summary')}
                                          </div>
                                        </div>
                                        }

                                        {this.displayCompare() &&
                                          <div className={`${classes.modalItem} optional tax_classifier`} onClick={() => this.compareMultipleFiles(folder)}>
                                            <SubjectIcon style={{color: '#959daf', marginRight: '6px'}} />&nbsp;
                                            <div className={classes.modalText}>
                                              {t('file-management.content.file-mgmt.doc-content-txt.doc-file-op-drop.compare')}
                                            </div>
                                          </div>
                                        }

                                        <div className={`${classes.modalItem}`} onClick={() => this.handleFiles(folder)}>
                                          <EyeIcon style={{color: '#959daf', marginRight: '6px'}} />&nbsp;
                                          <div className={classes.modalText}>
                                            {t('file-management.content.file-mgmt.doc-content-txt.doc-file-op-drop.view')}
                                          </div>
                                        </div>


                                        <div className={classes.modalItem}>
                                          <DownloadIcon style={{color: '#959daf', marginRight: '6px'}} />&nbsp;
                                          {this.getDownloadDiv(downloadLoading, folder)}
                                        </div>

                                        <div
                                          className={`${classes.modalItem}`} style={{display: 'flex'}}
                                          onClick={() => this.openDeleteFileModal(folder, true)}>
                                          <DeleteIcon style={{color: '#959daf', marginRight: '6px'}} />&nbsp;
                                          <div className={classes.modalText}>
                                            {t('file-management.content.file-mgmt.doc-content-txt.doc-file-op-drop.remove')}
                                          </div>
                                        </div>
                                      </Paper>
                                    </div>
                                  </ClickAwayListener> : null
                              }
                              </div>
                          </TableCell>
                        </TableRow>
                      )
                    }
                  )}
                </TableBody>) : null}
                {files.length > 0 ? (<TableBody>
                  {files.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((folder, index) => {
                    // LIST VIEW
                      const isItemSelected = this.isSelected(folder.uuid);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          className={`${classes.rowBorderBottom}`}
                          // hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={folder.uuid}
                          selected={isItemSelected}
                          style={{
                            height: '60px',
                            borderBottom: (index === this.state.rowsPerPage - 1) || (folder.uuid === files[files.length - 1].uuid) ? 'none' : ''
                          }}>
                          <TableCell padding="checkbox" style={{width: '10%'}} className={`${classes.tableHead} ${classes.borderNone} ${classes.alignLeft}`}>
                            <Checkbox
                              className={classes.marLeft}
                              style={{color: isItemSelected ? '#959daf' : '',}}
                              checked={isItemSelected}
                              inputProps={{'aria-labelledby': labelId}}
                              onClick={event => this.handleClick(folder.uuid)}
                            />
                          </TableCell>
                          <TableCell
                            component="th" className={`${classes.fileName} ${classes.borderNone}`}
                            onClick={() => this.handleFile(folder)}>
                            {this.getFileType(folder, '20px')} {/* File Management List View */}
                            &nbsp;<span style={{marginTop: 'auto', marginLeft: '8px'}}>{folder.name}</span></TableCell>
                          <TableCell
                            style={{width: '20%'}} className={`${classes.tableBody} ${classes.borderNone}`}
                            onClick={() => this.handleFile(folder)}>{FileManagement.getDateString(folder.status_changed_at)}</TableCell>
                          <TableCell style={{width: '10%'}} className={`${classes.tableBody} ${classes.borderNone}`} key={folder.uuid}>
                            <div className='position-relative'>
                              <div className='position-relative'>
                              <IconButton onClick={() => this.setActive(folder.uuid)}>
                                <MoreHorizIcon style={{color: '#959daf'}} />
                              </IconButton>
                                {
                                  activeId === folder.uuid ?
                                    <ClickAwayListener onClickAway={() => this.setActive(-1)}>
                                      <div>
                                        <Paper className={classes.optionsFiles} style={{width: 'auto'}} elevation={8}>
                                          <div className={`${classes.modalItem} optional`} style={{display: 'flex'}} onClick={() => this.handleFile(folder)}>
                                            <EyeIcon style={{color: '#959daf', marginRight: '16px'}} />&nbsp;
                                            <a className={classes.modalText} style={{margin: 'auto 0 auto 4px'}}>{t('file-management.content.file-mgmt.doc-content-txt.doc-file-op-drop.view')}</a> {/* eslint-disable-line */}
                                          </div>
                                          <div className={classes.modalItem} style={{display: 'flex'}}>
                                            <DownloadIcon style={{color: '#959daf', marginRight: '16px'}} />&nbsp;
                                            <a className={classes.modalText} style={{margin: 'auto 0 auto 4px'}} download href={payload.find(a => a.uuid === folder.uuid)["storage_path_to_get"]}>
                                              {t('file-management.content.file-mgmt.doc-content-txt.doc-file-op-drop.download')}</a>
                                          </div>
                                          <div
                                            className={`${classes.modalItem} ${payload.find(a => a.uuid === folder.uuid)['document_status'] === 'Processing' ? '' : ''}`} style={{display: 'flex'}}
                                            onClick={() => payload.find(a => a.uuid === folder.uuid)['document_status'] === 'Processing' ? this.openDeleteFileModal(folder.uuid) : this.openDeleteFileModal(folder.uuid)}>
                                            <DeleteIcon style={{color: '#959daf', marginRight: '16px'}} />&nbsp;
                                            <a className={classes.modalText} style={{margin: 'auto 0 auto 4px'}}>{t('file-management.content.file-mgmt.doc-content-txt.doc-file-op-drop.remove')}</a> {/* eslint-disable-line */}
                                          </div>
                                        </Paper></div>
                                    </ClickAwayListener> : null
                                }
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      )
                    }
                  )}
                </TableBody>) : null}
                {
                  files.length > 0 ?
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[10, 20, 30]}
                          colSpan={4}
                          count={files.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          labelRowsPerPage={t('file-management.per-page')}
                          labelDisplayedRows={({page}) => `${page + 1} of ${this.state.displayPage}`}
                          SelectProps={{
                            inputProps: {'aria-label': 'Results per page'},
                            native: true,
                          }}
                          className={classes.borderNone}
                          onChangePage={this.handleChangePage}
                          onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                      </TableRow>
                    </TableFooter> : null
                }
              </Table>
              </React.Fragment>
          }
        </React.Fragment>
    )
  }

  displayDocuments(children){
    const {t, classes} = this.props;
    const { activeId, payload , moveModal, data, sortAlpha, sortTime, page, rowsPerPage, downloadLoading} = this.state;

    let files = [];
    let folders = [];
    children.forEach((child) => {
      if(child.children){
        folders.push(child)
      } else {
        files.push(child)
      }
    });
    let byName = folders.slice(0);
    byName.sort(function(a,b) {
      let x = a.name.toLowerCase();
      let y = b.name.toLowerCase();
      return x < y ? -1 : x > y ? 1 : 0;
    });
    files = files.filter(a=> a.name !== 'ai.nirveda');
    folders = byName.slice(0);

    files = _.orderBy(files, ["id", "name"], ["desc", "asc"])
    folders = _.orderBy(folders, ["id", "name"], ["desc", "asc"])

    sortTime && files.reverse();
    sortAlpha && folders.reverse();
    return(
      <React.Fragment>
        {(files.length === 0 && folders.length === 0)
          ? <div className="emptyBox">
              <InsertDriveFileIcon style={{color: '#959daf', fontSize: '5rem', textAlign: 'center',}}  /><br />
              Looks like you don't have any documents here.<br />Click <b>"New"</b> to upload new documents <br /> or <b>"Move"</b> from Files.
            </div>
          : <React.Fragment>
              {
                folders.length > 0 ?
                  <React.Fragment>
                    <div className='sortDiv'>
                    <Typography variant='subtitle1' className={classes.folderArea}><b>{t('file-management.content.file-mgmt.doc-content-txt.folders')}</b></Typography>
                      <div onClick={() => this.handleSort('sortAlpha')} className='sortItem' >
                        <div className={classes.textUnderline}>{t('file-management.apha-sort')}</div>
                      </div>
                    </div>
                    <Grid
                      className={folders.length === 0 ? 'footerFixed fiveFiles': 'fiveFiles'}
                      container
                      spacing={10}
                      direction="row"
                      justify="flex-start"
                      alignItems="center">
                      {folders.map((folder, index) =>
                        <Grid item xs={6} sm={4} lg={3} key={folder.name + index}>
                          <div className={classes.itemFolder}>
                            <div>
                            <IconButton  onClick={() => this.setActive(folder)} className={classes.folderMenu}>
                              <MoreVertIcon style={{color: '#959daf'}}  className="margin"/>
                              </IconButton>
                              {
                                activeId === this.getDirectoryId(folder) ?
                                  <ClickAwayListener onClickAway={() => this.setActive(-1)}>
                                    <div>
                                      <Paper className={classes.optionsFiles}>

                                        {!this.displayCompare() &&
                                        <div className={`${classes.modalItem} optional property_search`} onClick={() => this.compareMultipleFiles(folder)}>
                                          <SubjectIcon style={{color: '#959daf', marginRight: '6px'}} />&nbsp;
                                          <div className={classes.modalText}>
                                            {t('file-management.content.file-mgmt.doc-content-txt.doc-file-op-drop.summary')}
                                          </div>
                                        </div>
                                        }

                                        {this.displayCompare() &&
                                          <div className={`${classes.modalItem} optional tax_classifier`} onClick={() => this.compareMultipleFiles(folder)}>
                                            <SubjectIcon style={{color: '#959daf', marginRight: '6px'}} />&nbsp;
                                            <div className={classes.modalText}>
                                              {t('file-management.content.file-mgmt.doc-content-txt.doc-file-op-drop.compare')}
                                            </div>
                                          </div>
                                        }
                                        <div className={`${classes.modalItem}`} onClick={() => this.handleFiles(folder)}>
                                          <EyeIcon style={{color: '#959daf', marginRight: '6px'}} />&nbsp;
                                          <div className={classes.modalText}>
                                            {t('file-management.content.file-mgmt.doc-content-txt.doc-file-op-drop.view')}
                                          </div>
                                        </div>

                                        <div className={classes.modalItem} onClick={() => this.showMoveModal(true)}>
                                          <FolderIcon style={{color: '#959daf', marginRight: '6px'}} />&nbsp;
                                          <DropdownMenu
                                            isOpen={moveModal}
                                            close={() => null}
                                            menuAlign="left"
                                            toggle={
                                              <div className={classes.modalText}>
                                                {t('file-management.content.file-mgmt.doc-content-txt.doc-file-op-drop.move')}
                                                <ArrowIcon className="moveIcon" />
                                              </div>}
                                          >
                                            <li className="normal-li" onClick={e => this.moveToDirectory(e, '')}>
                                              {t('file-management.home')}
                                            </li>
                                            { data.children.map(a => this.dropDownDir(a, '/')) }
                                          </DropdownMenu>
                                        </div>

                                        <div className={classes.modalItem}>
                                          <DownloadIcon style={{color: '#959daf', marginRight: '6px'}} />&nbsp;
                                          {this.getDownloadDiv(downloadLoading, folder)}
                                        </div>

                                        <div
                                          className={`${classes.modalItem}`} style={{display: 'flex'}}
                                          onClick={() => this.openDeleteFileModal(folder, true)}>
                                          <DeleteIcon style={{color: '#959daf', marginRight: '6px'}} />&nbsp;
                                          <div className={classes.modalText}>
                                            {t('file-management.content.file-mgmt.doc-content-txt.doc-file-op-drop.remove')}
                                          </div>
                                        </div>
                                      </Paper>
                                    </div>
                                  </ClickAwayListener> : null
                              }
                            </div>
                            <div onClick={() => this.handleFolder(folder.name)} style={{padding: '3rem 0', width: '100%', textAlign: 'center'}}>
                              <FolderIcon style={{color: '#d3d3d3', marginRight: '12px'}}/>
                              <span className={classes.textFolder}>{folder.name}</span>
                            </div>
                          </div>
                        </Grid>
                      )}
                    </Grid>
                    <div className={classes.separatorBorder} />
                  </React.Fragment> :
                  null
              }
              {
                files.length > 0 ?
                  <React.Fragment>
                    <div className='sortDiv' >
                      <Typography variant='subtitle1' className={classes.folderArea}>
                        <b>{t('file-management.content.file-mgmt.doc-content-txt.files')}</b>
                      </Typography>
                      <span className={classes.fileCount}> {files.length}</span>
                      <div onClick={() => this.handleSort('sortTime')} className='sortItem'>
                      <div className={classes.textUnderline}>{t('file-management.most-recent-sort')}</div>
                      <div className='display-flex'>{sortTime ? <ArrowUp className='sortIcon'/> : <ArrowDown className='sortIcon'/>}</div></div>
                    </div>
                    <Grid
                      className={folders.length === 0 ? 'footerFixed fiveFiles': 'fiveFiles'}
                      container
                      spacing={7}
                      direction="row"
                      justify="flex-start"
                      alignItems="center">
                      {files.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((folder) =>
                        <Grid item xs={6} sm={4} lg={2} key={folder.uuid}>
                          <div className={`${classes.itemFile} padding-10`} >
                            <div>
                              <div className='top-right-drop-icon'>
                              <IconButton onClick={() => this.setActive(folder.uuid)} >
                              <MoreVertIcon style={{color: '#959daf'}}  className="margin"/>
                              </IconButton>
                              {
                                activeId === folder.uuid ?
                                  <ClickAwayListener onClickAway={() => this.setActive(-1)}>
                                    <div>
                                    <Paper className={classes.optionsFiles}>
                                      <div  className={classes.modalItem} id="view" onClick={() => this.handleFile(folder)}>
                                        <EyeIcon style={{color: '#959daf', marginRight: '6px'}} />&nbsp;
                                        <div className={classes.modalText}>{t('file-management.content.file-mgmt.doc-content-txt.doc-file-op-drop.view')}</div>
                                      </div>
                                      <div  className={classes.modalItem} id="move" onClick={() => this.showMoveModal(true)}>
                                        <FolderIcon style={{color: '#959daf',marginRight: '6px'}} />&nbsp;
                                        <DropdownMenu
                                            isOpen={moveModal}
                                            close={() => null}
                                            toggle={<div className={classes.modalText}>{t('file-management.content.file-mgmt.doc-content-txt.doc-file-op-drop.move')}<ArrowIcon className='moveIcon'/></div>}
                                            menuAlign='left'
                                        >
                                          <li className='normal-li' onClick={(e)=> this.moveToLocation(e, '')}>{t('file-management.home')}</li>
                                          {
                                            data.children.map(a=> this.dropDown(a, '/'))
                                          }
                                        </DropdownMenu>
                                      </div>
                                      <div className={classes.modalItem} id="download">
                                        <DownloadIcon style={{color: '#959daf',marginRight: '6px'}} />&nbsp;
                                        {this.getDownloadDiv(downloadLoading, [folder])}
                                      </div>
                                      <div className={`${classes.modalItem} ${payload.find(a => a.uuid === folder.uuid)['document_status'] === 'Processing' ? '' : ''}`} id="delete" style={{display: 'flex'}}
                                        onClick={() => payload.find(a => a.uuid === folder.uuid)['document_status'] === 'Processing' ? this.openDeleteFileModal(folder) : this.openDeleteFileModal(folder)}>
                                          <DeleteIcon style={{color: '#959daf',marginRight: '6px'}}  />&nbsp;
                                          <div className={classes.modalText} >{t('file-management.content.file-mgmt.doc-content-txt.doc-file-op-drop.remove')}</div>
                                      </div>
                                      </Paper>
                                    </div>
                                  </ClickAwayListener>: null
                                }
                                </div>
                            </div>
                            <Grid container justify='center' className="cursor-pointer" onClick={() => this.handleFile(folder)}>
                              {this.getFileType(folder, '70px')}{/* File Management Grid View */}
                              </Grid>
                            <Tooltip title={folder.name}>
                            <div className='file-name'>
                              <b>{folder.name}</b>
                            </div>
                            </Tooltip>
                            <div className='file-name font-10'>{(payload.find(a => a.uuid === folder.uuid).size/(1024*1024)).toFixed(2)}&nbsp;MB</div>
                          </div>
                        </Grid>
                      )}
                    </Grid>
                    <TablePagination
                      rowsPerPageOptions={[10, 20, 30]}
                      colSpan={4}
                      count={files.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      labelRowsPerPage={t('file-management.per-page')}
                      labelDisplayedRows={({ page})=> `${page + 1} of ${this.state.displayPage}`}
                      SelectProps={{
                        inputProps: { 'aria-label': 'Results per page' },
                        native: true,
                      }}
                      className={classes.borderNone}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      component='div'/>
                  </React.Fragment> :
                  null
              }
            </React.Fragment>
        }
      </React.Fragment>
    )
  }

  setActive = (folder) => {
    let uuid = folder

    if (typeof folder === 'object')
      uuid = this.getDirectoryId(folder)

    console.log(uuid);

    const { activeId } = this.state;
    this.setState({
      activeId: activeId === uuid ? -1 : uuid,
      moveModal: false
    });
  }

  setActiveTrash = (uuid) => {
    const { activeIdTrash } = this.state;
    this.setState({activeIdTrash: activeIdTrash === uuid ? -1 : uuid});
  }

  showTrash = (value) => {
    const {history} = this.props;
    history.push(value ? '/trash' : '/files')
  }

  getDirectoryId = (directory) => {
    let uuid = -10
    if (typeof directory === 'object' && directory.hasOwnProperty('children')) {
      _.forEach(directory.children, (obj, i) => {
        if (obj.name === 'ai.nirveda')
          uuid = obj.uuid;
      });
    }
    return uuid
  }

  getTrashFiles = () => {
    const params = {
      org_id: getUserOrg(),
    };
    this.setState({
      loading: true
    })
    let esc = encodeURIComponent;
    let query = Object.keys(params)
      .map(k => esc(k) + '=' + esc(params[k]))
      .join('&');
    GetRequest(URL.getTrashUrl + '?' + query, (data)=> {
      let trashFiles = [];
      if(typeof data.payload !== "undefined"){
        trashFiles = data.payload.filter(a=> a.name !== 'ai.nirveda')
      }
      this.setState({ trashFiles , loading: false,
        displayTrashPage: Math.floor((trashFiles.length)/this.state.rowsPerPage + 1)
      });
    }, (err)=> {
      this.setState({loading: false});
      console.log(err)});
  };

  toggleDropdown = () => {
    this.setState(prevState => ({...prevState, openDropdown: !prevState.openDropdown}));
  }

  updateFiles(operation) {
    const {selected} = this.state;
    this.setState({openDropdown: false});
    if (operation === 'delete') {
      selected.forEach((a) => {
        this.removeFileTrash(a.uuid);
      });
    } else {
      selected.forEach((a) => {
        this.restore(a);
      });
    }
    this.setState({selected: []});
  }

  closeListDropdown = () => {
    this.setState({openDropdown: false})
  }

  tempImportTest = () => {
    let query = "task_id=d56a92f2-39d1-44cc-ad42-ea78a5b5da7b&collection=asdf"
    GetRequest(URL.importFiles + "?" + query,(response) => {
      log(response)
    }, (error) => {
      toast.error(error.message.detail)
      // this.setState({loading: false})
    });
  }


  render() {
    const { t, classes, changeTab } = this.props;
    const { data, trash, activeIdTrash, trashFiles, openCreate, search, deleteFolderId, page, rowsPerPage, sortAlpha,
      sortTime, uploadModal, searchModal, presentLocation, folderChildren, folderName , openNew, loading, firstTimeModal,
      deleteFolderModal, deleteForeverModal, deleteMultipleFilesModal, openDropdown} = this.state;


    let searchFilter = folderChildren;
    if(search.length > 2){
      searchFilter = [];
      folderChildren.forEach(f => {
        if((f["name"].toLowerCase().indexOf(search.toLowerCase()) > -1)){
          searchFilter.push(f);
        }
      })
    }

    return(
      <React.Fragment>
        <Grid item xs={12} sm={2} className={classes.menu}>
          <div className="file-menu-div">
            <div className={classes.createDiv}>
              <Button variant="contained" disabled={trash} size='medium'
                className={`${classes.createButton} createButton`}
                onClick={() => this.toggleCreate()}
                // onClick={() => this.tempImportTest()}
                // ccc
                // propdebug
              >
                {t('file-management.create-btn-txt')}
              </Button>
              {openCreate ? (
                <ClickAwayListener onClickAway={this.handleClickAway}><div>
                <Paper className={classes.menu1}>
                  <Paper className={classes.dropdownContent} elevation={3}>
                    <p className={`${classes.eachOption} ${classes.openSansFamily} optional property_search`} onClick={() => this.openSearchModal(true)}>
                      <span style={{marginRight: '24px'}}>
                        <SearchIcon alt="upload" className="upload-icon" />
                      </span>{t('file-management.dropdown.create-btn.new-search')}
                    </p>
                    <p className={`${classes.eachOption} ${classes.openSansFamily}`} onClick={() => this.openUploadModal(true)}>
                      <span style={{marginRight: '24px'}}>
                        <img src={UploadImage} alt="upload" className="upload-icon" />
                      </span>{t('file-management.dropdown.create-btn.new-upload')}
                    </p>
                    <p className={`${classes.completedBorder} ${classes.eachOption} ${classes.openSansFamily}`} onClick={() => this.openFolder()}>
                      <span style={{marginRight: '24px'}}>
                        <img src={FolderImage} alt="folder" style={{marginLeft: '4px'}} className="upload-icon"/>
                      </span>{t('file-management.dropdown.create-btn.new-folder')}
                    </p>
                  </Paper>
                </Paper>
                  </div></ClickAwayListener>
              ) : null}
            </div>
            <MenuList className="mt-3 work-menu">
              <MenuItem button={false} component='li' className={`${classes.menuItem} customScroll`} style={{minHeight: '50px'}}>
                {
                  Object.keys(data).length !== 0 ?
                    <Treebeard
                      data={data}
                      onToggle={this.onToggle}
                      style={style}
                      // decorators={decorators}
                    /> :
                    <React.Fragment>
                      <FolderIcon className={classes.icon}/>
                      <div className={`${classes.menuText} file-mng-items`} onClick={() => this.showTrash(false)}>
                        <span className={!trash? 'fontBold' : ''}>{t('file-management.side-nav-txt.file-mgmt')}</span>
                      </div>
                    </React.Fragment>
                }
              </MenuItem>
              <MenuItem button={false} component='li' className={classes.menuItem} style={{minHeight: '50px'}}>
                <React.Fragment>
                  <DeleteIcon className={classes.icon}/>
                  <div className={`${classes.menuText} file-mng-items`} style={{ marginTop: '2px'}} onClick={() => this.showTrash(true)}>
                    <span className={trash? 'fontBold' : ''}>{t('file-management.side-nav-txt.trash')}</span>
                  </div>
                </React.Fragment>
              </MenuItem>
            </MenuList>
          </div>
        </Grid>
        <Grid item xs={12} sm={10} className={classes.contentGrid}>
          <Box boxShadow={2} className={classes.content}>
            {
              trash ?
                <Fragment>
                  {loading ? <div className={classes.loader}><CircularProgress className='loader' style={{ color: '#5fafff'}} size={20} /></div>:
                      <Grid>
                        {
                          <div className='trashSearch'>
                            <Grid container direction="row" justify="space-between" alignItems="flex-start" className={`${classes.innerGrid}`}>
                              <TextField
                                  name="search"
                                  id="search"
                                  onChange={this.handleChange}
                                  value={search}
                                  className={classes.searchBar}
                                  variant='outlined'
                                  placeholder= {t('file-management.search-placeholder')}
                                  InputProps={{
                                    endAdornment: <InputAdornment position="end"><SearchIcon style={{ color: 'grey'}}/></InputAdornment>
                                  }}
                                  inputProps={{
                                   style: {
                                      padding: '0.75rem 1rem'
                                    }
                                  }}
                              />
                              <div className={classes.line} />
                              </Grid>
                            </div>
                        }
                      {this.props.displayType === 'module'  ?
                      <Grid container direction="column" className={`${classes.mainGrid} trashScroll`}>
                      <Typography variant='subtitle1' className={classes.folderArea} >
                        <span style={{color: '#1b1b1b', fontWeight: 'bold'}}>{t('file-management.content.trash-content-txt.deleted-files')}</span>
                        <span className={classes.fileCount}> {trashFiles.length}</span>
                      </Typography>
                      <Grid
                          className='minScroll fiveFiles'
                        container
                        spacing={7}
                        direction="row"
                        justify="flex-start"
                        alignItems="center">
                        {trashFiles && trashFiles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((folder) =>
                          <Grid item sm={2} xs={6} key={folder.uuid}>
                            <div className={`${classes.itemFile} padding-10`}>
                              <div>
                                <div className='top-right-drop-icon'>
                                  <MoreVertIcon style={{color: '#959daf'}}
                                                onClick={() => this.setActiveTrash(folder.uuid)}/>
                                  {
                                    activeIdTrash === folder.uuid ?
                                        <ClickAwayListener onClickAway={() => this.setActiveTrash(-1)}><div>
                                      <Paper className={classes.optionsFiles} style={{width: '150px'}}>
                                        <div className={classes.modalItem} onClick={() =>this.restore(folder)}>
                                          <RestoreIcon style={{color: '#959daf',marginRight: '16px'}}  />&nbsp;
                                          <a className={classes.modalText}>{t('file-management.dropdown.trash-dropdown.restore')}</a> {/* eslint-disable-line */}
                                        </div>

                                        <div className={classes.modalItem}
                                             onClick={() => this.openDeleteForeverModal(folder.uuid)}>
                                          <DeleteIcon style={{color: '#959daf',marginRight: '16px'}}  />
                                          <a className={classes.modalText}>{t('file-management.dropdown.trash-dropdown.del-forever')}</a> {/* eslint-disable-line */}
                                        </div>
                                      </Paper> </div></ClickAwayListener>: null
                                  }
                                </div>
                              </div>
                              <Grid container justify='center'>
                                {this.getFileType(folder, '70px')}{/* Trash List View */}
                                </Grid>
                              <Tooltip title={folder.name}>
                              <div className='file-name'><b>{folder.name}</b></div>
                              </Tooltip>
                              <div className='file-name font-10'>{(folder.size/(1024*1024)).toFixed(2)}&nbsp;MB</div>
                            </div>
                          </Grid>
                        )}
                      </Grid>
                              {
                                trashFiles.length > 0 ?
                                    <TablePagination
                                        rowsPerPageOptions={[10, 20, 30]}
                                        colSpan={4}
                                        count={trashFiles.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        labelRowsPerPage={t('file-management.per-page')}
                                        labelDisplayedRows={({ page})=> `${page + 1} of ${this.state.displayTrashPage}`}
                                        SelectProps={{
                                          inputProps: { 'aria-label': 'Results per page' },
                                          native: true,
                                        }}
                                        style={{borderBottom: 'none'}}
                                        onChangePage={this.handleChangeTrashPage}
                                        onChangeRowsPerPage={this.handleChangeTrashRowsPerPage}
                                    /> : null
                              }
                            </Grid> :
                          <Grid className='trashScroll'>
                              <div style={{display: 'flex'}}>
                                <div className={classes.filesHeading}>{t('file-management.content.trash-content-txt.deleted-files')}</div>
                                <div className={classes.fileCount} style={{margin: '0 1rem',paddingTop: '2px'}}>{trashFiles.length}</div>
                              </div>
                              <Table className={classes.table} style={{tableLayout: 'fixed'}}>
                                <TableHead>
                                  <TableRow className={classes.tableHeadBorder}>
                                    <TableCell padding="checkbox" style={{width: '10%'}} className={classes.tableHead}>
                                      <div style={{display: 'flex'}}>
                                        <Checkbox
                                            onClick={event => this.handleAllSelect()}
                                            checked={this.state.selected.length !== 0 && this.state.selected.length === trashFiles.length}
                                            indeterminate={this.state.selected.length > 0 && this.state.selected.length < trashFiles.length}
                                            style={{color: this.state.selected.length > 0 ? '#959daf' : '', padding: '0', marginLeft: '32px'}}
                                        />
                                        {/*<ArrowDownIcon/>*/}
                                          <div style={{position: 'relative', display: 'flex'}}>
                                            <ArrowDownIcon onClick={event => this.toggleDropdown()} style={{cursor: 'pointer'}}/>
                                            {openDropdown ? (
                                                <ClickAwayListener onClickAway={this.closeListDropdown}>
                                              <Paper elevation={8} className={classes.dropdownList}>
                                                <div className={`${classes.completedListBorder} ${classes.popHover}`} onClick={event => this.openDeleteForeverModal(-1)}>{t('file-management.dropdown.trash-dropdown.del-forever')}</div>
                                                <div onClick={event => this.updateFiles('restore')} style={{borderBottom: 'none'}} className={classes.popHover}>{t('file-management.dropdown.trash-dropdown.restore')}</div>
                                              </Paper></ClickAwayListener>) : null}
                                          </div>
                                        {/*<DeleteIcon onClick={this.clearAll} style={{display : this.state.selected.length > 0 ? 'flex' : 'none', color: '#1e2a5a', marginTop: '2px'}} />*/}
                                      </div>
                                    </TableCell>
                                    <TableCell className={classes.tableHead} style={{width: '60%', textAlign: 'left'}}>
                                        <div className='sortDiv'>
                                            <div onClick={() => this.handleSort('sortAlpha')} className='sortItem' >
                                                <div className={classes.textUnderline} style={{fontSize: '15px'}}>{t('table.file-name')}</div>
                                                <div className='display-flex'>{sortAlpha ? <ArrowUp className='sortIcon'/> : <ArrowDown className='sortIcon' />}</div>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell className={classes.tableHead} style={{width: '20%'}}>
                                        <div className='sortDiv'>
                                            <div onClick={() => this.handleSort('sortTime')} className='sortItem' >
                                                <div className={classes.textUnderline} style={{fontSize: '15px'}}>{t('table.last-mod')}</div>
                                                <div className='display-flex'>{sortTime ? <ArrowUp className='sortIcon'/> : <ArrowDown className='sortIcon' />}</div>
                                            </div>
                                        </div>
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                {trashFiles.length > 0 ? (<TableBody>
                                  {trashFiles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((folder, index) => {
                                    const isItemSelected = this.isSelected(folder);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow className={classes.rowBorderBottom}
                                            // hover
                                                  role="checkbox"
                                                  aria-checked={isItemSelected}
                                                  tabIndex={-1}
                                                  key={folder.uuid}
                                                  selected={isItemSelected}
                                                  style={{height: '60px',borderBottom: (index === this.state.rowsPerPage -1) || (folder.uuid === trashFiles[trashFiles.length - 1].uuid) ? 'none' : ''}}>
                                          <TableCell padding="checkbox" style={{width: '10%'}} className={`${classes.tableHead} ${classes.borderNone} ${classes.alignLeft}`}>
                                            <Checkbox
                                                style={{color: isItemSelected ? '#959daf' : '', padding: '0',marginLeft:'32px'}}
                                                checked={isItemSelected}
                                                inputProps={{ 'aria-labelledby': labelId }}
                                                onClick={event => this.handleClick(folder)}
                                            />
                                          </TableCell>
                                          <TableCell component="th" className={`${classes.fileName} ${classes.borderNone}`}>
                                          {this.getFileType(folder, '20px')}{/* Trash Grid View */}
                                          &nbsp;<span style={{marginTop: 'auto',marginLeft:'8px'}}>{folder.name}</span></TableCell>
                                          <TableCell style={{width: '20%'}} className={`${classes.tableBody} ${classes.borderNone}`}>
                                            {FileManagement.getDateString(folder.status_changed_at)}</TableCell>
                                          <TableCell style={{width: '10%'}} className={`${classes.tableBody} ${classes.borderNone}`} key={folder.uuid}>
                                            <div className='position-relative'>

                                                <div className='position-relative'>
                                                  <MoreHorizIcon style={{color: '#959daf'}}
                                                                onClick={() => this.setActiveTrash(folder.uuid)}/>
                                                  {
                                                    activeIdTrash === folder.uuid ?
                                                        <ClickAwayListener onClickAway={() => this.setActiveTrash(-1)}><div>
                                                        <Paper className={classes.optionsFiles}>
                                                          <div
                                                              onClick={() => this.openDeleteForeverModal(folder.uuid)} style={{display: 'flex'}}>
                                                            <div style={{margin: 'auto'}}><DeleteIcon style={{color: '#959daf',marginRight: '16px'}}  /></div>
                                                            <div style={{margin: 'auto'}}><a className={classes.modalText}>{t('file-management.dropdown.trash-dropdown.del-forever')}</a></div> {/* eslint-disable-line */}
                                                          </div>
                                                        </Paper> </div></ClickAwayListener>: null
                                                  }
                                                </div>
                                            </div>
                                          </TableCell>
                                        </TableRow>
                                    )}
                                  )}
                                </TableBody>) : null}
                                {
                                  trashFiles.length > 0 ?
                                      <TableFooter>
                                        <TableRow>
                                          <TablePagination
                                              rowsPerPageOptions={[10, 20, 30]}
                                              colSpan={4}
                                              count={trashFiles.length}
                                              rowsPerPage={rowsPerPage}
                                              page={page}
                                              labelRowsPerPage={t('file-management.per-page')}
                                              labelDisplayedRows={({ page})=> `${page + 1} of ${this.state.displayTrashPage}`}
                                              SelectProps={{
                                                inputProps: { 'aria-label': 'Results per page' },
                                                native: true,
                                              }}
                                              className={classes.borderNone}
                                              onChangePage={this.handleChangeTrashPage}
                                              onChangeRowsPerPage={this.handleChangeTrashRowsPerPage}
                                          />
                                        </TableRow>
                                      </TableFooter> : null
                                }
                              </Table>
                            </Grid>
                    }
                      </Grid>
                  }
                </Fragment>
                 :
                <Fragment>
                  {
                    loading?<div className={classes.loader}><CircularProgress className='loader' style={{ color: '#5fafff'}} size={20} /></div>:
                      <Fragment>
                        {
                          (!data.name)?<div className="iconBox"><InsertDriveFileIcon style={{color: '#959daf', fontSize: '5rem', textAlign: 'center',}}  /><br /> Looks like you don't have any documents here.<br />Click <b>"New"</b> to upload new documents.</div>:
                            <Grid container  direction="column" className={classes.mainGrid}>
                              <div className='trashSearch'>
                              <Grid container direction="row" justify="space-between" alignItems="flex-start" className={classes.innerGrid}>
                                {
                                  presentLocation !== '/' ? <Paper elevation={0} className={classes.paper}>
                                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>} aria-label="Breadcrumb">
                                      <Typography color="textPrimary" className='hovePointer' onClick={() => this.handleCrumb(0)}>{t('file-management.side-nav-txt.file-mgmt')}</Typography>
                                      {
                                        (presentLocation.substring(1).split('/').length > 2) &&
                                        <Typography color="textPrimary" >...</Typography>
                                      }
                                      {
                                        presentLocation.substring(1).split('/').map((name, index) =>{ { /* eslint-disable-line */}
                                          if(presentLocation.substring(1).split('/').length < 3){
                                            return  <Typography key={name} className='hovePointer' onClick={() => this.handleCrumb(index+1)} color="textPrimary">{name}</Typography>
                                        } else{
                                            if(index > presentLocation.substring(1).split('/').length - 3 ){
                                              return <Typography key={name} className='hovePointer' onClick={() => this.handleCrumb(index+1)} color="textPrimary">{name}</Typography>
                                            }
                                      }
                                        })
                                      }
                                    </Breadcrumbs>
                                  </Paper> : null
                                }
                              </Grid>
                                <Grid container direction="row" justify="space-between" alignItems="flex-start" className={classes.innerGrid}>
                                  <TextField
                                    name="search"
                                    id="search"
                                    onChange={this.handleChange}
                                    value={search}
                                    className={classes.searchBar}
                                    variant='outlined'
                                    placeholder= {t('file-management.search-placeholder')}
                                    InputProps={{
                                      endAdornment: <InputAdornment position="end"><SearchIcon style={{ color: 'grey'}}/></InputAdornment>
                                    }}
                                    inputProps={{
                                     style: {
                                        padding: '0.75rem 1rem'
                                      }
                                    }}
                                  />
                                  <div className={classes.line} />
                                </Grid>
                              </div>
                              <Grid>
                                {this.props.displayType === 'module'  ? (
                                    <Grid className={`${classes.gridView} pageContent1 ${classes.innerGrid} ${presentLocation === '/' ? 'trashScroll' : 'fileScroll'}`}>
                                      {
                                        folderChildren && this.displayDocuments(searchFilter)
                                      }
                                    </Grid>  ) :
                                  <Grid className={`${classes.gridView} pageContent1 ${classes.innerGrid} ${presentLocation === '/' ? 'trashScroll' : 'fileScroll'}`}>
                                    {
                                      folderChildren && this.displayListOfDocuments(searchFilter)
                                    }
                                  </Grid>
                                }
                              </Grid>
                              <div>
                              </div>
                            </Grid>
                        }
                      </Fragment>
                  }
                </Fragment>
            }
          </Box>
        </Grid>

        <Modal
          onClose={() => this.openSearchModal(false)}
          open={searchModal}
          className={"modalSearch"}
          >
          <PropertySearch 
            openSearchModal={this.openSearchModal}
            getFiles={this.getFiles}
            history={this.props.history}
          />
        </Modal>

        <Modal
          open={uploadModal}
          onKeyDown={e => {this.receivedOnKeyDown(e)}}
          className="upload-modal-scroll"
        >
          <React.Fragment>
            <FileUpload
              location = {trash ? '/' :presentLocation}
              onClose = {this.openUploadModal}
              changeTab={changeTab}
              history={this.props.history}
            />
          </React.Fragment>
        </Modal>

        <Modal open={openNew}>
          <React.Fragment>
            <div className={classes.modal} key="inputForm">
              <div className={classes.modalTitle}>
                <Typography variant="h6" >
                  {t('file-management.modal.create-folder.header')}
                </Typography>
              </div>
              <div className={classes.modalContent}>
                <Typography variant="subtitle1" >
                  <b>{t('file-management.modal.create-folder.title')}</b>
                </Typography>
                <form onSubmit={event => {event.preventDefault();}} noValidate>
                  <TextField
                    name="folderName"
                    autoFocus
                    id="folderName"
                    label={t('file-management.modal.create-folder.label')}
                    onChange={this.handleChange}
                    value={folderName}
                    className={classes.formInput}
                  />
                  <div className={classes.submit} key="inputForm">
                    <Button style={{marginRight: '10px'}} onClick={this.closeFolder}>{t('file-management.modal.create-folder.cancel-btn')}</Button>
                    <Button disabled={!folderName.replace(/\s/g, '').length} onClick={this.createFolder}>{t('file-management.modal.create-folder.add-btn')}</Button>
                  </div>
                </form>
              </div>
            </div>
          </React.Fragment>
        </Modal>

        <Modal open={deleteFolderModal}>
          <React.Fragment>
            <div className={classes.modal} >
              <div style={{padding: '20px'}}>
                <div className={classes.modalSubject}>
                  <b>{t('file-management.modal.delete-folder.title')}</b>
                </div>
                <div className={classes.modalDescription}>
                  <div>{t('file-management.modal.delete-folder.txt1')}</div>
                  <div>{t('file-management.modal.delete-folder.txt2')}<b>{t('file-management.modal.delete-folder.bold')}</b>{t('file-management.modal.delete-folder.txt3')}</div>
                </div>
                <div className={classes.submitDelete}>
                  <Button className={classes.actionButton} onClick={() => this.closeDeleteModal()}>{t('file-management.modal.delete-folder.cancel-btn')}</Button>
                  { this.state.deleteDirectory &&
                    <Button className={classes.actionButton} autoFocus onClick={() => this.removeDirectory(deleteFolderId)}>
                      {t('file-management.modal.delete-folder.remove-btn')}
                    </Button>
                  }
                  { !this.state.deleteDirectory &&
                    <Button className={classes.actionButton} autoFocus onClick={() => this.removeFile(deleteFolderId)}>
                      {t('file-management.modal.delete-folder.remove-btn')}
                    </Button>
                  }
                </div>
              </div>
            </div>
          </React.Fragment>
        </Modal>

        <Modal open={deleteMultipleFilesModal}>
          <React.Fragment>
            <div className={classes.modal} >
              <div style={{padding: '20px'}}>
                <div className={classes.modalSubject}>
                  <b>{t('file-management.modal.delete-folder.title')}</b>
                </div>
                <div className={classes.modalDescription}>
                  <div>{t('file-management.modal.delete-folder.txt1')}</div>
                  <div>{t('file-management.modal.delete-folder.txt2')}<b>{t('file-management.modal.delete-folder.bold')}</b>{t('file-management.modal.delete-folder.txt3')}</div>
                </div>
                <div className={classes.submitDelete}>
                  <Button className={classes.actionButton} onClick={this.closeMultipleDeleteFilesModal}>
                    {t('file-management.modal.delete-folder.cancel-btn')}
                  </Button>
                  <Button className={classes.actionButton} autoFocus onClick={this.removeMultipleFiles}>
                    {t('file-management.modal.delete-folder.remove-btn')}
                  </Button>
                </div>
              </div>
            </div>
          </React.Fragment>
        </Modal>

        <Modal open={deleteForeverModal}>
          <React.Fragment>
            <div className={classes.modal} key="inputForm">
              <div style={{padding: '20px'}}>
                <div className={classes.modalSubject}>
                {t('file-management.modal.delete-permanently.title')}
                </div>
                <div className={classes.modalDescription}>
                  <div>{t('file-management.modal.delete-permanently.txt1')}</div>
                  <div>{t('file-management.modal.delete-permanently.txt2')}</div>
                </div>
                <div className={classes.submitDelete}>
                  <Button className={classes.actionButton} onClick={() => this.closeDeleteForeverModal()}>{t('file-management.modal.delete-permanently.cancel-btn')}</Button>
                  <Button className={classes.actionButton} id={deleteFolderId} autoFocus onClick={() => this.removeFileTrash(deleteFolderId)}>{t('file-management.modal.delete-permanently.remove-btn')}</Button>
                </div>
              </div>
            </div>
          </React.Fragment>
        </Modal>

        <Modal open={firstTimeModal}>
          <React.Fragment>
            <div className={classes.modal} >
              <div style={{padding: '20px'}}>
                <div className={classes.modalSubject}>
                  <b>Welcome!</b>
                </div>
                <div className={classes.modalDescription}>
                  <div>Looks like its your first time here.</div>
                  <div>Have a look around or click <b>New</b> to start uploading your documents  </div>
                </div>
                <div className={classes.submitDelete}>
                  <Button className={classes.actionButton} autoFocus onClick={() => this.closeFirstTimeModal()}>Ok</Button>
                </div>
              </div>
            </div>
          </React.Fragment>
        </Modal>
        
      </React.Fragment>
    )
  }
}

export default  withTranslation()(withStyles(styles)(FileManagement));
