import {withStyles} from '@material-ui/core';
import moment from 'moment-timezone';
import React from 'react';
import {toast} from 'react-toastify';
import {Redirect, Route, Switch} from 'react-router-dom';
import Account from './account';
import Admin from './admin';
import Footer from './common/Footer';
import Header from './common/Header';
import {getUserOrg, isLoggedIn} from './common/loginService';
import {GetRequest} from './common/requests';
import {URL} from './common/url';
import Login from './login';
import Reset from './login/reset';
import SuperAdmin from './superadmin';
import Swagger from './swagger';
import WorkSpace from './workspace';
import {TASK_ERROR_CODES} from './common/constants';

import {
  getUserName
} from './common/loginService';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  content: {
    background: '#F4F6F9',
    minHeight: '90vh'
  }
};

const pollInterval = 2.5;

class Routes extends React.Component {

  state = {
    pollHandle: null,
    badgeNotification: false,
    queueLastVisited: null,
    resultViewerProcessing: {},
  }


  componentDidMount() {
    localStorage.setItem('userActiveTime', moment.utc().format());
    localStorage.removeItem('lastQueryTimeUTC')
    this.setState({
      pollHandle: setInterval(
        this.get_task_states,
        pollInterval * 1000)
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.pollHandle);
  }

  arraysEqual(a1, a2) {
    return JSON.stringify(a1) === JSON.stringify(a2);
  }


  checkForFailures = (docStates) => {

      if(!docStates){
          return
      }
      let userActiveTime = moment.utc(
          localStorage.getItem('userActiveTime')
      )
      docStates.forEach(doc => {

      if (doc['document_status'] === 'Failed' &&
        moment.utc(doc['status_changed_at']) > userActiveTime &&
        doc['task']['created_by'] === getUserName()) {

        const error = doc['task']['response']['error'];
        if (error) {
          const type = error['type'];
          toast.error(
            doc['name'] + ' - ' +
            TASK_ERROR_CODES[type]
          );
          userActiveTime = moment.utc().format();
          localStorage.setItem('userActiveTime', userActiveTime);
        }
      }
    });
  };


  updateResultViewerProcessing = (docStates) => {
    /*
    Determines if any resultViewerDocIds are processing/completed.
    This data is sent as props to ResultViewer
     */
    const {resultViewerProcessing} = this.state;
    if (!docStates || !docStates.length) {
      return resultViewerProcessing;
    }
    let newResultViewerProcessing = Object.assign(
      {}, resultViewerProcessing);
    docStates.forEach(docState => {
      const uuid = docState['uuid'];
      newResultViewerProcessing[uuid] = docState;
      delete newResultViewerProcessing[uuid]['uuid'];
    });
    this.setState({
      resultViewerProcessing: newResultViewerProcessing
    });
    return newResultViewerProcessing;
  };

  setQueueLastVisited = (newQueueLastVisited) => {
    /*
     Update queue last visited, representing when the user last
     visited the queue (returned by documents endpoint).
     */
    const {queueLastVisited} = this.state;
    if (!newQueueLastVisited && !queueLastVisited) {
      this.setState({queueLastVisited: moment.utc()});
    }
    if (!newQueueLastVisited) {
      return;
    }
    newQueueLastVisited = moment.utc(newQueueLastVisited);
    if (!queueLastVisited ||
      (newQueueLastVisited > queueLastVisited)) {
      this.setState({
        queueLastVisited: newQueueLastVisited
      });
    }
  };

  setBadgeNotification = (docStates) => {
    /*
    Currently this returns a bool, yet can easily be converted into a count
    simply by using badgeNotification.length.
     */

    const terminalStates = ['Complete', 'Failed'];
    let badgeNotification = Object.keys(docStates).filter(
      id => {
        let status_changed_at = moment.utc(
          docStates[id]['status_changed_at']
        );
        return (status_changed_at > this.state.queueLastVisited
          && terminalStates
            .includes(docStates[id]['document_status']));
      }
    );
    badgeNotification = !!badgeNotification.length;
    if (badgeNotification !== this.state.badgeNotification) {
      this.setState({badgeNotification: badgeNotification});
    }
  };

  setLastQueryTimeUTC = (lastQueryTimeUTC) => {
      lastQueryTimeUTC = moment.utc(lastQueryTimeUTC).format()
      localStorage.setItem(
          'lastQueryTimeUTC',
           lastQueryTimeUTC
      )
  }


  get_task_states = () => {
    /*
     This is used to poll for all org documents as well
     as their states. Replace with WebSocket in the future.
     */
    let lastQueryTimeUTC = localStorage.getItem('lastQueryTimeUTC')

    if (!isLoggedIn()){
        //Dont poll if user isn't logged in.
        return
    }
    const params = {
      org_id: getUserOrg(),
      queued: false,
      trash: false,
      poll: true
    };

    if(lastQueryTimeUTC){
        lastQueryTimeUTC = moment.utc(lastQueryTimeUTC)
        // subtract to create a buffer, in the case
        // of a race condition
        params['last_query_time'] = lastQueryTimeUTC
            .subtract(pollInterval/2,'seconds')
            .format()
    }
    let esc = encodeURIComponent;
    let query = Object.keys(params)
      .map(k => esc(k) + '=' + esc(params[k]))
      .join('&');
    GetRequest(URL.documents + '?' + query, (data) => {
      this.setLastQueryTimeUTC(data['time_of_query_utc'])
      this.setQueueLastVisited(data['queue_last_visited'])
      this.checkForFailures(data['payload'])
      const all_doc_states = this.updateResultViewerProcessing(
        data['payload']
      );
      this.setBadgeNotification(all_doc_states);
    });
  };

  PrivateRoute = (props) => {
    let {badgeNotification} = this.state;
    const path = props.location.pathname;
    badgeNotification = path !== '/queue' ? badgeNotification : false;
    return (
      isLoggedIn() ?
        <React.Fragment>
          <Header
            badgeNotification={badgeNotification}
            path={path}
          />
          <div className="route-cntr"><Route {...props} /></div>
          <Footer />
        </React.Fragment>
        : <Redirect to="/login" />
    );
  };

  render() {
    const {classes} = this.props;
    const {resultViewerProcessing} = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <Switch>
            <Redirect
              exact
              from="/"
              to="/files"
            />
            <this.PrivateRoute
              render={() =>
                <WorkSpace
                  arraysEqual={this.arraysEqual}
                  resultViewerProcessing={resultViewerProcessing}
                  openModal={true}
                />}
              path={['/files', '/trash', '/queue', '/results']}
            />
            <this.PrivateRoute
              exact
              component={SuperAdmin}
              path={['/superadmin/orgs', '/superadmin/users']}
            />
            <this.PrivateRoute
              component={Account}
              exact
              path="/account"
            />
            <this.PrivateRoute
              component={Admin}
              exact
              path="/admin/:id"
            />
            <this.PrivateRoute
              component={Swagger}
              exact
              path="/documentation"
            />
            <Route
              component={Reset}
              exact
              path="/reset"
            />
            <Route
              exact
              path="/changepassword"
              render={(props) => <Reset change {...props} />}
            />
            <Route
              exact
              path="/login"
              render={(props) => isLoggedIn() ? <Redirect to="/files" /> : <Login {...props} />}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Routes);
