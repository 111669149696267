/**
 * Created by Akhtar on 02/04/2020.
 */

import React, {Fragment} from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image
} from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
    margin: 15,
    padding: 15,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  summaryContainer: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent :'space-between',
    fontSize: 15,
    width: '90%',
    paddingBottom: 30,
    alignItems: 'center',
  },
  title: {
    fontSize: 15,
    fontWeight: 'bold',
    margin: '0 auto',
    textAlign: 'center',
  },
  summaryDetails: {
    display: "flex",
    flexDirection: "row",
  },
  topContainer: {
    display: "flex",
    flexDirection: "row",
  },
  image: {
    width: 150,
    // backgroundColor: '#70b7ff'
  },
  mainTitle: {
    fontSize: 20,
    alignItems: 'center',
    padding: '20'
  },
  key: {
    fontWeight: 600,
    fontSize: 12,
    width: '35%',
  },
  value: {
    fontSize: 12,
    padding: 3,
    width: '63%',
    alignItems: 'left',
    fontWeight: 400,
  },
  titleCont:{
    alignItems: 'center',
    width: '100%',
  },
  subTitle: {
    margin: '0 auto',
    alignItems: 'center',
    paddingTop: 50,
    paddingBottom: 25,
    textTransform: 'uppercase',
    fontSize: 15,
  },
  vote: {
    display: 'flex',
    flexDirection: 'row'
  },
  rating: {
    height: 10,
    width: 10
  },
  vote_text: {
    fontSize: 10
  },
  vote_pop: {
    fontSize: 10,
    padding: 2,
    backgroundColor: '#000',
    color: '#fff'
  },
  vote_pop_text: {
    fontSize: 10,
    marginLeft: 4
  },
  overviewContainer: {
    minHeight: 110
  },
  detailsFooter: {
    display: 'flex',
    flexDirection: 'row'
  },

});


export function PdfDocument(props) {
  let {data} = props

  let apn = data.apn
  let address = data.address
  let encumbrances = data.encumbrances
  let docs = data.docs

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.header}>
            <Image
              style={styles.image}
              src="images/novare_logo.jpg"
            />
            <Text style={styles.mainTitle}>Quick Snapshot</Text>
        </View>
        <View style={styles.topContainer}>
            <Fragment><Text style={styles.key}>Property Address</Text><Text style={styles.value}>{address}</Text></Fragment>
        </View>
        <View style={styles.topContainer}>
            <Fragment><Text style={styles.key}>APN</Text><Text style={styles.value}>{apn}</Text></Fragment>
        </View>
        <View style={styles.topContainer}>
            <Fragment><Text style={styles.key}>Encumbrances</Text><Text style={styles.value}>{encumbrances}</Text></Fragment>
        </View>
        <View style={styles.topContainer}>
            <Fragment><Text style={styles.key}>Document Count</Text><Text style={styles.value}>{docs.length}</Text></Fragment>
        </View>


        {docs
          ? docs.map((doc, index) => {
            let doc_title = <View style={styles.titleCont}><Fragment><Text style={styles.subTitle}>{doc.doc}</Text></Fragment></View>
            let fields = []
            doc.items.map((field, index2) => {
              let frag = <View key={index2} style={styles.summaryContainer}>
                <View style={styles.summaryDetails}>
                  {(field.label.toLowerCase().indexOf('legal') > -1) 
                  ? <Fragment><Text style={styles.key}>{field.label}</Text><Text style={styles.value}>{field.value}</Text></Fragment>
                  : <Fragment><Text style={styles.key}>{field.label}</Text><Text style={styles.value}>{field.value}</Text></Fragment>
                  }
                </View>
              </View>
              fields.push(frag)
            })

            let final_html = [doc_title, fields]
            return final_html

          })
          : ''}
      </Page>
    </Document>
  );
}
