import {withStyles} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import * as PropTypes from 'prop-types';
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';


const styles = {
  searchLeft:{
    background :'#f4f6f9',
    height: '485px',
    padding: '40px'
  },
  mainTitle: {
    fontWeight: '600',
    color: '#5fafff',
    margin: '0 0 0.5rem 0',
    fontSize: '1.5rem'
  },
  subTitle: {
    fontWeight: '600',
    color: '#999999',
    margin: '0.5rem 0 2rem 0',
    fontSize: '0.9rem'
  },
  textLabel:{
    fontSize:'18px',
    fontWeight:'bold',
    color: '#292929!important'
  },
  searchRight:{
    padding: '45px 45px 0 45px',
  },
  textInput:{
    width: '100%',
    padding: '20px 0',
  },
  fullWidth:{
    width: '100%'
  }
};


class StateCounty extends Component {
  static propTypes = {
    // modalOpen: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
  };

  state = {
    submit: false,
    activeStep: 0,
    skipped: new Set()
  };

  render() {
    const { t, classes, handleChange } = this.props;

    return (
        <Grid container spacing={1}>
            <Grid item xs={4}>
                <div className={classes.searchLeft}>
                    <Typography variant='h5' className={classes.mainTitle}>{t('property-search.property-search-modal.state-county')}</Typography>
                    <Typography variant="h6" className={classes.subTitle}>{t('property-search.property-search-modal.step')} 1</Typography>
                </div>
            </Grid>
            <Grid item xs={8}>
                <div className={classes.searchRight}>

                <FormControl className={classes.fullWidth}>
                <InputLabel shrink className={classes.textLabel}>{t('property-search.property-search-modal.step-state-county.state')}</InputLabel>
                <TextField
                      name="state"
                      id="state"
                      required
                      onChange={handleChange}
                      variant='outlined'
                      className={'textInput'}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin="normal"
                      
                    />
                </FormControl>

                <FormControl className={classes.fullWidth}>
                <InputLabel shrink className={classes.textLabel}>{t('property-search.property-search-modal.step-state-county.county')}</InputLabel>
                <TextField
                      name="county"
                      id="county"
                      required
                      onChange={handleChange}
                      variant='outlined'
                      className={'textInput'}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin="normal"
                      
                    />
                </FormControl>
              </div>
            </Grid>
        </Grid>
    );
  }
}

export default withTranslation()(withStyles(styles)(StateCounty));
