import {Paper} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import EmailIcon from '@material-ui/icons/Email';
import ErrorIcon from '@material-ui/icons/Error';
import LockIcon from '@material-ui/icons/Lock';
import Visibility from '@material-ui/icons/Visibility';
import * as PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import {withTranslation} from 'react-i18next';
import BackGroundImage from '../assets/images/Login_Background.png';
import PoweredLogo from '../assets/images/poweredby.png';
import Footer from '../common/Footer';
import {login} from '../common/loginService';

let brand = process.env.REACT_APP_ORG_BRAND
if( brand === undefined){
  brand = 'nirveda'
};

const styles = {
  paper: {
    padding: '72px 80px 16px 80px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'white',
    borderRadius: '30px',
    textAlign: 'center',
    boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.55)',
    // height: '400px'
  },
  iconColor: {
    color: '#5EAAFF !important'
  },
  avatar: {
    margin: '8px',
    height: '150px',
    paddingTop: '20px',
    paddingBottom: '15px',
    width: '100px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: '1rem',
  },
  submit: {
    margin: '24px 0 0 0',
    padding: '8px 40px',
    background: 'linear-gradient(70deg, #5EAAFF, #5159F8)',
    borderRadius: '8px',
    textTransform: 'unset',
    fontSize: '20px',
    fontWeight: '600',
    // width: '142px'
  },
  disabledButton: {
    margin: '24px 0 0 0',
    padding: '8px 40px',
    background: 'linear-gradient(150deg, #a3a3a3, #353535)',
    borderRadius: '8px',
    textTransform: 'unset',
    color: 'white !important',
    fontSize: '20px',
    fontWeight: '600'
  },
  button: {
    display: 'none',
  },
  main: {
    background: '#6094FB',
    backgroundImage: `url(${BackGroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    padding: '8px',
  },
  circle: {
    margin: 'auto',
    background: 'white',
    width: '200px',
    borderTopLeftRadius: '200px',
    borderTopRightRadius: '200px',
    height: '100px',
    textAlign: 'center',
    boxShadow: '0 10px 15px 0 rgba(0, 0, 0, 0.55)',
  },
  container: {
    marginTop: '7%',
    maxWidth: '700px',
  },
  text: {
    width: '100%',
  },
  forgot: {
    fontSize: '13px',
    // marginTop: '3px',
    color: '#686868',
    '&:hover': {
      cursor: 'pointer',
    }
  },
  icon: {
    color: '#959CAE',
    marginTop: '2rem',
    marginLeft: '0.5rem',
    position: 'absolute'
  },
  errorIcon: {
    color: '#ef65b1',
    marginTop: '-1.6rem',
    marginLeft: '0.5rem',
    //position: 'absolute',
    float: 'left',
    marginRight: '0.5rem',
  },
  errorIconLogin: {
    color: '#ef65b1',
    marginTop: '2rem',
    marginLeft: '-1.5rem',
    position: 'relative',
    left: '2rem'
  },
  textStyle: {
    color: '#1b1b1b',
    fontSize: '28px',
    fontWeight: '500',
      fontFamily: 'Raleway,sans-serif !important'
  },
  loaderDiv: {
    background: '#6094FB',
    backgroundImage: `url(${BackGroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    padding: '16px 24px',
    width: '142px',
    borderRadius: '8px',
    margin: '24px auto auto auto',
    display: 'flex',
    justifyContent: 'center'
  },
  tenantLogoBox:{
    maxWidth: '260px',
    marginTop: '-30px'
  },
  tenantPoweredBox:{
    marginBottom: '20px'
  }
};

class Login extends React.Component{
  static propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  state={
    email: '',
    password: '',
    submit: false,
    emailError: '',
    passwordError: '',
    loading: false,
    showPassword: false,
    commonError: '',
    customLogo: false,
  };



  controller = new window.AbortController();

  componentDidMount = () => {
    // evaluate based on ORG
    this.setState({
      customLogo:true
    })
  }


  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(prevState => ({ ...prevState, [name]: value , commonError: ''}),() => {
      this.checkValidations();
    });
  };

  handleShowPassword = () => {
    const { showPassword } = this.state;
    this.setState({showPassword: !showPassword});
  };

  static validatePassword(p) {
    let re = /(?=.*\d)(?=.*[a-z])(?=.*[!@#$%^&*])(?=.*[A-Z]).{8,}/;
    return re.test(p);
  }

  submitLogin = () => {
    const { email, password } = this.state;
    const { history } = this.props;
    this.setState({submit: true, loading: true}, () => {
      if( this.checkValidations()){
        login(email.toLowerCase(),password, () => {
          history.push('/files');
        }, (error, msg) => {
          if(error){
              this.setState({commonError: msg})
          }else {
            history.push('/login');
          }
          this.setState({loading: false})
        });} else {
        this.setState( { loading: false})
      }
    });
  };

  resetPassword = () => {
    const { history } = this.props;
    history.push('/reset');
  };

  onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.submitLogin();
    }
  };

  checkValidations = () => {
    const {email, submit, password} = this.state;
    const { t } = this.props;
    if (!submit) {
      return;
    }
    let valid = true;
    let emailError = "";
    let passwordError = "";

    if (email) {
      if (!Login.validateEmail(email)) {
        emailError = t('msg.invalid-email-1');
        valid = false;
      }
    } else {
      emailError = t('msg.invalid-email-1');
      valid = false;
    }
    if(password === ''){
      passwordError = t('msg.pwd-err-generic');
      valid = false;
    }
    if (password) {
      if (password.length < 8 || !Login.validatePassword(password)) {
        passwordError = t('msg.pwd-err-generic');
        valid = false;
      }
    }
    this.setState({emailError, passwordError});
    return valid;
  };

  static validateEmail(email) {
    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  render() {
    const { t } = this.props;
    const {classes} = this.props;
    const {email, password, emailError, passwordError, loading, showPassword, commonError} = this.state;
    return (
      <React.Fragment>
        <div className={`${classes.main} login-main`}>
          <Container component="main" className={classes.container}>

            {(brand === 'nirveda')
            ?
            <Paper className={classes.circle}>
              <a href='/'><img src={require(`../assets/images/${brand}-hero-logo.png`)} alt="Nirveda" className={classes.avatar}/></a>
            </Paper>
            : null}
            <Paper className={classes.paper}>

            {(brand !== 'nirveda')  
            ?
            <div className={classes.tenantPoweredBox}>
              <a href='/'>              
                <img src={require(`../assets/images/${brand}-hero-logo.png`)} alt="Brand Header" className={classes.tenantLogoBox}/>
                <img src={PoweredLogo} alt="Powered By Nirveda" className={classes.poweredBy}/>
              </a>
            </div> 
            : null}

              <Typography component="h1" variant="h5" className={classes.textStyle}>
                <b style={{fontFamily: '\'Raleway\', sans-serif !important'}}>{t('welcome-page.welcome-msg.bold')} </b> {t('welcome-page.welcome-msg.normal')}
              </Typography>
              {(commonError !== '') && <div className='commonError'>{commonError}</div> }
              <form className={classes.form} onSubmit={event => {event.preventDefault()}} noValidate>
                <TextField
                  variant="outlined"
                  margin="normal"
                  className={classes.text}
                  id="email"
                  label="Email"
                  placeholder={t('welcome-page.email-placeholder')}
                  name="email"
                  value={email}
                  onChange={this.handleChange}
                  helperText={emailError}
                  error={emailError !== ''}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><EmailIcon style={{color: '#d3d3d3'}}/></InputAdornment>,
                  }}
                  autoFocus
                  onKeyDown={this.onKeyDown}
                />
                {emailError !== '' ?
                  <ErrorIcon className={classes.errorIconLogin}/> :
                  null}
                <TextField
                  variant="outlined"
                  margin="normal"
                  className={classes.text}
                  name="password"
                  label={t('welcome-page.password')}
                  placeholder={t('welcome-page.password')}
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  value={password}
                  onChange={this.handleChange}
                  helperText={passwordError}
                  error={passwordError !== ''}
                  onKeyDown={this.onKeyDown}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><LockIcon style={{color: '#d3d3d3'}}/></InputAdornment>,
                  }}
                />
                {
                  <Fragment>
                    <Visibility className={classes.icon} style={{ color: showPassword ? '': '#ebebeb'}} onClick={this.handleShowPassword} />
                      {passwordError !== '' &&
                        <Fragment>
                          <ErrorIcon className={classes.errorIcon} />
                        </Fragment>
                      }
                  </Fragment>
                }
                <button
                  className={classes.button}
                  id="submit-button"
                  type="button"
                />
                  { loading ? <div className={classes.loaderDiv}><CircularProgress style={{ color: 'white'}} size={20} /></div> :
                    <label htmlFor="submit-button">
                      <div>
                        <Button
                          size="medium"
                          variant="contained"
                          color="primary"
                          className={(emailError || passwordError || commonError) ? classes.disabledButton : classes.submit}
                          href="#"
                          type="submit"
                          onClick={this.submitLogin}
                          disabled={emailError !== '' || passwordError !== '' || loading || commonError !== ''}
                        >
                          <span>{t('welcome-page.login-btn.text')}</span>
                        </Button>
                      </div>
                    </label>
                }
                <Grid container style={{marginTop: '4px'}}>
                  <Grid item xs>
                    <span className={classes.forgot} onClick={this.resetPassword}>{t('welcome-page.forgot-pwd.text')}</span>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Container>
        </div>
        <Footer light/>
      </React.Fragment>
    );
  }
}

export default withTranslation()(withStyles(styles)(Login));
