import {renderChart} from './AgGridChartingUtils';
import {
  addAgColumn,
  addAgRow,
  deleteAgColumn,
  deleteAgRows,
  handleName,
  setAsTableHeader,
  updateHeader,
  updateSelectedValuesOfAColumn
} from './AgGridMethods';


/**
 *Function used for the AGGrid to create the pop up table items, returns object
 *  in the format as described in the AGGrid documentation
 *
 * @memberof ResultViewer
 */
export const getContextMenuItems = (params, _this) => {
  const {processing, activeId, isAggView} = _this.state;
  const disabled = processing.includes(activeId)
    || (isAggView && processing.length);
  const toolTips = {
    'processing': 'Table utilities are unavailable while processing.'
  };
  let tooltip = toolTips['processing'];

  var contextMenu = [
    {
      name: 'Export',
      action: '',
      subMenu: [
        {
          'name': 'Export CSV',
          action: () => {
            handleName(params, '.csv', _this);
            _this.gridApi.exportDataAsCsv(params);
          }
        },
        {
          'name': 'Export XLS',
          action: () => {
            handleName(params, '.xlsx', _this);
            _this.gridApi.exportDataAsExcel(params);
          }
        }
      ]
    },
    // 'excelXmlExport',
    // "chartRange",
    {
      name: 'Edit <b>Selected Rows</b>',
      disabled: disabled,
      action: (e) => {
        updateSelectedValuesOfAColumn(params, true, _this);
      }
    },
    {
      name: 'Generate BI Chart',
      action: () => renderChart(params, null, _this),
      cssClasses: ['tax_classifier', 'optional']
    },
    'separator',
    {
      name: 'Table Utilities',
      disabled: disabled,
      tooltip: tooltip,
      subMenu: [
        {
          name: 'Use This Row as Header',
          action: () => setAsTableHeader(_this),
          cssClasses: ['tax_classifier', 'optional']
        },
        {
          name: 'Add Row',
          action: '',
          subMenu: [
            {
              name: 'Above',
              action: () => addAgRow('above', _this)
            },
            {
              name: 'Below',
              action: () => addAgRow('below', _this)
            }
          ]
        },
        {
          name: 'Add Column',
          action: '',
          subMenu: [
            {
              name: 'Right',
              action: () => addAgColumn(params, 'right', _this)
            },
            {
              name: 'Left',
              action: () => addAgColumn(params, 'left', _this)
            }
          ]
        },
        {
          name: 'Delete Row(s)',
          // We have to pass an empty string as headerRow since we have hardcoded our deleteAgRows to conatin the logic for setAsTableHeader(), may need further refactoring
          action: () => deleteAgRows('',_this)  
        },
        {
          name: 'Delete This Column',
          action: () => deleteAgColumn(params, _this)
        }
      ]
    },
    'autoSizeAll',
    {
          name: "Search Google",
          action: () => {
           window.open('https://www.google.com/search?q='+params.value, '_blank');
          },
    }
  ];
  return contextMenu;
}


export const getColumnMenuItems = (params, _this) => {
  const {processing, activeId, isAggView} = _this.state;
  const tableProcessing = processing.includes(activeId);
  const disabled = tableProcessing || (isAggView && processing.length);
  const menuItems = [];
  const toolTip = 'Item unavailable in while processing';

  menuItems.push(
    {
      name: 'Edit',
      disabled: disabled,
      tooltip: toolTip,
      action: '',
      subMenu: [
        {
          name: 'Edit <b>Column Name</b>',
          action: (e) => {
            _this.setState({colDescData: {params: params}}, () => {
              _this.toggleColDescriptionModal(true);
            });
          }
        },
        {
          name: 'Set as <b>Description</b>',
          action: (e) => {
            updateHeader(params, 'Description', null, _this);
          },
          cssClasses: ['tax_classifier', 'optional']
        },
        {
          name: 'Set as <b>Amount</b>',
          action: (e) => {
            updateHeader(params, 'Amount', null, _this);
          },
          cssClasses: ['tax_classifier', 'optional']
        }
      ]
    },
    {
      name: 'Table',
      action: '',
      subMenu: [
        {
          name: '<b>Add</b> a Column',
          tooltip: toolTip,
          disabled: disabled,
          action: (e) => {
            addAgColumn(params, 'left', _this);
          }
        },
        {
          name: '<b>Delete</b> this Column',
          tooltip: toolTip,
          disabled: disabled,
          action: (e) => {
            deleteAgColumn(params, _this);
          }
        },
        params.defaultItems[2],
        params.defaultItems[3],
        params.defaultItems[6]
      ]
    });

  menuItems.push({
    name: '<b>Chart</b> _this Column',
    tooltip: toolTip,
    action: (e) => {
      renderChart(true, params.column.colId, _this);
    }
  });

  menuItems.push(params.defaultItems[0]);
  return menuItems;
}
