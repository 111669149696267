import React, {Component} from 'react';
import { RedocStandalone } from 'redoc';
import {GetRequest} from "../common/requests";
import {URL} from "../common/url";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

class Swagger extends Component {

  state = {
    json: '',
    loading: true
  };

  componentDidMount() {
    this.getSwaggerJson();
  }

  getSwaggerJson = () => {
    this.setState({loading: true});
    GetRequest(URL.getSwaggerJson, (data) => {
      this.setState({json: data.payload, loading: false})
    },(err)=>{
      this.setState({loading: false})
    })
  };

  render() {
    const {loading, json} = this.state;
    return (
     <div className='redocDiv'>
       {
         loading ? <div className="swagger-loader"><CircularProgress className='loader' color="secondary" size={20} /></div> :
             <RedocStandalone spec={json}/>
       }
     </div>
    );
  }
}

export default Swagger;
