import {AllModules} from '@ag-grid-enterprise/all-modules';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import withStyles from '@material-ui/core/styles/withStyles';
import 'ag-grid-autocomplete-editor/main.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import 'ag-grid-enterprise';
import jQuery from 'jquery';
import * as JsSearch from 'js-search';
import _ from 'lodash';
import * as PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import {withTranslation} from 'react-i18next';
import {pdfjs} from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import {toast} from 'react-toastify';
import {getUserOrgCapabilities} from '../../../common/loginService';
import {PatchRequestV2, PostRequest} from '../../../common/requests';
import {URL} from '../../../common/url';
import Utils from '../../../utils';
import {renderChart, toggleChartForData} from './ComponentMethods/AgGridChartingUtils';
import {getColumnMenuItems, getContextMenuItems} from './ComponentMethods/AgGridMenuUtils';
import {handleName, updateData, updateHeader, updateSelectedValuesOfAColumn} from './ComponentMethods/AgGridMethods';
import {
  generateCsvData,
  getAggTable,
  getDocuments,
  getResultDate,
  getTaskResults,
  handleTableView,
  renderDocFromBlob
} from './ComponentMethods/ResultViewerMethods';

import './custom.scss';
import LeftContainer from './LeftContainer';
import AgGridTable from './modals/AgGridTable';
import EditColumnNameModal from './modals/EditColumnNameModal';
import EditTableCellModal from './modals/EditTableCellModal';
import RightContainer from './RightContainer';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

let log = console.info.bind(window.console);

const styles = {
  closeBox: {
    position: 'absolute',
    right: '20px',
    top: '20px',
    cursor: 'pointer'
  },
  backIcon: {
    color: '#838faa',
    //borderRadius: '50%',
    //border: '1px solid #e1e3e4',
    //marginRight: '10px',
    height: '26px',
    width: '36px',
    display: 'flex',
    cursor: 'pointer'
  },
  modal: {
    //background: '#fff',
    //position: 'absolute',
    //width: 'auto',
    //left: '56px',
    //right: '56px',
    //bottom: '0',
    //textAlign: 'left',
    //borderRadius: '20px 20px 0px 0px',
    //zIndex: '999',
    //boxShadow: '0px 0px 65px -15px rgba(0,0,0,0.25)'
    height: '100%'
  },
  modalTitle: {
    background: 'linear-gradient(70deg, #5159F8, #5EAAFF)',
    color: 'white',
    textAlign: 'left',
    padding: '15px'
  },
  modalContent: {
    padding: '20px'
  },
  modalSubject: {
    color: '#141b2d',
    fontSize: '21px',
    fontFamily: '\'Open Sans\', sans-serif !important',
    marginBottom: '24px'
  },
  modalDescription: {
    fontFamily: '\'Open Sans\', sans-serif !important',
    color: '#1e2a5a',
    //overflowY: 'scroll',
    //paddingRight: '10px',
    height: '100%'
  },
  modalItem: {
    '&:hover': {
      cursor: 'pointer'
    },
    display: 'flex'
  },
  modalText: {
    verticalAlign: 'super',
    textDecoration: 'none !important',
    color: '#141b2d',
    fontFamily: '\'Open Sans\', sans-serif !important',
    fontSize: '17px'
  },
  textInput: {
    width: '100%',
    padding: '10px 0!important'
  },
  paper: {
    height: '100%',
    textAlign: 'center',
    paddingTop: '1rem',
    position: 'relative',
    borderTop: 'none'
  },
  leftInfo: {
    textAlign: 'right',
    width: '30%',
    marginRight: '5%',
    fontWeight: '600',
    color: '#1b1b1b',
    marginLeft: '20px'
  },
  rightInfo: {
    textAlign: 'left',
    width: 'calc(65% - 32px)',
    color: '#1b1b1b'
  },
  labelInfo: {
    color: '#1b1b1b',
    lineHeight: '20px',
    marginLeft: '0.5rem'
  },
  content: {
    textAlign: 'left'
  },
  title: {
    color: '#618bff',
    marginBottom: '1rem',
    marginTop: '0.5rem'
  },
  data: {
    margin: '1rem 1rem 1rem 2rem'
  },
  input: {
    width: '1.5rem',
    height: '2rem'
  },
  rightTitle: {
    marginLeft: '2rem',
    fontFamily: 'Open Sans, sans-serif !important',
    fontSize: '16px',
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.38,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#000000',
    width: '55%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },

  loader: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    marginTop: '32px',
    marginRight: 'auto'
  },
  classifyLoader: {
    position: 'absolute',
    right: '70px'
  },
  submitDelete: {
    borderTop: '1px solid #E1E3E4',
    textAlign: 'right',
    paddingTop: '16px',
    marginTop: '30px'
  },
  cancelButton: {
    color: '#d3d3d3',
    fontSize: '16px',
    marginRight: '10px'
  },
  actionButton: {
    color: '#d3d3d3',
    fontSize: '16px',
    fontWeight: 'bold',
    marginRight: '10px'
  },
  saveButton: {
    color: 'white',
    background: '#618bff',
    padding: '0.5rem 2rem',
    borderRadius: '8px',
    textTransform: 'unset',
    fontFamily: 'Raleway !important',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#618bff'
    }
  },
  parentContainer: {
    display: 'flex',
    width: '100%'
  },
  formControl: {
    width: '20%'
  },
  filesContainer: {
    width: '100%',
    marginBottom: '10px',
    minHeight: '40px',
    backgroundColor: 'white',
    textAlign: 'center',
    display: 'block'
  },
  leftContainer: {
    width: 'calc(50% - 20px)',
    backgroundColor: 'white',
    marginRight: '20px',
    padding: '1em 1.5em',
    minHeight: '600px',
    position: 'relative'
  },
  rightContainer: {
    width: '53%',
    backgroundColor: 'white',
    marginLeft: '20px',
    padding: '1em 2em',
    minHeight: '600px'
  },
  paginationRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  fileInfoContainer: {
    width: '100%',
    display: 'flex',
    padding: '4px 0',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  hoverPointer: {
    padding: '4px 0',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  backButton: {
    width: '10%',
    margin: 'auto auto auto 0',
    color: '#e1e3e4'
  },
  leftHeader: {
    width: '100%',
    display: 'flex'
  },
  searchButton: {
    width: '60%',
    borderRadius: '8px',
    border: 'solid 1px #e1e3e4',
    backgroundColor: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    maxWidth: '240px',
    minHeight: '38px',
    padding: '0 12px',
    float: 'left'
  },
  searchInput: {
    border: 'none',
    width: 'calc(100% - 32px)',
    '&:focus': {
      outline: 'none'
    }
  },
  fileContainer: {
    margin: '40px 2rem 1rem 44px',
    maxHeight: '90vh',
    overflow: 'auto',
    minHeight: '600px'
  },
  fileContentHeader: {
    display: 'flex'
  },
  paginationButton: {
    minWidth: 'auto',
    padding: 0,
    fontSize: '12px',
    color: 'black !important'
  },
  rightMenu: {
    display: 'flex',
    marginLeft: 'auto'
  },
  zoomInput: {
    maxWidth: '24px',
    height: '24px',
    fontSize: '12px',
    textAlign: 'center',
    margin: 'auto 8px'
  },
  borderBox: {
    maxWidth: '40px',
    border: '1px solid #737373',
    padding: '2px',
    color: '#000000',
    borderRadius: '2px',
    margin: 'auto 2px'
  },
  pageInput: {
    maxWidth: '24px',
    fontSize: '10px',
    textAlign: 'center',
    margin: 'auto 8px'
  },
  italicSmall: {
    fontStyle: 'Italic',
    fontSize: '12px'
  },
  smallIcon: {
    fontSize: '12px'
  },
  zoomArea: {
    marginRight: '32px'
  },
  highlightedValue: {
    backgroundColor: '#e5f0f9'
  },
  menuIcon: {
    width: '10%',
    textAlign: 'right',
    margin: 'auto 0px auto auto',
    position: 'relative',
    color: '#959daf'
  },

  moreIcon: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  menuContainer: {
    position: 'absolute',
    top: '32px',
    right: 0,
    minWidth: '215px',
    maxWidth: '250px',
    borderRadius: '10px',
    boxShadow: '0 5px 2px 0 rgba(209, 218, 229, 0.7)',
    border: 'solid 1px #e1e3e4',
    backgroundColor: '#ffffff',
    zIndex: '9'
  },
  menuItem: {
    fontSize: '15px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.18,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#1e2a2a',
    padding: '12px'
  }

};


//Add any additional images types we can safetly load
//To be removed once zoom functionality is reworked for viewing documents
const validImgTypes = ['png', 'jpeg'];

class ResultViewer extends React.Component {

  state = {
    activeId: null,
    isAggView: false,
    docIndex: 0,
    modules: AllModules,
    openModal: false,
    notificationModal: false,
    tableRow: {headers: [], rows: []},
    tableData: null,
    documents: null,
    doc_list: [],
    aggTable: null,
    capabilities: [],
    numPages: null,
    pageNumber: 1,
    fileURL: '',
    search: '',
    tableUpdating: false,
    data: null,
    documentBlobs: [],
    resultTypes: [],
    selectedResult: '',
    boundingRegion: null,
    open: false,
    zoomValue: 100,
    fileKeys: [],
    suggestions: [],
    value: '',
    selectedKey: '',
    selectedProperty: null,
    enabledIndex: null,
    enabledColIndex: null,
    filterDetails: false,
    filterFlag: false,
    doneEditing: [],
    pollingTimer: '',
    key: 1,
    query: '',
    scope: 1,
    viewId: 0,
    rowId: -1,
    loadingLeft: true,
    loadingRight: true,
    classifyLoading: false,
    confidence: true,
    csvData: [],
    activeDoc: null,
    docData: null,
    openCapModal: false,
    minHeight: 300,
    winHeight: 800,
    winWidth: 800,
    resultDate: '',
    newResultDate: '',
    columnDefs: [],
    rowData: [],
    tableName: '',
    selectedTableIndex: null,
    resultViewerFiles: [],
    selectedFile: null,
    editingEnabled: false,
    statusBar: {
      statusPanels: [
        {
          statusPanel: 'agTotalAndFilteredRowCountComponent',
          align: 'left'
        },
        {
          statusPanel: 'agTotalRowCountComponent',
          align: 'center'
        },
        {statusPanel: 'agFilteredRowCountComponent'},
        {statusPanel: 'agSelectedRowCountComponent'},
        {statusPanel: 'agAggregationComponent'}
      ]
    },
    // sideBar works only if we have proper column definitions ie we know the datatypes of each and every column
    // Included a hardcoded example of columnDefs array
    sideBar: {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel'
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel'
        }
      ]
    },
    showColumnDescModal: false,
    showEditCellsModal: false,
    batchEdit: {},
    processing: [],
    showExportMenu: false,
    anchorEl: null
  };

  static propTypes = {
    classes: PropTypes.object.isRequired,
    changeTab: PropTypes.func.isRequired
  };

  controller = new window.AbortController();

  headers = [
    {label: 'Label', key: 'label'},
    {label: 'Value', key: 'value'}
  ];

  componentDidUpdate(prevProps, prevState) {
    /*
    A polling mechanism retrieves state of documents asynchronously
    within Routes.js. This data serves as a feedback to determine
    the actual states of documents in the ResultViewer.

    Routes.js provides an array of processing ids (resultViewerProcessing)
    which is reconciled with the documents "known" to be processing
    in ResultViewer (also an array, stored on state as "processing").
    */
    const {resultViewerProcessing, arraysEqual} = this.props;
    let {processing, id} = this.state;
    let newProcessing = [...processing];
    id = id.split(',');
    const processingStatus = 'Processing';
    const terminalStatus = ['Complete', 'Failed'];
    /*
    processing of a result viewer document transitioned
    from true -> false (note that these props originate in
    routes.js from asynchronous polling). This case represents
    a completed document.
    */

    id.forEach(id => {
      if (!Object.keys(prevProps.resultViewerProcessing).length) {
        return;
      }
      if(prevProps.resultViewerProcessing[id] &&
        prevProps.resultViewerProcessing[id]['document_status']
          === processingStatus &&
          terminalStatus.includes(
              resultViewerProcessing[id]['document_status']))
      {
        if (newProcessing.includes(id)){
          newProcessing.splice(newProcessing.indexOf(id), 1)
        }
        // Edit lock will be removed in getTaskResults below
        // (need to fetch results before lock removal):
        getTaskResults(id, this);
      }
    });
    /*
    The case below detects the completion of an aggregate
    table classification. Upon completion, it calls getAggTable
    to refresh the data and unlock the table.
    */
    if (prevState.processing.length &&
      !this.state.processing.length) {
      getAggTable(this);
    }
    /*
    This catches non user-initiated processing events
    */
    id.forEach(id => {
      if (!Object.keys(resultViewerProcessing).length) {
        return;
      }
      if (resultViewerProcessing[id]['document_status'] === processingStatus) {
        if (!newProcessing.includes(id)) {
          newProcessing.push(id);
        }
      }
    });
    let newState = {};
    /*
    To generate the csv data, we need the aggregate table, the document list,
    and the document data. Once all three are updated the right csv data will
    be generated
    */
    let arr = JSON.stringify(generateCsvData(this));
    let prevArr = JSON.stringify(prevState.csvData);
    if (prevState.csvData === null || prevArr !== arr) {
      newState['csvData'] = generateCsvData(this);
    }
    // Determine if processing needs update
    if (!arraysEqual(processing, newProcessing)) {
      newState['processing'] = newProcessing;
    }
    // batch call to state
    if (Object.keys(newState).length) {
      this.setState(newState);
    }
  }

  componentDidMount() {
    /*
      Result viewer loading flow
      If only 1 doc
      - Get document info immediately
      - Get task_result
      - Log result date
      - render data

      If multiple docs
      - Get aggregate document info
      - Get aggregate task_results
      - Log result date????
      - render data
    */

    const {changeTab} = this.props;
    changeTab('result');
    const urlParams = new URLSearchParams(window.location.search);
    /*
    Note: We should expect the id url param to be one or many uuids
    */
    let id = urlParams.get('id');

    // Attaching our org capabilities as classes so we can use css
    // to hide / show based on capability
    let capabilities = getUserOrgCapabilities();
    capabilities = JSON.parse(capabilities);
    capabilities = capabilities['document-extractor'];
    _.forEach(capabilities, (cap) => {
      // log(cap)
      document.body.classList.add(cap);
    });

    // Init Search >> Need to refactor
    let jsSearch = new JsSearch.Search('index');
    jsSearch.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
    jsSearch.addIndex('value');
    jsSearch.addIndex('label');
    jsSearch.addIndex(['items', 'label']);
    jsSearch.addIndex(['items', 'value']);
    jsSearch.addIndex(['items', 'elements', 'label']);
    jsSearch.addIndex(['items', 'elements', 'value']);
    jsSearch.addIndex(['enriched_data', 'label']);
    jsSearch.addIndex(['enriched_data', 'value']);

    this.setState({
      id: id, // this can be many uuids
      search: jsSearch,
      minHeight: Math.floor(window.outerHeight * 0.2),
      winHeight: Math.floor(window.outerHeight),
      winWidth: Math.floor(window.outerWidth),
      capabilities: capabilities
    }, () => {
      // Load the Doc(s)
      getDocuments(this);
      getResultDate(true, this);
      getTaskResults(null, this);
      getAggTable(this);
      renderDocFromBlob(this);
    });

    // console.clear()
  }

  componentWillUnmount() {
    clearInterval(this.state.pollingTimer);

    // Should prevent rerendering of the component in case state changes for the specified variables
    // Currently not working, so commented out. Also uncomment the setState() in clearFilters() to work with shouldComponentUpdate
    // shouldComponentUpdate(nextProps, nextState) {
    //   if(this.state.filterFlag != nextState.filterFlag && this.state.value != nextState.value  && this.state.value != nextState.value && this.state.suggestions != nextState.suggestions && this.state.filterDetails != nextState.filterDetails) {
    //        return false
    //   }
    //   return true
  }

  scrollToBottom() {
    this.el.scrollIntoView({behavior: 'smooth'});
  }

  updateActiveDoc = (id, regions, index) => {

    const {activeId, documents} = this.state;
    const newDoc = documents[id];
    
    this.setState({
      activeRegions: regions,
      activeIndex: index
    })

    if (id !== activeId) {
      this.setState({
        activeId: id,
        activeDoc: newDoc,
        activeRegions: regions,
        activeIndex: index
      }, () => {
        // this.clearCanvas()
          renderDocFromBlob(this);
      });
    }

  };

  onDocumentLoadSuccess = ({numPages}) => {
    this.setState({numPages});

    setTimeout(() => {
      const {activeRegions, activeIndex} = this.state;
      this.goToPage(activeRegions, activeIndex);
    }, 100);
  };

  goToPrevPage = () => {
    this.setState(state => ({pageNumber: state.pageNumber - 1, key: state.key + 1}), this.clearCanvas);
  };

  goToNextPage = () => {
    this.setState(state => ({pageNumber: state.pageNumber + 1, key: state.key + 1}), this.clearCanvas);
  };


 setupCanvas = (canvas) => {
    // Get the device pixel ratio, falling back to 1.
    var dpr = window.devicePixelRatio || 1;
    // Get the size of the canvas in CSS pixels.
    var rect = canvas.getBoundingClientRect();
    // Give the canvas pixel dimensions of their CSS
    // size * the device pixel ratio.
    canvas.width = rect.width * dpr;
    canvas.height = rect.height * dpr;
    var ctx = canvas.getContext('2d');
    // Scale all drawing operations by the dpr, so you
    // don't have to worry about the difference.
    ctx.scale(dpr, dpr);
    return ctx;
  };

  /**
  * GoToPage - set page number and draws a line from extracted value in LeftContainer to region 
  * in RightContainer
  *
  * @param {*} region {}
  * @param {*} id {}
  * @param {*} row {}
  * @param {*} isTable {}
  * @returns {*} {}
  * @memberof ResultViewer
  */
  goToPage = (regions, id, row, isTable) => {
    // This function needs to evaluate whether we have rendered a view for the document or not
    let width = 0;
    try {
      for (let i = 0; i < row.length; ++i) {
        if (typeof row[i].id !== 'string')
          width += row[i].regions[0].width;
      }
      
      regions = [{
        page: row[0].regions[0].page,
        type: row[0].regions[0].type,
        
        x: row[0].regions[0].x,
        y: row[0].regions[0].y,
        
        height: row[0].regions[0].height,
        width: width
      }];
    } catch (e) {
      // log(e)
    }
    
    if (regions === undefined || regions.length === 0 || regions[0].x === undefined) {
      log(regions)
      return true;
    }

    const {key} = this.state;
    this.setState({pageNumber: regions[0].page, key: key + 1, viewId: id}, async () => {

        const {activeId, activeIndex} = this.state;
        // Highlight the selected row
        jQuery('.highlightRow').removeClass('highlightRow');
        let activeRow = jQuery('#dataRow_' + activeId + '_' + activeIndex);
        activeRow.addClass('highlightRow');
  
        // Edit row if requested
        if (row){
          this.enableEditing(row)
        }
  
        let boundingRegion = regions.find(a => a.type === 'bounding');
        if (boundingRegion === undefined){
          log('Regions not found')
          boundingRegion = regions[0];
        }
  
        let mainCanvas = jQuery('#nvDoc');

        // try removing top canvas first so it reinits everytime
        let canvas = document.getElementById('topCanvas');
        if(canvas !== null){
          canvas.parentNode.removeChild(canvas);
        }
        if (canvas === null || canvas === undefined) {
          log('recreating canvas')
          canvas = document.createElement('canvas');
          canvas.setAttribute('id', 'topCanvas');
        }


        let scale = window.devicePixelRatio || 1;
  
        /*
        There is a bug here where if the scale of the device is above 1, 
        it will reprocess the canvas size repeatedly leading to render errors
        The size of the canvas is repeatedly reduced
        See here: https://snipboard.io/bJ4pe3.jpg

        */


        // let ctx = this.setupCanvas(canvas)

        canvas.height = mainCanvas.height();
        canvas.width = mainCanvas.width();

        // Making sure to resize our highlight canvas to pdf canvas size... 
        // TODO: Intelligently resize the pdf canvas to container 
        let pdfCanvas = mainCanvas.find('canvas.react-pdf__Page__canvas').eq(0);
        log(pdfCanvas)
        if ( pdfCanvas.length > 0){
          canvas.height = pdfCanvas.height();
          canvas.width = pdfCanvas.width();
        }

        var ctx = canvas.getContext('2d');
        // Scale all drawing operations by the dpr, so you
        // don't have to worry about the difference.
        // ctx.scale(dpr, dpr);

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.strokeStyle = '#3399ff';
    
        // right hand region for extracted data
        ctx.strokeRect(
          boundingRegion.x * canvas.width - 2, 
          boundingRegion.y * canvas.height - 2, 
          boundingRegion.width * canvas.width + 4, 
          boundingRegion.height * canvas.height + 4
        );
        ctx.fillStyle = '#e1edf9';
        ctx.globalAlpha = 0.4;
        ctx.fillRect(boundingRegion.x * canvas.width, boundingRegion.y * canvas.height, boundingRegion.width * canvas.width, boundingRegion.height * canvas.height);
        mainCanvas.append(canvas);

        if (isTable) {
          /**
           * Was used to highlight selected row of the table in the document.
           * With AgGrid coming into play, not being used anymore.
          **/
        } else {

        }
    
        let div = document.getElementById('middleDiv');
        let height = div.clientHeight;
        let width = div.clientWidth;

        let lineCanvas = document.getElementById('lineCanvas');
        if (lineCanvas === null) {
          lineCanvas = document.createElement('canvas');
          lineCanvas.setAttribute('id', 'lineCanvas');
        }
    
        lineCanvas.width = width;
        lineCanvas.height = height;
        let leftBox = document.getElementsByClassName('highlightRow')[0];
        let c = lineCanvas.getContext('2d');
    
        if (leftBox === undefined) {
          // just bail if we can't draw the line
          return false;
        }
    
        c.beginPath();
        c.lineWidth = 2;
        c.arc(6, leftBox.getBoundingClientRect().top - div.getBoundingClientRect().top + leftBox.clientHeight / 2, 4, 0, 2 * Math.PI);
        c.moveTo(10, leftBox.getBoundingClientRect().top - div.getBoundingClientRect().top + leftBox.clientHeight / 2);
        c.lineTo(width / 2, leftBox.getBoundingClientRect().top - div.getBoundingClientRect().top + leftBox.clientHeight / 2);
        c.lineTo(width / 2, canvas.getBoundingClientRect().top - div.getBoundingClientRect().top + boundingRegion.y * canvas.height + boundingRegion.height * canvas.height / 2);
        c.lineTo(width - 10, canvas.getBoundingClientRect().top - div.getBoundingClientRect().top + boundingRegion.y * canvas.height + boundingRegion.height * canvas.height / 2);
        c.arc(width - 6, canvas.getBoundingClientRect().top - div.getBoundingClientRect().top + boundingRegion.y * canvas.height + boundingRegion.height * canvas.height / 2, 4, 0, 2 * Math.PI);
        c.strokeStyle = '#5EAEFF';
        c.stroke();
        c.strokeStyle = '#5EAEFF';
        c.fillStyle = "#FFFFFF"; 
        c.beginPath();
        c.arc(width - 6, canvas.getBoundingClientRect().top - div.getBoundingClientRect().top + boundingRegion.y * canvas.height + boundingRegion.height * canvas.height / 2, 2.8, 0, 2 * Math.PI);
        c.closePath();
        c.fill();
        div.append(lineCanvas);

      try {
      } catch (e) {
        if (e instanceof TypeError)
          // toast.error(this.props.t("result-viewer.error-highlight"))
        console.log('Error rendering highlights');
        console.warn(e);
      }
    });
  };

  clearCanvas = () => {
    this.setState({viewId: 0}, () => {
      jQuery('.highlightRow').removeClass('highlightRow');
      let canvas = document.getElementById('topCanvas');
      let lineCanvas = document.getElementById('lineCanvas');
      if (canvas !== null) {
        canvas.outerHTML = '';
      }
      if (lineCanvas !== null) {
        lineCanvas.outerHTML = '';
      }
    });
  };

  clearFilters = () => {
    jQuery('.dataresult').removeClass('hide');
    this.setState({
      suggestions: [],
      filterDetails: false,
      filterFlag: false,
      value: ''
    });
  };

  enableEditing = (row) => {
    console.log('editing')
    jQuery('.highlightRow').removeClass('highlightRow');
    let activeRow = jQuery('#dataRow_' + row.uuid + '_' + row.index);
    activeRow.addClass('highlightRow');
    activeRow.find('.rowValue').addClass('hide');
    activeRow.find('.rowField').removeClass('hide');
    
    const index = row.index ? row.index : row.id
    document.getElementById('enableEditing' + index).focus();

    // log('enabling editing for:', index);
    // if (typeof eindex !== 'undefined') {
    //   this.setState({
    //     enabledIndex: 'enriched_' + index
    //   }, () => {
    //     document.getElementById('enableEditing' + index).focus();
    //   });
    // }
    // else if (index !== "undefined"){
    //   this.setState({
    //     enabledIndex: index
    //   });
    // }
  };

  disableEditing = (uuid, value, index, eindex) => {
    /*
      Todo: update the name of this function to something like saveFieldChange
      So that it's clearer what it actually does.

      The challenge we have is this:
      - Normalize some wacky json structure to and organized visual layout
      - Allow for deep editing of that json structure from the frontend
      - Efficiently save those changes to the db
      - Efficiently update state

    */

    jQuery('.rowField').addClass('hide');
    jQuery('.rowValue').removeClass('hide');

    const {docData, doneEditing} = this.state;
    let updatedList = {...doneEditing};
    let tempData = {...docData};
    // log(docData[uuid])
    // return

    docData[uuid].data.extracted_data = this.replaceValue(value, index, docData[uuid].data.extracted_data);
    updatedList = this.state.doneEditing.concat(index);
    this.setState({
      enabledIndex: null,
      docData: docData,
      doneEditing: updatedList,
      editingEnabled: false,
    }, () => {
      PatchRequestV2(
        URL.getFeedbackUrl + '/' + uuid + '/update_data',
        this.controller.signal,
        {data: docData[uuid].data},
        (d) => {

        }, (err) => {
          this.setState({docData: tempData});
        }
      );
    });
  };

  setEditingEnabled = value => {
    this.setState({editingEnabled: value})
  }
  
  replaceValue = (value, index, fileData) => {
    /*for (let i = 0; i< tempData.length; i++) {
      let item = tempData[i];
      if (item.type === 'field') {
        if (item.index === index) {
          tempData[i].value = value;
          return tempData;
        }
      } else {
        for (let j = 0; j < item.items.length; j++) {
          for(let k =0; k < item.items[j].elements.length; k++) {
            let innerItem = item.items[j].elements[k];
            if (innerItem.type === 'field' && innerItem.index === index) {
              tempData[i].items[j].elements[k].value= value;
              return tempData;
            }
          }
        }
      }
    }*/
    if (fileData.items && fileData.items.length > 0) {
      for (let j = 0; j < fileData.items.length; j++) {
        for (let k = 0; k < fileData.items[j].elements.length; k++) {
          let innerItem = fileData.items[j].elements[k];
          if (innerItem.type === 'field') {
            if (innerItem.index === index) {
              fileData.items[j].elements[k].value = value;
              return fileData;
            }
          } else {
            fileData.items[j].elements[k] = this.replaceValue(value, index, fileData.items[j].elements[k]);
            return fileData;
          }
        }
      }
    } else {
      for (let i = 0; i < fileData.length; i++) {
        let item = fileData[i];
        if (item.type === 'field') {
          if ((item.index ? item.index : item.id) === index) {
            fileData[i].value = value;
            return fileData;
          }

        } else {
          for (let j = 0; j < item.items.length; j++) {
            for (let k = 0; k < item.items[j].elements.length; k++) {
              let innerItem = item.items[j].elements[k];
              if (innerItem.type === 'field') {
                if ((innerItem.index ? innerItem.index : innerItem.id) === index) {
                  fileData[i].items[j].elements[k].value = value;
                  return fileData;
                }

              } else {
                fileData[i].items[j].elements[k] = this.replaceValue(value, index, item.items[j].elements[k]);
                return fileData;
              }
            }
          }
        }
      }
    }
  }

  handlePageChange = (event) => {
    const {name, value} = event.target;
    const {numPages, key} = this.state;
    const int = parseInt(value);
    if (int > 0 && int <= numPages) {
      this.setState(prevState => ({...prevState, [name]: int}));
    } else if (int > numPages || int < 0) {
      this.setState({key: key + 1});
    }
  };

  handleZoomPlus = async () => {
    this.setState(state => ({scope: Number((Math.ceil((this.state.scope + 0.001) * 4) / 4).toFixed(2))}), this.clearCanvas);
  };

  handleZoomDown = () => {
    this.setState(state => ({scope: Number((Math.floor((state.scope - 0.001) * 4) / 4).toFixed(2))}), this.clearCanvas);
  };

  handleZoomPlusImage = () => {
    this.setState(state => ({scope: Number((Math.ceil((this.state.scope + 0.001) * 4) / 4).toFixed(2))}), () => {
      this.clearCanvas();
      const image = document.getElementById('imageRight');
      image.style.width = `${this.state.scope * 100}%`;
      image.style.height = 'auto';
    });
  };

  handleZoomDownImage = () => {
    this.setState(state => ({scope: Number((Math.floor((state.scope - 0.001) * 4) / 4).toFixed(2))}), () => {
      this.clearCanvas();
      const image = document.getElementById('imageRight');
      image.style.width = `${this.state.scope * 100}%`;
      image.style.height = 'auto';
    });
  };

  onSearch = (event) => {
    let newValue = event.target.value;
    if (!newValue || newValue.length === 0) {
      this.clearFilters();
    } else {
      this.setState({
        filterFlag: true,
        value: newValue
      });
      let results = this.state.search.search(event.target.value);
      jQuery('.dataresult').addClass('hide');

      _.forEach(results, (result) => {
        console.log('Result', result.label.toLowerCase());
        console.log('Event Target Value ', event.target.value.toLowerCase());
        console.log(result.label.toLowerCase().indexOf(event.target.value.toLowerCase()));
        if (result.label.toLowerCase().indexOf(event.target.value.toLowerCase()) >= 0) {
          console.log(jQuery('.dataresult:contains(\'' + result.label + '\')'));
          jQuery('.dataresult:contains(\'' + result.label + '\')').removeClass('hide');
        }
      });
    }
  };

  handleClose = (value) => {
    this.setState({open: value});
  };

  updateResult = (item) => {
    // This updates the result type for the document
    const {selectedResult} = this.state;
    if (item['result_type'] !== selectedResult['result_type']) {
      // Tech Debt.  This should write back to the db
    }
  };

  toggleConfidence = (e) => {
    this.setState({confidence: e.target.checked});
  };

  closeDeleteForeverModal = () => {
    this.setState({deleteForeverModal: false});
  };

  closeFolder = () => {
    /*
    * Function is called upon closing a table
    * Resets table data, closes modal, sets aggView to false.
    * */
    this.setState({
      isAggView: false,
      tableData: null,
      openModal: false
    });
  };

  setAggView = () => {
    const {aggTable} = this.state;
    this.setState({
        isAggView: true
      },
      () => handleTableView(aggTable, this));
  };

  openNotifyModal = (value) => {
    this.setState({notificationModal: value});
  };

  closeNotifyModal = () => {
    this.setState({notificationModal: false});
  };

  /**
   * Generates the name of a file given the parameters
   *
   * @param {*} path {The path (collection) the file belongs to}
   * @param {*} docName {The name of the file}
   * @param {*} extension {The extension of the file}
   * @param {*} tableName {The name of the table we are looking at (if any)}
   * @returns {*} {Generated name for the download file}
   * @memberof ResultViewer
   */
  createName = (path, docName, extension, tableName) => {
    const {capabilities} = this.state;

    let fileName = '';

    // path (and all fields below could be: null, '', undefined)
    // all of which return false in an if statement

    if (capabilities.indexOf('property_search') < 0) {

      if (path) {
        fileName += path + '_';
      }

      if (docName) {
        let parsedName = docName.replace(/[ ,.]/g, '_');
        fileName += parsedName + '_';
      }

      if (tableName) {
        fileName += tableName + '_';
      }

      fileName += 'export';

      if (extension) {
        fileName += extension;
      }

    } else {
      fileName = docName.replace(/[ ,.]/g, '_') + '_Summary';
    }

    return fileName;
  }

  onTableSearch = (e) => {
    this.gridApi.setQuickFilter(document.getElementById('filter-text-box').value);
  };

  handleChange = e => {
    const id = e.target.value;
    const {history} = this.props;
    history.push(`/results?id=${id}`);
    this.getDocument(id);
  };

  modalOpen = (value) => {
    this.setState({openModal: value});
  };

  toggleChart = value => {
    let {chartHeight, chartWidth} = this.state;

    if (!value) {
      chartHeight = 300;
      chartWidth = 500;
    }

    this.setState({showChart: value, chartHeight, chartWidth});
  };

  tooltip = (x, y, e) => {
    return x.toString() + ': ' + y.toString();
  };

  onResizeStop = e => {
    const chartWidth = jQuery('.chartDiv')[0].clientWidth;
    const chartHeight = jQuery('.chartDiv')[0].clientHeight;
    this.setState({chartWidth, chartHeight});
  };

  onRangeSelectionChanged = e => {
    this.gridApi = e.api;
    this.gridColumnApi = e.columnApi;
    if (this.state.showChart)
      renderChart(e);
  };

  addToBrowserHistory = e => {
    const id = e.target.value;
    const {history} = this.props;
    history.push(`/results?id=${id}`);
    // this.getDocument() // Refactor
  };

  toggleColDescriptionModal = () => {
    const showModal = this.state.showColumnDescModal;
    let stateValues = {
      showColumnDescModal: !showModal,
      colDescription: '',
      filterFlag: false
    };

    if (!showModal) {
      const params = this.state.colDescData.params;
      stateValues['colDescription'] = params.column.colId;
    }

    this.setState({...stateValues});
  };

  toggleEditCellsModal = () => {
    const showModal = this.state.showEditCellsModal;
    let stateValues = {
      showEditCellsModal: !showModal,
      cellEditVal: '',
      filterFlag: false
    };

    if (!showModal) {
      const params = this.state.cellData.params;
      stateValues['batchEdit'] = {
        noOfRows: params.api.getSelectedNodes().length,
        column: params.column.colId,
        name: params.column.headerName
      };
      stateValues['cellEditVal'] = params.value;
    } else {
      stateValues.batchEdit = {};
    }

    this.setState({...stateValues});
  };

  setColumnDescription = (event) => {
    const {name, value} = event.target;
    this.setState({[name]: value});
  };

  setCellEditValue = (event) => {
    const {name, value} = event.target;
    this.setState({[name]: value});
  };

  toggleMenu = () => {
    this.setState({
      showExportMenu: !this.state.showExportMenu
    });
  };

  rowDoubleClicked = (e) => {
    const {processing, activeId} = this.state;
    const currentIdProcessing = processing.includes(activeId);
    if (currentIdProcessing) {
      toast.info('Editing is disabled while processing',
        {
          position: 'bottom-right',
          hideProgressBar: true,
          autoClose: 3000,
          toastId: 'noEdit'
        });
    }
  };

  cellClicked = e => {
    if (e.column.colId.toLowerCase() === 'document') {
      const splitList = e.value.split('-');
      const tableLabel = splitList[splitList.length - 1].trim();
      const filteredTableList = this.state.docData[e.data.uuid].data.extracted_data.filter(obj => obj.label === tableLabel);
      if (filteredTableList.length > 0)
        handleTableView(filteredTableList[0], this);
    }
  }

  handleClick = event => {
    this.setState({anchorEl: event.currentTarget});
  }

  handleCloseMenu = () => {
    this.setState({anchorEl: null});
  }

  setGridOptions = (params) => {
    this.gridOptions = params;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  setProcessingIds = (docIds) => {
    const {processing} = this.state;
    let newProcessing = [...processing];
    docIds.forEach(id => {
      if (!newProcessing.includes(id)) {
        newProcessing.push(id);
      }
    });
    this.setState({
      processing: newProcessing
    }, () => this.generateTasks(docIds));
  }

  /**
   * This function generates multiple tasks for a list of uuids, and handles toast message
   *
   * @param {*} docIds The list of ids who's task should be created
   * @memberof ResultViewer
   */
  generateTasks = async (docIds) => {
    const docSuccesses = {};
    const docFails = [];
    for (const id of docIds) {
      await this.generateTask(id).then(name => docSuccesses[id] = name).catch(err => docFails.push(id));
    }
    //Handle notifying the user of successes
    if (Object.keys(docSuccesses).length === 1) {
      toast.info(docSuccesses[Object.keys(docSuccesses)[0]] + ' has been submitted for processing');
    } else if (Object.keys(docSuccesses).length > 1) {
      toast.info('You\'ve submitted ' + Object.keys(docSuccesses).length + ' documents for processing');
    }

    //Handle notifying the user of any failures
    if (docFails.length > 0) {
      toast.error(`There was an issue with ${docFails.length} document${docFails.length !== 1 ? 's' : ''}`);
    }
  }

  /**
   * This function generates a task for the given uuid
   *
   * @param {*} uuid the uuid of the document we are generating the task for
   * @returns {Promise} Promise that can be used whether or not to send toastify messages
   * @memberof ResultViewer
   */
  generateTask = (uuid) => {
    const {isAggView, tableData} = this.state;
    if (tableData['uuid'] === uuid || isAggView) {
      handleTableView(tableData, this);
    }
    let payload = {'id': uuid, capability: 'tax_classifier'};

    return new Promise((resolve, reject) => {
      PostRequest(URL.generateTask, this.controller.signal, payload,
        (data) => {
          //If specified to not notify the user, do not notify the user
          const oldFileName = this.state.activeDoc.name;
          const fileName = oldFileName.split('.')[0];
          const fileExt = oldFileName.split('.')[1];
          const shortName =
            fileName.length > 4
              ? fileName.substring(0, 3) + '...'
              : fileName;
          const newName = shortName + fileExt;
          resolve(newName);
        }, (err) => {
          log(err);
          reject(err);
        });
    });
  }

  render() {
    const open = Boolean(this.state.anchorEl);
    const {t, classes, history} = this.props;

    const {
      activeDoc, openModal, notificationModal, pageNumber, numPages, fileURL, docData, activeId,
      confidence, loadingLeft, loadingRight, filterFlag, scope, filterDetails,
      csvData, documents, doc_list, docIndex, doneEditing, pdfData, capabilities,
      minHeight, winHeight, winWidth, columnDefs, rowData, value, aggTable,
      modules, resultViewerFiles, selectedFile, showChart, chartData, chartLegends, isAggView,
      showColumnDescModal, colDescription, colDescData, showEditCellsModal, cellEditVal,
      cellData, statusBar, sideBar, batchEdit, processing, tableName, anchorEl, editingEnabled
    } = this.state;

    let currentIdProcessing = processing.includes(activeId);
    let locked = (isAggView && processing.length) || currentIdProcessing;

    return (
      <Fragment>
        {selectedFile &&
        <div className={classes.filesContainer}>
          <FormControl className={classes.formControl}>
            <Select
              labelId="result-file-label"
              id="result-file-id"
              value={selectedFile ? selectedFile : ''}
              onChange={this.addToBrowserHistory}
            >
              {resultViewerFiles.length > 0 && resultViewerFiles.map(obj => (
                <MenuItem
                  key={obj.id}
                  value={obj.uuid}>
                  {obj.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        }

        <div className={classes.parentContainer} ref={el => {this.el = el;}} key={Utils.genKey}>
          <LeftContainer
            _this={this}
            classes={classes}
            loadingLeft={loadingLeft}
            documents={documents}
            aggTable={aggTable}
            activeDoc={activeDoc}
            csvData={csvData}
            pdfData={pdfData}
            history={history}
            value={value}
            filterFlag={filterFlag}
            confidence={confidence}
            docData={docData}
            doc_list={doc_list}
            doneEditing={doneEditing}
            capabilities={capabilities}
            filterDetails={filterDetails}
            docIndex={docIndex}
            editingEnabled={editingEnabled}
            handleTableView={handleTableView}
            setEditingEnabled = {this.setEditingEnabled}
            createName={this.createName}
            clearFilters={this.clearFilters}
            onSearch={this.onSearch}
            toggleConfidence={this.toggleConfidence}
            handleBack={this.handleBack}
            showExportMenu={this.showExportMenu}
            updateActiveDoc={this.updateActiveDoc}
            goToPage={this.goToPage}
            disableEditing={this.disableEditing}
            enableEditing={this.enableEditing}
            setAggView={this.setAggView}
          />

          <RightContainer
            classes={classes}
            open={notificationModal}
            loadingRight={loadingRight}
            fileURL={fileURL}
            validImgTypes={validImgTypes}
            activeDoc={activeDoc}
            pageNumber={pageNumber}
            numPages={numPages}
            scope={scope}
            handleZoomDown={this.handleZoomDown}
            handleZoomPlus={this.handleZoomPlus}
            handlePageChange={this.handlePageChange}
            handleZoomDownImage={this.handleZoomDownImage}
            onDocumentLoadSuccess={this.onDocumentLoadSuccess}
            closeNotifyModal={this.closeNotifyModal}
            clearCanvas={this.clearCanvas}
            goToPrevPage={this.goToPrevPage}
            goToNextPage={this.goToNextPage}
            handleZoomPlusImage={this.handleZoomPlusImage}
          />
        </div>

        <AgGridTable
          _this={this}
          t={t}
          classes={classes}
          openModal={openModal}
          openActionMenu={open}
          showChart={showChart}
          winWidth={winWidth}
          winHeight={winHeight}
          chartData={chartData}
          chartLegends={chartLegends}
          minHeight={minHeight}
          anchorEl={anchorEl}
          isAggView={isAggView}
          processing={processing}
          activeId={activeId}
          doc_list={doc_list}
          tableName={tableName}
          locked={locked}
          columnDefs={columnDefs}
          rowData={rowData}
          modules={modules}
          statusBar={statusBar}
          sideBar={sideBar}
          currentIdProcessing={currentIdProcessing}
          getContextMenuItems={getContextMenuItems}
          getColumnMenuItems={getColumnMenuItems}
          toggleChartForData={toggleChartForData}
          handleName={handleName}
          updateData={updateData}
          modalOpen={this.modalOpen}
          toggleChart={this.toggleChart}
          onResizeStop={this.onResizeStop}
          setProcessingIds={this.setProcessingIds}
          closeFolder={this.closeFolder}
          onTableSearch={this.onTableSearch}
          handleClick={this.handleClick}
          handleCloseMenu={this.handleCloseMenu}
          toggleMenu={this.toggleMenu}
          gridApi={this.gridApi}
          gridColumnApi={this.gridColumnApi}
          gridOptions={this.gridOptions}
          rowDoubleClicked={this.rowDoubleClicked}
          cellClicked={this.cellClicked}
          defaultColDef={this.state.defaultColDef}
          tooltip={this.tooltip}
          setGridOptions={this.setGridOptions}
        />

        <EditColumnNameModal
          _this={this}
          t={t}
          classes={classes}
          open={showColumnDescModal}
          colDescription={colDescription}
          colDescData={colDescData}
          updateHeader={updateHeader}
          toggleColDescriptionModal={this.toggleColDescriptionModal}
          setColumnDescription={this.setColumnDescription}
        />

        <EditTableCellModal
          t={t}
          _this={this}
          open={showEditCellsModal}
          batchEdit={batchEdit}
          classes={classes}
          cellEditVal={cellEditVal}
          cellData={cellData}
          updateSelectedValuesOfAColumn={updateSelectedValuesOfAColumn}
          toggleEditCellsModal={this.toggleEditCellsModal}
          setCellEditValue={this.setCellEditValue}
        />
      </Fragment>
    );
  }
}

export default withTranslation()(withStyles(styles)(ResultViewer));
