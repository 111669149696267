import { createBrowserHistory } from 'history';
import { PostRequest } from "./requests";
import {URL} from "./url";
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import i18n from "../i18n"
const history = createBrowserHistory();

const ACCESS_TOKEN_KEY = 'access-token';
const REFRESH_TOKEN_KEY = 'refresh-token';
const USER_NAME_KEY = 'user-name';
const USER_ROLE = 'user-role';
const USER_ORG_ID = 'user-org-id';
const IS_FIRST_TIME = 'is-first-time';
const USER_TIMEZONE = 'user-timezone';
const USER_LANGUAGE = 'user-language';
const ORG_CAPABILITIES = '';


const controller = new window.AbortController();


export const getToken = () => localStorage.getItem(ACCESS_TOKEN_KEY);
export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN_KEY);
export const getRole = () => localStorage.getItem(USER_ROLE);
export const getUserName = () => localStorage.getItem(USER_NAME_KEY);
export const getUserOrg = () => localStorage.getItem(USER_ORG_ID);
export const getUserOrgCapabilities = () => localStorage.getItem(ORG_CAPABILITIES);

export const getFirstTime = () => localStorage.getItem(IS_FIRST_TIME);
export const getUserTimezone = () => localStorage.getItem(USER_TIMEZONE);
export const getUserLanguage = () => localStorage.getItem(USER_LANGUAGE);

export const logout = () => {
  PostRequest(URL.logout, controller.signal, [],()=> {
    },
    (err)=> {
      console.log('err',err);
    });
  removeLocalData();
  history.push('/login');
};

export const token_refresh = () => {
  // Just logout for now.
  logout()
  window.location = "/login"


  //TODO: Generate new access token by using refresh token.

  // const payload = {
  //   "refresh": getRefreshToken()
  // }
  //
  // PostRequest(URL.token_refresh, controller.signal, payload,(body)=> {
  //   console.log("body", body)
  //   localStorage.setItem(ACCESS_TOKEN_KEY, body["access"]);
  //  },
  //   (err)=> {
  //     console.log("err", err)
  //     logout()
  //     window.location = "/login"
  //   });
};

const removeLocalData = () => {
  const items = [USER_NAME_KEY, ACCESS_TOKEN_KEY, USER_ROLE, REFRESH_TOKEN_KEY, USER_ORG_ID, USER_TIMEZONE, USER_LANGUAGE, ORG_CAPABILITIES];
  items.map(item => (localStorage.removeItem(item)));
};

export const login = (username, password, successCallback, errorCallback) => {
  removeLocalData();
  const payload = {
   email: username,
    pwd: password,
  };
  PostRequest(URL.login, controller.signal, payload,(body)=> {
      let user_language = body["user_language"] === "" ? "en" : body["user_language"]
      i18n.changeLanguage(user_language);
      let user_timezone = body["user_timezone"];
      user_timezone = user_timezone === "" ? moment.tz.guess() : user_timezone;

      localStorage.setItem(USER_NAME_KEY, body["user_name"]);
      localStorage.setItem(ACCESS_TOKEN_KEY, body["access_token"]);
      localStorage.setItem(REFRESH_TOKEN_KEY, body["refresh_token"]);
      localStorage.setItem(USER_ROLE, body["category"]);
      localStorage.setItem(USER_ORG_ID, body["org_id"]);
      localStorage.setItem(IS_FIRST_TIME, body["is_first_time"]);
      localStorage.setItem(USER_TIMEZONE, user_timezone);
      localStorage.setItem(USER_LANGUAGE, user_language);
      localStorage.setItem(ORG_CAPABILITIES, body["org_capabilities"]);

      successCallback && successCallback();
    },
    (err)=> {
      if(err.message["description"]=== 'ERROR LOGGING IN'){
        errorCallback(true, i18n.t('usrname-pwd-invalid'))
      } else if(err.message["description"] === 'RECORD NOT FOUND'){
        errorCallback(true, i18n.t('msg.usrname-pwd-not-exist'))
      }
      else errorCallback && errorCallback();
  });
};

export const isLoggedIn = () => {
  return getToken();
};
