import React, {Component, Fragment} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Utils from '../../../utils';
import CreateGroup from '../createGroup';
import {Table} from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Link from "@material-ui/core/Link";
import {DeleteRequest, GetRequest, PatchRequest} from "../../../common/requests";
import {URL} from "../../../common/url";
import TextField from "@material-ui/core/TextField";
import GroupIcon from "@material-ui/icons/GroupAdd";
import withStyles from "@material-ui/core/styles/withStyles";
import BackIcon from '@material-ui/icons/KeyboardArrowLeft';
import PersonDisplayIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import { withTranslation } from 'react-i18next';


const styles = {
  personIcon: {
    fontSize: '18px',
    marginRight: '10px'
  },
  buttonAdd: {
    textTransform: 'unset',
    borderRadius: '30px',
    color: '#959CAE',
    marginLeft: '1rem',
    fontSize: '14px',
    padding: '10px 16px'
  },
  buttonRectangle:{
    textTransform: 'unset',
    borderRadius: '6px',
    fontSize: '15px',
    color: '#959CAE',
    marginLeft: '1rem',
    padding: '12px 24px',
  },
  updateButton: {
    fontSize: '15px',
    padding: '16px 24px',
    lineHeight: 1,
    textTransform: 'unset',
    borderRadius: '8px',
    color: '#959CAE',
    marginLeft: '1rem',
    fontWeight: '600 !important'
  },
  groupClick: {
    '&:hover': {
      cursor: "pointer",
      background: '#F4F6F9'
    },
  },
  groupBread: {
    '&:hover': {
      cursor: "pointer",
      outline: 'none'
    },
  },
  headTitle: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  nameTitle: {
    marginTop: '2rem',
  },
  description: {
    marginTop: '1rem',
    marginBottom: '2rem',
  },
  modal: {
    background: 'white',
    position: 'relative',
    width: '450px',
    top: '30%',
    left: '35%',
    textAlign: 'left',
  },
  modalSubject: {
    color: '#141b2d',
    fontSize: '21px',
    fontFamily: '\'Open Sans\', sans-serif !important',
    marginBottom: '24px'
  },
  modalDescription: {
    fontFamily: '\'Open Sans\', sans-serif !important',
    color: '#1e2a5a'
  },
  submit: {
    textAlign: 'right',
    marginTop: '24px'
  },
  actionButton: {
    marginRight: '10px',
    color: '#000000',
    fontWeight: 'bold',
    fontSize: '15px',
    fontFamily: '\'Open Sans\', sans-serif !important'
  },
  detailsTitle: {
    fontFamily: 'Raleway !important',
    marginBottom: '1rem',
    marginTop: '1rem',
    fontSize: '36px',
    fontWeight: '600',
    color: '#1b1b1b',
    lineHeight: '1.2',
    letterSpacing: '0.36px',
    textAlign: 'left'
  },
  groupName: {
    fontSize: '17px',
    fontWeight: 'bold',
    lineHeight: '1.29',
    fontFamily: 'OpenSans'
  },
  editGroupDescription: {
    marginTop: '1.5rem'
  },
  editTitle: {
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  editDescription: {
    width: '70%',
    marginTop: '1rem',
  },
  editName: {
    marginTop: '1rem',
  },
  tableHeader: {
    borderBottom: '2px solid grey'
  },
  colHeader: {
    fontSize: '15px !important',
    // fontFamily: 'OpenSans !important',
    color: '#686868',
    fontWeight: 'bold'
  },
  userCount: {
    marginBottom: '1rem'
  },
  editBack: {
    paddingLeft: 0,
    marginLeft: '-0.5rem',
  },
  helperText: {
    marginRight: '30%',
    textAlign: 'right',
    fontSize: '15px',
    color: '#686868',
    marginTop: '0.5rem',
  },
  nameHelperText: {
    textAlign: 'left',
    fontSize: '15px',
    color: 'hotpink',
  },
  tableIcon:{
    marginRight: '0.5rem',
    marginBottom: '-0.5rem',
  },
  searchBar: {
    width: '50%',
    paddingTop: '1rem',
  },
  loader: {
    position: 'relative',
    left: 'calc(50% - 20px)',
    marginTop: '40px',
    marginRight: 'auto'
  }
};

class Group extends Component{

  state= {
    groupList: [],
    groupCount: 0,
    page: 0,
    groupUserList: [],
    groupUserCount: 0,
    pageUser: 0,
    rowsPerPage: Utils.pageSize,
    search: '',

    openModal: false,
    deleteGroupModal: false,
    selectedGroup: null,
    edit: false,
    name: '',
    description: '',
    groupsLoading: true,
    usersLoading: true,
    nameError: ''
  };

  controller = new window.AbortController();
  cleanup = null;

  componentDidMount() {
    this.cleanup = () => {
      this.controller.abort();
    };
    this.getGroups();
  }

  componentWillUnmount() {
    if (this.cleanup) this.cleanup();
    this.cleanup = null;
  }

  getGroups = (page, pageSize) => {
    const {search, rowsPerPage} = this.state
    this.setState({groupsLoading: true})

    if (page === undefined) {
      page = 1
      this.setState({page: 0})
    }

    if (pageSize === undefined)
      pageSize = rowsPerPage

    const params = {
      org_id: this.props.orgId,
      [Utils.pageQueryParam]: page,
      [Utils.pageSizeQueryParam]: pageSize
    };

    if (search)
      params["search"] = search

    const queryParams = Utils.encodeQueryParams(params)

    GetRequest(URL.groups + "?" + queryParams,(response)=> {
      const {data: groupList, count: groupCount} = response
      this.setState({
        groupList,
        groupCount,
        groupsLoading: false,
      }, () => {});
    }, (err)=> {this.setState({groupsLoading: false})})
  };

  modalOpen = (value) => {
    this.setState({openModal: value});
    if(!value){
      this.getGroups();
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage
    });

    this.getGroups(newPage + 1)
  };

  handleChangeRowsPerPage = (event) => {
    const pageSize = parseInt(event.target.value, 10)
    this.setState({
      page: 0,
      rowsPerPage: pageSize
    });

    this.getGroups(1, pageSize)
  };

  handleChangePageUser = (event, newPage) => {
    this.setState(prevState => ({pageUser: newPage}), () => {
      this.getGroupUsers(newPage + 1, this.state.rowsPerPage)
    })
  };

  handleChangeRowsPerPageUser = (event) => {
    const pageSize = parseInt(event.target.value, 10)
    this.setState({
      pageUser: 0,
      rowsPerPage: pageSize
    });

    this.getGroupUsers(1, pageSize)
  };

  handleGroup = (value) => {
    this.setState({
      selectedGroup: value,
      groupUserList: [],
    }, () => { value && this.getGroupUsers();})
  };

  getGroupUsers = (page, pageSize) => {
    this.setState({usersLoading: true})

    if (page === undefined){
      page = 1
      this.setState({pageUser: 0})
    }

    if (pageSize === undefined)
      pageSize = this.state.rowsPerPage

    const params = {
      org_id: this.props.orgId,
      group_id: this.state.selectedGroup.id,
      [Utils.pageQueryParam]: page,
      [Utils.pageSizeQueryParam]: pageSize
    };
    const queryParams = Utils.encodeQueryParams(params)

    GetRequest(URL.users + "?" + queryParams,(response)=> {
      const {data: groupUserList, count: groupUserCount} = response
      this.setState({
        groupUserList,
        groupUserCount,
        usersLoading: false,
      }, () => {});
    }, (err)=> {this.setState({usersLoading: false})})
  };

  openDeleteGroupModal = () => {
    this.setState({deleteGroupModal: true})
  };

  closeDeleteModal = () => {
    this.setState({deleteGroupModal: false})
  };

  deleteGroup = () => {
    const { selectedGroup } = this.state;

    DeleteRequest(URL.groups +'/'+ selectedGroup.id ,()=> {
      this.handleGroup(null);
      this.getGroups();
      this.closeDeleteModal();
    }, (err)=> {
      console.log(err);
      this.closeDeleteModal();
    });
  };

  updateGroup = () => {
    const { selectedGroup , name , description } = this.state;
    if (name === '' || name.trim().length === 0) {
      this.setState({nameError: true});
      return;
    }
    const payload = {
      // oldname: selectedGroup.name,
      name: name,
      description: description,
    };

    PatchRequest(URL.groups+ '/' + selectedGroup.id,this.controller.signal,payload,(data)=> {
      this.handleGroup(null);
      this.editGroup(false);
    }, (err)=> {console.log(err)});
  };

  editGroup = (value) => {
    const { selectedGroup } = this.state;
    this.setState({
      edit: value,
      name: value ? selectedGroup.name : '',
      description: value ? selectedGroup.description : '',
      nameError: '',
    }, () => { !value && this.getGroups()})
  };

  handleSearch = (event) => {
    if (event.constructor.name === "SyntheticEvent") {
      const {name, value} = event.currentTarget;

      // Just set the search value in state. Additionally, call the api in the setState callback function,
      // upon clearing everything on search bar .
      this.setState(
        prevState => ({[name]: value}),
        () => {
          if (!value)
            this.getGroups(1, this.state.rowsPerPage);
        });
    }
    else {
      // Call api on hitting enter key.
      if (event.keyCode === Utils.enterKeyCode)
        this.getGroups(1, this.state.rowsPerPage)
    }
  }

  render() {
    const {t, classes, orgId } = this.props;

    const {openModal , groupList, groupCount, groupUserList, groupUserCount,
      page, pageUser, rowsPerPage, search, selectedGroup, edit, description,
      name, groupsLoading, usersLoading, deleteGroupModal, nameError } = this.state;

    return(
      <Grid container direction="column" key="grid1">
        {
          edit ?
            <React.Fragment>
              <Grid container direction="row" justify="space-between" alignItems="flex-start">
                <Button onClick={() => this.editGroup(false)} className={classes.editBack}><BackIcon />{t('admin.groups.back-btn')}</Button>
                <Button variant="outlined" className={classes.updateButton} onClick={() => this.updateGroup()}>{t('admin.groups.update-btn')}</Button>
              </Grid>
              <Grid item>
                <div className={classes.detailsTitle}>{t('admin.groups.details')}</div>
                <div className={classes.groupName} >{t('admin.groups.grp-name')}</div>
                <TextField
                  name="name"
                  id="name"
                  onChange={this.handleChange}
                  value={name}
                  variant='outlined'
                  className={classes.editName}
                  inputProps={{
                    style: {
                      padding: '10px'
                    },
                    maxLength: 64
                  }}
                  helperText={nameError ? t('admin.groups.msg.grp-name-empty') : null}
                  error={nameError !== ''}
                />
                <br />
                <div className={`${classes.groupName} ${classes.editGroupDescription}`}>{t('admin.groups.details')}</div>
                <TextField
                  name="description"
                  id="description"
                  onChange={this.handleChange}
                  value={description}
                  variant='outlined'
                  className={classes.editDescription}
                  inputProps={{ maxLength: 100,style: {padding: 0} }}
                  multiline
                />
                <p className={classes.helperText}>{t('admin.groups.msg.char-limit')}</p>
              </Grid>
            </React.Fragment>
            : (selectedGroup ?
            <React.Fragment>
              <Grid container direction="row" justify="space-between" alignItems="flex-start">
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="Breadcrumb">
                  <Link color="inherit"  className={classes.groupBread} onClick={() => this.handleGroup(null)}>
                  {t('admin.groups.title')}
                  </Link>
                  <Typography variant='h6' className="overflow-ellipsis" style={{maxWidth: '300px'}}>{selectedGroup.name}</Typography>
                </Breadcrumbs>
                <div>
                  <Button variant="outlined" className={classes.buttonRectangle} onClick={() => this.editGroup(true)}><b>{t('admin.groups.edit-details')}</b></Button>
                  <Button variant="outlined" className={classes.buttonRectangle} onClick={() => this.openDeleteGroupModal()}><b>{t('admin.groups.del-grp')}</b></Button>
                </div>
              </Grid>
              <Grid item>
                <Typography variant='h5' className={classes.nameTitle}><b>{selectedGroup.name}</b></Typography>
                <Typography variant='subtitle2' className={classes.description}>{selectedGroup.description}</Typography>
                <Typography variant='subtitle1'><b>{t('admin.groups.grp-mem')}</b></Typography>
                <Typography variant='h4' className={classes.userCount}>{groupUserCount}</Typography>
              </Grid>
              <Fragment>
                {
                  usersLoading?<div className={classes.loader}><CircularProgress className='loader' style={{ color: '#5fafff'}} size={20} /></div>:
                    <Grid>
                      <Table style={{tableLayout:'fixed'}}>
                        <TableHead>
                          <TableRow className={classes.tableHeader}>
                            <TableCell className={classes.colHeader}><b>{t('admin.groups.grp-mem')}</b></TableCell>
                            <TableCell className={classes.colHeader}><b>{t('admin.groups.table.role')}</b></TableCell>
                            <TableCell className={classes.colHeader}><b>{t('admin.groups.table.email')}</b></TableCell>
                            <TableCell className={classes.colHeader}><b>{t('admin.groups.table.status')}</b></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {groupUserList && groupUserList.map(row => (
                            <TableRow key={row["first_name"]}>
                              <TableCell component="th" scope="row">
                                <PersonDisplayIcon className={classes.tableIcon}/>
                                {row["first_name"] + ' '+ row['last_name']}
                              </TableCell>
                              <TableCell>{row.user_category}</TableCell>
                              <TableCell>{row.email}</TableCell>
                              <TableCell>{row.user_status.toLowerCase() === 'active' ? t('admin.groups.grp-act') : t('admin.groups.grp-inact')}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              rowsPerPageOptions={Utils.rowsPerPageOptions}
                              colSpan={4}
                              count={groupUserCount}
                              rowsPerPage={rowsPerPage}
                              labelRowsPerPage={t('admin.groups.table.per-page')}
                              page={pageUser}
                              SelectProps={{
                                inputProps: { 'aria-label': 'Results per page' },
                                native: true,
                              }}
                              onChangePage={this.handleChangePageUser}
                              onChangeRowsPerPage={this.handleChangeRowsPerPageUser}
                              style={{paddingTop: '20px'}}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </Grid>
                }
              </Fragment>
            </React.Fragment>
            :
            <React.Fragment>
              <Grid container direction="row" justify="space-between" alignItems="flex-start">
                <Typography variant='h6'>{t('admin.groups.title')}</Typography>
                <Button variant="outlined" className={classes.buttonAdd} onClick={() => this.modalOpen(true)}>
                  <GroupIcon className={classes.personIcon} />
                  {t('admin.groups.create-group-btn')}</Button>
              </Grid>
              <Grid>
                <TextField
                  name="search"
                  id="search"
                  onChange={this.handleSearch}
                  onKeyDown={this.handleSearch}
                  value={search}
                  className={classes.searchBar}
                  variant='outlined'
                  placeholder={t('admin.groups.search-placeholder')}
                  InputProps={{
                    endAdornment: <InputAdornment position="end"><SearchIcon style={{ color: 'grey'}}/></InputAdornment>
                  }}
                  inputProps={{
                    style: {
                      padding: '0.75rem 1rem'
                    }
                  }}
                />
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" className={classes.headTitle}>
                  <b>{t('admin.groups.sub')}</b>
                </Typography>
               </Grid>
              <Fragment>
                {
                  groupsLoading?<div className={classes.loader}><CircularProgress className='loader' style={{ color: '#5fafff'}} size={20} /></div>:
                    <Grid>
                      <Table style={{tableLayout:'fixed'}}>
                        <TableHead>
                          <TableRow className={classes.tableHeader}>
                            <TableCell className={classes.colHeader} style={{ paddingLeft: '0'}}>{t('admin.groups.table.grp-name')}</TableCell>
                            <TableCell className={classes.colHeader}>{t('admin.groups.table.desc')}</TableCell>
                            <TableCell className={classes.colHeader}>{t('admin.groups.table.members')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {groupList && groupList.map(row => (
                            <TableRow key={row.name} className={classes.groupClick} onClick={() => this.handleGroup(row)}>
                              <TableCell component="th" scope="row" className="overflow-ellipsis" style={{ paddingLeft: '0'}}>
                                <Tooltip title={row.name}><span>{row.name}</span></Tooltip>
                              </TableCell>
                              <TableCell className="overflow-ellipsis">{row.description}</TableCell>
                              <TableCell>{row.users}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              rowsPerPageOptions={Utils.rowsPerPageOptions}
                              colSpan={3}
                              count={groupCount}
                              rowsPerPage={rowsPerPage}
                              labelRowsPerPage={t('admin.groups.table.per-page')}
                              page={page}
                              SelectProps={{
                                inputProps: { 'aria-label': 'Results per page' },
                                native: true,
                              }}
                              onChangePage={this.handleChangePage}
                              onChangeRowsPerPage={this.handleChangeRowsPerPage}
                              style={{paddingTop: '20px'}}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </Grid>
                }
              </Fragment>
            </React.Fragment>)
        }
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          onClose={() => this.modalOpen(false)}
          open={openModal} >
          <React.Fragment>
            <CreateGroup
              orgId={orgId}
              modalOpen={this.modalOpen}
              getGroups={this.getGroups}
              />
          </React.Fragment>
        </Modal>
        <Modal open={deleteGroupModal}>
          <React.Fragment>
            <div className={classes.modal} key="inputForm">
              <div style={{padding: '20px'}}>
                <div className={classes.modalSubject}>
                {t('admin.groups.modal.title')}
                </div>
                <div className={classes.modalDescription}>
                  <div>{t('admin.groups.modal.txt1')}</div>
                  <div>{t('admin.groups.modal.txt2')}</div>
                </div>
                  <div className={classes.submit}>
                    <Button className={classes.actionButton} onClick={() => this.closeDeleteModal()}>{t('admin.groups.modal.cancel-btn')}</Button>
                    <Button className={classes.actionButton} onClick={() => this.deleteGroup(selectedGroup)}>{t('admin.groups.modal.remove-btn')}</Button>
                  </div>
              </div>
            </div>
          </React.Fragment>
        </Modal>
      </Grid>
    )
  }
}

export default withTranslation()(withStyles(styles)(Group));
